import { ReactElement } from 'react';
import { genuisAreas, WGSkills } from 'components/UsersList/UsersList';
import { WorkingGenuisIcon } from 'components';

export default function WGAssessment({ wgSkills }: { wgSkills?: WGSkills }): ReactElement {
  return (
    <div className="flex mx-2">
      {genuisAreas
        .filter((area) => area?.id !== '1')
        .map((area) => {
          if (wgSkills) {
            const values = Object.keys(wgSkills).filter((key) => wgSkills[key] === area.id);
            return (
              <div key={area.id} className="flex my-2 mx-1">
                <div className="relative flex">
                  <WorkingGenuisIcon color={area?.color} />
                  <div className="flex absolute text-xs capitalize w-6 h-6 mt-1 font-bold justify-around ">
                    <span>{values[1]?.charAt(0)}</span>
                  </div>
                </div>
                <div className=" relative flex">
                  <WorkingGenuisIcon color={area?.color} />
                  <div className="flex absolute text-xs capitalize w-6 h-6 mt-1 font-bold justify-around">
                    <span> {values[0]?.charAt(0)}</span>
                  </div>
                </div>
              </div>
            );
          }
        })}
    </div>
  );
}
