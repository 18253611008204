export type FileAttachment = {
  id?: number;
  url?: string;
  file_name?: string;
  status?: string;
  file_type?: string;
};

export enum FileStatus {
  InProgress = 'in-progress',
  Complete = 'complete',
  Failed = 'failed',
}
