import { NextRouter } from 'next/router';
import getTeamData from './getTeamData';

export default function getTeamId(router: NextRouter): string {
  let teamId = router.query.team as string;

  if (!teamId) {
    const teamData = getTeamData();
    teamId = teamData?.teamId;
  }

  return teamId;
}
