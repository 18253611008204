import { gql } from 'urql';
import { OBJECTIVE_FRAGMENT } from './fragments';

const GET_OBJECTIVE = gql`
  query GetObjective($id: Int!) {
    objectives_by_pk(id: $id) {
      ...ObjectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`;

export default GET_OBJECTIVE;
