import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { useRouter } from 'next/router';
import { Fragment, ReactElement } from 'react';
import { Team } from 'data/teams/types';
import { CheckIcon } from '@heroicons/react/solid';

type UserTeamsListProps = {
  teams: Team[];
  team: number;
};

export default function UserTeamsList({ teams, team }: UserTeamsListProps): ReactElement {
  const router = useRouter();
  const companyId = router.query.company;
  const menuItems = [];
  menuItems.push({
    label: 'View',
    onClick: async (id: number) => {
      router.push(`/company/${companyId}/team/${id}`);
    },
  });

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th scope="col" className="relative px-6 py-3 w-16">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {teams.map((_team) => {
                  const { id, name } = _team;
                  return (
                    <tr
                      key={id}
                      className="cursor-pointer"
                      onClick={() => router.push(`/company/${_team.company?.id}/team/${_team.id}`)}
                    >
                      <td className="h-12 px-6 whitespace-nowrap">
                        <div className="flex items-center">
                          {id === Number(team) ? <CheckIcon className="h-4 w-4 text-gray-900" /> : null}
                          <span className="ml-2">{name}</span>
                        </div>
                      </td>
                      <td className="h-12 w-16 whitespace-nowrap text-sm text-gray-500 flex border-l border-gray-200">
                        {menuItems.length ? (
                          <Menu as="div" className="inline-block text-left mx-3">
                            {({ open }) => (
                              <>
                                <div>
                                  <Menu.Button className="flex items-center">
                                    <span className="sr-only">Open options</span>
                                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    static
                                    className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    {menuItems.map((menuItem) => (
                                      <div className="py-1" key={menuItem.label}>
                                        <Menu.Item>
                                          <button
                                            type="button"
                                            className="block px-4 py-2 text-sm text-left w-full"
                                            onClick={async () => {
                                              await menuItem.onClick(id);
                                            }}
                                          >
                                            {menuItem.label}
                                          </button>
                                        </Menu.Item>
                                      </div>
                                    ))}
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
