import { gql } from 'urql';

const ADD_ORGS = gql`
  mutation AddOrgs($objects: [org_insert_input!] = {}) {
    insert_org(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export default ADD_ORGS;
