import { captureException } from '@sentry/nextjs';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import { Avatar, RichTextEditor, FileAttachmentDialog, ErrorToast, SuccessToast } from 'components';
import { CompanyUser, RoleTypes } from 'components/UsersList/UsersList';
import { Objective, ObjectiveStatus, ObjectiveStatusValue } from 'data/objectives/types';
import { Dispatch, Fragment, ReactElement, SetStateAction, useEffect, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import button from 'styles/button';
import { classNames } from 'utils';
import { useQuery, useMutation } from 'urql';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { GET_MEASURABLE } from 'data/measurables';
import { FileAttachment } from 'types/file-attachment';
import { ErrorToastError } from 'components/Toast/ErrorToast';
import { DELETE_OBJECTIVE_FILE } from 'data/objectives';
import { useSession } from 'next-auth/react';
import { filterUsersByRoles } from 'utils/filterUsers';
import GET_WEBHOOKS_BY_USER_AND_TYPE from 'data/actions/get-webhooks-by-user-and-type';

export const STATUS_OPTIONS: ObjectiveStatus[] = [
  { value: 'on-track', label: 'On Track' },
  { value: 'off-track', label: 'Off Track' },
  { value: 'complete', label: 'Done' },
];

export default function ObjectiveForm({
  form,
  setForm,
  users,
  objectiveFiles,
  refetchFiles,
  handleUserSelection,
}: {
  form: Objective;
  setForm: Dispatch<SetStateAction<Objective>>;
  users: CompanyUser[];
  objectiveFiles?: FileAttachment[];
  refetchFiles?;
  handleUserSelection?;
}): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const selectedUser = users.find(({ user }) => user.id === form.owner)?.user;

  // Fetch the  measurable
  const [measurableResult] = useQuery({
    query: GET_MEASURABLE,
    variables: { id: form?.measurable_id },
    pause: !form?.id && !form?.measurable_id,
  });

  const measurable = measurableResult.data?.measurables?.[0];
  const displayMeasurableLink = measurable?.id && measurable?.teams_id === Number(router.query.team);

  const [fileUploadDailogOpen, setFileUploadDailogOpen] = useState(false);
  const [, deleteObjectiveFile] = useMutation(DELETE_OBJECTIVE_FILE);
  const [error, setError] = useState<ErrorToastError>();
  const [deleteFile, setDeleteFile] = useState<string>();

  useEffect(() => {
    const found = users.find((v) => v.user_id === Number(session.id));
    if (handleUserSelection) {
      handleUserSelection(found);
    }
  }, []);

  return (
    <>
      <div className="p-6 overflow-auto flex-1">
        <form className="w-full">
          <div className="w-full">
            <label htmlFor="title" className="block text-xs text-gray-700">
              Title
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full text-sm border-gray-300 rounded-md"
                defaultValue={form.title}
                onChange={(event) => {
                  setForm({
                    ...form,
                    title: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row mt-4 gap-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="description" className="block text-xs text-gray-700">
                Description
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.description}
                  onChange={(content) => {
                    setForm({
                      ...form,
                      description: content,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="key-results" className="block text-xs text-gray-700">
                Key Results
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.key_results}
                  onChange={(content) => {
                    setForm({
                      ...form,
                      key_results: content,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col lg:flex-row mt-4 gap-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="30-day" className="block text-xs text-gray-700">
                30-Day Milestones
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.column_30_day}
                  onChange={(content) => {
                    setForm({
                      ...form,
                      column_30_day: content,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="60-day" className="block text-xs text-gray-700">
                60-Day Milestones
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.column_60_day}
                  onChange={(content) => {
                    setForm({
                      ...form,
                      column_60_day: content,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {form?.actions?.length > 0 ? (
            <div className="mt-4">
              <label htmlFor="actions" className="block text-xs text-gray-700">
                Actions
              </label>
              <div className="mt-1 text-xs text-blue-500 font-semibold rounded-md border border-gray-300  pl-2 py-2 max-h-28 overflow-y-auto">
                {form.actions
                  .filter((action) => action.teams_id === Number(router.query.team))
                  .map((action) => {
                    return (
                      <div key={action.id} className="flex space-x-2 mt-1">
                        <span> . </span>
                        <div className="underline">
                          <Link
                            href={`/company/${router.query.company}/team/${router.query.team}/actions/${action?.id}`}
                            legacyBehavior
                          >
                            {action.title}
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}

          {displayMeasurableLink ? (
            <div className="mt-4 text-xs text-blue-500 font-semibold underline">
              <Link href={`/company/${router.query.company}/team/${router.query.team}/scoreboard/${measurable?.id}`}>
                Measurable link
              </Link>
            </div>
          ) : null}

          <div className="w-full flex flex-col lg:flex-row mt-4 gap-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="location" className="block text-xs text-gray-700">
                Status
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 block w-full pl-3 pr-10 h-12 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary rounded-md"
                defaultValue={form.status}
                onChange={(event) => {
                  setForm({
                    ...form,
                    status: event.target.value as ObjectiveStatusValue,
                  });
                }}
              >
                {!form.status ? <option value={null} disabled selected></option> : null}
                {STATUS_OPTIONS.map((status) => (
                  <option key={status.value} value={status.value}>
                    {status.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col flex-1">
              <label htmlFor="due-date" className="block text-xs text-gray-700">
                Due Date
              </label>
              <div className="mt-1">
                <input
                  type="date"
                  name="due-date"
                  id="due-date"
                  className="focus:ring-primary focus:border-primary text-sm block w-full border-gray-300 rounded-md h-12"
                  defaultValue={form.due}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      due: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <Listbox
                value={form.owner}
                onChange={(value) => {
                  setForm({
                    ...form,
                    owner: value,
                  });

                  console.log('called');

                  const found = users.find((v) => v.user_id === value);
                  console.log({ found });
                  if (handleUserSelection) {
                    handleUserSelection(found);
                  }
                }}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-xs text-gray-700">Owner</Listbox.Label>
                    <div className="mt-1">
                      <Listbox.Button
                        className={classNames(
                          button.white(),
                          'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                        )}
                      >
                        {selectedUser ? (
                          <span className="flex items-center">
                            <Avatar user={selectedUser} showName={true} />
                          </span>
                        ) : (
                          <span className="flex items-center text-gray-500">Select a user</span>
                        )}
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          static
                          className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                          {filterUsersByRoles(users, [RoleTypes.ADMIN, RoleTypes.MEMBER]).map(({ user }) => (
                            <Listbox.Option
                              key={user.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-gray-100' : '',
                                  'cursor-default select-none relative py-2 pl-3 pr-9',
                                )
                              }
                              value={user.id}
                            >
                              {({ selected }) => (
                                <>
                                  <div className="flex items-center mr-2">
                                    <Avatar user={user} showName={true} />
                                  </div>

                                  {selected ? (
                                    <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <>
            <div className="block text-xs text-gray-700 mt-4">File Attachments</div>
            <div className="mt-1 rounded-md border border-gray-300 px-4 w-full">
              <div className="flex justify-end mt-2 mb-6">
                <button
                  type="button"
                  className={classNames(
                    button.primary(),
                    'inline-flex items-center justify-center rounded-md shadow-sm text-sm font-semibold text-white ',
                  )}
                  onClick={() => {
                    setFileUploadDailogOpen(true);
                  }}
                >
                  Add Files
                </button>
              </div>
              <>
                <div className="flex text-xs font-semibold text-gray-500 uppercase bg-gray-100 h-8 w-full">
                  <div className="w-5/6 py-2 ml-2"> File Name</div>
                  <div
                    className={classNames(objectiveFiles?.length > 2 ? '-ml-4' : '', 'flex w-1/6 py-2 justify-center')}
                  >
                    Delete
                  </div>
                </div>
                {objectiveFiles?.length > 0 ? (
                  <div className="max-h-28 overflow-y-auto">
                    <div className="mt-2 text-xs text-blue-500 font-semibold mb-2">
                      {objectiveFiles
                        .filter((file) => file.url !== null)
                        .map((file) => {
                          return (
                            <div key={file.id} className="mt-3">
                              <div className="flex">
                                <div className="w-5/6 truncate">
                                  <Link href={file.url} className="ml-2" target="_blank">
                                    {file.file_name}
                                  </Link>
                                </div>
                                <div className="flex w-1/6 justify-center">
                                  <button
                                    type="button"
                                    onClick={async () => {
                                      // Delete file
                                      const objectiveId = form.id;
                                      const { error: deleteError } = await deleteObjectiveFile({
                                        fileId: file.id,
                                        id: objectiveId,
                                        activity: 'update',
                                        timestamp: Date.now(),
                                        userId: session.id,
                                      });
                                      refetchFiles({ variables: { objectiveId } });
                                      const key = 'objective'.concat('_').concat(file?.id?.toString());
                                      await fetch(`/api/s3/file-delete`, {
                                        method: 'POST',
                                        headers: {
                                          'content-type': 'application/json',
                                        },
                                        body: JSON.stringify({ key: key }),
                                      });
                                      if (deleteError) {
                                        setError(deleteError);
                                        captureException(deleteError);
                                      } else {
                                        setDeleteFile('File Deleted');
                                      }
                                    }}
                                  >
                                    <XIcon fill="red" className="h-4 w-4 mb-0.5" />
                                  </button>
                                </div>
                              </div>
                              <div className="mt-2 w-full border-t border-gray-300" />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center mb-6">
                    <span className="mt-4 text-sm font-semibold text-gray-400 items-center">
                      There are no files at this time
                    </span>
                  </div>
                )}
              </>
            </div>
          </>
          {form.created_at ? (
            <div className="mt-6 text-center text-gray-400 text-sm">
              <span>Created on {new Date(form.created_at).toLocaleDateString()}</span>
            </div>
          ) : null}
        </form>
      </div>
      <FileAttachmentDialog
        entityType={'objective'}
        open={fileUploadDailogOpen}
        setOpen={setFileUploadDailogOpen}
        refetchFiles={refetchFiles}
        form={form}
        setForm={setForm}
      />
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={deleteFile} setMessage={setDeleteFile} />
    </>
  );
}
