export default function getPluralEntity(entity: string): string {
  switch (entity) {
    case 'action':
    case 'Action':
    case 'objective':
    case 'Objective':
      return `${entity}s`;
    case 'opportunity':
    case 'Opportunity':
      return `${entity.substr(0, entity.length - 1)}ies`;
    default:
      return entity;
  }
}
