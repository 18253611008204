import { gql } from 'urql';

const GET_INVITE_BY_EMAIL = gql`
  query GetInviteByEmail($email: String!, $company_id: Int!) {
    invites(where: { email: { _eq: $email }, company_id: { _eq: $company_id } }) {
      id
    }
  }
`;

export default GET_INVITE_BY_EMAIL;
