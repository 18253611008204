import { gql } from 'urql';

const UPDATE_ACTION = gql`
  mutation UpdateAction($_set: actions_set_input!, $id: Int!, $activity: String, $timestamp: bigint, $userId: Int) {
    update_actions_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }

    insert_actions_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default UPDATE_ACTION;
