import { Transition } from '@headlessui/react';
import { Fragment, ReactElement, ReactNode, useEffect } from 'react';

type ToastProps = {
  open: boolean;
  content: ReactNode;
  onClose?: () => void;
  time?: number;
};

Toast.defaultProps = {
  time: 3000,
};

export default function Toast({ open, onClose, content, time }: ToastProps): ReactElement {
  useEffect(() => {
    let timeout;
    if (open) {
      timeout = setTimeout(() => {
        onClose?.();
      }, time);
    }

    () => clearTimeout(timeout);
  }, [open, onClose, time]);

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      <div className="w-full left-1/2 transform -translate-x-1/2 bottom-10 absolute flex items-center justify-center gap-x-2 gap-y-2">
        <Transition
          show={open}
          as={Fragment}
          enter="transform ease-out duration-300 transition"
          enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enterTo="translate-y-0 opacity-100 sm:translate-x-0"
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {content}
        </Transition>
      </div>
    </div>
  );
}
