import { gql } from 'urql';

const ADD_ORG = gql`
  mutation AddOrg($object: org_insert_input!) {
    insert_org_one(object: $object) {
      id
    }
  }
`;

export default ADD_ORG;
