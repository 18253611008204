import Image from 'next/image';
import { ReactElement } from 'react';
import { classNames } from 'utils';

export default function S2HalfScreenImage({
  src,
  preText,
  postText,
  background,
}: {
  src?: string;
  preText?: string | ReactElement;
  postText?: string | ReactElement;
  background?: string;
}): ReactElement {
  return (
    <div
      className={classNames(
        background || 'bg-gray-400',
        'flex flex-col lg:block relative flex-1 space-around xl:px-36',
      )}
    >
      {preText ? (
        <div className="text-gray-600 flex flex-col h-1/3 items-start justify-end mt-10 mx-16 xl:mx-0 text-4xl font-bold">
          {preText}
        </div>
      ) : null}
      {src ? (
        <div
          className={classNames(
            preText ? 'xl:ml-24' : 'md:mt-48',
            !preText && !postText ? 'h-1/2' : 'h-1/3',
            'mt-10 relative',
          )}
        >
          <Image
            className="object-contain"
            src={src || '/system-and-soul-illustration.png'}
            alt=""
            fill
            sizes="100vw"
          />
        </div>
      ) : null}
      {postText ? (
        <div className="text-gray-600 flex flex-col h-1/3 items-center justify-start mt-10 mx-32 xl:mx-0 text-4xl font-bold">
          {postText}
        </div>
      ) : null}
    </div>
  );
}
