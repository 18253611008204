import { gql } from 'urql';

const GET_COMPANY_BY_NAME = gql`
  query GetCompany($name: String) {
    companies(where: { name: { _ilike: $name } }) {
      id
    }
  }
`;

export default GET_COMPANY_BY_NAME;
