import { gql } from 'urql';

const ADD_COMPANY_USER = gql`
  mutation AddCompanyUser($company_id: Int!, $role_id: Int!, $user_id: Int!) {
    insert_company_users_one(object: { company_id: $company_id, role_id: $role_id, user_id: $user_id }) {
      affected_rows
    }
  }
`;

export default ADD_COMPANY_USER;
