import { gql } from 'urql';

const GET_USER_PROFILE_IMAGE_FILES = gql`
  query GetUserProfileImageFiles($where: user_profile_image_files_bool_exp!) {
    user_profile_image_files(where: $where, order_by: [{ updated_at: desc }]) {
      id
      url
      file_name
      status
      file_type
    }
  }
`;

export default GET_USER_PROFILE_IMAGE_FILES;
