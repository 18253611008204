import { Bet, Roadmap } from 'components/Roadmap/types';
import { Objective } from 'data/objectives/types';
import { ReactElement, useMemo } from 'react';

type RoadmapPreviewProps = {
  roadmap: Roadmap;
  bets: Bet[];
  objectives: Objective[];
};

export default function RoadmapPreview({ roadmap, bets, objectives }: RoadmapPreviewProps): ReactElement {
  const objectivesArr = useMemo(() => {
    const arr = Array(4).fill('');
    objectives?.slice(0, 10).forEach((obj, i) => {
      arr[i] = obj.title;
    });

    return arr;
  }, [objectives]);

  return (
    <div className="absolute pt-6 left-0 h-print-v w-print-v">
      <div className="overflow-auto mx-2">
        <div className="flex justify-center text-gray-900 text-4xl mt-0 font-bold whitespace-nowrap">
          {`${roadmap?.title || 'S2'} Road Map`.toUpperCase()}
        </div>
        <div className="flex flex-row items-start w-full mb-12 justify-between">
          <div className="flex flex-col ml-4">
            <div className="mt-8">
              <label
                htmlFor="onliness.0"
                className="mt-2 text-sm font-semibold text-gray-700 flex items-start justify-end relative"
              >
                <span className="whitespace-nowrap">We are the only</span>
                <div className="ml-1 relative">
                  <textarea
                    disabled
                    name="onliness.0"
                    id="onliness.0"
                    className="block text-sm w-64 border-0 rounded-none focus:ring-0 shadow-none p-0 resize-none"
                    defaultValue={roadmap?.onliness?.[0] || ''}
                    maxLength={60}
                    rows={2}
                  />
                </div>
              </label>
              <label
                htmlFor="onliness.1"
                className="mt-2 text-sm font-semibold text-gray-700 flex items-start justify-end relative"
              >
                that
                <div className="ml-1 relative">
                  <textarea
                    disabled
                    name="onliness.1"
                    id="onliness.1"
                    className="block text-sm w-64 border-0 rounded-none focus:ring-0 shadow-none p-0 resize-none"
                    defaultValue={roadmap?.onliness?.[1] || ''}
                    maxLength={60}
                    rows={2}
                  />
                </div>
              </label>
            </div>
            <div className="mt-6">
              <label
                htmlFor="destination.0"
                className="mt-2 text-sm font-semibold text-gray-700 flex items-start justify-end relative"
              >
                We are going
                <div className="ml-1 relative">
                  <textarea
                    disabled
                    name="destination.0"
                    id="destination.0"
                    className="block text-sm w-64 border-0 rounded-none focus:ring-0 shadow-none p-0 resize-none"
                    defaultValue={roadmap?.destination?.[0] || ''}
                    maxLength={60}
                    rows={2}
                  />
                </div>
              </label>
              <label
                htmlFor="destination.1"
                className="mt-2 text-sm font-semibold text-gray-700 flex items-start justify-end relative"
              >
                by
                <div className="ml-1 relative">
                  <input
                    disabled
                    type="text"
                    name="destination.1"
                    id="destination.1"
                    className="block text-sm w-64 border-0 rounded-none focus:ring-0 shadow-none p-0"
                    maxLength={24}
                    defaultValue={roadmap?.destination?.[1] || ''}
                  />
                </div>
              </label>
              <label
                htmlFor="destination.2"
                className="mt-2 text-sm font-semibold text-gray-700 flex items-start justify-end relative"
              >
                because
                <div className="ml-1 relative">
                  <textarea
                    disabled
                    name="destination.2"
                    id="destination.2"
                    className="block text-sm w-64 border-0 rounded-none focus:ring-0 shadow-none p-0 resize-none"
                    defaultValue={roadmap?.destination?.[2] || ''}
                    maxLength={60}
                    rows={2}
                  />
                </div>
              </label>
            </div>
          </div>
          <div className="block relative flex-half h-full transform -translate-y-12 scale-75">
            <div className="block relative flex-half h-full">
              <div className="roadmap-circle absolute top-2 left-1/4 transform -translate-x-12">
                <div className="font-bold absolute w-48 left-1/4 top-1/4 transform -translate-x-5 -translate-y-10 text-center text-primary">
                  What we are deeply passionate about
                </div>
                <div className="w-36 absolute roadmap-lines-2-md top-1/3 left-1/2 transform -translate-x-1/2">
                  <textarea
                    disabled
                    name="venns.0"
                    id="venns.0"
                    className="w-full text-sm overflow-hidden block border-0 rounded-none focus:ring-0 shadow-none p-0 bg-transparent resize-none"
                    defaultValue={roadmap?.venns?.[0] || ''}
                    maxLength={30}
                    rows={2}
                  />
                </div>
              </div>
              <div className="roadmap-circle absolute top-44 -left-1/4 transform translate-x-16">
                <div className="font-bold absolute left-4 w-40 top-1/3 transform -translate-x-2 translate-y-4 text-center text-primary">
                  What we can be best in the world at
                </div>
                <div className="w-36 absolute roadmap-lines-2-md top-1/2 transform translate-y-6 left-6">
                  <textarea
                    disabled
                    name="venns.1"
                    id="venns.1"
                    className="w-full text-sm overflow-hidden block border-0 rounded-none focus:ring-0 shadow-none p-0 bg-transparent resize-none"
                    defaultValue={roadmap?.venns?.[1] || ''}
                    maxLength={30}
                    rows={2}
                  />
                </div>
              </div>
              <div className="roadmap-circle relative top-44 left-1/3 transform translate-x-2">
                <div className="font-bold absolute text-center right-0 w-48 top-1/3 transform translate-y-4 text-primary">
                  What drives our economic engine
                </div>
                <div className="w-36 absolute roadmap-lines-2-md top-1/2 transform translate-y-6 right-6">
                  <textarea
                    disabled
                    name="venns.2"
                    id="venns.2"
                    className="w-full text-sm overflow-hidden block border-0 rounded-none focus:ring-0 shadow-none p-0 bg-transparent resize-none"
                    defaultValue={roadmap?.venns?.[2] || ''}
                    maxLength={30}
                    rows={2}
                  />
                </div>
              </div>
              <div className="font-bold absolute left-1/3 top-3/4 transform translate-x-8 translate-y-8 text-primary">
                CLARITY
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start justify-center space-x-2">
          <div className="flex flex-col space-y-1">
            <div className="w-64 ml-1 relative leading-5 text-gray-800 text-sm font-semibold">Values</div>
            {Array.isArray(roadmap?.values) &&
              roadmap.values.map((value, i) => (
                <div key={`value-${i}`} className="ml-1 relative">
                  <input
                    disabled
                    type="text"
                    name={`values.${i}`}
                    id={`values.${i}`}
                    className="block text-sm w-64 border-0 rounded-none focus:ring-0 shadow-none p-0"
                    maxLength={24}
                    defaultValue={roadmap?.values?.[i]?.title || ''}
                  />
                </div>
              ))}
          </div>
          <div className="flex flex-col space-y-1">
            <div className="w-64 ml-1 relative leading-5 text-gray-800 text-sm font-semibold">+ Org Habits</div>
            {Array.isArray(roadmap?.habits) &&
              roadmap.habits.map((habit, i) => (
                <div key={`habit-${i}`} className="ml-1 relative">
                  <input
                    disabled
                    type="text"
                    name={`habits.${i}`}
                    id={`habits.${i}`}
                    className="block text-sm w-64 border-0 rounded-none focus:ring-0 shadow-none p-0"
                    maxLength={24}
                    defaultValue={roadmap?.habits?.[i]?.title || ''}
                  />
                </div>
              ))}
          </div>
          <div className="flex flex-col space-y-1">
            <div className="w-64 ml-1 relative leading-5 text-gray-800 text-sm font-semibold">= Culture</div>
            {Array.isArray(roadmap?.culture) &&
              roadmap.culture.map((c, i) => (
                <div key={`culture-${i}`} className="ml-1 relative">
                  <input
                    disabled
                    type="text"
                    name={`culture.${i}`}
                    id={`culture.${i}`}
                    className="block text-sm w-64 border-0 rounded-none focus:ring-0 shadow-none p-0"
                    maxLength={24}
                    defaultValue={roadmap?.culture?.[i]?.title || ''}
                  />
                </div>
              ))}
          </div>
        </div>
        <div className="flex justify-center w-full mt-4 px-4">
          <div className="relative w-full">
            <div className="absolute inset-0 flex items-center" aria-hidden="true">
              <div className="w-full border-t-2 border-primary" />
            </div>
            <div className="relative flex justify-center">
              <span className="px-2 bg-white text-gray-800 font-semibold">Bets</span>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-start mt-2 justify-center space-x-4">
          {bets.map((bet, i) => (
            <div key={`bet-${i}`} className="flex">
              <div className="flex flex-col">
                <div className="ml-1 w-20">
                  <label
                    htmlFor={`bets.${i}.date`}
                    className="text-sm font-semibold text-gray-800 flex items-start justify-end relative"
                  >
                    Date:
                    <div className="ml-1 mb-1 relative">
                      <input
                        disabled
                        type="text"
                        name={`bets.${i}.date`}
                        id={`bets.${i}.date`}
                        className="w-12 text-sm border-0 rounded-none focus:ring-0 shadow-none p-0"
                        maxLength={12}
                        defaultValue={bets?.[i].date || ''}
                      />
                    </div>
                  </label>
                </div>
                <div className="ml-1 w-20">
                  <label
                    htmlFor={`bets.${i}.revenue`}
                    className="text-sm font-semibold text-gray-800 flex items-start justify-end relative"
                  >
                    R:
                    <div className="ml-1 mb-1 relative">
                      <input
                        disabled
                        type="text"
                        name={`bets.${i}.revenue`}
                        id={`bets.${i}.revenue`}
                        className="w-12 text-sm border-0 rounded-none focus:ring-0 shadow-none p-0"
                        maxLength={12}
                        defaultValue={bets?.[i].revenue || ''}
                      />
                    </div>
                  </label>
                </div>
                <div className="ml-1 w-20">
                  <label
                    htmlFor={`bets.${i}.profit`}
                    className="text-sm font-semibold text-gray-800 flex items-start justify-end relative"
                  >
                    P:
                    <div className="ml-1 mb-1 relative">
                      <input
                        disabled
                        type="text"
                        name={`bets.${i}.profit`}
                        id={`bets.${i}.profit`}
                        className="w-12 text-sm border-0 rounded-none focus:ring-0 shadow-none p-0"
                        maxLength={12}
                        defaultValue={bets?.[i].profit || ''}
                      />
                    </div>
                  </label>
                </div>
                <div className="ml-1 w-20">
                  <label
                    htmlFor={`bets.${i}.economic`}
                    className="text-sm font-semibold text-gray-800 flex items-start justify-end relative"
                  >
                    EE:
                    <div className="ml-1 mb-1 relative">
                      <input
                        disabled
                        type="text"
                        name={`bets.${i}.economic`}
                        id={`bets.${i}.economic`}
                        className="w-12 text-sm border-0 rounded-none focus:ring-0 shadow-none p-0"
                        maxLength={12}
                        defaultValue={bets?.[i].economic || ''}
                      />
                    </div>
                  </label>
                </div>
              </div>
              {i < 2 ? (
                <div className="flex flex-col w-40">
                  {bet.objectives?.map((objective, j) => (
                    <div key={`bet-${i}-objective-${j}`} className="ml-1 text-sm">
                      <label
                        htmlFor={`bets.${i}.objective.${j}`}
                        className="text-sm font-semibold text-gray-800 flex items-start justify-start"
                      >
                        <div className="ml-1 mb-1 relative">
                          <input
                            disabled
                            type="text"
                            name={`bets.${i}.objective.${j}`}
                            id={`bets.${i}.objective.${j}`}
                            className="block border-0 text-sm rounded-none focus:ring-0 shadow-none p-0 flex-auto w-full"
                            maxLength={24}
                            defaultValue={objective?.title || ''}
                          />
                        </div>
                      </label>
                    </div>
                  ))}
                </div>
              ) : null}
              {i === 2 ? (
                <div className="flex flex-col w-40">
                  {objectivesArr?.map((objective, j) => (
                    <div key={`objective-${j}`} className="ml-1 text-sm">
                      <label
                        htmlFor={`bets.${i}.objective.${j}`}
                        className="text-sm font-semibold text-gray-800 flex items-start justify-start"
                      >
                        <div className="ml-1 mb-1 relative">
                          <span
                            id={`bets.${i}.objective.${j}`}
                            className="h-5 block flex-auto font-normal overflow-hidden whitespace-nowrap w-36 overflow-ellipsis"
                          >
                            {objective || ''}
                          </span>
                        </div>
                      </label>
                    </div>
                  ))}
                  {objectives?.length > 10 ? <div className="ml-2">+{objectives.length - 10} more</div> : null}
                </div>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
