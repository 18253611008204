export default function getScoreColor(
  score: string | number,
  goal: string | number,
  conditional: string,
  min_range: string | number,
  max_range: string | number,
): { fg: string; bg: string } {
  const green = 'text-green-800';
  const bgGreen = 'bg-green-50';
  const red = 'text-red-800';
  const bgRed = 'bg-red-50';
  const fallback = 'text-gray-900';
  const bgFallback = 'bg-white';

  if (score === undefined || score === null) {
    return { fg: fallback, bg: bgFallback };
  }
  switch (conditional) {
    case 'gte':
      if (Number(score) >= Number(goal)) {
        return { fg: green, bg: bgGreen };
      }
      break;
    case 'gt':
      if (Number(score) > Number(goal)) {
        return { fg: green, bg: bgGreen };
      }
      break;
    case 'eq':
      if (Number(score) === Number(goal)) {
        return { fg: green, bg: bgGreen };
      }
      break;
    case 'lt':
      if (Number(score) < Number(goal)) {
        return { fg: green, bg: bgGreen };
      }
      break;
    case 'lte':
      if (Number(score) <= Number(goal)) {
        return { fg: green, bg: bgGreen };
      }
      break;
    case 'range':
      if ((max_range !== 0 && !max_range) || (min_range !== 0 && !min_range)) {
        return { fg: fallback, bg: bgFallback };
      } else if (Number(score) <= Number(max_range) && Number(score) >= Number(min_range)) {
        return { fg: green, bg: bgGreen };
      }
      break;
    default:
      return { fg: fallback, bg: bgFallback };
  }

  return { fg: red, bg: bgRed };
}
