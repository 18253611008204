import { OpportunityType } from './types';

export { default as ADD_OPPORTUNITY } from './add-opportunity';
export { default as ADD_OPPORTUNITIES } from './add-opportunities';
export { default as GET_OPPORTUNITIES } from './get-opportunities';
export { default as GET_OPPORTUNITY } from './get-opportunity';
export { default as UPDATE_OPPORTUNITIES } from './update-opportunities';
export { default as UPDATE_OPPORTUNITY } from './update-opportunity';
export { default as ADD_OPPORTUNITY_COMMENTS } from './add-opportunity-comments';
export { default as UPDATE_OPPORTUNITY_COMMENT } from './update-opportunity-comment';
export { default as GET_OPPORTUNITY_COMMENTS } from './get-opportunity-comments';
export { default as DELETE_OPPORTUNITY_COMMENT } from './delete-opportunity-comment';
export { default as GET_OPPORTUNITY_FILES } from './get-opportunity-files';
export { default as ADD_OPPORTUNITY_FILE } from './add-opportunity-file';
export { default as UPDATE_OPPORTUNITY_FILE } from './update-opportunity-file';
export { default as DELETE_OPPORTUNITY_FILE } from './delete-opportunity-file';

export const OPPORTUNITY_TYPE_OPTIONS: OpportunityType[] = [
  { value: 'awareness', label: 'Awareness' },
  { value: 'analyze', label: 'Analyze' },
  { value: 'design', label: 'Design' },
  { value: 'people', label: 'People' },
  { value: 'future', label: 'Future' },
  { value: 'na', label: 'N/A' },
];

export enum OpportunityTypes {
  All = 'all',
  Future = 'future',
  Awareness = 'awareness',
}

export enum SortStatus {
  Default = 'default',
  Down = 'down',
  Up = 'up',
}

export enum SortFields {
  Priority = 'priority',
  Type = 'type',
  Assignee = 'user.name',
}
