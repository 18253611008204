import { gql } from 'urql';

const UPDATE_MEETINGS = gql`
  mutation UpdateMeetings($_set: meetings_set_input!, $ids: [Int!]!) {
    update_meetings(where: { id: { _in: $ids } }, _set: $_set) {
      affected_rows
    }
  }
`;

export default UPDATE_MEETINGS;
