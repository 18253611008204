import { gql } from 'urql';

const UPDATE_ORG = gql`
  mutation UpdateOrg($set: org_set_input, $id: Int!) {
    update_org_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

export default UPDATE_ORG;
