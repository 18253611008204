import { Dialog, Transition, Listbox } from '@headlessui/react';
import { XIcon, CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { ErrorToast, SuccessToast } from 'components';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation, OperationContext } from 'urql';
import { classNames, refreshData } from 'utils';
import { UPDATE_COMPANY_USER } from 'data/company';
import { genuisAreas, skillTypes } from 'components/UsersList/UsersList';

type WGProfileDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  enabledVOPSProfile: boolean;
  selectedWGSkills;
  setSelectedWGSkills;
  userInfo;
  refetchUsers: (opts?: Partial<OperationContext>) => void;
  setVOPSDialogOpen;
  setOtherAssessmentsDialogOpen;
};

export const wgSkills = ['wonder', 'invention', 'discernment', 'galvanizing', 'enablement', 'tenacity'];

// eslint-disable-next-line
export function validate(selectedWGSkills, setValidity, setValidationMsg): boolean {
  let validity = true;
  if (Object.keys(selectedWGSkills).filter((key) => selectedWGSkills[key] === '1').length > 0) {
    setValidity(false);
    setValidationMsg('Please enter an Area for each Type of Genius');
    return !validity;
  }
  genuisAreas.forEach((area) => {
    if (Object.keys(selectedWGSkills).filter((key) => selectedWGSkills[key] === area.id).length > 2) {
      setValidity(false);
      setValidationMsg('Please choose no more than 2 Areas');
      validity = false;
      return true;
    }
  });
  return validity;
}

export default function WGProfileDialog({
  open,
  setOpen,
  enabledVOPSProfile,
  userInfo,
  selectedWGSkills,
  setSelectedWGSkills,
  refetchUsers,
  setVOPSDialogOpen,
  setOtherAssessmentsDialogOpen,
}: WGProfileDialogProps): ReactElement {
  const router = useRouter();
  const [, updateCompanyUser] = useMutation(UPDATE_COMPANY_USER);
  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();
  const cancelButtonRef = useRef(null);

  const [validity, setValidity] = useState<boolean>(true);
  const [validationMsg, setValidationMsg] = useState<string>('');

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={() => {
            setOpen(false);
            setValidity(true);
          }}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative">
                {/* Empty div to get rounded corners. Can't put overflow on form because of dropdowns */}
                <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden"></div>
                <form
                  className="align-bottom text-left background-transparent shadow-xl transform transition-all sm:align-middle"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    const isValid = validate(selectedWGSkills, setValidity, setValidationMsg);
                    if (!isValid) {
                      return 1;
                    }
                    const { error: err } = await updateCompanyUser({
                      ids: [userInfo?.id],
                      _set: {
                        wg_skills: selectedWGSkills,
                      },
                    });

                    if (err) {
                      setError(err);
                    } else {
                      const companyId = router.query.company;
                      refetchUsers({ variables: { companyId } });
                      setUpdated(`WG skills added`);
                    }
                    setValidity(true);
                    setOpen(false);
                    refreshData(router);
                  }}
                >
                  <div className="flex items-center justify-between  w-full">
                    <div className="flex text-md font-semibold text-gray-900 ml-6 mt-4">
                      {enabledVOPSProfile ? (
                        <Listbox
                          value={'1'}
                          onChange={async (value: string) => {
                            if (value === '2') {
                              setOpen(false);
                              setVOPSDialogOpen(true);
                            }
                            if (value === '3') {
                              setOpen(false);
                              setOtherAssessmentsDialogOpen(true);
                            }
                            setValidity(true);
                          }}
                        >
                          {({ open: _open }) => (
                            <div className="flex space-x-4">
                              <div className="relative">
                                <Listbox.Button
                                  className={classNames(
                                    button.white({ withBase: false }),
                                    'relative w-64 h-8 rounded-md shadow-sm pr-7 text-left cursor-default text-sm py-1 font-semibold text-gray-900 mb-2',
                                  )}
                                >
                                  {skillTypes.some((_area) => _area.id === '1') ? (
                                    <span className="flex items-center">
                                      <span className="ml-3 block truncate">
                                        {skillTypes.find((_area) => _area.id === '1')?.name}
                                      </span>
                                    </span>
                                  ) : (
                                    <span className="flex items-center ml-3 text-gray-500">Select skill type</span>
                                  )}
                                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={_open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    {skillTypes.map((_area) => {
                                      return (
                                        <Listbox.Option
                                          key={_area.id}
                                          className={({ active }) =>
                                            classNames(
                                              active ? 'bg-gray-100' : '',
                                              'cursor-default select-none relative py-2 pr-9',
                                            )
                                          }
                                          value={_area.id}
                                        >
                                          {({ selected }) => (
                                            <>
                                              <div className="flex items-center">
                                                <span
                                                  className={classNames(
                                                    selected ? 'font-semibold' : 'font-normal',
                                                    'ml-3 block truncate text-xs',
                                                  )}
                                                >
                                                  {_area.name}
                                                </span>
                                              </div>

                                              {selected ? (
                                                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      );
                                    })}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </div>
                          )}
                        </Listbox>
                      ) : (
                        'Working Genius'
                      )}
                    </div>
                    <XIcon
                      className="cursor-pointer fill-current text-gray-400 h-6 w-6  mr-2"
                      onClick={() => {
                        setOpen(false);
                        setValidity(true);
                      }}
                    />
                  </div>
                  <div className="mt-1 ml-6 w-80 text-xs mr-2">
                    These are {userInfo?.name || 'user'}
                    {`'s`} Working Genius Skills. Select up to two options to be displayed on {userInfo?.name || 'user'}
                    {`'s`} profile.
                  </div>

                  <div className="flex flex-col p-6">
                    {wgSkills.map((wgSkill, i) => {
                      return (
                        <div key={i} className="flex items-center">
                          <div className="flex mt-2">
                            <Listbox
                              value={selectedWGSkills[wgSkill] || '1'}
                              onChange={async (value: string) => {
                                setSelectedWGSkills({ ...selectedWGSkills, [wgSkill]: value });
                                setValidity(true);
                              }}
                            >
                              {({ open: _open }) => (
                                <div className="flex space-x-4">
                                  <Listbox.Label className="block text-xs text-gray-700 mt-2 w-24 capitalize font-semibold">
                                    {wgSkill}
                                  </Listbox.Label>
                                  <div className="relative">
                                    <Listbox.Button
                                      className={classNames(
                                        button.white({ withBase: false }),
                                        'relative w-40 h-8 rounded-md shadow-sm pr-7 text-left cursor-default text-xs py-1',
                                      )}
                                    >
                                      {selectedWGSkills[wgSkill] ? (
                                        <span className="flex items-center">
                                          <span className="ml-3 block truncate">
                                            {genuisAreas.find((_area) => _area.id === selectedWGSkills[wgSkill])?.name}
                                          </span>
                                        </span>
                                      ) : (
                                        <span className="flex items-center ml-3 text-gray-500">Area</span>
                                      )}
                                      <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={_open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options
                                        static
                                        className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                      >
                                        {genuisAreas.map((_area) => {
                                          return (
                                            <Listbox.Option
                                              key={_area.id}
                                              className={({ active }) =>
                                                classNames(
                                                  active ? 'bg-gray-100' : '',
                                                  'cursor-default select-none relative py-2 pr-9',
                                                )
                                              }
                                              value={_area.id}
                                            >
                                              {({ selected }) => (
                                                <>
                                                  <div className="flex items-center">
                                                    <span
                                                      className={classNames(
                                                        selected ? 'font-semibold' : 'font-normal',
                                                        'ml-3 block truncate text-xs',
                                                      )}
                                                    >
                                                      {_area.name}
                                                    </span>
                                                  </div>

                                                  {selected ? (
                                                    <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                  ) : null}
                                                </>
                                              )}
                                            </Listbox.Option>
                                          );
                                        })}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </div>
                              )}
                            </Listbox>
                          </div>
                        </div>
                      );
                    })}
                    {!validity ? (
                      <span className="mt-2 text-red-600 text-xs font-semibold" id="validation-error">
                        {validationMsg}
                      </span>
                    ) : null}
                  </div>
                  <div className="border-t border-grey bg-gray-50 overflow-hidden rounded-b-lg px-6 py-3 flex items-center justify-end">
                    <button
                      type="button"
                      className={classNames(
                        button.white(),
                        'w-auto inline-flex items-center justify-center rounded-md shadow-sm text-sm text-gray-700 ml-3',
                      )}
                      onClick={() => {
                        setOpen(false);
                        setValidity(true);
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={classNames(
                        button.primary(),
                        'w-auto inline-flex items-center justify-center rounded-md text-sm ml-3',
                      )}
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
