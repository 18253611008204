import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const GET_TEAMS = gql`
  query GetTeams($where: teams_bool_exp!) {
    teams(where: $where, order_by: { name: asc }) {
      id
      name
      type
      scoreboard_url
      scoreboard_url_change

      team_members {
        id
        company_user {
          id
          user_id
          wg_skills
          vops_skills
          user {
            ...UserFragment
          }
        }
      }

      team_permissions {
        id
        permission {
          id
          operation
          table
        }
      }

      company {
        id
        expired
        enabled_wg_profile
        enabled_vops_profile
        enabled_assessments
      }
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_TEAMS;
