import { gql } from 'urql';

const COMPANY_FRAGMENT = gql`
  fragment CompanyFragment on companies {
    id
    name
    image
    expired
    enabled_wg_profile
    enabled_vops_profile
    enabled_email_notifs
    week_start_on
    enabled_assessments
  }
`;

export { COMPANY_FRAGMENT };
