const base = 'px-4 py-2 font-semibold';
const disabled = 'opacity-80 cursor-not-allowed';

type ButtonProps = {
  withBase?: boolean;
  isDisabled?: boolean;
};

const button = {
  primary(
    { withBase = true, isDisabled = false }: ButtonProps = {
      withBase: true,
      isDisabled: false,
    },
  ): string {
    return `${withBase ? base : ''} ${
      isDisabled
        ? disabled
        : 'hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
    } bg-primary text-white`;
  },
  white(
    { withBase = true, isDisabled = false }: ButtonProps = {
      withBase: true,
      isDisabled: false,
    },
  ): string {
    return `${withBase ? base : ''}
      ${
        isDisabled
          ? disabled
          : 'hover:bg-primary-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500'
      }
     border border-gray-300 bg-white hover:bg-gray-50`;
  },
  red(
    { withBase = true, isDisabled = false }: ButtonProps = {
      withBase: true,
      isDisabled: false,
    },
  ): string {
    return `${withBase ? base : ''} ${
      isDisabled ? disabled : 'hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600'
    } bg-red-600 text-white`;
  },
  green(
    { withBase = true, isDisabled = false }: ButtonProps = {
      withBase: true,
      isDisabled: false,
    },
  ): string {
    return `${withBase ? base : ''} ${
      isDisabled
        ? disabled
        : 'hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-600'
    } bg-green-600 text-white`;
  },
};

export default button;
