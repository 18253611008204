import { InformationCircleIcon } from '@heroicons/react/outline';
import React, { ReactElement, useState } from 'react';

type HealthyFitGuideBoxProps = {
  informationToShow: JSX.Element;
};

const HelpInfo = ({ informationToShow }: HealthyFitGuideBoxProps): ReactElement => {
  const [showInformation, setShowInformation] = useState(false);
  return (
    <>
      <InformationCircleIcon
        className={`w-5 h-5 mr-1 text-gray-500 ml-3 cursor-pointer float-left`}
        onClick={() => setShowInformation(true)}
      />

      {showInformation ? (
        <>
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50" id="my-modal">
            <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white ">
              <div className="mt-3 text-center">
                <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-300">
                  <InformationCircleIcon className="text-gray-100" />
                </div>
                {/* <h3 className="text-lg leading-6 font-medium text-gray-900">Helpful Information</h3> */}
                <div className="mt-2 px-7 py-3 text-left">{informationToShow}</div>
                <div className="items-center px-4 py-3">
                  <button
                    id="ok-btn"
                    className="px-4 py-2 bg-blue-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
                    onClick={() => setShowInformation(false)}
                  >
                    OK
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default HelpInfo;
