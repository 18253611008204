import { gql } from 'urql';

const ADD_USER_PROFILE_IMAGE_FILE = gql`
  mutation AddUserProfileImageFile($object: user_profile_image_files_insert_input!) {
    insert_user_profile_image_files_one(object: $object) {
      id
    }
  }
`;

export default ADD_USER_PROFILE_IMAGE_FILE;
