import { gql } from 'urql';

const ADD_TEAM_MEMBERS = gql`
  mutation AddTeamMembers($objects: [team_members_insert_input!]!) {
    insert_team_members(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export default ADD_TEAM_MEMBERS;
