import MeetingConnectCard from 'components/MeetingConnectCard';
import { ReactElement } from 'react';
import { MeetingParticipant, MeetingConnect, MeetingConnectUser } from 'data/meetings/types';

export default function MeetingConnectList({
  participants,
  roadmapValues,
  connectData,
  setConnectData,
  teamMembers,
  companyUsers,
}: {
  participants: MeetingParticipant[];
  roadmapValues: string[];
  connectData: MeetingConnect[];
  setConnectData;
  teamMembers: MeetingConnectUser[];
  companyUsers: MeetingConnectUser[];
}): ReactElement {
  return (
    <>
      <div className="h-full bg-gray-100 rounded-lg shadow px-4">
        <ul role="list" className="grid grid-cols-1 gap-2 xl:grid-cols-2 2xl:grid-cols-3 pt-5 pb-8">
          {participants.map((participant) => {
            return (
              <li key={participant.id}>
                <div
                  style={{ gridTemplateColumns: 'auto minmax(0, 1fr) auto' }}
                  className="col-span-1 shadow-sm rounded-md"
                >
                  <MeetingConnectCard
                    participant={participant}
                    connectData={connectData}
                    setConnectData={setConnectData}
                    roadmapValues={roadmapValues}
                    teamMembers={teamMembers}
                    companyUsers={companyUsers}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
