import { Dialog, Transition } from '@headlessui/react';
import { Measurable } from 'data/measurables/types';
import { ADD_SCORE, UPDATE_SCORE } from 'data/scores';
import { Score } from 'data/scores/types';
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfDay,
  format,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfDay,
  subMonths,
  subQuarters,
  subWeeks,
  subDays,
  subYears,
  startOfYear,
  endOfYear,
} from 'date-fns';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation } from 'urql';
import { classNames, refreshData } from 'utils';

export function getIntervalDate(
  interval: string,
  sub: number,
  givenDate: Date = new Date(),
  week_start_on: number,
): { start: Date; end: Date; strVal: string; strStart: string; strEnd: string } {
  let subberFn: (date: Date, num: number) => Date;
  let startOfFn;
  let endOfFn;
  let startStr;
  let endStr;
  switch (interval) {
    case 'monthly':
      subberFn = subMonths;
      startOfFn = startOfMonth;
      endOfFn = endOfMonth;
      startStr = 'MMM';
      endStr = '';
      break;
    case 'quarterly':
      subberFn = subQuarters;
      startOfFn = startOfQuarter;
      endOfFn = endOfQuarter;
      startStr = 'MMM';
      endStr = '-MMM';
      break;
    case 'annually':
      subberFn = subYears;
      startOfFn = startOfYear;
      endOfFn = endOfYear;
      startStr = 'yyyy';
      endStr = '';
      break;
    case 'daily':
      subberFn = subDays;
      startOfFn = startOfDay;
      endOfFn = endOfDay;
      startStr = 'MMM d';
      endStr = '';
      break;
    case 'weekly':
    default:
      subberFn = subWeeks;
      startOfFn = startOfWeek;
      endOfFn = endOfWeek;
      startStr = 'MMM d';
      endStr = '-d';
      break;
  }

  const date = subberFn(givenDate, sub);

  const start = interval === 'weekly' ? startOfFn(date, { weekStartsOn: week_start_on }) : startOfFn(date);
  const end = interval === 'weekly' ? endOfFn(date, { weekStartsOn: week_start_on }) : endOfFn(date);
  const strVal = `${format(start, startStr)}${endStr ? format(end, endStr) : ''}`;
  const strStart = format(start, 'yyyy-MM-dd');
  const strEnd = format(end, 'yyyy-MM-dd');

  // console.log([interval, start, end, strVal, strStart, strEnd]);

  return {
    start,
    end,
    strVal,
    strStart,
    strEnd,
  };
}

type NewScoreDialogProps = {
  setError: (err: CombinedError) => void;
  setUpdated: (str: string) => void;
  setOpen: (open: boolean) => void;
  score: {
    score: Score;
    measurable: Measurable;
    date: Date;
  };
  week_start_on: number;
  open: boolean;
};

export default function NewScoreDialog({
  setError,
  setUpdated,
  score,
  week_start_on,
  open,
  setOpen,
}: NewScoreDialogProps): ReactElement {
  const router = useRouter();
  const [, addScore] = useMutation(ADD_SCORE);
  const [, updateScore] = useMutation(UPDATE_SCORE);
  const [saving, setSaving] = useState(false);
  const cancelButtonRef = useRef(null);
  const scoreInputRef = useRef(null);

  const { strVal, strStart } = getIntervalDate(score?.measurable?.interval, 0, score?.date, week_start_on);

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setSaving(false)}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm sm:w-full"
              onSubmit={async (event) => {
                event.preventDefault();
                setSaving(true);

                let value = event.target['score-value'].value;
                if (value === '') {
                  value = null;
                }

                let err: CombinedError;
                if (score.score) {
                  const { error: _err } = await updateScore({
                    id: score.score.id,
                    _set: {
                      value,
                    },
                  });
                  err = _err;
                } else {
                  const { error: _err } = await addScore({
                    object: {
                      measurable_id: score.measurable.id,
                      value,
                      date: strStart,
                    },
                  });
                  err = _err;
                }

                if (err) {
                  setError(err);
                } else {
                  setUpdated('Score updated');
                }

                setOpen(false);
                refreshData(router);
              }}
            >
              <div onClick={() => scoreInputRef?.current?.focus()}>
                <div>
                  <div className="bg-white p-6">
                    <div className="flex items-start px-8">
                      <div className="w-full">
                        <Dialog.Title as="h2" className="my-4 text-2xl leading-6 font-semibold text-gray-900">
                          {score?.measurable?.title}
                        </Dialog.Title>
                        <Dialog.Description className="my-2 text-gray-900">{strVal}</Dialog.Description>
                        <div className="my-2">
                          <input
                            type="number"
                            step="any"
                            name="score-value"
                            className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                            defaultValue={score?.score?.value}
                            ref={scoreInputRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="border border-grey px-4 py-3 flex justify-end">
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={saving}
                    className={classNames(
                      button.primary({ isDisabled: saving }),
                      'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                    )}
                  >
                    Save
                  </button>
                </div>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
