import { gql } from 'urql';

const UPDATE_ACTION_COMMENT = gql`
  mutation UpdateActionComment(
    $commentId: Int!
    $_set: action_comments_set_input!
    $id: Int!
    $activity: String
    $timestamp: bigint
    $userId: Int
  ) {
    update_action_comments_by_pk(pk_columns: { id: $commentId }, _set: $_set) {
      id
    }

    insert_actions_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default UPDATE_ACTION_COMMENT;
