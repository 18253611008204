import { gql } from 'urql';
import { MEETING_FRAGMENT } from './fragments';

const GET_MEETING = gql`
  query GetMeeting($id: Int!) {
    meetings(where: { id: { _eq: $id } }) {
      ...MeetingFragment
    }
  }
  ${MEETING_FRAGMENT}
`;

export default GET_MEETING;
