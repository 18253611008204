import { gql } from 'urql';

const DELETE_ORG = gql`
  mutation DeleteOrg($id: Int!) {
    delete_org_by_pk(id: $id) {
      id
    }
  }
`;

export default DELETE_ORG;
