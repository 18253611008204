import { gql } from 'urql';

const ADD_ACTION_FILE = gql`
  mutation AddActionFile(
    $object: action_files_insert_input!
    $id: Int!
    $activity: String
    $timestamp: bigint
    $userId: Int
  ) {
    insert_action_files_one(object: $object) {
      id
    }

    insert_actions_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default ADD_ACTION_FILE;
