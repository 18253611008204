import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const OBJECTIVE_FRAGMENT = gql`
  fragment ObjectiveFragment on objectives {
    column_30_day
    column_60_day
    company_id
    description
    due
    id
    key_results
    owner
    archived
    user {
      ...UserFragment
    }
    status
    title
    teams_id
    created_at
    measurable_id
    actions {
      id
      title
      teams_id
    }
    objective_files {
      id
      url
      file_name
      file_type
      status
    }
  }
  ${USER_FRAGMENT}
`;

export { OBJECTIVE_FRAGMENT };
