import { gql } from 'urql';

const ADD_ACTION_COMMENTS = gql`
  mutation AddActionComment(
    $objects: [action_comments_insert_input!]!
    $id: Int!
    $activity: String
    $timestamp: bigint
    $userId: Int
  ) {
    insert_action_comments(objects: $objects) {
      returning {
        id
      }
    }

    insert_actions_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default ADD_ACTION_COMMENTS;
