import { ReactElement, ReactNode } from 'react';
import { classNames } from 'utils';

type CardProps = {
  children: ReactElement | ReactNode;
  className?: string;
};

export default function Card({ className = 'm-6 flex-1', children }: CardProps): ReactElement {
  return <div className={classNames('h-max bg-white shadow rounded-lg', className ?? '')}>{children}</div>;
}
