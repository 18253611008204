import { gql } from 'urql';
import { COMPANY_FRAGMENT } from './fragments';

const UPDATE_COMPANY = gql`
  mutation UpdateCompany($id: Int!, $set: companies_set_input!) {
    update_companies(where: { id: { _eq: $id } }, _set: $set) {
      affected_rows
      returning {
        ...CompanyFragment
      }
    }
  }
  ${COMPANY_FRAGMENT}
`;

export default UPDATE_COMPANY;
