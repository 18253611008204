import { Dialog, Transition, Listbox } from '@headlessui/react';
import { XIcon, CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { ErrorToast, SuccessToast } from 'components';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation, OperationContext } from 'urql';
import { classNames, refreshData } from 'utils';
import { UPDATE_COMPANY_USER } from 'data/company';
import { skillTypes } from 'components/UsersList/UsersList';

type OtherAssessmentsDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  userInfo;
  refetchUsers: (opts?: Partial<OperationContext>) => void;
  setWGDialogOpen;
  setVOPSDialogOpen;
  otherAssessments;
  setOtherAssessments;
};

export default function OtherAssessmentsDialog({
  open,
  setOpen,
  userInfo,
  refetchUsers,
  setWGDialogOpen,
  setVOPSDialogOpen,
  otherAssessments,
  setOtherAssessments,
}: OtherAssessmentsDialogProps): ReactElement {
  const router = useRouter();
  const [, updateCompanyUser] = useMutation(UPDATE_COMPANY_USER);
  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();
  const cancelButtonRef = useRef(null);

  const handleAddAssessment = () => {
    setOtherAssessments([...otherAssessments, '']);
  };

  const handleAssessmentChange = (index: number, value: string) => {
    const updatedAssessments = otherAssessments.map((assessment, i) => {
      if (i === index) return value;
      return assessment;
    });
    setOtherAssessments(updatedAssessments);
  };

  const handleRemoveAssessment = async (index: number) => {
    const updatedAssessments = otherAssessments.filter((_, i) => i !== index);
    setOtherAssessments(updatedAssessments);
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={() => {
            setOpen(false);
          }}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative">
                {/* Empty div to get rounded corners. Can't put overflow on form because of dropdowns */}
                <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden"></div>
                <form
                  className="align-bottom text-left background-transparent shadow-xl transform transition-all sm:align-middle"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    const { error: err } = await updateCompanyUser({
                      ids: [userInfo?.id],
                      _set: {
                        other_assessments: otherAssessments,
                      },
                    });
                    const companyId = router.query.company;
                    refetchUsers({ variables: { companyId } });
                    if (err) {
                      setError(err);
                    } else {
                      refetchUsers({ variables: { companyId } });
                      setUpdated(`Assessment Details added.`);
                    }
                    setOpen(false);
                    refreshData(router);
                  }}
                >
                  <div className="flex items-center justify-between  w-full">
                    <div className="flex text-md font-semibold text-gray-900 ml-6 mt-4">
                      <Listbox
                        value={'3'}
                        onChange={async (value: string) => {
                          if (value === '1') {
                            setOpen(false);
                            setWGDialogOpen(true);
                          }
                          if (value === '2') {
                            setOpen(false);
                            setVOPSDialogOpen(true);
                          }
                        }}
                      >
                        {({ open: _open }) => (
                          <div className="flex space-x-4">
                            <div className="relative">
                              <Listbox.Button
                                className={classNames(
                                  button.white({ withBase: false }),
                                  'relative w-64 h-8 rounded-md shadow-sm pr-7 text-left cursor-default text-sm py-1 font-semibold text-gray-900 mb-2',
                                )}
                              >
                                {skillTypes.some((_area) => _area.id === '3') ? (
                                  <span className="flex items-center">
                                    <span className="ml-3 block truncate">
                                      {skillTypes.find((_area) => _area.id === '3')?.name}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="flex items-center ml-3 text-gray-500">Select skill type</span>
                                )}
                                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={_open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  static
                                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  {skillTypes.map((_area) => {
                                    return (
                                      <Listbox.Option
                                        key={_area.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-default select-none relative py-2 pr-9',
                                          )
                                        }
                                        value={_area.id}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  selected ? 'font-semibold' : 'font-normal',
                                                  'ml-3 block truncate text-xs',
                                                )}
                                              >
                                                {_area.name}
                                              </span>
                                            </div>

                                            {selected ? (
                                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    );
                                  })}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </div>
                        )}
                      </Listbox>
                    </div>
                    <XIcon
                      className="cursor-pointer fill-current text-gray-400 h-6 w-6 ml-6 mr-2"
                      onClick={() => {
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="mt-1 ml-6 mr-2 w-96 text-xs mr-2">
                    Type in any additional assessments results below. These results will display upon scrolling over
                    their image or profile icon and on their Personal Road Map
                  </div>
                  {otherAssessments.map((assessment, index) => (
                    <div className="flex flex-col px-6 mt-2 font-semibold text-gray-700" key={index}>
                      <div className="flex items-center space-x-2">
                        <input
                          type="text"
                          name={`assessment-${index}`}
                          id={`assessment-${index}`}
                          className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-400 rounded-md"
                          placeholder="Enter additional assessment details here"
                          value={assessment}
                          onChange={(e) => handleAssessmentChange(index, e.target.value)}
                        />
                        {index > 0 && (
                          <div className="cursor-pointer" onClick={() => handleRemoveAssessment(index)}>
                            <XIcon className="h-4 w-4 mb-0.5 text-red-500" />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                  <button
                    type="button"
                    className={classNames(
                      button.primary(),
                      'inline-flex items-center justify-center my-2 px-6 items-center rounded-md text-sm ml-6',
                    )}
                    onClick={handleAddAssessment}
                  >
                    Add New Section
                  </button>
                  <div className="border-t border-grey bg-gray-50 overflow-hidden rounded-b-lg px-6 py-3 flex items-center justify-end">
                    <button
                      type="button"
                      className={classNames(
                        button.white(),
                        'w-auto inline-flex items-center shadow-sm justify-center rounded-md text-sm ml-3',
                      )}
                      onClick={() => {
                        setOpen(false);
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={classNames(
                        button.primary(),
                        'w-auto inline-flex items-center justify-center rounded-md text-sm ml-3',
                      )}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
