import { Config } from 'types/d3';

const animationDuration = 350;
const shouldResize = true;

// Nodes
const nodeWidth = 180;
const nodeHeight = 210;
const nodeSpacing = 12;
const nodePaddingX = 10;
const nodePaddingY = 16;
const avatarWidth = 48;
const nodeBorderRadius = 4;
const margin = {
  top: 20,
  right: 20,
  bottom: 20,
  left: 20,
};

// Lines
const lineType = 'angle';
const lineDepthY = 120; /* Height of the line for child nodes */

// Colors
const backgroundColor = '#fff';
const borderColor = '#c9c9c9';
const nameColor = '#222d38';
const titleColor = '#617080';
const reportsColor = '#92A0AD';

const borderRadius = 4;

const config: Config = {
  borderRadius,
  margin,
  animationDuration,
  nodeWidth,
  nodeHeight,
  nodeSpacing,
  nodePaddingX,
  nodePaddingY,
  nodeBorderRadius,
  avatarWidth,
  lineType,
  lineDepthY,
  backgroundColor,
  borderColor,
  nameColor,
  titleColor,
  reportsColor,
  shouldResize,
};

export default config;
