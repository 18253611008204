import { gql } from 'urql';

const UPDATE_ORGS = gql`
  mutation UpdateOrgs($ids: [Int]!, $set: org_set_input) {
    update_org(where: { id: { _in: $ids } }, _set: $set) {
      affected_rows
    }
  }
`;

export default UPDATE_ORGS;
