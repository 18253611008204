import { Popover, Transition } from '@headlessui/react';
import { ArrowNarrowRightIcon, CogIcon, UserAddIcon } from '@heroicons/react/outline';
import { CheckIcon, ChevronDownIcon, XIcon } from '@heroicons/react/solid';
import { Company } from 'data/company/types';
import { Team } from 'data/teams/types';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useState } from 'react';
import button from 'styles/button';
import { classNames } from 'utils';

type CompanySelectorProps = {
  companies: Company[];
  teams: Team[];
  canAddUsers: boolean;
  team?: number;
};

const OTHER_COMPANIES_LINKS_COUNT = 3;
const DISPLAY_TEAMS_COUNT = 6;

export default function CompanySelector({ companies, teams, team, canAddUsers }: CompanySelectorProps): ReactElement {
  const router = useRouter();

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const selectedCompany = companies.find((company) => company.id === Number(router.query.company));
  const otherCompanies = companies
    .filter((company) => company.id !== selectedCompany?.id)
    .slice(0, OTHER_COMPANIES_LINKS_COUNT);

  const selectedTeam = teams.find((_team) => _team.id === Number(team));
  const displayTeams = teams.slice(0, DISPLAY_TEAMS_COUNT);

  const MAX_WORD_LENGTH = 20;
  const TRIM_WORD_LENGTH = 40;
  const TEAM_MAX_WORD_LENGTH = 28;
  const TEAM_TRIM_WORD_LENGTH = 50;
  const maxWordLength = selectedCompany?.name
    ?.trim()
    .split(' ')
    .find((word) => word.length > MAX_WORD_LENGTH);
  const teamMaxWordLength = selectedTeam?.name
    ?.trim()
    .split(' ')
    .find((word) => word.length > TEAM_MAX_WORD_LENGTH);

  return (
    <Popover as="div" className="relative inline-block text-left w-full">
      {({ open, close }) => (
        <>
          <Popover.Button className="h-max-32 h-24 group w-full flex items-center justify-between p-4 text-lg text-left font-bold text-gray-100 hover:text-gray-200">
            <div className="flex flex-col px-2 w-3/4 company-name">
              <span className={classNames(maxWordLength ? 'truncate' : '', 'text-xl font-semibold')}>
                {selectedCompany?.name?.length > TRIM_WORD_LENGTH
                  ? selectedCompany?.name?.slice(0, TRIM_WORD_LENGTH).concat('...')
                  : selectedCompany?.name}
              </span>
              <span className={classNames(teamMaxWordLength ? 'truncate' : '', 'text-sm text-gray-300 font-normal')}>
                {selectedTeam?.name?.length > TEAM_TRIM_WORD_LENGTH
                  ? selectedTeam?.name?.slice(0, TEAM_TRIM_WORD_LENGTH).concat('...')
                  : selectedTeam?.name}
              </span>
            </div>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Popover.Panel className="p-2 w-full absolute left-0 top-0 bg-transparent z-50">
              <div className="overflow-auto w-full rounded-md shadow-lg bg-white divide-y divide-gray-100 focus:outline-none">
                {selectedCompany ? (
                  <div className="flex flex-col">
                    <div className="flex justify-between items-center px-4 py-4 w-64">
                      <div className="flex items-center w-full">
                        <Link href={`/company/${selectedCompany?.id}/team/${selectedTeam?.id}`}>
                          <div className="flex items-center">
                            <Image
                              src="/system-and-soul-logo.svg"
                              alt="logo"
                              height={32}
                              width={32}
                              style={{
                                maxWidth: '100%',
                                height: 'auto',
                              }}
                            />
                            <span className={classNames(maxWordLength ? 'truncate' : '', 'ml-2 font-bold uppercase')}>
                              {selectedCompany?.name?.length > 20
                                ? selectedCompany?.name?.slice(0, 20).concat('...')
                                : selectedCompany?.name}
                            </span>
                          </div>
                        </Link>
                      </div>
                      <Popover.Button onClick={close}>
                        <XIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </Popover.Button>
                    </div>
                    <div className="flex flex-col mx-4">
                      {canAddUsers ? (
                        <Popover.Button>
                          <Link
                            href={`/company/${router.query.company}/settings/users/add`}
                            className="flex items-center py-2 pl-10 pr-2 rounded hover:bg-gray-100"
                          >
                            <UserAddIcon className="h-5 w-5 text-gray-400" />
                            <span className="text-sm ml-3 font-semibold text-gray-700 truncate">Add Users</span>
                          </Link>
                        </Popover.Button>
                      ) : null}
                      <Popover.Button>
                        <Link
                          href={`/company/${router.query.company}/settings`}
                          className="flex items-center py-2 pl-10 pr-2 mb-2 rounded hover:bg-gray-100"
                        >
                          <CogIcon className="h-5 w-5 text-gray-400" />
                          <span className="text-sm ml-3 font-semibold text-gray-700 truncate">Company Settings</span>
                        </Link>
                      </Popover.Button>
                    </div>
                  </div>
                ) : null}
                {teams.length ? (
                  <div className="px-2 py-4">
                    <div>
                      <div className="px-2 mb-2 text-gray-400 text-sm font-semibold">TEAMS</div>
                      {displayTeams.map((_team) =>
                        _team.id === Number(team) ? (
                          <div key={_team.id} className="w-full py-2 px-2 flex items-center">
                            <CheckIcon className="h-4 w-4 text-gray-900" />
                            <span className="ml-2 text-sm font-semibold truncate">{_team.name}</span>
                          </div>
                        ) : (
                          <Popover.Button key={_team.id} className="w-full py-2 px-2 rounded hover:bg-gray-100">
                            <Link
                              href={`/company/${_team.company.id}/team/${_team.id}`}
                              className="flex text-sm items-center text-gray-900"
                            >
                              <CheckIcon className="h-4 w-4 invisible" />
                              <span className="ml-2 truncate">{_team.name}</span>
                            </Link>
                          </Popover.Button>
                        ),
                      )}
                    </div>
                    {teams.length > DISPLAY_TEAMS_COUNT ? (
                      <Popover.Button className="mx-2">
                        <Link
                          href={`/company/${router.query.company}/team/user-teams`}
                          className="text-sm font-semibold flex items-center text-primary"
                        >
                          <span className="mr-1">View all teams</span>
                          <ArrowNarrowRightIcon className="h-3 w-3" />
                        </Link>
                      </Popover.Button>
                    ) : null}
                  </div>
                ) : null}
                {otherCompanies.length ? (
                  <div className="px-2 py-4">
                    <div>
                      <div className="px-2 mb-2 text-gray-400 text-sm font-semibold">
                        {selectedCompany ? 'OTHER ' : ''}COMPANIES
                      </div>
                      {otherCompanies.map((company) => (
                        <Popover.Button key={company.id} className="w-full py-3 px-2 rounded hover:bg-gray-100">
                          <Link
                            href={`/company/${company.id}`}
                            className="text-sm font-semibold flex items-center text-gray-900"
                          >
                            <Image
                              src="/system-and-soul-logo.svg"
                              alt="logo"
                              height={24}
                              width={24}
                              style={{
                                maxWidth: '100%',
                                height: 'auto',
                              }}
                            />
                            <span className={classNames(maxWordLength ? 'truncate' : '', 'ml-0.5')}>
                              {company.name?.length > 25 ? company.name?.slice(0, 25).concat('...') : company.name}
                            </span>
                          </Link>
                        </Popover.Button>
                      ))}
                    </div>
                    {companies.length > OTHER_COMPANIES_LINKS_COUNT + 1 ? (
                      <Popover.Button className="mx-2">
                        <Link href={`/company/all`} className="text-sm font-semibold flex items-center text-primary">
                          <span className="mr-1">View all companies</span>
                          <ArrowNarrowRightIcon className="h-3 w-3" />
                        </Link>
                      </Popover.Button>
                    ) : null}
                  </div>
                ) : null}
                <div className="p-3 bg-gray-100">
                  <Popover.Button className={classNames(button.primary(), 'rounded block py-2 w-full')}>
                    <Link href={`/company/create`} className="text-gray-100 text-center">
                      Add New Company
                    </Link>
                  </Popover.Button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}
