import { Dialog, Transition, Listbox } from '@headlessui/react';
import { XIcon, CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import { ErrorToast, SuccessToast } from 'components';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation, OperationContext } from 'urql';
import { classNames, refreshData } from 'utils';
import { UPDATE_COMPANY_USER } from 'data/company';
import { skillTypes, VOPSSkills } from 'components/UsersList/UsersList';

type VOPSProfileDialogDialogProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  vopsScore;
  setVOPSScore;
  userInfo;
  refetchUsers: (opts?: Partial<OperationContext>) => void;
  enabledWGProfile;
  setWGDialogOpen;
  setOtherAssessmentsDialogOpen;
};

// eslint-disable-next-line
export function validate(vopsScore: VOPSSkills, setVOPSValidity, setvopsValidationMsg): boolean {
  let validity = true;
  const scores = vopsScore ? Object.values(vopsScore) : [];
  const total = scores.reduce((accum, score) => {
    return accum + (Number(score) || 0);
  }, 0);
  if (total !== 960) {
    setVOPSValidity(false);
    setvopsValidationMsg('hmm.. Something’s not quite right. Make sure your scores total 960');
    validity = false;
  }
  return validity;
}

export default function VOPSProfileDialog({
  open,
  setOpen,
  vopsScore,
  setVOPSScore,
  userInfo,
  refetchUsers,
  enabledWGProfile,
  setWGDialogOpen,
  setOtherAssessmentsDialogOpen,
}: VOPSProfileDialogDialogProps): ReactElement {
  const router = useRouter();
  const [, updateCompanyUser] = useMutation(UPDATE_COMPANY_USER);
  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();
  const cancelButtonRef = useRef(null);

  const [vopsValidity, setVOPSValidity] = useState<boolean>(true);
  const [vopsValidationMsg, setvopsValidationMsg] = useState<string>('');

  useEffect(() => {
    console.log({ vopsScore });
  }, [vopsScore]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={() => {
            setOpen(false);
            setVOPSValidity(true);
          }}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative">
                {/* Empty div to get rounded corners. Can't put overflow on form because of dropdowns */}
                <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden"></div>
                <form
                  className="align-bottom text-left background-transparent shadow-xl transform transition-all sm:align-middle"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    const isValid = validate(vopsScore, setVOPSValidity, setvopsValidationMsg);
                    if (!isValid) {
                      return 1;
                    }
                    const { error: err } = await updateCompanyUser({
                      ids: [userInfo?.id],
                      _set: {
                        vops_skills: vopsScore,
                      },
                    });

                    if (err) {
                      setError(err);
                    } else {
                      const companyId = router.query.company;
                      refetchUsers({ variables: { companyId } });
                      setUpdated(`VOPS skills added`);
                    }
                    setVOPSValidity(true);
                    setOpen(false);
                    refreshData(router);
                  }}
                >
                  <div className="flex items-center justify-between  w-full">
                    <div className="flex text-md font-semibold text-gray-900 ml-6 mt-4">
                      <Listbox
                        value={'2'}
                        onChange={async (value: string) => {
                          if (value === '1') {
                            setOpen(false);
                            setWGDialogOpen(true);
                          }
                          if (value === '3') {
                            setOpen(false);
                            setOtherAssessmentsDialogOpen(true);
                          }
                          setVOPSValidity(true);
                        }}
                      >
                        {({ open: _open }) => (
                          <div className="flex space-x-4">
                            <div className="relative">
                              <Listbox.Button
                                className={classNames(
                                  button.white({ withBase: false }),
                                  'relative w-64 h-8 rounded-md shadow-sm pr-7 text-left cursor-default text-sm py-1 font-semibold text-gray-900 mb-2',
                                )}
                              >
                                {skillTypes.some((_area) => _area.id === '2') ? (
                                  <span className="flex items-center">
                                    <span className="ml-3 block truncate">
                                      {skillTypes.find((_area) => _area.id === '2')?.name}
                                    </span>
                                  </span>
                                ) : (
                                  <span className="flex items-center ml-3 text-gray-500">Select skill type</span>
                                )}
                                <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={_open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  static
                                  className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  {skillTypes.map((_area) => {
                                    return (
                                      <Listbox.Option
                                        key={_area.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-default select-none relative py-2 pr-9',
                                          )
                                        }
                                        value={_area.id}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  selected ? 'font-semibold' : 'font-normal',
                                                  'ml-3 block truncate text-xs',
                                                )}
                                              >
                                                {_area.name}
                                              </span>
                                            </div>

                                            {selected ? (
                                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    );
                                  })}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </div>
                        )}
                      </Listbox>
                    </div>
                    <XIcon
                      className="cursor-pointer fill-current text-gray-400 h-6 w-6 ml-6 mr-2"
                      onClick={() => {
                        setVOPSValidity(true);
                        setOpen(false);
                      }}
                    />
                  </div>
                  <div className="mt-1 ml-6 mr-2 w-96 text-xs mr-2">
                    These are {userInfo?.name || 'user'}
                    {`'s`} VOPS - Predictable Success Skills. Enter your scores for each category to be displayed on{' '}
                    {userInfo?.name || 'user'}
                    {`'s`} profile.
                  </div>

                  <div className="flex flex-col p-6 font-semibold text-gray-700">
                    <div>
                      <label htmlFor="title" className="block text-xs text-gray-700">
                        Visionary
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          step="any"
                          name="visionary"
                          id="visionary"
                          className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-400 rounded-md"
                          placeholder="Enter your score here"
                          defaultValue={vopsScore?.visionary}
                          onChange={(event) => {
                            setVOPSScore({
                              ...vopsScore,
                              visionary: event.target.value,
                            });
                            setVOPSValidity(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-6">
                      <label htmlFor="title" className="block text-xs text-gray-700">
                        Operator
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          step="any"
                          name="operator"
                          id="operator"
                          className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-400 rounded-md"
                          placeholder="Enter your score here"
                          defaultValue={vopsScore?.operator}
                          onChange={(event) => {
                            setVOPSScore({
                              ...vopsScore,
                              operator: event.target.value,
                            });
                            setVOPSValidity(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-6">
                      <label htmlFor="title" className="block text-xs text-gray-700">
                        Processor
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          step="any"
                          name="processor"
                          id="processor"
                          className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-400 rounded-md"
                          placeholder="Enter your score here"
                          defaultValue={vopsScore?.processor}
                          onChange={(event) => {
                            setVOPSScore({
                              ...vopsScore,
                              processor: event.target.value,
                            });
                            setVOPSValidity(true);
                          }}
                        />
                      </div>
                    </div>
                    <div className="mt-6">
                      <label htmlFor="title" className="block text-xs text-gray-700">
                        Synergist
                      </label>
                      <div className="mt-1">
                        <input
                          type="number"
                          step="any"
                          name="synergist"
                          id="synergist"
                          className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-xs border-gray-400 rounded-md"
                          placeholder="Enter your score here"
                          defaultValue={vopsScore?.synergist}
                          onChange={(event) => {
                            setVOPSScore({
                              ...vopsScore,
                              synergist: event.target.value,
                            });
                            setVOPSValidity(true);
                          }}
                        />
                      </div>
                    </div>
                    {!vopsValidity ? (
                      <span className="mt-2 text-red-600 text-xs font-semibold" id="validation-error">
                        {vopsValidationMsg}
                      </span>
                    ) : null}
                  </div>
                  <div className="border-t border-grey bg-gray-50 overflow-hidden rounded-b-lg px-6 py-3 flex items-center justify-end">
                    <button
                      type="button"
                      className={classNames(
                        button.white(),
                        'w-auto inline-flex items-center justify-center rounded-md shadow-sm text-sm text-gray-700 ml-3',
                      )}
                      onClick={() => {
                        setOpen(false);
                        setVOPSValidity(true);
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={classNames(
                        button.primary(),
                        'w-auto inline-flex items-center justify-center rounded-md text-sm ml-3',
                      )}
                    >
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
