import { XIcon } from '@heroicons/react/outline';
import { ComponentProps, Dispatch, ReactElement, SetStateAction } from 'react';
import Toast from './Toast';

type SuccessToastProps = {
  message: string;
  setMessage?: Dispatch<SetStateAction<unknown>>;
  icon: (props: ComponentProps<'svg'>) => JSX.Element;
  color: string;
};

ErrorToast.defaultProps = {
  color: 'text-primary',
};

export default function ErrorToast({ message, setMessage, icon: Icon, color }: SuccessToastProps): ReactElement {
  return (
    <Toast
      open={Boolean(message)}
      onClose={() => {
        setMessage?.(null);
      }}
      time={5000}
      content={
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <Icon className={`h-6 w-6 ${color}`} aria-hidden="true" />
              </div>
              <div className="ml-3 w-0 flex-1">
                <p className="font-semibold text-gray-900">{message}</p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  className="rounded-md border-0 inline-flex text-gray-700"
                  onClick={() => {
                    setMessage?.(null);
                  }}
                >
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
