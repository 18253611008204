import { gql } from 'urql';

const GET_ROLES = gql`
  query GetRoles {
    roles(order_by: [{ id: asc }]) {
      id
      name
    }
  }
`;

export default GET_ROLES;
