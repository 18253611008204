import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const GET_COMPANY_WITH_TEAMS = gql`
  query GetCompanyWithTeams($id: Int, $user_id: Int) {
    companies(where: { id: { _eq: $id } }) {
      id
      name
      image
      teams(where: { team_members: { company_user: { user_id: { _eq: $user_id } } }, deleted: { _neq: true } }) {
        id
        name
        type

        team_members {
          id

          company_user {
            id
            user {
              ...UserFragment
            }
          }
        }
        company {
          id
          expired
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_COMPANY_WITH_TEAMS;
