import { ReactElement } from 'react';
import { useMutation } from 'urql';
import { UPDATE_TEAM_MEMBER } from 'data/team_members';
import { OpportunityTypes } from 'data/opportunities';
import { classNames } from 'utils';

export default function OpportunitiesFutureToggle({
  isFutureToggle,
  setIsFutureToggle,
  teamMemberId,
  filterType,
}: {
  isFutureToggle: boolean;
  setIsFutureToggle: (futureToggle: boolean) => void;
  teamMemberId: number;
  filterType: string;
}): ReactElement {
  const [, updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER);
  const disabled = filterType === OpportunityTypes.Future;
  return (
    <div className="ml-8">
      <div className="flex items-center mt-1 h-5">
        <input
          id="future-toggle"
          type="checkbox"
          className={classNames(disabled ? 'cursor-not-allowed' : '', 'rounded cursor-pointer')}
          disabled={disabled}
          defaultChecked={isFutureToggle}
          onChange={async (event) => {
            event.target.checked ? setIsFutureToggle(true) : setIsFutureToggle(false);
            await updateTeamMember({
              id: teamMemberId,
              set: {
                future_type_toggle: event.target.checked,
              },
            });
          }}
        />
        <div className="ml-3 text-sm">
          <label htmlFor="comments" className="font-medium text-gray-700">
            Future
          </label>
        </div>
      </div>
    </div>
  );
}
