import { gql } from 'urql';

const UPDATE_USER = gql`
  mutation UpdateUser($set: users_set_input!, $id: Int!) {
    update_users_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

export default UPDATE_USER;
