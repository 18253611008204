import { gql } from 'urql';

const GET_INVITE = gql`
  query GetInvite($id: uuid!) {
    invites_by_pk(id: $id) {
      id
      company_id
      role_id
      email
      team_id
    }
  }
`;

export default GET_INVITE;
