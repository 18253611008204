import { gql } from 'urql';

const ADD_SCORE = gql`
  mutation AddScore($object: scores_insert_input!) {
    insert_scores_one(object: $object) {
      id
    }
  }
`;

export default ADD_SCORE;
