import { gql } from 'urql';

const ADD_MEASURABLE = gql`
  mutation AddMeasurable($object: measurables_insert_input!) {
    insert_measurables_one(object: $object) {
      id
    }
  }
`;

export default ADD_MEASURABLE;
