import { gql } from 'urql';

const INSERT_COMPANY_MUTATION = gql`
  mutation InsertCompany($name: String, $user_id: Int, $role_id: Int) {
    insert_companies_one(object: { name: $name, company_users: { data: { role_id: $role_id, user_id: $user_id } } }) {
      id
      name
      company_users {
        id
      }
    }
  }
`;

export default INSERT_COMPANY_MUTATION;
