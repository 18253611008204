import { gql } from 'urql';

const ADD_COMPANY_USERS = gql`
  mutation AddCompanyUsers($objects: [company_users_insert_input!]!) {
    insert_company_users(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export default ADD_COMPANY_USERS;
