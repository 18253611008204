import { CheckIcon, RefreshIcon, SelectorIcon, SparklesIcon, StarIcon } from '@heroicons/react/outline';
import { Card, CardContent, RichTextViewer } from 'components';
import Image from 'next/image';
import { Fragment, ReactElement, useState, useRef, useEffect } from 'react';
import { classNames } from 'utils';
import { Bet, Roadmap as RoadmapType } from './types';
import { Transition, Popover, Listbox } from '@headlessui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Team } from 'data/teams/types';
import button from 'styles/button';

type RoadmapProps = {
  roadmap: RoadmapType;
  bets: Bet[];
  teams: Team[];
  setformBetsTeamId?: (teamId: number) => void;
  formBetsTeamId?: number;
};

export default function Roadmap({
  roadmap,
  bets,
  teams,
  setformBetsTeamId,
  formBetsTeamId,
}: RoadmapProps): ReactElement {
  const router = useRouter();
  const [showPopover, setShowPopover] = useState<string>();
  const [team, setTeam] = useState<Team>(null);

  useEffect(() => {
    setTeam(teams.find((teamObject) => teamObject.id === formBetsTeamId));
  }, [formBetsTeamId, teams]);

  const buttonRef = useRef(null);
  return (
    <div className="bg-gray-50 pt-4 h-full overflow-y-auto">
      <div className="relative mx-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-start">
          <span className="pr-3 text-xl font-bold bg-gray-50 text-gray-500">Identity</span>
        </div>
      </div>
      <Card>
        <CardContent>
          <div className="flex flex-wrap text-sm gap-x-1">
            <span className="">We are the only</span>
            <span className="text-primary font-semibold">{roadmap?.onliness?.[0] || 'N/A'}</span>
            <span className="">that</span>
            <span className="text-primary font-semibold">{roadmap?.onliness?.[1] || 'N/A'}</span>
          </div>
          <div className="flex flex-wrap text-sm gap-x-1 my-6">
            <span className="">We are going to</span>
            <span className="text-primary font-semibold">{roadmap?.destination?.[0] || 'N/A'}</span>
            <span className="">by</span>
            <span className="text-primary font-semibold">{roadmap?.destination?.[1] || 'N/A'}</span>
            <span className="">because</span>
            <span className="text-primary font-semibold">{roadmap?.destination?.[2] || 'N/A'}</span>
          </div>
        </CardContent>
      </Card>
      <div className="relative mx-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-start">
          <span className="pr-3 text-xl font-bold bg-gray-50 text-gray-500">Hedgehog</span>
        </div>
      </div>
      <Card>
        <CardContent>
          <div className="flex flex-col md:flex-row">
            <Image
              src="/system-and-soul-hedgehog.svg"
              alt="hedgehog"
              height={128}
              width={128}
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
            <div className="flex flex-1 flex-col items-center px-4 md:pl-4 md:pr-10 py-4">
              <div className="flex flex-col flex-1 items-center lg:items-start justify-center p-1">
                <span className="text-xs">What we are deeply passionate about...</span>
                <span className="text-md text-primary font-semibold">{roadmap?.venns?.[0] || 'N/A'}</span>
              </div>
              <div className="flex flex-col items-center lg:flex-row lg:justify-between w-full">
                <div className="flex flex-col items-center lg:items-start p-1">
                  <span className="text-xs">What we can be best in the world at...</span>
                  <span className="text-md text-primary font-semibold">{roadmap?.venns?.[1] || 'N/A'}</span>
                </div>
                <div className="flex flex-col items-center lg:items-start p-1">
                  <span className="text-xs">What drives our economic engine...</span>
                  <span className="text-md text-primary font-semibold">{roadmap?.venns?.[2] || 'N/A'}</span>
                </div>
              </div>
            </div>
          </div>
        </CardContent>
      </Card>
      <div className="relative mx-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-start">
          <span className="pr-3 text-xl font-bold bg-gray-50 text-gray-500">Culture</span>
        </div>
      </div>
      <div className="grid grid-rows-3 sm:grid-rows-2 sm:grid-cols-2 lg:grid-rows-1 lg:grid-cols-3 mt-8 mb-4 mx-4 gap-y-6">
        <div className="flex-1 relative my-2 mx-2">
          <Card className="flex-1 m-0 h-full">
            <CardContent>
              <div className="flex flex-1 flex-col pt-2 px-4 text-xs">
                <div className="self-center text-gray-600 my-4">Values</div>
                <ul className="list-disc text-gray-800">
                  {Array.isArray(roadmap?.values) &&
                    roadmap?.values
                      ?.filter((value) => value?.title?.trim().length !== 0)
                      .map((value, i) => (
                        <li key={i}>
                          {value?.description && value?.description?.trim().length !== 0 ? (
                            <Popover className="relative">
                              <div>
                                <Popover.Button
                                  ref={buttonRef}
                                  onMouseEnter={() => {
                                    setShowPopover('value-' + i);
                                  }}
                                  onMouseLeave={() => setShowPopover(null)}
                                  className="text-left"
                                >
                                  {value?.title}
                                </Popover.Button>
                                <Transition
                                  show={showPopover === 'value-' + i}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Popover.Panel className="z-10 absolute rounded-lg border border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="h-full text-blue-500 m-2">
                                      <RichTextViewer text={value?.description} />
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </div>
                            </Popover>
                          ) : (
                            value?.title
                          )}
                        </li>
                      ))}
                </ul>
              </div>
            </CardContent>
          </Card>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-12 w-12 bg-primary-400 shadow-lg rounded-md p-3">
            <StarIcon color="white" />
          </div>
        </div>
        <div className="flex-1 relative my-2 mx-2">
          <Card className="flex-1 m-0 h-full">
            <CardContent>
              <div className="flex flex-1 flex-col pt-2 px-4 text-xs">
                <div className="self-center text-gray-600 my-4">Org Habits</div>
                <ul className="list-disc text-gray-800">
                  {Array.isArray(roadmap?.habits) &&
                    roadmap?.habits
                      ?.filter((habit) => habit?.title?.trim().length !== 0)
                      .map((habit, i) => (
                        <li key={i}>
                          {habit?.description && habit?.description?.trim().length !== 0 ? (
                            <Popover className="relative">
                              <div>
                                <Popover.Button
                                  ref={buttonRef}
                                  onMouseEnter={() => {
                                    setShowPopover('habit-' + i);
                                  }}
                                  onMouseLeave={() => setShowPopover(null)}
                                  className="text-left"
                                >
                                  {habit?.title}
                                </Popover.Button>
                                <Transition
                                  show={showPopover === 'habit-' + i}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Popover.Panel className="z-10 absolute rounded-lg border border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="h-full text-blue-500 m-2">
                                      <RichTextViewer text={habit?.description} />
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </div>
                            </Popover>
                          ) : (
                            habit?.title
                          )}
                        </li>
                      ))}
                </ul>
              </div>
            </CardContent>
          </Card>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-12 w-12 bg-primary-400 shadow-lg rounded-md p-3">
            <RefreshIcon color="white" />
          </div>
        </div>
        <div className="flex-1 relative my-2 mx-2">
          <Card className="flex-1 m-0 h-full">
            <CardContent>
              <div className="flex flex-1 flex-col pt-2 px-4 text-xs">
                <div className="self-center text-gray-600 my-4">Culture</div>
                <ul className="list-disc text-gray-800">
                  {Array.isArray(roadmap?.culture) &&
                    roadmap?.culture
                      ?.filter((c) => c?.title?.trim().length !== 0)
                      .map((c, i) => (
                        <li key={i}>
                          {c?.description && c?.description?.trim().length !== 0 ? (
                            <Popover className="relative">
                              <div>
                                <Popover.Button
                                  ref={buttonRef}
                                  onMouseEnter={() => {
                                    setShowPopover('culture-' + i);
                                  }}
                                  onMouseLeave={() => setShowPopover(null)}
                                  className="text-left"
                                >
                                  {c?.title}
                                </Popover.Button>
                                <Transition
                                  show={showPopover === 'culture-' + i}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Popover.Panel className="z-10 absolute rounded-lg border border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="h-full text-blue-500 m-2">
                                      <RichTextViewer text={c?.description} />
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </div>
                            </Popover>
                          ) : (
                            c?.title
                          )}
                        </li>
                      ))}
                </ul>
              </div>
            </CardContent>
          </Card>
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2 h-12 w-12 bg-primary-400 shadow-lg rounded-md p-3">
            <SparklesIcon color="white" />
          </div>
        </div>
      </div>
      <div className="relative m-6">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-200" />
        </div>
        <div className="relative flex justify-start">
          <span className="pr-3 text-xl font-bold bg-gray-50 text-gray-500">Bets</span>
        </div>
      </div>
      <div className="relative m-6">
        <Listbox
          value={team}
          onChange={(value) => {
            // to do set team_id
            setformBetsTeamId(value?.id);
          }}
        >
          {({ open }) => (
            <>
              <Listbox.Label className="block text-sm font-semibold text-gray-900">Team</Listbox.Label>
              <div className="mt-1 relative">
                <Listbox.Button
                  className={classNames(
                    button.white(),
                    'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                  )}
                >
                  {formBetsTeamId ? (
                    <span className="flex items-center">{team?.name}</span>
                  ) : (
                    <span className="flex items-center text-gray-500">Select a team</span>
                  )}
                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Listbox.Button>

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options
                    static
                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                  >
                    {teams?.map((teamObject) => (
                      <Listbox.Option
                        key={teamObject.id}
                        className={({ active }) =>
                          classNames(active ? 'bg-gray-100' : '', 'cursor-default select-none relative py-2 pl-3 pr-9')
                        }
                        value={teamObject}
                      >
                        {({ selected }) => (
                          <>
                            <div className="flex items-center">{teamObject.name}</div>

                            {selected ? (
                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>
      {bets.map((bet, i) => (
        <Fragment key={i}>
          <div className="mx-6 text-lg font-semibold text-gray-700">{bet.label}</div>
          <Card className={classNames(i === bets.length - 1 ? 'mb-20' : 'mb-4', 'mx-6 mt-4')}>
            <CardContent className="p-0">
              <div className="flex flex-col lg:flex-row">
                <div className="flex-1 grid grid-rows-4 xs:grid-cols-2 xs:grid-rows-2 p-4 border-b lg:border-r border-gray-100">
                  <div className="flex flex-col">
                    <label className="text-xs my-1 text-gray-600">Date</label>
                    <span className="text-primary text-md font-semibold">{bet.date || 'N/A'}</span>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs my-1 text-gray-600">Revenue</label>
                    <span className="text-primary text-md font-semibold">{bet.revenue || 'N/A'}</span>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs my-1 text-gray-600">Economic engine</label>
                    <span className="text-primary text-md font-semibold">{bet.economic || 'N/A'}</span>
                  </div>
                  <div className="flex flex-col">
                    <label className="text-xs my-1 text-gray-600">Profit</label>
                    <span className="text-primary text-md font-semibold">{bet.profit || 'N/A'}</span>
                  </div>
                </div>
                <div className="flex-1 p-4">
                  <label className="text-xs text-gray-600">
                    {i === 0 ? 'Objectives' : i === 1 ? '1 Year Plan' : '3 Year Direction'}
                  </label>
                  <ul>
                    {bet.objectives
                      ?.filter((objective) => objective?.title?.trim().length !== 0)
                      .map((objective, j) => (
                        <li key={j} className="text-sm text-gray-800 my-1 ml-4 list-disc">
                          {objective?.description && objective?.description?.trim().length !== 0 ? (
                            <Popover className="relative">
                              <div>
                                <Popover.Button
                                  ref={buttonRef}
                                  onMouseEnter={() => {
                                    setShowPopover('objective-' + i + j);
                                  }}
                                  onMouseLeave={() => setShowPopover(null)}
                                  className="text-left"
                                >
                                  {objective?.title}
                                </Popover.Button>
                                <Transition
                                  show={showPopover === 'objective-' + i + j}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Popover.Panel className="z-10 absolute rounded-lg border border-gray-400 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <div className="h-full text-blue-500 m-2">
                                      <RichTextViewer text={objective?.description} />
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </div>
                            </Popover>
                          ) : (
                            objective?.title
                          )}
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </CardContent>
          </Card>
        </Fragment>
      ))}
      <div className="relative text-center mb-9 -mt-9">
        <Link
          href={`/company/${router.query.company}/roadmap-objectives`}
          className="text-blue-500 underline text-sm font-semibold"
        >
          View Company Objectives Dashboard
        </Link>
      </div>
    </div>
  );
}
