import { gql } from 'urql';
import { COMPANY_FRAGMENT } from './fragments';

const GET_COMPANY = gql`
  query GetCompany($id: Int) {
    companies(where: { id: { _eq: $id } }) {
      ...CompanyFragment
      teams {
        id
        name

        company {
          id
          expired
        }
      }
    }
  }
  ${COMPANY_FRAGMENT}
`;

export default GET_COMPANY;
