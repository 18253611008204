import { gql } from 'urql';

const DELETE_TEAMS = gql`
  mutation DeleteTeam($where: teams_bool_exp!) {
    update_teams(where: $where, _set: { deleted: true }) {
      returning {
        id
      }
    }
  }
`;

export default DELETE_TEAMS;
