import React from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { classNames } from 'utils';

interface CollapseButtonProps {
  isOpen: boolean;
  onToggle: () => void;
}

const CollapseButton: React.FC<CollapseButtonProps> = ({ isOpen, onToggle }) => {
  return (
    <div className={classNames(isOpen ? 'mt-2 mr-5' : 'my-3.5 mr-2.5', 'relative z-10 cursor-pointer hidden sm:flex')}>
      <button className="primary" onClick={onToggle}>
        {isOpen ? (
          <div className="h-10">
            <ChevronRightIcon className="h-6 w-6 text-gray-200 flex justify-center  border border-gray-300" />
          </div>
        ) : (
          <ChevronLeftIcon className="h-6 w-6 mt-30 text-blue-200 flex justify-center border border-gray-300" />
        )}
      </button>
    </div>
  );
};

export default CollapseButton;
