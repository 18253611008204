import { captureException } from '@sentry/nextjs';
import { Dialog, Transition } from '@headlessui/react';
import { UPDATE_USER } from 'data/user';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation, OperationContext } from 'urql';
import { classNames } from 'utils';
import { User } from 'components/UsersList/UsersList';

type EditUserNameDialogProps = {
  setError: (err: CombinedError) => void;
  setUpdated: (str: string) => void;
  setOpen: (open: boolean) => void;
  user: User;
  open: boolean;
  refetchUsers: (opts?: Partial<OperationContext>) => void;
};

export default function EditUserNameDialog({
  setError,
  setUpdated,
  user,
  open,
  setOpen,
  refetchUsers,
}: EditUserNameDialogProps): ReactElement {
  const router = useRouter();
  const [, updateUser] = useMutation(UPDATE_USER);
  const [saving, setSaving] = useState(false);
  const cancelButtonRef = useRef(null);
  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setSaving(false)}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <form
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-sm sm:w-full"
              onSubmit={async (event) => {
                event.preventDefault();
                setSaving(true);
                const value = event.target['user-name'].value;
                const { error: updateError } = await updateUser({
                  set: {
                    name: value,
                  },
                  id: user?.id,
                });

                if (updateError) {
                  setError(updateError);
                  captureException(updateError);
                } else {
                  setUpdated('User updated');
                }
                setOpen(false);
                const companyId = router.query.company;
                refetchUsers({ variables: { companyId } });
              }}
            >
              <div>
                <div className="bg-white p-6">
                  <div className="flex items-start px-8">
                    <div className="w-full">
                      <Dialog.Title as="h2" className="my-4 text-2xl leading-6 font-semibold text-gray-900">
                        Edit Name
                      </Dialog.Title>
                      <Dialog.Description className="my-2 text-gray-900">
                        <span> For User:</span>
                        <span className="text-sm text-primary inline-block">{user?.email}</span>
                      </Dialog.Description>
                      <div className="my-2">
                        <input
                          type="text"
                          step="any"
                          name="user-name"
                          className="shadow-sm focus:ring-primary focus:border-primary block w-full sm:text-sm border-gray-300 rounded-md"
                          defaultValue={user?.name}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border border-grey px-4 py-3 flex justify-end">
                <button
                  type="button"
                  className="w-auto inline-flex justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={saving}
                  className={classNames(
                    button.primary({ isDisabled: saving }),
                    'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                  )}
                >
                  Save
                </button>
              </div>
            </form>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
