import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { ErrorToast, Loader } from 'components';
import { DELETE_ORG, UPDATE_ORG } from 'data/org';
import { Org } from 'data/org/types';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useRef, useState } from 'react';
import { CombinedError, useMutation } from 'urql';
import { classNames, refreshData } from 'utils';
import { getChildren } from 'components/ReactOrgChart/react/OrgChart';

type OrgChartNodeDeleteConfirmationProps = {
  org: Org[];
  removing: number;
  setRemoving: (editing: number) => void;
  setViewing: (viewing: boolean) => void;
  setRefresh: (refresh: boolean) => void;
  setMessage: (message: string) => void;
};

export default function OrgChartNodeDeleteConfirmation({
  org,
  removing,
  setRemoving,
  setRefresh,
  setViewing,
  setMessage,
}: OrgChartNodeDeleteConfirmationProps): ReactElement {
  const router = useRouter();
  const [error, setError] = useState<CombinedError>();
  const [deleting, setDeleting] = useState<boolean>(false);

  const [, updateOrg] = useMutation(UPDATE_ORG);
  const [, deleteOrg] = useMutation(DELETE_ORG);

  const cancelButtonRef = useRef(null);

  return (
    <>
      <ErrorToast error={error} setError={setError} />
      <Transition.Root show={removing !== null} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={removing !== null}
          onClose={() => setRemoving(null)}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="flex items-start px-8">
                  <div className="py-6 w-full">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                      Are you sure you want to delete this title?
                    </Dialog.Title>
                  </div>
                </div>
                <button ref={cancelButtonRef} onClick={() => setRemoving(null)}>
                  <XIcon className="absolute top-4 cursor-pointer right-4 h-6 w-6" />
                </button>
                <div className="border px-4 py-3 flex justify-end">
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                    onClick={() => setRemoving(null)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'bg-red hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
                      'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                    )}
                    onClick={async () => {
                      setDeleting(true);
                      const id = removing;
                      const orgToRemove = org.find((o) => o.id === id);
                      const parent = orgToRemove.parent;
                      const children = getChildren(orgToRemove, org);
                      const siblings = getChildren(
                        org.find((o) => o.id === parent),
                        org,
                      );

                      const updateNodes = [];
                      const curIndex = siblings.map((s) => s.id).indexOf(id);
                      const left =
                        siblings?.find((sibling) => sibling?.right_sibling === id) || siblings[0]?.id !== id
                          ? siblings?.[curIndex - 1]
                          : null;
                      const right =
                        siblings?.find((sibling) => sibling?.id === orgToRemove?.right_sibling) ||
                        siblings[siblings.length - 1]?.id !== id
                          ? siblings?.[curIndex + 1]
                          : null;

                      // Update right sibling' left sibling
                      if (right) {
                        siblings
                          ?.filter((sibling) => sibling?.right_sibling === right?.id)
                          .map((sibling) => {
                            updateNodes.push({ id: sibling.id, set: { right_sibling: null } });
                          });
                      }

                      if (children.length > 0) {
                        const childrenRightSiblingIds = children
                          ?.filter((child) => child.right_sibling !== null)
                          ?.map(({ right_sibling }) => right_sibling);
                        const leftMostChild = children.find((child) => !childrenRightSiblingIds.includes(child?.id));
                        const rightMostChild = children[children.length - 1];
                        if (left) {
                          updateNodes.push({ id: left.id, set: { right_sibling: leftMostChild?.id || null } });
                        }
                        children.map((child) => {
                          const set =
                            child.id === rightMostChild?.id
                              ? { parent: parent, right_sibling: right?.id || null }
                              : { parent: parent };
                          updateNodes.push({ id: child.id, set: set });
                        });
                      } else if (left) {
                        updateNodes.push({ id: left.id, set: { right_sibling: right?.id || null } });
                      }

                      let err: CombinedError;
                      await Promise.all(
                        updateNodes.map(async (node) => {
                          const updateRes = await updateOrg({
                            id: node.id,
                            set: node.set,
                          });

                          if (updateRes.error) {
                            err = updateRes.error;
                          }
                        }),
                      );

                      const deleteRes = await deleteOrg({
                        id: removing,
                      });
                      if (deleteRes.error) {
                        err = deleteRes.error;
                      }

                      if (err) {
                        setError(err);
                      } else {
                        await refreshData(router);
                        setMessage('Removed');
                        setRefresh(true);
                      }

                      setDeleting(false);
                      setRemoving(null);
                      setViewing(false);
                    }}
                  >
                    {deleting ? <Loader color="text-white" /> : 'Delete'}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
