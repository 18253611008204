import d3 from 'd3';
import { Config } from 'types/d3';
import { convertImageToBase64, helpers, wrapText } from '../utils';
import supervisorIcon from './components/supervisorIcon';
import { onExpandClick, onLeftClick, onRightClick, PERSON_REPORTS_CLASS } from './onClick';
import renderLines from './renderLines';
import { getVOPSValue, genuisAreas } from 'components/UsersList/UsersList';
import { drag } from './onDrag';

export const CHART_NODE_CLASS = 'org-chart-node';
export const CHART_NODE_DRAGGING_CLASS = 'org-chart-node-drag';
const PERSON_NAME_CLASS = 'org-chart-person-name';
const PERSON_TITLE_CLASS = 'org-chart-person-title';
const PERSON_HIGHLIGHT = 'org-chart-person-highlight';
const CIRCLE_TRANSFORM_Y = 15;

const INTERIM_STATUS_COLOR = '#FFC864';
const OPEN_STATUS_COLOR = '#24C196';

function render(config: Config): void {
  const {
    svg,
    tree,
    animationDuration,
    nodeWidth,
    nodeHeight,
    nodePaddingX,
    nodePaddingY,
    backgroundColor,
    nameColor,
    titleColor,
    reportsColor,
    borderColor,
    avatarWidth,
    lineDepthY,
    treeData,
    sourceNode,
    onPersonClick,
    onConfigChange,
    onMovePerson,
    onAddClick,
    onMoveUpDown,
  } = config;

  function centerNode(source) {
    const scale = config.zoom.scale();
    const x = -source.x0 * scale + config.elemWidth / 2;
    const y = -source.y0 * scale + config.elemHeight / 2;
    d3.select('g')
      .transition()
      .duration(750)
      .attr('transform', 'translate(' + x + ',' + y + ')scale(' + scale + ')');
    config.zoom.scale(scale);
    config.zoom.translate([x, y]);
    config.center_node = null;
  }

  // Compute the new tree layout.
  const nodes = tree.nodes(treeData).reverse();
  const links = tree.links(nodes);

  config.links = links;
  config.nodes = nodes;

  // Normalize for fixed-depth.
  nodes.forEach(function (d) {
    d.y = d.depth * lineDepthY;
  });

  // Update the nodes
  const node = svg.selectAll('g.' + CHART_NODE_CLASS).data(
    nodes.filter((d) => `${d.id}`),
    (d) => `${d.id}`,
  );

  const parentNode = sourceNode || treeData;

  svg.selectAll('#supervisorIcon').remove();

  supervisorIcon({
    svg,
    config,
    treeData,
  });

  // Enter any new nodes at the parent's previous position.
  const nodeEnter = node
    .enter()
    .insert('g')
    .call(drag(config))
    .attr('class', CHART_NODE_CLASS)
    .attr('data-id', (d) => d.id)
    .attr('transform', `translate(${parentNode.x0}, ${parentNode.y0})`);

  // Person Card Shadow
  nodeEnter
    .append('rect')
    .attr('width', nodeWidth)
    .attr('height', nodeHeight)
    .attr('fill', backgroundColor)
    .attr('stroke', (d) =>
      d.person?.org_status === 'interim'
        ? INTERIM_STATUS_COLOR
        : d.person?.org_status === 'open'
        ? OPEN_STATUS_COLOR
        : borderColor,
    )
    .attr('pointer-events', 'none')
    .attr('fill-opacity', 0.05)
    .attr('stroke-opacity', 0.025)
    .attr('filter', 'url(#boxShadow)');

  // Person Card Container
  const expanderHeight = 40;
  const contentHeight = nodeHeight - expanderHeight;

  nodeEnter
    .append('rect')
    .attr('class', (d) => (d.isHighlight ? `${PERSON_HIGHLIGHT} box` : 'box'))
    .attr('width', nodeWidth)
    .attr('height', contentHeight)
    .attr('id', (d) => d.id)
    .attr('fill', backgroundColor)
    .attr('stroke', (d) =>
      d.person?.org_status === 'interim'
        ? INTERIM_STATUS_COLOR
        : d.person?.org_status === 'open'
        ? OPEN_STATUS_COLOR
        : borderColor,
    )
    .style('cursor', 'pointer');

  nodeEnter
    .append('rect')
    .attr('class', (d) => (d.isHighlight ? `${PERSON_HIGHLIGHT} box` : 'box'))
    .attr('width', nodeWidth)
    .attr('height', expanderHeight)
    .attr('id', (d) => `${d.id}-expander`)
    .attr('fill', backgroundColor)
    .attr('stroke', (d) =>
      d.person?.org_status === 'interim'
        ? INTERIM_STATUS_COLOR
        : d.person?.org_status === 'open'
        ? OPEN_STATUS_COLOR
        : borderColor,
    )
    .attr('transform', () => `translate(0,${contentHeight})`);

  // Dragging area
  nodeEnter
    .append('rect')
    .attr('class', CHART_NODE_DRAGGING_CLASS)
    .attr('width', nodeWidth)
    .attr('height', nodeHeight)
    .attr('opacity', '0.1')
    .attr('fill', backgroundColor)
    .attr('pointer-events', 'mouseover')
    .on('mousemove', function (d) {
      config.selectedNode = d;
      d3.select(`[data-id="` + d.id + `"]`)
        .select('.' + CHART_NODE_DRAGGING_CLASS)
        .style('stroke', 'blue')
        .style('stroke-width', 2)
        .attr('opacity', '0.5');
      onConfigChange(config);
    })
    .on('mouseout', function (d) {
      config.selectedNode = null;
      d3.select(`[data-id="` + d.id + `"]`)
        .select('.' + CHART_NODE_DRAGGING_CLASS)
        .style('fill', backgroundColor)
        .style('stroke-width', 1)
        .attr('opacity', '0.1');
      onConfigChange(config);
    });

  const namePos = {
    x: nodeWidth / 2,
    y: nodePaddingY * 1.8 + avatarWidth,
  };

  const avatarPos = {
    x: nodeWidth / 2 - avatarWidth / 2,
    y: nodePaddingY,
  };

  // Person's Name
  nodeEnter
    .append('text')
    .attr('class', PERSON_NAME_CLASS + ' unedited')
    .attr('x', namePos.x)
    .attr('y', namePos.y)
    .attr('dy', '.3em')
    .attr('pointer-events', 'none')
    .style('cursor', 'pointer')
    .style('fill', nameColor)
    .style('font-weight', 'bold')
    .style('font-size', 14)
    .text((d) => {
      const name = d.person.user_typed_name || d.person.name;
      const formatName = name ? name.trim() : name;
      return formatName?.length > 18 ? formatName?.slice(0, 14).concat('...') : formatName;
    });

  function generateAssessmentHTMLContent(d) {
    const wgSkills = d?.person?.wg_skills;
    const otherAssessments = d?.person?.other_assessments;
    const vopsValue = getVOPSValue(d?.person?.vops_skills);
    const enabledAssessments = d?.person?.enabled_assessments;
    const wgMarginX = enabledAssessments && vopsValue ? '0.125rem;' : '1rem;';
    const vopsMarginX = enabledAssessments && wgSkills ? '0.125rem;' : '4.5rem;';

    const htmlContent =
      (enabledAssessments && wgSkills) ||
      (enabledAssessments && vopsValue) ||
      (enabledAssessments && otherAssessments && Array.isArray(otherAssessments) && otherAssessments.length > 0)
        ? '<div style="display: flex; background-color: rgba(255, 255, 255, 1); border-color: rgba(156, 162, 175, 1);  border-width: 2px;  border-radius: 0.5rem; height: auto;">'
            .concat(
              enabledAssessments && wgSkills
                ? '<div style="display: flex; margin-left:' +
                    wgMarginX +
                    'margin-right:' +
                    wgMarginX +
                    '">' +
                    genuisAreas
                      .filter((area) => area?.id !== '1')
                      .map((area) => {
                        if (wgSkills) {
                          const values = Object.keys(wgSkills).filter((key) => wgSkills[key] === area.id);
                          return (
                            '<div style="display: flex; margin-top: 0.25rem; margin-bottom: 0.25rem; margin-left: 0.125rem; margin-right: 0.125rem;">' +
                            '<div style="display: flex; position: relative;">' +
                            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.61 39.67" stroke="' +
                            area.color +
                            '"style="width: 1.25rem; height: 1.25rem;" fill=' +
                            area.color +
                            '>' +
                            '<path d="M39.6,22.11c.07-.62-.15-.88-.73-1.05l-2.14-.63c-.87-.26-.87-.26-.91-1.18a.53.53,0,0,0,0-.13l-.61-4.25,2.47-1.62c.59-.38.71-.68.44-1.31a19.86,19.86,0,0,0-2.39-4.11,3.24,3.24,0,0,0-.56-.55.83.83,0,0,0-1-.08c-.91.52-1.85,1-2.64,1.43L27.16,5.4c.2-.93.43-2,.65-3a.81.81,0,0,0-.53-1A19.89,19.89,0,0,0,22.08,0,.87.87,0,0,0,21,.72c-.28.89-.57,1.78-.82,2.67a.46.46,0,0,1-.55.4,5.74,5.74,0,0,0-1.17.06c-1.2.18-2.4.39-3.65.59L13.09,1.78c-.26-.4-.58-.55-.95-.39A20.25,20.25,0,0,0,7.33,4.23a.86.86,0,0,0-.21,1.21c.21.4.44.79.65,1.19.3.55.58,1.1.86,1.65a15.62,15.62,0,0,0-2.91,3.81.48.48,0,0,1-.62.29c-.88-.19-1.77-.37-2.66-.53-.69-.13-1,0-1.22.65-.42,1.33-.78,2.68-1.13,4A4.37,4.37,0,0,0,0,17.79a.85.85,0,0,0,.73.85c.9.24,1.78.55,2.67.81a.37.37,0,0,1,.31.44A7.07,7.07,0,0,0,3.78,21c.18,1.25.39,2.5.59,3.78L1.79,26.48a.82.82,0,0,0-.36,1.22A19.93,19.93,0,0,0,4.1,32.21a.9.9,0,0,0,1.33.26c.84-.45,1.68-.89,2.51-1.35a.33.33,0,0,1,.48.07A16.66,16.66,0,0,0,12.11,34c.2.12.28.21.22.47-.21,1-.39,2-.59,2.92a.8.8,0,0,0,.54,1,19.57,19.57,0,0,0,5.16,1.31.91.91,0,0,0,1.13-.75c.26-.88.51-1.77.79-2.64.05-.15.25-.35.39-.35a17.51,17.51,0,0,0,4.89-.77l.46.67c.46.68.89,1.38,1.37,2a.81.81,0,0,0,1.24.29c.95-.48,1.9-1,2.81-1.51a10.15,10.15,0,0,0,1.7-1.29c.45-.41.43-.72.14-1.25-.44-.81-.88-1.61-1.29-2.43a.48.48,0,0,1,0-.44,19.45,19.45,0,0,0,2.82-3.76.52.52,0,0,1,.4-.18c.93.17,1.86.35,2.79.55.63.14,1,0,1.2-.61.34-1,.67-2.05,1-3.09A15.34,15.34,0,0,0,39.6,22.11ZM16.84,31.35a11.87,11.87,0,1,1,14.41-8.59A11.87,11.87,0,0,1,16.84,31.35Z"></path></svg>' +
                            '<div style="text-transform: capitalize; font-weight: 600; font-size: 0.75rem; line-height: 1rem; justify-content: space-around; width: 1.25rem; height: 1.25rem ; display: flex; position: absolute; margin-top: 0.125rem;">' +
                            '<span>' +
                            values[1]?.charAt(0) +
                            '</span>' +
                            '</div>' +
                            '</div>' +
                            '<div style="display: flex; position: relative;">' +
                            '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.61 39.67" stroke="' +
                            area.color +
                            '"style="width: 1.25rem ; height: 1.25rem;" fill=' +
                            area.color +
                            '>' +
                            '<path d="M39.6,22.11c.07-.62-.15-.88-.73-1.05l-2.14-.63c-.87-.26-.87-.26-.91-1.18a.53.53,0,0,0,0-.13l-.61-4.25,2.47-1.62c.59-.38.71-.68.44-1.31a19.86,19.86,0,0,0-2.39-4.11,3.24,3.24,0,0,0-.56-.55.83.83,0,0,0-1-.08c-.91.52-1.85,1-2.64,1.43L27.16,5.4c.2-.93.43-2,.65-3a.81.81,0,0,0-.53-1A19.89,19.89,0,0,0,22.08,0,.87.87,0,0,0,21,.72c-.28.89-.57,1.78-.82,2.67a.46.46,0,0,1-.55.4,5.74,5.74,0,0,0-1.17.06c-1.2.18-2.4.39-3.65.59L13.09,1.78c-.26-.4-.58-.55-.95-.39A20.25,20.25,0,0,0,7.33,4.23a.86.86,0,0,0-.21,1.21c.21.4.44.79.65,1.19.3.55.58,1.1.86,1.65a15.62,15.62,0,0,0-2.91,3.81.48.48,0,0,1-.62.29c-.88-.19-1.77-.37-2.66-.53-.69-.13-1,0-1.22.65-.42,1.33-.78,2.68-1.13,4A4.37,4.37,0,0,0,0,17.79a.85.85,0,0,0,.73.85c.9.24,1.78.55,2.67.81a.37.37,0,0,1,.31.44A7.07,7.07,0,0,0,3.78,21c.18,1.25.39,2.5.59,3.78L1.79,26.48a.82.82,0,0,0-.36,1.22A19.93,19.93,0,0,0,4.1,32.21a.9.9,0,0,0,1.33.26c.84-.45,1.68-.89,2.51-1.35a.33.33,0,0,1,.48.07A16.66,16.66,0,0,0,12.11,34c.2.12.28.21.22.47-.21,1-.39,2-.59,2.92a.8.8,0,0,0,.54,1,19.57,19.57,0,0,0,5.16,1.31.91.91,0,0,0,1.13-.75c.26-.88.51-1.77.79-2.64.05-.15.25-.35.39-.35a17.51,17.51,0,0,0,4.89-.77l.46.67c.46.68.89,1.38,1.37,2a.81.81,0,0,0,1.24.29c.95-.48,1.9-1,2.81-1.51a10.15,10.15,0,0,0,1.7-1.29c.45-.41.43-.72.14-1.25-.44-.81-.88-1.61-1.29-2.43a.48.48,0,0,1,0-.44,19.45,19.45,0,0,0,2.82-3.76.52.52,0,0,1,.4-.18c.93.17,1.86.35,2.79.55.63.14,1,0,1.2-.61.34-1,.67-2.05,1-3.09A15.34,15.34,0,0,0,39.6,22.11ZM16.84,31.35a11.87,11.87,0,1,1,14.41-8.59A11.87,11.87,0,0,1,16.84,31.35Z"></path></svg>' +
                            '<div style="text-transform: capitalize; font-weight: 600; font-size: 0.75rem; line-height: 1rem; justify-content: space-around; width: 1.25rem; height: 1.25rem ; display: flex; position: absolute; margin-top: 0.125rem;">' +
                            '<span>' +
                            values[0]?.charAt(0) +
                            '</span>' +
                            '</div>' +
                            '</div>' +
                            '</div>'
                          );
                        }
                      })
                      .join('') +
                    '</div>'
                : '',
            )
            .concat(
              enabledAssessments && wgSkills && enabledAssessments && vopsValue
                ? '<div style="border-left: 1px solid black; height: 15px; margin-top: 0.375rem ;"></div>'
                : '',
            )
            .concat(
              enabledAssessments && vopsValue
                ? '<div style="font-weight: 600; font-size: 0.75rem; line-height: 1rem;">' +
                    '<div style="margin-top: 0.375rem; margin-bottom: 0.25rem; margin-left:' +
                    vopsMarginX +
                    'margin-right:' +
                    vopsMarginX +
                    '">' +
                    vopsValue +
                    '</div>' +
                    '</div>'
                : '',
            )
            .concat(
              enabledAssessments && (wgSkills || vopsValue)
                ? '</div>' // Close the previous container if wgSkills or vopsValue was enabled
                : '',
            )
            .concat(
              '<div style="margin-top: 0.25rem;">', // Container for otherAssessments
            )
            .concat(
              otherAssessments && otherAssessments.length > 0
                ? otherAssessments
                    .map((assessment) => {
                      return (
                        '<div style="font-weight: 400; font-size: 0.75rem; line-height: 1rem; padding: 0.3rem">' +
                        assessment +
                        '</div>'
                      );
                    })
                    .join('')
                : '',
            )
            .concat('</div>') + '</div>'
        : '';
    return htmlContent;
  }

  function generateMissionHtmlContent(d) {
    const missionText = d.person?.mission;
    return `<div style="display: flex; background-color: rgba(255, 255, 255, 1); height: auto; font-weight: 400; font-size: 0.75rem; line-height: 1rem; padding: 0.3rem;">
      ${missionText}
    </div>`;
  }

  // Person's WG/VOPS Profile
  // nodeEnter
  //   .append('foreignObject')
  //   .attr('class', PERSON_PROFILE_CLASS + ' unedited')
  //   .attr('x', nodeWidth / 120)
  //   .attr('y', namePos.y + nodePaddingY * 1.8)
  //   .attr('width', 176)
  //   .attr('height', 36)
  //   .html((d) => {
  //     const wgSkills = d?.person?.wg_skills;
  //     const vopsValue = getVOPSValue(d?.person?.vops_skills);
  //     const enabledAssessments = d?.person?.enabled_assessments;
  //     const wgMarginX = enabledAssessments && vopsValue ? '0.125rem;' : '1rem;';
  //     const vopsMarginX = enabledAssessments && wgSkills ? '0.125rem;' : '4.5rem;';

  //     return (enabledAssessments && wgSkills) || (enabledAssessments && vopsValue)
  //       ? '<div style="display: flex; background-color: rgba(255, 255, 255, 1); border-color: rgba(156, 162, 175, 1);  border-width: 2px;  border-radius: 0.5rem; height: 2rem;">'
  //           .concat(
  //             enabledAssessments && wgSkills
  //               ? '<div style="display: flex; margin-left:' +
  //                   wgMarginX +
  //                   'margin-right:' +
  //                   wgMarginX +
  //                   '">' +
  //                   genuisAreas
  //                     .filter((area) => area?.id !== '1')
  //                     .map((area) => {
  //                       if (wgSkills) {
  //                         const values = Object.keys(wgSkills).filter((key) => wgSkills[key] === area.id);
  //                         return (
  //                           '<div style="display: flex; margin-top: 0.25rem; margin-bottom: 0.25rem; margin-left: 0.125rem; margin-right: 0.125rem;">' +
  //                           '<div style="display: flex; position: relative;">' +
  //                           '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.61 39.67" stroke="' +
  //                           area.color +
  //                           '"style="width: 1.25rem; height: 1.25rem;" fill=' +
  //                           area.color +
  //                           '>' +
  //                           '<path d="M39.6,22.11c.07-.62-.15-.88-.73-1.05l-2.14-.63c-.87-.26-.87-.26-.91-1.18a.53.53,0,0,0,0-.13l-.61-4.25,2.47-1.62c.59-.38.71-.68.44-1.31a19.86,19.86,0,0,0-2.39-4.11,3.24,3.24,0,0,0-.56-.55.83.83,0,0,0-1-.08c-.91.52-1.85,1-2.64,1.43L27.16,5.4c.2-.93.43-2,.65-3a.81.81,0,0,0-.53-1A19.89,19.89,0,0,0,22.08,0,.87.87,0,0,0,21,.72c-.28.89-.57,1.78-.82,2.67a.46.46,0,0,1-.55.4,5.74,5.74,0,0,0-1.17.06c-1.2.18-2.4.39-3.65.59L13.09,1.78c-.26-.4-.58-.55-.95-.39A20.25,20.25,0,0,0,7.33,4.23a.86.86,0,0,0-.21,1.21c.21.4.44.79.65,1.19.3.55.58,1.1.86,1.65a15.62,15.62,0,0,0-2.91,3.81.48.48,0,0,1-.62.29c-.88-.19-1.77-.37-2.66-.53-.69-.13-1,0-1.22.65-.42,1.33-.78,2.68-1.13,4A4.37,4.37,0,0,0,0,17.79a.85.85,0,0,0,.73.85c.9.24,1.78.55,2.67.81a.37.37,0,0,1,.31.44A7.07,7.07,0,0,0,3.78,21c.18,1.25.39,2.5.59,3.78L1.79,26.48a.82.82,0,0,0-.36,1.22A19.93,19.93,0,0,0,4.1,32.21a.9.9,0,0,0,1.33.26c.84-.45,1.68-.89,2.51-1.35a.33.33,0,0,1,.48.07A16.66,16.66,0,0,0,12.11,34c.2.12.28.21.22.47-.21,1-.39,2-.59,2.92a.8.8,0,0,0,.54,1,19.57,19.57,0,0,0,5.16,1.31.91.91,0,0,0,1.13-.75c.26-.88.51-1.77.79-2.64.05-.15.25-.35.39-.35a17.51,17.51,0,0,0,4.89-.77l.46.67c.46.68.89,1.38,1.37,2a.81.81,0,0,0,1.24.29c.95-.48,1.9-1,2.81-1.51a10.15,10.15,0,0,0,1.7-1.29c.45-.41.43-.72.14-1.25-.44-.81-.88-1.61-1.29-2.43a.48.48,0,0,1,0-.44,19.45,19.45,0,0,0,2.82-3.76.52.52,0,0,1,.4-.18c.93.17,1.86.35,2.79.55.63.14,1,0,1.2-.61.34-1,.67-2.05,1-3.09A15.34,15.34,0,0,0,39.6,22.11ZM16.84,31.35a11.87,11.87,0,1,1,14.41-8.59A11.87,11.87,0,0,1,16.84,31.35Z"></path></svg>' +
  //                           '<div style="text-transform: capitalize; font-weight: 600; font-size: 0.75rem; line-height: 1rem; justify-content: space-around; width: 1.25rem; height: 1.25rem ; display: flex; position: absolute; margin-top: 0.125rem;">' +
  //                           '<span>' +
  //                           values[1]?.charAt(0) +
  //                           '</span>' +
  //                           '</div>' +
  //                           '</div>' +
  //                           '<div style="display: flex; position: relative;">' +
  //                           '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39.61 39.67" stroke="' +
  //                           area.color +
  //                           '"style="width: 1.25rem ; height: 1.25rem;" fill=' +
  //                           area.color +
  //                           '>' +
  //                           '<path d="M39.6,22.11c.07-.62-.15-.88-.73-1.05l-2.14-.63c-.87-.26-.87-.26-.91-1.18a.53.53,0,0,0,0-.13l-.61-4.25,2.47-1.62c.59-.38.71-.68.44-1.31a19.86,19.86,0,0,0-2.39-4.11,3.24,3.24,0,0,0-.56-.55.83.83,0,0,0-1-.08c-.91.52-1.85,1-2.64,1.43L27.16,5.4c.2-.93.43-2,.65-3a.81.81,0,0,0-.53-1A19.89,19.89,0,0,0,22.08,0,.87.87,0,0,0,21,.72c-.28.89-.57,1.78-.82,2.67a.46.46,0,0,1-.55.4,5.74,5.74,0,0,0-1.17.06c-1.2.18-2.4.39-3.65.59L13.09,1.78c-.26-.4-.58-.55-.95-.39A20.25,20.25,0,0,0,7.33,4.23a.86.86,0,0,0-.21,1.21c.21.4.44.79.65,1.19.3.55.58,1.1.86,1.65a15.62,15.62,0,0,0-2.91,3.81.48.48,0,0,1-.62.29c-.88-.19-1.77-.37-2.66-.53-.69-.13-1,0-1.22.65-.42,1.33-.78,2.68-1.13,4A4.37,4.37,0,0,0,0,17.79a.85.85,0,0,0,.73.85c.9.24,1.78.55,2.67.81a.37.37,0,0,1,.31.44A7.07,7.07,0,0,0,3.78,21c.18,1.25.39,2.5.59,3.78L1.79,26.48a.82.82,0,0,0-.36,1.22A19.93,19.93,0,0,0,4.1,32.21a.9.9,0,0,0,1.33.26c.84-.45,1.68-.89,2.51-1.35a.33.33,0,0,1,.48.07A16.66,16.66,0,0,0,12.11,34c.2.12.28.21.22.47-.21,1-.39,2-.59,2.92a.8.8,0,0,0,.54,1,19.57,19.57,0,0,0,5.16,1.31.91.91,0,0,0,1.13-.75c.26-.88.51-1.77.79-2.64.05-.15.25-.35.39-.35a17.51,17.51,0,0,0,4.89-.77l.46.67c.46.68.89,1.38,1.37,2a.81.81,0,0,0,1.24.29c.95-.48,1.9-1,2.81-1.51a10.15,10.15,0,0,0,1.7-1.29c.45-.41.43-.72.14-1.25-.44-.81-.88-1.61-1.29-2.43a.48.48,0,0,1,0-.44,19.45,19.45,0,0,0,2.82-3.76.52.52,0,0,1,.4-.18c.93.17,1.86.35,2.79.55.63.14,1,0,1.2-.61.34-1,.67-2.05,1-3.09A15.34,15.34,0,0,0,39.6,22.11ZM16.84,31.35a11.87,11.87,0,1,1,14.41-8.59A11.87,11.87,0,0,1,16.84,31.35Z"></path></svg>' +
  //                           '<div style="text-transform: capitalize; font-weight: 600; font-size: 0.75rem; line-height: 1rem; justify-content: space-around; width: 1.25rem; height: 1.25rem ; display: flex; position: absolute; margin-top: 0.125rem;">' +
  //                           '<span>' +
  //                           values[0]?.charAt(0) +
  //                           '</span>' +
  //                           '</div>' +
  //                           '</div>' +
  //                           '</div>'
  //                         );
  //                       }
  //                     })
  //                     .join('') +
  //                   '</div>'
  //               : '',
  //           )
  //           .concat(
  //             enabledAssessments && wgSkills && enabledAssessments && vopsValue
  //               ? '<div style="border-left: 1px solid black; height: 15px; margin-top: 0.375rem ;"></div>'
  //               : '',
  //           )
  //           .concat(
  //             enabledAssessments && vopsValue
  //               ? '<div style="font-weight: 600; font-size: 0.75rem; line-height: 1rem;">' +
  //                   '<div style="margin-top: 0.375rem; margin-bottom: 0.25rem; margin-left:' +
  //                   vopsMarginX +
  //                   'margin-right:' +
  //                   vopsMarginX +
  //                   '">' +
  //                   vopsValue +
  //                   '</div>' +
  //                   '</div>'
  //               : '',
  //           ) + '<div>'
  //       : '';
  //   });

  // Person's Title
  nodeEnter
    .append('text')
    .attr('class', PERSON_TITLE_CLASS + ' unedited')
    .attr('x', nodeWidth / 2)
    .attr('y', namePos.y + nodePaddingY * 1.6)
    .attr('dy', '0.1em')
    .attr('pointer-events', 'none')
    .style('font-size', 14)
    .style('cursor', 'pointer')
    .style('fill', titleColor)
    .text((d) => {
      const title = d.person?.title ? d.person.title.trim() : d.person?.title;
      return title?.length > 18 ? title?.slice(0, 15).concat('...') : title;
    });

  // Person's mission
  nodeEnter
    .append('text')
    .attr('pointer-events', 'mouseover')
    .attr('class', PERSON_TITLE_CLASS + ' unedited')
    .attr('id', (d) => `mission-${d.id}`)
    .attr('x', nodeWidth / 2)
    .attr('y', namePos.y + nodePaddingY * 3.6)
    .attr('dy', '0.1em')
    .style('font-size', 12)
    .style('cursor', 'pointer')
    .style('fill', titleColor)
    .text((d) => {
      const mission = d.person?.mission ? d.person.mission.trim() : d.person?.mission;
      return mission?.length > 30 ? mission?.slice(0, 30).concat('...') : mission;
    })
    .on('mouseover', function (d) {
      if (d.person?.mission && d.person.mission.length > 30) {
        const missionText = d3.select(`#mission-${d.id}`).node();
        const bbox = (missionText as Element).getBoundingClientRect();
        const missionHtmlContent = generateMissionHtmlContent(d);
        const popupX = bbox.x + bbox.width + 10; // 10px to the right of the avatar
        const popupY = bbox.y; // Align with the top of the avatar
        const tooltipDiv = d3
          .select('body')
          .append('div')
          .attr('class', 'tooltip')
          .style('position', 'absolute')
          .style('left', `${popupX}px`)
          .style('top', `${popupY}px`)
          .style('background', 'white')
          .style('padding', '5px')
          .style('border', '1px solid black')
          .style('border-radius', '5px')
          .style('display', 'block') // Ensure it's visible
          .html(missionHtmlContent);
      }
    })
    .on('mouseout', function () {
      d3.select('.tooltip').remove();
    });

  const optionsPanel = nodeEnter.append('g');

  const plusWidth = 20;
  const plusHeight = 20;

  if (onAddClick) {
    optionsPanel
      .append('text')
      .attr('x', nodePaddingX * 11 + plusWidth)
      .attr('dx', '0.2em')
      .attr('y', nodeHeight - (expanderHeight - plusHeight / 2))
      .attr('dy', '0.8em')
      .style('font-size', plusHeight)
      .attr('fill', titleColor)
      .style('cursor', 'pointer')
      .text('+')
      .on('click', (d) => {
        const children = d.children || d._children || [];
        const rightSiblingIds = children
          ?.filter((child) => child.right_sibling !== null)
          ?.map(({ right_sibling }) => right_sibling);
        const right =
          (rightSiblingIds.length > 0
            ? children?.filter((child) => !rightSiblingIds.includes(child.id))?.[0]?.id
            : children[0]?.id) || null;
        onAddClick(d, right);
      });
  }

  if (onPersonClick) {
    optionsPanel
      .append('foreignObject')
      .attr('x', nodeWidth - plusWidth - nodePaddingX)
      .attr('dx', '0.2em')
      .attr('y', nodeHeight - (expanderHeight - plusHeight / 2))
      .attr('dy', '2em')
      .style('cursor', 'pointer')
      .attr('width', 25)
      .attr('height', 25)
      .html(() => {
        return (
          '<div><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="' +
          titleColor +
          '" class="w-4 h-4">' +
          '<path d="M21.731 2.269a2.625 2.625 0 00-3.712 0l-1.157 1.157 3.712 3.712 1.157-1.157a2.625 2.625 0 000-3.712zM19.513 8.199l-3.712-3.712-8.4 8.4a5.25 5.25 0 00-1.32 2.214l-.8 2.685a.75.75 0 00.933.933l2.685-.8a5.25 5.25 0 002.214-1.32l8.4-8.4z" />' +
          '<path d="M5.25 5.25a3 3 0 00-3 3v10.5a3 3 0 003 3h10.5a3 3 0 003-3V13.5a.75.75 0 00-1.5 0v5.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5V8.25a1.5 1.5 0 011.5-1.5h5.25a.75.75 0 000-1.5H5.25z" />' +
          '</svg> <div/>'
        );
      })
      .on('click', function (datum) {
        (d3.event as Event).stopPropagation();
        if (onPersonClick) {
          onPersonClick(datum, d3.event as Event);
        }
      });
  }

  if (onMovePerson) {
    optionsPanel
      .append('text')
      .attr('id', (d) => `left-${d.id}`)
      .attr('x', nodePaddingX)
      .attr('dx', '0.2em')
      .attr('y', nodeHeight - (expanderHeight - plusHeight / 2))
      .attr('dy', '0.8em')
      .style('cursor', (d) => {
        const siblings = d.parent?.children || d.parent?._children || [];
        return !d.parent || siblings[0]?.id === d.id ? 'default' : 'pointer';
      })
      .text('<')
      .attr('fill', (d) => {
        const siblings = d.parent?.children || d.parent?._children || [];
        return !d.parent || siblings[0]?.id === d.id ? borderColor : titleColor;
      })
      .on('click', function (datum) {
        if (onLeftClick) {
          onLeftClick(config)(datum);
        }
      });
  }

  if (onMovePerson) {
    optionsPanel
      .append('text')
      .attr('id', (d) => `right-${d.id}`)
      .attr('x', nodePaddingX * 2 + plusWidth)
      .attr('dx', '0.2em')
      .attr('y', nodeHeight - (expanderHeight - plusHeight / 2))
      .attr('dy', '0.8em')
      .style('cursor', (d) => {
        const siblings = d.parent?.children || d.parent?._children || [];
        return !d.parent || siblings[siblings.length - 1]?.id === d.id ? 'default' : 'pointer';
      })
      .text('>')
      .attr('fill', (d) => {
        const siblings = d.parent?.children || d.parent?._children || [];
        return !d.parent || siblings[siblings.length - 1]?.id === d.id ? borderColor : titleColor;
      })
      .on('click', function (datum) {
        if (onRightClick) {
          onRightClick(config)(datum);
        }
      });
  }

  if (onMoveUpDown) {
    optionsPanel
      .append('path')
      .attr('id', (d) => `up-${d.id}`)
      .attr(
        'd',
        d3.svg.line()([
          [0, 0],
          [4, -4],
          [8, 0],
        ]),
      )
      .attr('transform', 'translate(' + 29 + ',' + 180 + ')')
      .style('cursor', (d) => {
        return !d.parent ? 'default' : 'pointer';
      })
      .style('fill', 'white')
      .style('stroke-width', 1.5)
      .attr('stroke', (d) => {
        return !d.parent ? borderColor : titleColor;
      })
      .on('click', (d) => {
        if (!d.parent) {
          return true;
        }
        onMoveUpDown(d, 'up');
      });
  }

  if (onMoveUpDown) {
    optionsPanel
      .append('path')
      .attr('id', (d) => `down-${d.id}`)
      .attr(
        'd',
        d3.svg.line()([
          [0, 0],
          [4, 4],
          [8, 0],
        ]),
      )
      .attr('transform', 'translate(' + 29 + ',' + 198 + ')')
      .style('cursor', (d) => {
        return d.children?.length > 0 || d._children?.length > 0 ? 'pointer' : 'default';
      })
      .style('fill', 'white')
      .style('stroke-width', 1.5)
      .attr('stroke', (d) => {
        return d.children?.length > 0 || d._children?.length > 0 ? titleColor : borderColor;
      })
      .on('click', (d) => {
        if (!(d.children?.length > 0 || d._children?.length > 0)) {
          return true;
        }
        onMoveUpDown(d, 'down');
      });
  }

  // Person's Reports
  const superviseeCircle = nodeEnter
    .append('g')
    .attr('cursor', 'pointer')
    .attr('display', (d) => (d.person?.totalReports > 0 ? 'block' : 'none'))
    .on('click', function (datum) {
      (d3.event as Event).stopPropagation();
      if (onExpandClick) {
        onExpandClick(config)(datum);
      }
    });

  superviseeCircle
    .append('circle')
    .attr('r', 10)
    .attr('cx', nodeWidth / 2)
    .attr('cy', nodeHeight + CIRCLE_TRANSFORM_Y)
    .attr('fill', backgroundColor)
    .attr('stroke', borderColor);

  superviseeCircle
    .append('text')
    .attr('class', PERSON_REPORTS_CLASS)
    .attr('x', nodeWidth / 2)
    .attr('y', nodeHeight + CIRCLE_TRANSFORM_Y)
    .attr('dominant-baseline', 'central')
    .attr('text-anchor', 'middle')
    .attr('pointer-events', 'none')
    .style('font-size', 14)
    .style('font-weight', 400)
    .style('cursor', 'pointer')
    .style('fill', reportsColor)
    .text(helpers.getTextForTitle);

  // Person's Avatar
  nodeEnter
    .append('image')
    .attr('id', (d) => `image-${d.id}`)
    .attr('width', avatarWidth)
    .attr('height', avatarWidth)
    .attr('x', avatarPos.x)
    .attr('y', avatarPos.y)
    .attr('stroke', borderColor)
    .attr('pointer-events', 'mouseover')
    .attr('s', (d) => {
      const image = d.person.image || '/system-and-soul-user.svg';
      if (image) {
        Promise.resolve(image).then((res) => {
          convertImageToBase64(res, function (dataUrl) {
            d3.select(`#image-${d.id}`).attr('href', dataUrl);
            d.person.image = dataUrl;
          });
          d.person.hasImage = true;
          return d.person.image;
        });
      }
      return null;
    })
    .attr('src', (d) => d.person.image || '/system-and-soul-user.svg')
    .attr('href', (d) => d.person.image || '/system-and-soul-user.svg')
    .style('cursor', 'pointer')
    .attr('clip-path', 'url(#avatarClip)')
    .on('mouseover', function (d) {
      const avatarImage = d3.select(`#image-${d.id}`).node();
      const bbox = (avatarImage as Element).getBoundingClientRect();

      // Calculate the desired position of the popup
      const popupX = bbox.x + bbox.width + 10; // 10px to the right of the avatar
      const popupY = bbox.y; // Align with the top of the avatar
      const assessmentContent = generateAssessmentHTMLContent(d);
      const popup = d3
        .select('body')
        .append('div')
        .attr('class', 'date-time-popup')
        .style('position', 'absolute')
        .style('left', popupX + 'px') // Adjusted for top left positioning
        .style('top', popupY + 'px') // Adjusted for top left positioning
        .style('background', 'white')
        .style('padding', '5px')
        .style('border', '1px solid black')
        .style('border-radius', '5px')
        .style('display', 'block') // Ensure it's visible
        .html(assessmentContent); // Using date-fns to format date and time

      // Additional styles for visibility and aesthetics can be added here
    })
    .on('mouseout', function () {
      d3.selectAll('.date-time-popup').remove(); // Remove the popup when not hovering
    });

  // Person Options
  // const nodeOptions = nodeEnter.append('g');

  // dots({
  //   svg: nodeOptions,
  //   x: nodeWidth - 30,
  //   y: 5,
  //   personOptions,
  // });

  // Transition nodes to their new position.
  const nodeUpdate = node
    .transition()
    .duration(animationDuration)
    .attr('transform', (d) => `translate(${d.x},${d.y})`);

  nodeUpdate
    .select('rect.box')
    .attr('fill', backgroundColor)
    .attr('stroke', (d) =>
      d.person?.org_status === 'interim'
        ? INTERIM_STATUS_COLOR
        : d.person?.org_status === 'open'
        ? OPEN_STATUS_COLOR
        : borderColor,
    );

  // Transition exiting nodes to the parent's new position.
  node
    .exit()
    .transition()
    .duration(animationDuration)
    .attr('transform', () => `translate(${parentNode.x},${parentNode.y})`)
    .remove();

  // Update the links
  svg.selectAll('path.link').data(links, (d) => `${d.target.id}`);

  // Wrap the title texts
  const wrapWidth = 124;
  svg.selectAll('text.unedited.' + PERSON_NAME_CLASS).call(wrapText, wrapWidth);
  svg.selectAll('text.unedited.' + PERSON_TITLE_CLASS).call(wrapText, wrapWidth);

  // Render lines connecting nodes
  renderLines(config);

  // Stash the old positions for transition.
  nodes.forEach(function (d) {
    d.x0 = d.x;
    d.y0 = d.y;
    if (config.center_node && Number(config.center_node) === d.id && d.depth > 1) {
      centerNode(d);
    }
  });

  let nodeLeftX = -70;
  let nodeRightX = 70;
  let nodeY = 200;
  nodes.map((d) => {
    nodeLeftX = d.x < nodeLeftX ? d.x : nodeLeftX;
    nodeRightX = d.x > nodeRightX ? d.x : nodeRightX;
    nodeY = d.y > nodeY ? d.y : nodeY;
  });

  config.nodeRightX = nodeRightX;
  config.nodeY = nodeY;
  config.nodeLeftX = nodeLeftX * -1;

  onConfigChange(config);
}

export default render;
