import { gql } from 'urql';
import { MEETING_FRAGMENT } from './fragments';

export default gql`
  query CURRENT_USER_MEETING($teams_id: Int, $company_user_id: Int) {
    meetings(
      where: { teams_id: { _eq: $teams_id }, presenter_id: { _eq: $company_user_id }, completed: { _neq: true } }
    ) {
      ...MeetingFragment
    }
  }
  ${MEETING_FRAGMENT}
`;
