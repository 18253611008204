import { gql } from 'urql';
import { MEETING_FRAGMENT } from './fragments';

const ADD_MEETING = gql`
  ${MEETING_FRAGMENT}

  mutation AddMeeting($object: meetings_insert_input!) {
    insert_meetings_one(object: $object) {
      ...MeetingFragment
    }
  }
`;

export default ADD_MEETING;
