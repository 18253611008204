import { gql } from 'urql';

const ADD_TEAM = gql`
  mutation AddTeam($object: teams_insert_input!) {
    insert_teams_one(object: $object) {
      id
      name
      type
    }
  }
`;

export default ADD_TEAM;
