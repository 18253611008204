import { gql } from 'urql';

const UPDATE_USER_PROFILE_IMAGE_FILE = gql`
  mutation UpdateUserProfileImageFile($fileId: Int!, $_set: user_profile_image_files_set_input!) {
    update_user_profile_image_files_by_pk(pk_columns: { id: $fileId }, _set: $_set) {
      id
    }
  }
`;

export default UPDATE_USER_PROFILE_IMAGE_FILE;
