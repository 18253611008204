import { captureException } from '@sentry/nextjs';
import { Listbox, Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { CheckIcon, SelectorIcon, ChevronDownIcon, XIcon } from '@heroicons/react/solid';
import {
  Avatar,
  RichTextEditor,
  RichTextViewer,
  CommentSection,
  FileAttachmentDialog,
  ErrorToast,
  SuccessToast,
} from 'components';
import { CompanyUser } from 'components/UsersList/UsersList';
import { Action } from 'data/actions/types';
import { GET_ACTION_COMMENTS, DELETE_ACTION_COMMENT, DELETE_ACTION_FILE } from 'data/actions';
import { Opportunity } from 'data/opportunities/types';
import { Dispatch, Fragment, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import button from 'styles/button';
import { classNames, refreshData } from 'utils';
import { DATE_FORMAT_OPTIONS, TIME_FORMAT_OPTIONS, Comment } from 'types/comment';
import { useQuery, useMutation } from 'urql';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Objective } from 'data/objectives/types';
import Link from 'next/link';
import { FileAttachment } from 'types/file-attachment';
import { ErrorToastError } from 'components/Toast/ErrorToast';

export default function ActionForm({
  form,
  setForm,
  users,
  opportunities,
  commentSectionOpen,
  setCommentSectionOpen,
  objectives,
  actionFiles,
  refetchFiles,
  handleUserSelection,
}: {
  form: Action;
  setForm: Dispatch<SetStateAction<Action>>;
  users: CompanyUser[];
  opportunities: Opportunity[];
  commentSectionOpen?: boolean;
  setCommentSectionOpen?: (commentSectionOpen: boolean) => void;
  objectives: Objective[];
  actionFiles?: FileAttachment[];
  refetchFiles?;
  handleUserSelection?;
}): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const selectedUser = users.find(({ user }) => user.id === form.assignee)?.user;
  const selectedOpportunity = opportunities.find(({ id }) => id === form.opportunity);
  const sortedOpportunities = useMemo(() => {
    return opportunities.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }, [opportunities]);

  const selectedObjective = objectives?.find(({ id }) => id === form.objective);
  const sortedObjectives = useMemo(() => {
    return objectives?.sort((a, b) => {
      return new Date(b.created_at).getTime() - new Date(a.created_at).getTime();
    });
  }, [objectives]);

  const assignees = form.assignees?.map((company_user) => company_user?.user_id) || [];
  function toggleAssignees(_users) {
    let _assignees = form.assignees || [];
    _users.forEach((_user) => {
      if (_assignees.map((company_user) => company_user.user_id).includes(_user.id)) {
        _assignees = _assignees.filter((company_user) => company_user.user_id !== _user.id);
      } else {
        _assignees.push(users.find(({ user_id }) => user_id === _user.id));
      }
    });

    setForm({
      ...form,
      assignees: _assignees,
    });
  }

  const [commentsResult, refetchComments] = useQuery({
    query: GET_ACTION_COMMENTS,
    variables: {
      where: {
        entity_id: { _eq: form?.id },
      },
    },
    pause: !form?.id,
  });
  const comments: Comment[] = commentsResult.data?.action_comments || [];

  const [displayEditor, setDisplayEditor] = useState(false);
  const [, deleteComment] = useMutation(DELETE_ACTION_COMMENT);
  const [comment, setComment] = useState<Comment>({
    id: null,
    comment: '',
    entity_id: form.id,
  });
  const commentActions = [];
  commentActions.push(
    {
      action: (_comment) => {
        setComment({
          comment: _comment?.comment,
          entity_id: form.id,
          id: _comment?.id,
        });
        setDisplayEditor(true);
      },
      label: 'Edit',
    },
    {
      action: async (_comment) => {
        const { error: deleteError } = await deleteComment({
          commentId: _comment?.id,
          id: form.id,
          activity: 'update',
          timestamp: Date.now(),
          userId: session.id,
        });
        if (deleteError) {
          captureException(deleteError);
        }
        refetchComments({ variables: { id: _comment?.entity_id } });
        await refreshData(router);
      },
      label: 'Delete',
    },
  );
  const [fileUploadDailogOpen, setFileUploadDailogOpen] = useState(false);
  const [, deleteActionFile] = useMutation(DELETE_ACTION_FILE);
  const [error, setError] = useState<ErrorToastError>();
  const [deleteFile, setDeleteFile] = useState<string>();

  useEffect(() => {
    const found = users.find((v) => v.user_id === Number(session.id));
    if (handleUserSelection) {
      handleUserSelection(found);
    }
  }, []);

  return (
    <>
      <div className="p-6 overflow-auto flex-1">
        <form className="w-full">
          <div className="w-full">
            <label htmlFor="title" className="block text-xs text-gray-700">
              Title
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full text-sm border-gray-300 rounded-md"
                defaultValue={form.title}
                onChange={(event) => {
                  setForm({
                    ...form,
                    title: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="w-full flex mt-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="description" className="block text-xs text-gray-700">
                Description
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.description}
                  onChange={(content) => {
                    setForm({
                      ...form,
                      description: content,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {commentSectionOpen ? (
            <div className="w-full flex  flex-col mt-4">
              <div className="flex flex-col flex-1">
                <label htmlFor="comments" className="block text-xs text-gray-700">
                  Comments
                </label>
                <div className="mt-1 h-full bg-gray-200 rounded-md shadow-sm">
                  {comments.length === 0 ? (
                    <div className="text-sm font-semibold text-gray-700 text-center py-6">
                      <span>There are no comments at this time</span>
                    </div>
                  ) : null}
                  {comments?.map((_comment, i) => {
                    const _user = users.find(({ user }) => user.id === _comment?.user_id)?.user;
                    return (
                      <div key={i} className="flex-1 flex items-center justify-between pr-4 relative my-6">
                        <div className="text-xs ml-4">
                          <div className="font-semibold">{_user?.name || _user?.email}</div>
                          <span className="text-xs text-gray-700 font-semibold italic">
                            {new Date(_comment?.timestamp).toLocaleDateString([], DATE_FORMAT_OPTIONS)}{' '}
                            {new Date(_comment?.timestamp).toLocaleTimeString([], TIME_FORMAT_OPTIONS)}
                          </span>
                          <div className="text-sm mt-0.5">
                            <RichTextViewer text={_comment?.comment} />
                          </div>
                        </div>

                        {Number(session?.id) === _comment?.user_id ? (
                          <div className="">
                            <Menu as="div" className="flex items-center justify-center w-full text-left">
                              {({ open }) => (
                                <>
                                  <div
                                    className="w-full h-full"
                                    onClick={(event) => {
                                      // Prevents redirecting to single page for item when clicking dots to close dropdown
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Menu.Button className="flex items-center justify-center h-full w-full">
                                      <span className="sr-only">Open options</span>
                                      <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="z-10 origin-top-right absolute right-0 top-4 mt-6 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                      <div className="py-1">
                                        {commentActions.map(({ label, action }) => (
                                          <Menu.Item key={`${_comment.id}-${label}`}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm w-full text-left',
                                                )}
                                                onClick={async (event) => {
                                                  event.stopPropagation();
                                                  action?.(_comment);
                                                }}
                                              >
                                                {label}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                {!displayEditor ? (
                  <div className="flex mt-2 mb-4 space-x-2 justify-end">
                    <button
                      type="button"
                      className={classNames(
                        button.primary(),
                        'inline-flex items-center justify-center  py-2 rounded-md shadow-sm text-sm font-semibold text-white',
                      )}
                      onClick={async () => {
                        setCommentSectionOpen(false);
                      }}
                    >
                      Hide Comments
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        button.primary(),
                        'inline-flex items-center justify-center  py-2 rounded-md shadow-sm text-sm font-semibold text-white',
                      )}
                      onClick={async () => {
                        setDisplayEditor(true);
                        setComment({
                          id: null,
                          comment: '',
                          entity_id: form.id,
                        });
                      }}
                    >
                      Add Comment
                    </button>
                  </div>
                ) : null}
              </div>

              {displayEditor ? (
                <CommentSection
                  comment={comment}
                  setComment={setComment}
                  setOpen={setDisplayEditor}
                  refetchComments={refetchComments}
                  entity={'action'}
                />
              ) : null}
            </div>
          ) : null}

          <div className="w-full flex flex-col md:flex-row mt-4 gap-y-4 gap-x-2">
            <div className="flex flex-col flex-1">
              <label htmlFor="due-date" className="block text-xs text-gray-700">
                Date
              </label>
              <div className="mt-1">
                <input
                  type="date"
                  name="due-date"
                  id="due-date"
                  className="focus:ring-primary focus:border-primary text-sm block w-full border-gray-300 rounded-md h-12"
                  defaultValue={form.date}
                  onChange={(event) => {
                    setForm({
                      ...form,
                      date: event.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <Listbox
                value={form.assignee}
                onChange={(value) => {
                  setForm({
                    ...form,
                    assignee: value,
                  });

                  const found = users.find((v) => v.user_id === value);
                  if (handleUserSelection) {
                    handleUserSelection(found);
                  }
                }}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-xs text-gray-700">Assignee</Listbox.Label>
                    <div className="mt-1">
                      <Listbox.Button
                        className={classNames(
                          button.white(),
                          'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                        )}
                      >
                        {selectedUser ? (
                          <span className="flex items-center">
                            <Avatar user={selectedUser} showName={true} />
                          </span>
                        ) : (
                          <span className="flex items-center text-gray-500">Select a user</span>
                        )}
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          static
                          className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                          {users.map(({ user }) => (
                            <Listbox.Option
                              key={user?.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-gray-100' : '',
                                  'cursor-default select-none relative py-2 pl-3 pr-9',
                                )
                              }
                              value={user?.id}
                            >
                              {({ selected }) => (
                                <>
                                  <div className="flex items-center mr-2">
                                    <Avatar user={user} showName={true} />
                                  </div>

                                  {selected ? (
                                    <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          {sortedOpportunities.length ? (
            <div className="w-full flex mt-4">
              <div className="flex flex-col flex-1">
                <Listbox
                  value={form.opportunity}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      opportunity: value,
                    });
                  }}
                >
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-xs text-gray-700">Opportunity</Listbox.Label>
                      <div className="mt-1">
                        <Listbox.Button
                          className={classNames(
                            button.white(),
                            'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                          )}
                        >
                          {selectedOpportunity ? (
                            <div className="flex items-center truncate text-blue-500 font-semibold underline">
                              <Link
                                href={`/company/${router.query.company}/team/${router.query.team}/opportunities/${selectedOpportunity?.id}`}
                                legacyBehavior
                              >
                                {(selectedOpportunity.title ? selectedOpportunity.title : '')
                                  .concat(' - ')
                                  .concat(new Date(selectedOpportunity.created_at).toLocaleDateString())}
                              </Link>
                            </div>
                          ) : (
                            <span className="flex items-center truncate text-gray-500">Link to an opportunity</span>
                          )}
                          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            static
                            className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                          >
                            {sortedOpportunities.map((opportunity) => (
                              <Listbox.Option
                                key={opportunity.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-gray-100' : '',
                                    'cursor-default select-none relative py-2 pl-3 pr-9',
                                  )
                                }
                                value={opportunity.id}
                              >
                                {({ selected }) => (
                                  <>
                                    <div className="flex items-center mr-2">
                                      <span
                                        className={classNames(
                                          selected ? 'font-semibold' : 'font-normal',
                                          opportunity.archived ? 'line-through font-medium text-gray-500' : '',
                                          'block truncate',
                                        )}
                                      >
                                        {(opportunity.title ? opportunity.title : '')
                                          .concat(' - ')
                                          .concat(new Date(opportunity.created_at).toLocaleDateString())}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>
          ) : null}
          {sortedObjectives?.length ? (
            <div className="w-full flex mt-4">
              <div className="flex flex-col flex-1">
                <Listbox
                  value={form.objective}
                  onChange={(value) => {
                    setForm({
                      ...form,
                      objective: value,
                    });
                  }}
                >
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-xs text-gray-700">Objective</Listbox.Label>
                      <div className="mt-1">
                        <Listbox.Button
                          className={classNames(
                            button.white(),
                            'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                          )}
                        >
                          {selectedObjective ? (
                            <div className="flex items-center truncate text-blue-500 font-semibold underline">
                              <Link
                                href={`/company/${router.query.company}/team/${router.query.team}/objectives/${selectedObjective?.id}`}
                                legacyBehavior
                              >
                                {(selectedObjective.title ? selectedObjective.title : '')
                                  .concat(' - ')
                                  .concat(new Date(selectedObjective.created_at).toLocaleDateString())}
                              </Link>
                            </div>
                          ) : (
                            <span className="flex items-center truncate text-gray-500">Link to an objective</span>
                          )}
                          <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options
                            static
                            className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                          >
                            {sortedObjectives.map((objective) => (
                              <Listbox.Option
                                key={objective.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'bg-gray-100' : '',
                                    'cursor-default select-none relative py-2 pl-3 pr-9',
                                  )
                                }
                                value={objective.id}
                              >
                                {({ selected }) => (
                                  <>
                                    <div className="flex items-center mr-2">
                                      <span
                                        className={classNames(
                                          selected ? 'font-semibold' : 'font-normal',
                                          objective.archived ? 'line-through font-medium text-gray-500' : '',
                                          'block truncate',
                                        )}
                                      >
                                        {(objective.title ? objective.title : '')
                                          .concat(' - ')
                                          .concat(new Date(objective.created_at).toLocaleDateString())}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>
          ) : null}

          <>
            <div className="block text-xs text-gray-700 mt-4">File Attachments</div>
            <div className="mt-1 rounded-md border border-gray-300 px-4 w-full">
              <div className="flex justify-end mt-2 mb-6">
                <button
                  type="button"
                  className={classNames(
                    button.primary(),
                    'inline-flex items-center justify-center rounded-md shadow-sm text-sm font-semibold text-white ',
                  )}
                  onClick={() => {
                    setFileUploadDailogOpen(true);
                  }}
                >
                  Add Files
                </button>
              </div>

              <>
                <div className="flex text-xs font-semibold text-gray-500 uppercase bg-gray-100 h-8 w-full">
                  <div className="w-5/6 py-2 ml-2"> File Name</div>
                  <div className={classNames(actionFiles?.length > 2 ? '-ml-4' : '', 'flex w-1/6 py-2 justify-center')}>
                    Delete
                  </div>
                </div>
                {actionFiles?.length > 0 ? (
                  <div className="max-h-28 overflow-y-auto">
                    <div className="mt-2 text-xs text-blue-500 font-semibold mb-2">
                      {actionFiles
                        .filter((file) => file.url !== null)
                        .map((file) => {
                          return (
                            <div key={file.id} className="mt-3">
                              <div className="flex">
                                <div className="w-5/6 truncate">
                                  <Link href={file.url} className="ml-2" target="_blank">
                                    {file.file_name}
                                  </Link>
                                </div>
                                <div className="flex w-1/6 justify-center">
                                  <button
                                    type="button"
                                    onClick={async () => {
                                      // Delete file
                                      const actionId = form.id;
                                      const { error: deleteError } = await deleteActionFile({
                                        fileId: file.id,
                                        id: actionId,
                                        activity: 'update',
                                        timestamp: Date.now(),
                                        userId: session.id,
                                      });
                                      refetchFiles({ variables: { actionId } });
                                      const key = 'action'.concat('_').concat(file?.id?.toString());
                                      await fetch(`/api/s3/file-delete`, {
                                        method: 'POST',
                                        headers: {
                                          'content-type': 'application/json',
                                        },
                                        body: JSON.stringify({ key: key }),
                                      });
                                      if (deleteError) {
                                        setError(deleteError);
                                        captureException(deleteError);
                                      } else {
                                        setDeleteFile('File Deleted');
                                      }
                                    }}
                                  >
                                    <XIcon fill="red" className="h-4 w-4 mb-0.5" />
                                  </button>
                                </div>
                              </div>
                              <div className="mt-2 w-full border-t border-gray-300" />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center mb-6">
                    <span className="mt-4 text-sm font-semibold text-gray-400 items-center">
                      There are no files at this time
                    </span>
                  </div>
                )}
              </>
            </div>
          </>

          <div className="w-full flex mt-8 border-t border-gray-300">
            <div className="flex flex-col flex-1">
              <label htmlFor="assignees" className="block text-xs text-gray-700 mt-8">
                Duplicate for Additional Assignees(Optional)
              </label>
              <Menu>
                <div className="mt-1">
                  <Menu.Button className="w-full rounded-md px-4 py-2 h-12 text-left cursor-default text-sm inline-flex justify-between items-center border border-gray-300 shadow-sm bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500 ">
                    Select Team Members
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>
                <div className="z-10">
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items
                      static
                      className="absolute mt-2 mb-6 max-w-xs rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                    >
                      {users.map(({ user }) => (
                        <Menu.Item key={user?.id}>
                          {({ active }) => (
                            <div
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'cursor-pointer select-none relative py-2 pl-3 pr-9',
                              )}
                              onClick={() => {
                                toggleAssignees([user]);
                              }}
                            >
                              <div className="flex items-center mr-2">
                                <Avatar user={user} showName={true} />
                              </div>

                              {assignees?.includes(user?.id) ? (
                                <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </div>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </div>
              </Menu>
            </div>
          </div>
          <div className="w-full flex mt-4 flex-wrap -m-2">
            {form?.assignees?.map((company_user) => (
              <div
                key={company_user?.user?.email}
                className="m-2 rounded-lg border border-gray-300 bg-white px-3 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
              >
                <Avatar user={company_user?.user} showName={true} />
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    toggleAssignees([company_user?.user]);
                  }}
                >
                  <XIcon height="16" width="16" />
                </div>
              </div>
            ))}
          </div>
          {form.created_at ? (
            <div className="mt-6 text-center text-gray-400 text-sm">
              <span>Created on {new Date(form.created_at).toLocaleDateString()}</span>
            </div>
          ) : null}
        </form>
      </div>
      <FileAttachmentDialog
        entityType={'action'}
        open={fileUploadDailogOpen}
        setOpen={setFileUploadDailogOpen}
        refetchFiles={refetchFiles}
        form={form}
        setForm={setForm}
      />
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={deleteFile} setMessage={setDeleteFile} />
    </>
  );
}
