import { gql } from 'urql';

const UPDATE_MEASURABLES = gql`
  mutation UpdateMeasurables($_set: measurables_set_input!, $ids: [Int!]!) {
    update_measurables(where: { id: { _in: $ids } }, _set: $_set) {
      affected_rows
    }
  }
`;

export default UPDATE_MEASURABLES;
