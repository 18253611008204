import { gql } from 'urql';

const UPDATE_OPPORTUNITIES = gql`
  mutation UpdateOpportunities(
    $_set: opportunities_set_input!
    $ids: [Int!]!
    $activityObjects: [opportunities_activity_insert_input!]!
    $activity: String
    $timestamp: bigint
    $userId: Int
  ) {
    update_opportunities(where: { id: { _in: $ids } }, _set: $_set) {
      affected_rows
    }

    insert_opportunities_activity(objects: $activityObjects) {
      affected_rows
    }
  }
`;

export default UPDATE_OPPORTUNITIES;
