import { captureException } from '@sentry/nextjs';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useRef, useState } from 'react';
import { CombinedError, useMutation } from 'urql';
import { classNames } from 'utils';
import { ErrorToast, SuccessToast } from 'components';
import { UPDATE_MEETING } from 'data/meetings';

type MeetingDeleteConfirmationProps = {
  removing: number;
  setRemoving: (editing: number) => void;
};

export default function MeetingDeleteConfirmation({
  removing,
  setRemoving,
}: MeetingDeleteConfirmationProps): ReactElement {
  const router = useRouter();
  const [, updateMeeting] = useMutation(UPDATE_MEETING);
  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={removing !== null} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={removing !== null}
          onClose={() => setRemoving(null)}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="flex items-start px-8">
                  <div className="py-6 w-full">
                    <Dialog.Title as="h3" className="text-lg leading-6 font-semibold text-gray-900">
                      Are you sure you want to delete this meeting?
                    </Dialog.Title>
                  </div>
                </div>
                <button ref={cancelButtonRef} onClick={() => setRemoving(null)}>
                  <XIcon className="absolute top-4 cursor-pointer right-4 h-6 w-6" />
                </button>
                <div className="border px-4 py-3 flex justify-end">
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                    onClick={() => setRemoving(null)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      'bg-red hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500',
                      'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                    )}
                    onClick={async () => {
                      const { error: deleteError } = await updateMeeting({
                        _set: {
                          deleted: true,
                        },
                        id: removing,
                      });

                      if (deleteError) {
                        setError(deleteError);
                        captureException(deleteError);
                      } else {
                        setUpdated('Removed');
                        router.push(`/company/${router.query.company}/team/${router.query.team}/meetings`);
                      }
                      setRemoving(null);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
