import { gql } from 'urql';
import { ACTION_FRAGMENT } from './fragments';

const GET_ACTIONS = gql`
  query GetActions($where: actions_bool_exp!) {
    actions(where: $where, order_by: [{ date: asc }, { assignee: asc }]) {
      ...ActionFragment
    }
  }
  ${ACTION_FRAGMENT}
`;

export default GET_ACTIONS;
