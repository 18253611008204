import { Transition } from '@headlessui/react';
import Loader from 'components/Loader';
import { Fragment, ReactElement } from 'react';

type FullScreenLoaderProps = {
  color?: string;
  background?: string;
  open: boolean;
  opaque?: boolean;
};

const defaultProps = {
  color: 'text-primary',
  background: 'bg-white',
  opaque: false,
};

export default function FullScreenLoader({ color, background, opaque, open }: FullScreenLoaderProps): ReactElement {
  const opacity = opaque ? 'opacity-100' : 'opacity-10';
  return (
    <Transition.Root show={open} as="div">
      <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden flex flex-col items-center justify-center">
        <div className={`fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 ${background} ${opacity}`}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="h-screen flex items-center justify-center">
              <Loader color={color} />
            </div>
          </Transition.Child>
        </div>
      </div>
    </Transition.Root>
  );
}

FullScreenLoader.defaultProps = defaultProps;
