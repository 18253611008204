import { gql } from 'urql';
import { MEETING_FRAGMENT } from './fragments';

export default gql`
  query GET_MEETINGS($where: meetings_bool_exp!) {
    meetings(where: $where, order_by: { date: desc }) {
      ...MeetingFragment
    }
  }
  ${MEETING_FRAGMENT}
`;
