import { gql } from 'urql';

const DELETE_COMPANY = gql`
  mutation DeleteCompany($id: Int!, $name: String) {
    delete_companies(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
        name
      }
    }
  }
`;

export default DELETE_COMPANY;
