import { ChevronRightIcon } from '@heroicons/react/solid';
import Link from 'next/link';
import { ReactElement } from 'react';
import { classNames } from 'utils';

export enum ACTION_STATES {
  READY,
  LOADING,
}

type TabProps = {
  current?: boolean;
  name: string;
  href: string;
};

type Crumb = {
  label: string;
  href: string;
};

type HeaderProps = {
  actions?: ReactElement;
  title?: string | ReactElement;
  tabs?: TabProps[];
  size?: 's' | 'l';
  crumbs?: Crumb[];
};

export default function Header({ actions, title, tabs, size, crumbs }: HeaderProps): ReactElement {
  let height: string;
  switch (size) {
    case 's':
      height = 'h-10';
      break;
    case 'l':
    default:
      height = 'h-20';
      break;
  }
  return (
    <div className="w-full relative px-5 flex justify-center bg-white border-gray-200 border-b flex-col">
      <div className={classNames(height, 'flex items-center justify-between py-4')}>
        {crumbs ? (
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              {crumbs.map((crumb) => (
                <li key={crumb.label}>
                  <div className="flex items-center">
                    {crumb.href ? (
                      <Link href={crumb.href} className="mr-4 underline text-md text-gray-500 hover:text-gray-900">
                        {crumb.label}
                      </Link>
                    ) : null}
                    <ChevronRightIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                </li>
              ))}
              <li>
                <div className="flex items-center">
                  <div className="text-md font-semibold text-gray-900">{title}</div>
                </div>
              </li>
            </ol>
          </nav>
        ) : (
          <h3 className="text-md leading-6 font-semibold text-gray-900">{title}</h3>
        )}
        <div className="flex">{actions}</div>
      </div>
      <div className="">
        <div className="hidden">
          <label htmlFor="current-tab" className="sr-only">
            {title}
          </label>
          <select
            id="current-tab"
            name="current-tab"
            className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary sm:text-sm rounded-md"
            defaultValue={tabs.find((tab) => tab.current)?.name}
          >
            {tabs.map((tab) => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div>
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                href={tab.href}
                aria-current={tab.current ? 'page' : undefined}
                passHref
                className={classNames(
                  tab.current
                    ? 'border-primary text-primary'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap pb-4 px-1 border-b-2 font-semibold text-sm',
                )}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}

Header.defaultProps = {
  actions: <></>,
  title: '',
  tabs: [],
};
