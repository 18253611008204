import Loader from 'components/Loader';
import { Provider } from 'next-auth/providers';
import { SignInAuthorizationParams, SignInOptions } from 'next-auth/react';
import { ReactElement, useState } from 'react';
import button from 'styles/button';
import { classNames } from 'utils';

const socialIcons = {
  facebook: (
    <svg className="w-5 h-5" fill="#1977f3" viewBox="0 0 20 20" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
        clipRule="evenodd"
      ></path>
    </svg>
  ),
  google: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20">
      <g transform="matrix(1, 0, 0, 1, 27.009001, -39.238998)">
        <path
          fill="#4285F4"
          d="M -3.264 51.509 C -3.264 50.719 -3.334 49.969 -3.454 49.239 L -14.754 49.239 L -14.754 53.749 L -8.284 53.749 C -8.574 55.229 -9.424 56.479 -10.684 57.329 L -10.684 60.329 L -6.824 60.329 C -4.564 58.239 -3.264 55.159 -3.264 51.509 Z"
        />
        <path
          fill="#34A853"
          d="M -14.754 63.239 C -11.514 63.239 -8.804 62.159 -6.824 60.329 L -10.684 57.329 C -11.764 58.049 -13.134 58.489 -14.754 58.489 C -17.884 58.489 -20.534 56.379 -21.484 53.529 L -25.464 53.529 L -25.464 56.619 C -23.494 60.539 -19.444 63.239 -14.754 63.239 Z"
        />
        <path
          fill="#FBBC05"
          d="M -21.484 53.529 C -21.734 52.809 -21.864 52.039 -21.864 51.239 C -21.864 50.439 -21.724 49.669 -21.484 48.949 L -21.484 45.859 L -25.464 45.859 C -26.284 47.479 -26.754 49.299 -26.754 51.239 C -26.754 53.179 -26.284 54.999 -25.464 56.619 L -21.484 53.529 Z"
        />
        <path
          fill="#EA4335"
          d="M -14.754 43.989 C -12.984 43.989 -11.404 44.599 -10.154 45.789 L -6.734 42.369 C -8.804 40.429 -11.514 39.239 -14.754 39.239 C -19.444 39.239 -23.494 41.939 -25.464 45.859 L -21.484 48.949 C -20.534 46.099 -17.884 43.989 -14.754 43.989 Z"
        />
      </g>
    </svg>
  ),
};

type SignInButtonsProps = {
  providers: { [key: string]: Provider };
  label: string;
  onClickButton: (providerId: string, options?: SignInOptions, authorizationParams?: SignInAuthorizationParams) => void;
  authorizationParams?: SignInAuthorizationParams;
};

export default function SignInButtons({
  providers = {},
  label,
  onClickButton,
  authorizationParams,
}: SignInButtonsProps): ReactElement {
  const [email, setEmail] = useState(null);
  const [progressing, setProgressing] = useState(false);

  return (
    <div className="mt-8">
      <div>
        <div>
          <p className="block text-sm font-medium text-gray-700">{label} with</p>
          <div className="mt-2 flex justify-between space-x-2">
            {providers
              ? Object.values(providers)
                  .filter((provider) => provider.id !== 'email')
                  .map((provider) => (
                    <div key={provider.name} className="flex-1">
                      <a
                        href="#"
                        className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                        onClick={() => onClickButton(provider.id, {}, authorizationParams)}
                      >
                        <span className="sr-only">
                          {label} with {provider.name}
                        </span>
                        {socialIcons[provider.id]}
                      </a>
                    </div>
                  ))
              : null}
          </div>
        </div>
        {providers?.email ? (
          <div>
            <div className="mt-6 relative">
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm">
                <span className="px-2 bg-white text-gray-500">Or continue with</span>
              </div>
            </div>
            <div className="mt-2 w-full">
              <div key={providers.email.name}>
                <div>
                  <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm"
                      onChange={(event) => setEmail(event.target.value)}
                    />
                  </div>
                </div>
                <button
                  type="button"
                  className={classNames(
                    button.primary(),
                    'w-full flex justify-center py-2 px-4 mt-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white',
                  )}
                  onClick={() => {
                    setProgressing(true);
                    onClickButton('email', { email }, authorizationParams);
                    setTimeout(() => setProgressing(false), 5000);
                  }}
                >
                  {progressing ? <Loader color="text-white" /> : label}
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
