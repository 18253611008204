import { gql } from 'urql';

const ADD_ACTION = gql`
  mutation AddAction($object: actions_insert_input!) {
    insert_actions_one(object: $object) {
      id
    }
  }
`;

export default ADD_ACTION;
