import { gql } from 'urql';

const ADD_OPPORTUNITIES = gql`
  mutation AddOpportunities($objects: [opportunities_insert_input!]!) {
    insert_opportunities(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export default ADD_OPPORTUNITIES;
