import { ReactElement, ReactNode } from 'react';
import { classNames } from 'utils';

type FloatingActionButtonsProps = {
  children?: ReactElement | ReactNode;
  hpos?: 'left' | 'center' | 'right';
  vpos?: 'top' | 'center' | 'bottom';
};

export default function FloatingActionButtons({ children, vpos, hpos }: FloatingActionButtonsProps): ReactElement {
  let vertical;
  switch (vpos) {
    case 'top':
      vertical = 'top-10';
      break;
    case 'center':
      vertical = 'top-1/2 transform translate-y-1/2';
      break;
    case 'bottom':
      vertical = 'bottom-10';
      break;
    default:
      break;
  }

  let horizontal;
  switch (hpos) {
    case 'left':
      horizontal = 'left-10';
      break;
    case 'center':
      horizontal = 'left-1/2 transform -translate-x-1/2';
      break;
    case 'right':
      horizontal = 'right-10';
      break;
    default:
      break;
  }

  return (
    <div
      className={classNames(
        horizontal ?? null,
        vertical ?? null,
        'absolute flex items-center justify-center gap-x-2 gap-y-2',
      )}
    >
      {children}
    </div>
  );
}
