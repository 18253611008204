import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowsExpandIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ClipboardCheckIcon,
  ClipboardListIcon,
  PuzzleIcon,
  ChartSquareBarIcon,
  LightningBoltIcon,
  SparklesIcon,
  DocumentTextIcon,
  CheckCircleIcon,
} from '@heroicons/react/outline';
import { PauseIcon, PlayIcon, StopIcon, XIcon } from '@heroicons/react/solid';
import { RichTextViewer } from 'components';
import StopMeetingDialog from 'components/StopMeetingDialog';
import { UPDATE_MEETING, UPDATE_MEETING_SECTION, UPDATE_MEETING_TIMERS } from 'data/meetings';
import { Meeting, MeetingSection } from 'data/meetings/types';
import { set, useMeetings } from 'data/providers/MeetingsProvider';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'urql';
import { classNames, digitalClock, getTeamId, gqlArray, isClient } from 'utils';
import { useQuery } from 'urql';
import { GET_TEAM } from 'data/teams';
import Marquee from 'react-fast-marquee';
import Tooltip from 'components/Tooltip';
import CompletedAnimation from '../../public/lottie/completed.json';
import Lottie from 'lottie-react';

export function getTimeElapsed(starts: number[], stops: number[]): number {
  let timeElapsed = 0;
  starts?.forEach((start, i) => {
    let stop;
    if (i < stops?.length) {
      stop = stops[i];
    } else {
      stop = Date.now();
    }

    timeElapsed += stop - start;
  });

  return timeElapsed / 1000;
}

export function getSectionTimeElapsed(section: MeetingSection): number {
  let timeElapsed = 0;
  const activeSectionTotalTime = section?.min_duration || section?.max_duration || null;
  section?.starts?.forEach((start, i) => {
    let stop;
    if (i < section?.stops?.length) {
      stop = section?.stops[i];
    } else {
      stop = Date.now();
    }
    timeElapsed += stop - start;
  });

  return activeSectionTotalTime && activeSectionTotalTime !== 0
    ? (timeElapsed / 1000 / activeSectionTotalTime) * 100
    : null;
}

export default function MeetingControls({ meeting, setOngoingMeeting, sidemenuIsCollapsed }): ReactElement {
  const router = useRouter();
  const [, updateMeetingTimers] = useMutation(UPDATE_MEETING_TIMERS);
  const [, updateMeeting] = useMutation(UPDATE_MEETING);
  const [, updateMeetingSection] = useMutation(UPDATE_MEETING_SECTION);
  const [meetingPanelOpen, setMeetingPanelOpen] = useState(true);
  const [sectionDetailsOpen, setSectionDetailsOpen] = useState(false);
  const [stopMeetingDialogOpen, setStopMeetingDialogOpen] = useState(false);
  const [completedAnimationRunning, setCompletedAnimationRunning] = useState(false);
  const [completedAnimationSectionId, setCompletedAnimationSectionId] = useState<number | null>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [sectionDetails, setSectionDetails] = useState<MeetingSection>();
  const [meetings, dispatch] = useMeetings();
  const [screenHeight, setScreenHeight] = useState<number | null>(null); // Initialize screenHeight as state
  const cancelButtonRef = useRef(null);

  const DEFAULT_MEETING_TYPE = 'standard_weekly_sync';
  const CONNECT = 'Connect';

  let activeMeeting: Meeting;
  const teamId = getTeamId(router);

  if (isClient() && meetings) {
    activeMeeting = meetings?.[teamId];
  }

  const [timeElapsed, setTimeElapsed] = useState(
    activeMeeting ? getTimeElapsed(activeMeeting.starts, activeMeeting.stops) : 0,
  );

  useEffect(() => {
    let interval;
    if (activeMeeting) {
      interval = setInterval(() => {
        setTimeElapsed(getTimeElapsed(activeMeeting.starts, activeMeeting.stops));
      }, 1000);
      setOngoingMeeting(true);
    }

    return () => clearInterval(interval);
  }, [activeMeeting]);

  // Set screenHeight after the component mounts
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScreenHeight(window.innerHeight);
    }
  }, []);

  const totalTime = useMemo(
    () =>
      activeMeeting?.duration ||
      activeMeeting?.meeting_sections?.reduce(
        (total, section) => total + (section.min_duration || section.max_duration || 0),
        0,
      ),
    [activeMeeting],
  );

  const progressPercent = (timeElapsed / totalTime) * 100;
  const activeMeetingSection =
    activeMeeting?.meeting_sections?.find((section) => section.id === activeMeeting.active_section_id) ||
    activeMeeting?.meeting_sections?.[0];
  const paused = activeMeeting?.starts?.length === activeMeeting?.stops?.length;

  // Display section time progress
  const PROGRESS = 99;
  const [activeSectionProgressPercent, setAtiveSectionProgressPercent] = useState(
    activeMeeting && activeMeetingSection ? getSectionTimeElapsed(activeMeetingSection) : 0,
  );

  useEffect(() => {
    let sectionInterval;
    setAtiveSectionProgressPercent(0);
    if (activeMeetingSection) {
      sectionInterval = setInterval(() => {
        setAtiveSectionProgressPercent(getSectionTimeElapsed(activeMeetingSection));
      }, 1000);
    }
    return () => clearInterval(sectionInterval);
  }, [activeMeetingSection]);

  const [result] = useQuery({
    query: GET_TEAM,
    variables: { id: teamId },
  });

  const team = result.data?.teams_by_pk;
  const scoreboardData = { scoreboard_url: team?.scoreboard_url, scoreboard_url_change: team?.scoreboard_url_change };

  const meetingNoteDisplay = router.asPath.includes('/team/');
  const isMeetingDetail = router.asPath.includes('/meetings') && router.query.id;

  return activeMeeting ? (
    <>
      {/* <div
        className={`z-20 bg-gray-50 w-full text-gray-900 border-t border-gray-400 overflow-y-hidden flex flex-col transition-all duration-500 ${
          isExpanded ? 'absolute h-screen' : 'relative h-32'
        }`}
      > */}
      <div
        style={{
          transition: 'transform 0.5s ease-in-out',
          transform: isExpanded ? 'translateY(0)' : screenHeight < 700 ? 'translateY(73%)' : 'translateY(79%)',
          position: 'absolute',
          bottom: 0,
          width: '100%',
          height: sidemenuIsCollapsed ? '75vh' : '65vh',
          zIndex: 20,
          backgroundColor: '#f9fafb', // Tailwind's bg-gray-50
          color: '#111827', // Tailwind's text-gray-900
          borderTop: '1px solid #d1d5db', // Tailwind's border-gray-400
        }}
      >
        <div className="w-full">
          {/* <div className="hidden xl:flex items-center h-6 text-white bg-gray-200">

            <div className="flex flex-col justify-between mr-2 py-px h-3">
              <span className="block w-20 h-px bg-gray-400"></span>
              <span className="block w-20 h-px bg-gray-400"></span>
              <span className="block w-20 h-px bg-gray-400"></span>
            </div>


            <span className="m-auto text-xs font-bold tracking-tight text-gray-400">Show Agenda</span>


            <div className="flex flex-col justify-between ml-2 py-px h-3">
              <span className="block w-20 h-px bg-gray-400"></span>
              <span className="block w-20 h-px bg-gray-400"></span>
              <span className="block w-20 h-px bg-gray-400"></span>
            </div>
          </div> */}
          <div
            className={classNames(
              !sidemenuIsCollapsed ? ' flex flex-col justify-between p-2 md:p-4' : 'flex flex-col justify-between p-2',
            )}
          >
            {!sidemenuIsCollapsed && (
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-col md:flex-row justify-start flex-1 max-w-full">
                  {!sidemenuIsCollapsed && (
                    <div className="flex flex-0 items-center">
                      {isExpanded ? (
                        <button onClick={() => setIsExpanded(false)} className="flex items-center text-gray-700 mr-1">
                          <ChevronDownIcon className="h-6 w-6" />
                        </button>
                      ) : (
                        <button onClick={() => setIsExpanded(true)} className="flex items-center text-gray-700 mr-1">
                          <ChevronUpIcon className="h-6 w-6" />
                        </button>
                      )}
                      <div className="tracking-tighter">
                        <Marquee speed={20}>{activeMeeting.title}</Marquee>
                      </div>
                    </div>
                  )}
                  <div
                    className="flex items-center justify-center flex-0 cursor-pointer text-gray-500 my-2 md:my-0"
                    onClick={() => {
                      router.push(
                        `/company/${router.query.company}/team/${router.query.team}/meetings/${activeMeeting.id}`,
                      );
                    }}
                  >
                    <ArrowsExpandIcon className="h-5 w-5 mx-2 text-gray-400" />
                  </div>
                  {meetingNoteDisplay && !isMeetingDetail ? (
                    <div
                      className="flex items-center justify-center flex-0  text-gray-500 my-2 md:my-0"
                      onClick={() => {
                        dispatch(
                          set({
                            teamId: Number(teamId),
                            meeting: {
                              ...activeMeeting,
                              display_meeting_note: true,
                            },
                          }),
                        );
                      }}
                    >
                      <DocumentTextIcon className="h-6 w-6 mx-1 text-gray-400 cursor-pointer" />
                    </div>
                  ) : null}

                  <div className="flex-0 text-primary text-center md:text-right w-full md:w-1/2">
                    {digitalClock(timeElapsed)}
                  </div>
                </div>
              </div>
            )}
            {!sidemenuIsCollapsed && (
              <div className="w-full flex items-center justify-end my-4 py-1 relative rounded overflow-hidden bg-gray-200">
                <div
                  className="bg-primary-100 h-full absolute top-0 left-0 rounded"
                  style={{ width: `${progressPercent < 100 ? progressPercent : 100}%` }}
                />
              </div>
            )}

            <div
              className={classNames(
                !sidemenuIsCollapsed
                  ? 'flex flex-col md:flex-row justify-between space-y-2 md:space-y-0 md:space-x-2 text-white'
                  : 'flex flex-col text-white',
              )}
            >
              {sidemenuIsCollapsed && (
                <div className="margin-auto text-primary text-center mb-1 text-sm tracking-tight">
                  {digitalClock(timeElapsed)}
                </div>
              )}
              <button
                type="button"
                className={classNames(
                  !sidemenuIsCollapsed
                    ? 'h-8 flex flex-1 text-xs rounded items-center justify-center gap-x-2 cursor-pointer bg-red py-1'
                    : 'h-8 flex flex-1 text-xs rounded items-center justify-center gap-x-2 cursor-pointer bg-red py-1 mb-2',
                )}
                onClick={async () => {
                  setIsExpanded(false);
                  setStopMeetingDialogOpen(true);
                }}
              >
                <Tooltip content="Stop Meeting">
                  <StopIcon className={classNames(!sidemenuIsCollapsed ? 'h-5 w-5' : 'h-6 w-6 z-40')} />
                </Tooltip>
                <span className={classNames(!sidemenuIsCollapsed ? 'hidden md:inline-block' : 'hidden')}>End</span>
              </button>
              <button
                type="button"
                className="h-8 flex flex-1 text-xs rounded items-center justify-center gap-x-2 cursor-pointer bg-primary py-1"
                onClick={async () => {
                  let meetingRes;
                  if (paused) {
                    const starts = activeMeeting.starts;
                    starts.push(Date.now());
                    const sectionStarts = activeMeetingSection?.starts || [];
                    if (activeMeetingSection) {
                      sectionStarts.push(Date.now());
                    }
                    meetingRes = await updateMeetingTimers({
                      meetingId: activeMeeting.id,
                      meeting: { starts: gqlArray(starts) },
                      meetingSectionId: activeMeetingSection?.id,
                      meetingSection: activeMeetingSection ? { starts: gqlArray(sectionStarts) } : {},
                    });
                  } else {
                    const stops = activeMeeting.stops;
                    stops.push(Date.now());
                    const sectionStops = activeMeetingSection?.stops || [];
                    if (activeMeetingSection) {
                      sectionStops.push(Date.now());
                    }
                    meetingRes = await updateMeetingTimers({
                      meetingId: activeMeeting.id,
                      meeting: { stops: gqlArray(stops) },
                      meetingSectionId: activeMeetingSection?.id,
                      meetingSection: activeMeetingSection ? { stops: gqlArray(sectionStops) } : {},
                    });
                  }

                  if (meetingRes.error) {
                    throw meetingRes.error;
                  }
                }}
              >
                {paused ? (
                  <>
                    <PlayIcon className={classNames(!sidemenuIsCollapsed ? 'h-5 w-5' : 'h-6 w-6')} />
                    <span className={classNames(!sidemenuIsCollapsed ? 'hidden md:inline-block' : 'hidden')}>Play</span>
                  </>
                ) : (
                  <>
                    <PauseIcon className={classNames(!sidemenuIsCollapsed ? 'h-5 w-5' : 'h-6 w-6')} />
                    <span className={classNames(!sidemenuIsCollapsed ? 'hidden md:inline-block' : 'hidden')}>
                      Pause
                    </span>
                  </>
                )}
              </button>
              {sidemenuIsCollapsed && meetingNoteDisplay && !isMeetingDetail ? (
                <div
                  className="flex items-center justify-center flex-0 text-gray-500 cursor-pointer"
                  onClick={() => {
                    dispatch(
                      set({
                        teamId: Number(teamId),
                        meeting: {
                          ...activeMeeting,
                          display_meeting_note: true,
                        },
                      }),
                    );
                  }}
                >
                  <DocumentTextIcon className="h-6 w-6 mx-1 text-gray-400 cursor-pointer mt-1" />
                  <div className="flex flex-col items-center justify-center mr-1">
                    <div className="text-xs text-center pt-1">Notes</div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {activeMeeting.meeting_sections?.length && !sidemenuIsCollapsed ? (
          <div
            className="w-full h-6 flex justify-center items-center cursor-pointer border border-gray-200 bg-gray-100"
            onClick={() => setMeetingPanelOpen(!meetingPanelOpen)}
          >
            <span className="hidden md:inline mr-2 text-sm">Agenda</span>
            {meetingPanelOpen ? <ChevronDownIcon height="16" width="16" /> : <ChevronUpIcon height="16" width="16" />}
          </div>
        ) : null}
        {!sidemenuIsCollapsed && (
          <div style={{ height: '55vh' }}>
            <div className={classNames(meetingPanelOpen ? 'h-5/6' : 'h-0', 'w-full overflow-x-hidden')}>
              {activeMeeting.meeting_sections?.map((section, i) => {
                const isActive = activeMeeting.active_section_id === section.id;
                const isSectionEnd = activeSectionProgressPercent > PROGRESS;
                return (
                  <div key={i}>
                    <div
                      className={classNames(
                        isActive && isSectionEnd
                          ? 'bg-red-200 border-red-500 border'
                          : isActive
                          ? 'bg-primary-50 border-primary-200 border'
                          : '',
                        isActive ? 'font-semibold' : 'border-t border-gray-200',
                        isActive || paused ? 'cursor-default' : 'cursor-pointer',
                        '',
                      )}
                      onClick={async () => {
                        if (!isActive && !paused) {
                          const newActiveSection = activeMeeting.meeting_sections.find((sec) => sec.id === section.id);
                          const now = Date.now();
                          const starts = [...(section.starts || [])];
                          starts.push(now);
                          const newSectionStops = [...(section.stops || [])];

                          const curSection = activeMeeting.meeting_sections.find(
                            (sec) => sec.id === activeMeeting.active_section_id,
                          );
                          const stops = [...(curSection.stops || [])];
                          stops.push(now);
                          const curSectionStarts = [...(curSection.starts || [])];

                          const meetingRes = await updateMeeting({
                            id: activeMeeting.id,
                            _set: { active_section_id: section.id },
                            addSections: [
                              {
                                id: section.id,
                                starts: gqlArray(starts),
                                stops: gqlArray(newSectionStops),
                              },
                              {
                                id: activeMeeting.active_section_id,
                                stops: gqlArray(stops),
                                starts: gqlArray(curSectionStarts),
                              },
                            ],
                            sectionsUpdateColumns: ['starts', 'stops'],
                          });

                          if (meetingRes.error) {
                            throw meetingRes.error;
                          } else {
                            dispatch(
                              set({
                                teamId: Number(teamId),
                                meeting: meetingRes?.data?.update_meetings_by_pk,
                              }),
                            );
                            if (newActiveSection) {
                              newActiveSection.starts = starts;
                              newActiveSection.stops = newSectionStops;
                            }
                            if (curSection) {
                              curSection.stops = stops;
                              curSection.starts = curSectionStarts;
                            }

                            dispatch(
                              set({
                                teamId: Number(teamId),
                                meeting: {
                                  ...activeMeeting,
                                  active_section_id: section.id,
                                },
                              }),
                            );
                          }
                        }
                      }}
                      key={section.id}
                    >
                      {completedAnimationSectionId === section.id ? (
                        <Lottie
                          className="h-16"
                          loop={false}
                          animationData={CompletedAnimation}
                          style={{ transform: 'scale(1.6)' }}
                          onComplete={() => setCompletedAnimationSectionId(null)} // Reset the completed animation section ID
                        />
                      ) : (
                        <>
                          <div className="flex flex-col md:flex-row md:items-center w-full text-xs p-2 md:p-4 h-22 md:h-16">
                            <input
                              id={`checkbox-${section.id}`}
                              aria-describedby="section-complete"
                              name={`section-${section.id}`}
                              type="checkbox"
                              className={classNames(
                                isActive && isSectionEnd
                                  ? 'focus:ring-red-500 text-red-600'
                                  : 'focus:ring-primary-500 text-primary-600',
                                'h-4 w-4 mx-auto md:mr-2  border-gray-300 rounded cursor-pointer',
                              )}
                              defaultChecked={Boolean(section.completed)}
                              onClick={(event) => event.stopPropagation()}
                              onChange={async (event) => {
                                if (event.target.checked) {
                                  setCompletedAnimationSectionId(section.id);
                                }
                                const meetingSectionRes = await updateMeetingSection({
                                  id: section.id,
                                  _set: {
                                    completed: !section.completed,
                                  },
                                });

                                if (meetingSectionRes.error) {
                                  throw meetingSectionRes.error;
                                } else {
                                  const currentSection = activeMeeting.meeting_sections.find(
                                    (sec) => sec.id === section.id,
                                  );
                                  currentSection.completed = !section.completed;
                                  dispatch(
                                    set({
                                      teamId: Number(teamId),
                                      meeting: {
                                        ...activeMeeting,
                                      },
                                    }),
                                  );
                                }
                              }}
                            />

                            <div className="flex-1 text-center md:text-left truncate tracking-tight my-2 md:mb-0 md:mt-0 md:pr-4 text-md tracking-tight">
                              <div
                                className="flex"
                                onClick={(e) => {
                                  if (activeMeeting?.enable_connect_feature && section?.title === CONNECT) {
                                    router.push(
                                      `/company/${router.query.company}/team/${activeMeeting?.teams_id}/meetings/connect`,
                                    );
                                  } else {
                                    e.stopPropagation();
                                    setSectionDetails(section);
                                    setSectionDetailsOpen(true);
                                  }
                                }}
                              >
                                <div className={classNames(section?.title?.length > 10 ? 'truncate w-24' : '')}>
                                  {section?.title}
                                </div>
                                {section.min_duration || section.max_duration
                                  ? ` (${(section.min_duration || section.max_duration) / 60} min)`
                                  : null}
                              </div>
                            </div>
                            {activeMeeting?.type !== DEFAULT_MEETING_TYPE &&
                            section.description &&
                            section.order !== 1 ? (
                              <div
                                className="flex justify-center flex-0 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSectionDetails(section);
                                  setSectionDetailsOpen(true);
                                }}
                              >
                                {section.completed ? (
                                  <ClipboardCheckIcon
                                    className={classNames(
                                      isActive && isSectionEnd
                                        ? 'text-red-400'
                                        : isActive
                                        ? 'text-primary'
                                        : 'text-gray-400',
                                      'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                    )}
                                  />
                                ) : (
                                  <ClipboardListIcon
                                    className={classNames(
                                      isActive && isSectionEnd
                                        ? 'text-red-400'
                                        : isActive
                                        ? 'text-primary'
                                        : 'text-gray-400',
                                      'h-4 md:h-8 w-4 md:w-8',
                                    )}
                                  />
                                )}
                              </div>
                            ) : null}
                            {section?.order === 1 ? (
                              <div
                                className="flex justify-center flex-0 cursor-pointer"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  if (activeMeeting?.enable_connect_feature) {
                                    router.push(
                                      `/company/${router.query.company}/team/${activeMeeting?.teams_id}/meetings/connect`,
                                    );
                                  } else {
                                    setSectionDetails(section);
                                    setSectionDetailsOpen(true);
                                  }
                                }}
                              >
                                {section.completed ? (
                                  <ClipboardCheckIcon
                                    className={classNames(
                                      isActive && isSectionEnd
                                        ? 'text-red-400'
                                        : isActive
                                        ? 'text-primary'
                                        : 'text-gray-400',
                                      'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                    )}
                                  />
                                ) : activeMeeting?.enable_connect_feature ? (
                                  <PuzzleIcon
                                    className={classNames(
                                      isActive && isSectionEnd
                                        ? 'text-red-400'
                                        : isActive
                                        ? 'text-primary'
                                        : 'text-gray-400',
                                      'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                    )}
                                  />
                                ) : (
                                  <ClipboardListIcon
                                    className={classNames(
                                      isActive && isSectionEnd
                                        ? 'text-red-400'
                                        : isActive
                                        ? 'text-primary'
                                        : 'text-gray-400',
                                      'h-4 md:h-8 w-4',
                                    )}
                                  />
                                )}
                              </div>
                            ) : null}
                            {activeMeeting?.type === DEFAULT_MEETING_TYPE && section?.order === 2 ? (
                              <div className="flex justify-center flex-0 cursor-pointer">
                                {section.completed ? (
                                  <ClipboardCheckIcon
                                    className={classNames(
                                      isActive && isSectionEnd
                                        ? 'text-red-400'
                                        : isActive
                                        ? 'text-primary'
                                        : 'text-gray-400',
                                      'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                    )}
                                  />
                                ) : (
                                  <div className="flex">
                                    <div
                                      onClick={() => {
                                        scoreboardData?.scoreboard_url_change
                                          ? window.open(scoreboardData?.scoreboard_url, '_blank')
                                          : router.push(
                                              `/company/${router.query.company}/team/${activeMeeting?.teams_id}/scoreboard`,
                                            );
                                      }}
                                    >
                                      <ChartSquareBarIcon
                                        className={classNames(
                                          isActive && isSectionEnd
                                            ? 'text-red-400'
                                            : isActive
                                            ? 'text-primary'
                                            : 'text-gray-400',
                                          'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                        )}
                                      />
                                    </div>
                                    <div
                                      onClick={() => {
                                        router.push(
                                          `/company/${router.query.company}/team/${activeMeeting?.teams_id}/objectives`,
                                        );
                                      }}
                                    >
                                      <LightningBoltIcon
                                        className={classNames(
                                          isActive && isSectionEnd
                                            ? 'text-red-400'
                                            : isActive
                                            ? 'text-primary'
                                            : 'text-gray-400',
                                          'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                        )}
                                      />
                                    </div>
                                    <div
                                      onClick={() => {
                                        router.push(
                                          `/company/${router.query.company}/team/${activeMeeting?.teams_id}/actions`,
                                        );
                                      }}
                                    >
                                      <ClipboardCheckIcon
                                        className={classNames(
                                          isActive && isSectionEnd
                                            ? 'text-red-400'
                                            : isActive
                                            ? 'text-primary'
                                            : 'text-gray-400',
                                          'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                        )}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null}
                            {activeMeeting?.type === DEFAULT_MEETING_TYPE && section?.order === 3 ? (
                              <div className="flex justify-center flex-0 cursor-pointer">
                                {section.completed ? (
                                  <ClipboardCheckIcon
                                    className={classNames(
                                      isActive && isSectionEnd
                                        ? 'text-red-400'
                                        : isActive
                                        ? 'text-primary'
                                        : 'text-gray-400',
                                      'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                    )}
                                  />
                                ) : (
                                  <div
                                    onClick={() => {
                                      router.push(
                                        `/company/${router.query.company}/team/${activeMeeting?.teams_id}/opportunities`,
                                      );
                                    }}
                                  >
                                    <SparklesIcon
                                      className={classNames(
                                        isActive && isSectionEnd
                                          ? 'text-red-400'
                                          : isActive
                                          ? 'text-primary'
                                          : 'text-gray-400',
                                        'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                      )}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : null}
                            {activeMeeting?.type === DEFAULT_MEETING_TYPE && section?.order === 4 ? (
                              <div className="flex justify-center flex-0 cursor-pointer">
                                {section.completed ? (
                                  <ClipboardCheckIcon
                                    className={classNames(
                                      isActive && isSectionEnd
                                        ? 'text-red-400'
                                        : isActive
                                        ? 'text-primary'
                                        : 'text-gray-400',
                                      'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                    )}
                                  />
                                ) : (
                                  <div
                                    onClick={async () => {
                                      setIsExpanded(false);
                                      setStopMeetingDialogOpen(true);
                                    }}
                                  >
                                    <CheckCircleIcon
                                      className={classNames(
                                        isActive && isSectionEnd
                                          ? 'text-red-400'
                                          : isActive
                                          ? 'text-primary'
                                          : 'text-gray-400',
                                        'h-4 md:h-8 w-4 md:w-8 transition-transform duration-150 transform hover:scale-110',
                                      )}
                                    />
                                  </div>
                                )}
                              </div>
                            ) : null}
                          </div>
                          <div className="h-2">
                            {isActive && activeSectionProgressPercent ? (
                              <div className="w-full flex my-4 py-1 relative  overflow-hidden bg-gray-200">
                                <div
                                  className={classNames(
                                    isActive && isSectionEnd ? 'bg-red' : 'bg-primary-200',
                                    'h-full absolute top-0 left-0',
                                  )}
                                  style={{
                                    width: `${
                                      activeSectionProgressPercent < 100 ? activeSectionProgressPercent : 100
                                    }%`,
                                  }}
                                />
                              </div>
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <Transition.Root show={sectionDetailsOpen} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={sectionDetailsOpen}
          onClose={setSectionDetailsOpen}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              afterLeave={() => setSectionDetails(null)}
            >
              <div className="relative inline-block align-bottom bg-white pt-4 px-4 pb-20 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div>
                  <div className="text-lg">{sectionDetails?.title}</div>
                  <div className="text-sm mt-4">
                    <RichTextViewer text={sectionDetails?.description} />
                  </div>
                </div>
                <button ref={cancelButtonRef} onClick={() => setSectionDetailsOpen(false)}>
                  <XIcon className="absolute top-4 cursor-pointer right-4 h-6 w-6" />
                </button>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <StopMeetingDialog
        open={stopMeetingDialogOpen}
        setOpen={setStopMeetingDialogOpen}
        meeting={activeMeeting}
        setOngoingMeeting={setOngoingMeeting}
      />
    </>
  ) : null;
}
