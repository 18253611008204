import { Node } from 'types/d3';

function getTextForTitle(datum: Node): string {
  if (!datum.person || !datum.person.totalReports) {
    return '';
  }

  const {
    person: { totalReports },
  } = datum;

  return datum.children ? '-' : `${totalReports}`;
}

export { getTextForTitle };
