import { ACCOUNT_SETTINGS_WALKTHROUGH } from 'config/joy-ride';
import React, { createContext, useState, useContext, ReactNode } from 'react';
import { Walkthrough } from 'types/joy-ride';

interface WalkthroughContextType {
  runningWalkthrough: Walkthrough;
  setRunningWalkthrough: (walkthrough: Walkthrough) => void;
  currentStep: number;
  setCurrentStep: (step: number) => void;
}

const WalkthroughContext = createContext<WalkthroughContextType>({
  runningWalkthrough: ACCOUNT_SETTINGS_WALKTHROUGH,
  setRunningWalkthrough: () => {},
  currentStep: 0,
  setCurrentStep: () => {},
});

interface WalkthroughProviderProps {
  children: ReactNode;
}

export const WalkthroughProvider: React.FC<WalkthroughProviderProps> = ({ children }) => {
  const [runningWalkthrough, setRunningWalkthrough] = useState<Walkthrough>(null);
  const [currentStep, setCurrentStep] = useState(0);

  return (
    <WalkthroughContext.Provider value={{ runningWalkthrough, setRunningWalkthrough, currentStep, setCurrentStep }}>
      {children}
    </WalkthroughContext.Provider>
  );
};

export const useWalkthrough = () => {
  return useContext(WalkthroughContext);
};
