import { gql } from 'urql';

const GET_OPPORTUNITY_COMMENTS = gql`
  query GetOpportunityComments($where: opportunity_comments_bool_exp!) {
    opportunity_comments(where: $where, order_by: [{ timestamp: desc }]) {
      id
      comment
      timestamp
      user_id
    }
  }
`;

export default GET_OPPORTUNITY_COMMENTS;
