import {
  CalendarIcon,
  ChartPieIcon,
  ChartSquareBarIcon,
  ClipboardCheckIcon,
  IdentificationIcon,
  LightningBoltIcon,
  MapIcon,
  SparklesIcon,
} from '@heroicons/react/outline';

type NavigationProps = {
  name: string;
  route: string;
  Icon: (props: React.ComponentProps<'svg'>) => JSX.Element;
  newFeature: boolean;
  description?: string;
};

export default function getNavigation(
  companyId: number | string,
  teamId: number | string,
  canViewRoadmap?: boolean,
  scoreboardData?: { scoreboard_url: string; scoreboard_url_change: boolean },
): NavigationProps[] {
  const navItems = [];
  if (companyId && canViewRoadmap) {
    navItems.push({
      name: 'S2 Road Map',
      route: `/company/${companyId}/roadmap`,
      Icon: MapIcon,
      newFeature: false,
      description: 'Create a magnetic vision, focus your strategy, and execute with confidence.',
    });
  }

  if (companyId) {
    navItems.push({
      name: 'Org Chart',
      route: `/company/${companyId}/org-chart`,
      Icon: IdentificationIcon,
      newFeature: false,
      description: 'Create clarity and design the structure of your organization.',
    });
  }

  if (companyId && teamId) {
    navItems.push({
      name: 'Meetings',
      route: `/company/${companyId}/team/${teamId}/meetings`,
      Icon: CalendarIcon,
      newFeature: false,
      description: 'Track the progress your team is making by capturing objectives, actions, data, and opportunities.',
    });
  }

  if (companyId && teamId) {
    navItems.push(
      {
        name: 'Scoreboard',
        route: scoreboardData?.scoreboard_url_change
          ? scoreboardData?.scoreboard_url
          : `/company/${companyId}/team/${teamId}/scoreboard`,
        Icon: ChartSquareBarIcon,
        newFeature: false,
        description: 'Track KPIs and the growth of your business with a collaborative Scoreboard.',
      },
      {
        name: 'Objectives',
        route: `/company/${companyId}/team/${teamId}/objectives`,
        Icon: LightningBoltIcon,
        newFeature: false,
        description: 'Keep track and share your progress for big objectives.',
      },
      {
        name: 'Actions',
        route: `/company/${companyId}/team/${teamId}/actions`,
        Icon: ClipboardCheckIcon,
        newFeature: false,
        description: `Your team's list of to-dos to complete before next week’s meeting.`,
      },
      {
        name: 'Opportunities',
        route: `/company/${companyId}/team/${teamId}/opportunities`,
        Icon: SparklesIcon,
        newFeature: false,
        description: 'An ongoing list of roadblocks and ideas to review and address weekly.',
      },
      {
        name: 'Healthy F.I.T.',
        route: `/company/${companyId}/team/${teamId}/healthyfit`,
        Icon: ChartPieIcon,
        newFeature: false,
        description:
          'Capture and share your skills, goals, passion, performance, and desired outcomes as an individual and member of your team.',
      },
    );
  }

  return navItems;
}
