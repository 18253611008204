export const extractText = (serializedString) => {
  try {
    // Parse the JSON string
    const parsedData = JSON.parse(serializedString);

    // Access the blocks array
    const blocks = parsedData.blocks;

    // Use Array.reduce to concatenate text from each block
    const extractedText = blocks.reduce((acc, block) => {
      return acc + block.text;
    }, '');

    return extractedText;
  } catch (error) {
    console.error('Failed to parse the serialized string:', error);
    return null; // or you could return an empty string, depending on your error handling preferences
  }
};
