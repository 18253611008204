import { gql } from 'urql';
import { MEASURABLE_FRAGMENT_WITH_FILTER } from './fragments';

const GET_MEASURABLE = gql`
  query GetMeasurable($id: Int!, $scoreDateFilter: scores_bool_exp = null) {
    measurables(where: { id: { _eq: $id } }) {
      ...MeasurableFragment
      scores(where: $scoreDateFilter) {
        id
        value
        date
        updated_at
      }
    }
  }
  ${MEASURABLE_FRAGMENT_WITH_FILTER}
`;

export default GET_MEASURABLE;
