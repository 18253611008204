import { captureException } from '@sentry/nextjs';
import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, PlusIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, SuccessToast } from 'components';
import { useTeamMembers } from 'data/hooks';
import { Measurable, MeasurableIntervalValue } from 'data/measurables/types';
import { ADD_MEASURABLES, DEFAULT_INTERVAL } from 'data/measurables';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation } from 'urql';
import { classNames, refreshData } from 'utils';

type CreateMeasurableDialogProps = {
  createMeasurable: { title: string; description: string };
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function CreateMeasurableDialog({
  createMeasurable,
  open,
  setOpen,
}: CreateMeasurableDialogProps): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [teamMembers] = useTeamMembers(router.query.team as string);

  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();
  const [measurables, setMeasurables] = useState<Measurable[]>([]);

  const users = useMemo(() => teamMembers.map(({ company_user }) => company_user), [teamMembers]);

  const defaultMeasurable = useMemo(
    () => ({
      title: `Measurable from '${createMeasurable?.title}'`,
      owner: Number(session.id),
      description: createMeasurable?.description,
      interval: DEFAULT_INTERVAL as MeasurableIntervalValue,
      archived: false,
    }),
    [createMeasurable, session],
  );

  useEffect(() => {
    setMeasurables([{ ...defaultMeasurable }]);
  }, [defaultMeasurable]);

  const [, addMeasurables] = useMutation(ADD_MEASURABLES);
  const cancelButtonRef = useRef(null);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative w-modal-sm md:w-modal-md">
                {/* Empty div to get rounded corners. Can't put overflow on form because of dropdowns */}
                <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden"></div>
                <form
                  className="align-bottom text-left background-transparent shadow-xl transform transition-all sm:align-middle"
                  onSubmit={async (event) => {
                    event.preventDefault();

                    const { error: err } = await addMeasurables({
                      objects: measurables.map((measurable) => ({
                        ...measurable,
                        company_id: router.query.company,
                        teams_id: router.query.team,
                      })),
                    });

                    if (err) {
                      setError(err);
                      captureException(err);
                    } else {
                      setUpdated(`Measurables added.`);
                      setMeasurables([]);
                    }

                    setOpen(false);
                    refreshData(router);
                  }}
                >
                  <div className="flex items-center justify-between p-6 w-full border-b border-gray-300">
                    <div className="text-gray-900 flex items-center">
                      <label className="text-md font-semibold text-gray-700 mr-2">Create Measurables</label>
                    </div>
                    <XIcon
                      className="cursor-pointer fill-current text-gray-400 right-0 h-6 w-6"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <div className="flex flex-col p-6 pt-2">
                    {measurables.map((measurable, i) => {
                      const selectedOwner = users.find(({ user }) => user.id === measurable.owner)?.user;
                      return (
                        <div key={i} className="flex relative space-x-2 mt-4 w-full">
                          <div className="flex-1">
                            <label htmlFor="title" className="block text-xs text-gray-700">
                              Title
                            </label>
                            <div className="mt-1">
                              <input
                                type="text"
                                name="title"
                                id="title"
                                value={measurable.title}
                                className="w-full h-8 shadow-sm focus:ring-primary focus:border-primary block text-xs border-gray-300 rounded-md"
                                placeholder={`Name the measurable`}
                                onChange={(event) => {
                                  const _measurables = measurables.map((_measurable) => ({ ..._measurable }));
                                  const _measurable = _measurables[i];
                                  _measurable.title = event.target.value;
                                  setMeasurables(_measurables);
                                }}
                              />
                            </div>
                          </div>
                          <div>
                            <Listbox
                              value={measurable.owner}
                              onChange={(value) => {
                                const _measurables = measurables.map((_measurable) => ({ ..._measurable }));
                                const _measurable = _measurables[i];
                                _measurable.owner = value;
                                setMeasurables(_measurables);
                              }}
                            >
                              {({ open: _open }) => (
                                <>
                                  <Listbox.Label className="block text-xs text-gray-700">Owner</Listbox.Label>
                                  <div className="mt-1 relative">
                                    <Listbox.Button
                                      className={classNames(
                                        button.white({ withBase: false }),
                                        'relative w-48 h-8 rounded-md shadow-sm pl-3 pr-7 text-left cursor-default text-xs py-1',
                                      )}
                                    >
                                      {selectedOwner ? (
                                        <span className="flex items-center">
                                          <Avatar user={selectedOwner} size="s" showName={true} />
                                        </span>
                                      ) : (
                                        <span className="flex items-center text-gray-500">Select a user</span>
                                      )}
                                      <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                        <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                      </span>
                                    </Listbox.Button>

                                    <Transition
                                      show={_open}
                                      as={Fragment}
                                      leave="transition ease-in duration-100"
                                      leaveFrom="opacity-100"
                                      leaveTo="opacity-0"
                                    >
                                      <Listbox.Options
                                        static
                                        className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                      >
                                        {users.map(({ user }) => (
                                          <Listbox.Option
                                            key={user.id}
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'bg-gray-100' : '',
                                                'cursor-default select-none relative py-2 pl-3 pr-9',
                                              )
                                            }
                                            value={user.id}
                                          >
                                            {({ selected }) => (
                                              <>
                                                <div className="flex items-center">
                                                  <Avatar user={user} showName={true} />
                                                </div>

                                                {selected ? (
                                                  <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                  </span>
                                                ) : null}
                                              </>
                                            )}
                                          </Listbox.Option>
                                        ))}
                                      </Listbox.Options>
                                    </Transition>
                                  </div>
                                </>
                              )}
                            </Listbox>
                          </div>
                          {measurables.length > 1 ? (
                            <div
                              className="w-6 text-red-500 cursor-pointer flex items-end mb-2 justify-center"
                              onClick={() => {
                                const _measurables = [...measurables];
                                _measurables.splice(i, 1);

                                setMeasurables(_measurables);
                              }}
                            >
                              <XIcon fill="currentColor" className="h-4 w-4 mb-0.5" />
                            </div>
                          ) : null}
                        </div>
                      );
                    })}
                    <button
                      type="button"
                      className="flex items-center text-primary self-start text-sm mt-4"
                      onClick={() => {
                        setMeasurables([...measurables, { ...defaultMeasurable }]);
                      }}
                    >
                      <PlusIcon className="h-3 w-3 inline" />
                      <span className="ml-1 underline text-xs">Add another measurable</span>
                    </button>
                  </div>
                  <div className="border-t border-grey bg-gray-50 overflow-hidden rounded-b-lg px-6 py-3 flex items-center justify-end">
                    <button
                      type="button"
                      className={classNames(
                        button.white(),
                        'w-auto inline-flex items-center justify-center rounded-md shadow-sm text-sm text-gray-700 ml-3',
                      )}
                      onClick={() => setOpen(false)}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={classNames(
                        button.primary(),
                        'w-auto inline-flex items-center justify-center rounded-md text-sm ml-3',
                      )}
                    >
                      <CheckIcon className="h-5 w-5 mr-2" />
                      {`Create Measurable${measurables.length > 1 ? 's' : ''}`}
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
