import { gql } from 'urql';
import { MEETING_SECTION_FRAGMENT } from './fragments';

export default gql`
  mutation UpdateMeetingSection($_set: meeting_sections_set_input, $id: Int!) {
    update_meeting_sections_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...MeetingSectionFragment
    }
  }
  ${MEETING_SECTION_FRAGMENT}
`;
