import { gql } from 'urql';

const ADD_OBJECTIVES = gql`
  mutation AddObjective($objects: [objectives_insert_input!]!) {
    insert_objectives(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export default ADD_OBJECTIVES;
