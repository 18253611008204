import { gql } from 'urql';

const ADD_OPPORTUNITY = gql`
  mutation AddOpportunity($object: opportunities_insert_input!) {
    insert_opportunities_one(object: $object) {
      id
    }
  }
`;

export default ADD_OPPORTUNITY;
