import { GET_COMPANY_USER_BY_ID } from 'data/company';
import { CURRENT_USER_MEETING } from 'data/meetings';
import { Meeting } from 'data/meetings/types';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { createContext, Dispatch, ReactElement, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react';
import { useQuery } from 'urql';
import { getTeamId } from 'utils';

type MeetingState = {
  [teamId: number]: Meeting;
};

type MeetingDispatchProps = {
  type: string;
  teamId: number;
  meeting?: Meeting;
};

const INITIAL_STATE: MeetingState = {};
const MeetingsStateContext = createContext(INITIAL_STATE);
const MeetingsDispatchContext = createContext<Dispatch<MeetingDispatchProps>>(null);

const actionTypes = {
  set: 'set',
  update: 'update',
  unset: 'unset',
};

function reducer(state, action) {
  switch (action.type) {
    case actionTypes.set:
    case actionTypes.update: {
      return {
        ...state,
        [action.teamId]: action.meeting,
      };
    }
    case actionTypes.unset: {
      const newState = { ...state };
      delete newState[action.teamId];

      return { ...newState };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

// const state = useMeetingsState();
function useMeetingsState(): MeetingState {
  const context = useContext(MeetingsStateContext);
  if (context === undefined) {
    throw new Error(`useMeetingsState must be used within a MeetingsProvider`);
  }
  return context;
}

// const dispatch = useMeetingsDispatch();
function useMeetingsDispatch(): Dispatch<MeetingDispatchProps> {
  const context = useContext(MeetingsDispatchContext);
  if (context === undefined) {
    throw new Error(`useMeetingsDispatch must be used within a MeetingsProvider`);
  }
  return context;
}

// const [state, dispatch] = useMeetings();
function useMeetings(): [MeetingState, Dispatch<MeetingDispatchProps>] {
  const context: [MeetingState, Dispatch<MeetingDispatchProps>] = [useMeetingsState(), useMeetingsDispatch()];
  if (context === undefined) {
    throw new Error(`useMeetings must be used within a MeetingsProvider`);
  }
  return context;
}

const set = ({ teamId, meeting }: { teamId: number; meeting: Meeting }): MeetingDispatchProps => ({
  type: 'set',
  teamId,
  meeting,
});

const update = ({ teamId, meeting }: { teamId: number; meeting: Meeting }): MeetingDispatchProps => ({
  type: 'update',
  teamId,
  meeting,
});

const unset = ({ teamId }: { teamId: number }): MeetingDispatchProps => ({
  type: 'unset',
  teamId,
});

type MeetingsProviderProps = {
  children: ReactElement | ReactNode;
};

function MeetingsProvider(props: MeetingsProviderProps): ReactElement {
  const router = useRouter();
  const { data: session, status: sessionStatus } = useSession();
  const sessionLoading = sessionStatus === 'loading';

  const companyId = router.query.company as string;
  const teamId = getTeamId(router);

  const [companyUserRes] = useQuery({
    query: GET_COMPANY_USER_BY_ID,
    variables: {
      company_id: companyId,
      user_id: session?.id,
    },
    pause: !companyId || sessionLoading || !session?.id,
  });

  const context = useMemo(() => ({ additionalTypenames: ['meetings'] }), []);

  const [meetingRes] = useQuery({
    query: CURRENT_USER_MEETING,
    variables: {
      teams_id: teamId,
      company_user_id: companyUserRes?.data?.company_users?.[0]?.id,
    },
    pause: !teamId,
    context,
  });

  const meeting = meetingRes?.data?.meetings?.[0];

  const [state, dispatch] = useReducer(
    reducer,
    { ...INITIAL_STATE, meeting: { ...meeting } },
    (_state: MeetingState) => _state,
  );

  useEffect(() => {
    if (!meeting?.completed) {
      dispatch(set({ teamId: Number(teamId), meeting }));
    }
  }, [meeting, teamId]);

  return (
    <MeetingsStateContext.Provider value={state}>
      <MeetingsDispatchContext.Provider value={dispatch}>{props.children}</MeetingsDispatchContext.Provider>
    </MeetingsStateContext.Provider>
  );
}

MeetingsProvider.propTypes = {};

export {
  MeetingsProvider as default,
  useMeetings,
  useMeetingsState,
  useMeetingsDispatch,
  actionTypes,
  set,
  update,
  unset,
};
