import { gql } from 'urql';
import { OBJECTIVE_FRAGMENT } from './fragments';

const GET_OBJECTIVES = gql`
  query GetObjectives($where: objectives_bool_exp!) {
    objectives(where: $where, order_by: [{ owner: asc }, { due: asc }]) {
      ...ObjectiveFragment
    }
  }
  ${OBJECTIVE_FRAGMENT}
`;

export default GET_OBJECTIVES;
