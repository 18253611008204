export default function initials(name?: string, len = 2): string {
  return (
    name
      ?.split(' ')
      .map((word) => word[0])
      .slice(0, len)
      .join('')
      .toUpperCase() || ''
  );
}
