import { gql } from 'urql';
import { OPPORTUNITY_FRAGMENT } from './fragments';

const GET_OPPORTUNITIES = gql`
  query GetOpportunities($where: opportunities_bool_exp!) {
    opportunities(where: $where, order_by: [{ priority: asc }, { id: desc }]) {
      ...OpportunityFragment
    }
  }
  ${OPPORTUNITY_FRAGMENT}
`;

export default GET_OPPORTUNITIES;
