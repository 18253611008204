import { PlusIcon } from '@heroicons/react/solid';
import QuickAddDialog, { EntityType } from 'components/QuickAddDialog/QuickAddDialog';
import { ReactElement, useState } from 'react';
import button from 'styles/button';
import { classNames } from 'utils';

export default function QuickAddButton({ entityType }: { entityType: EntityType }): ReactElement {
  const [dialogOpen, setDialogOpen] = useState(false);

  return (
    <>
      <button
        type="button"
        className={classNames(
          button.primary(),
          'ml-3 inline-flex items-center justify-center rounded-md shadow-sm text-sm',
        )}
        onClick={() => {
          setDialogOpen(true);
        }}
      >
        <PlusIcon className="w-4 h-4 mr-1" />
        <div className="flex items-center justify-center">Add</div>
      </button>
      <QuickAddDialog entityType={entityType} open={dialogOpen} setOpen={setDialogOpen} />
    </>
  );
}
