import { gql } from 'urql';

const GET_OPPORTUNITY_FILES = gql`
  query GetOpportunityFiles($where: opportunity_files_bool_exp!) {
    opportunity_files(where: $where, order_by: [{ updated_at: desc }]) {
      id
      url
      file_name
      status
      file_type
    }
  }
`;

export default GET_OPPORTUNITY_FILES;
