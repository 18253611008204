import { gql } from 'urql';

const GET_ACTION_COMMENTS = gql`
  query GetActionComments($where: action_comments_bool_exp!) {
    action_comments(where: $where, order_by: [{ timestamp: desc }]) {
      id
      comment
      timestamp
      user_id
    }
  }
`;

export default GET_ACTION_COMMENTS;
