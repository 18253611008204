import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const GET_TEAM = gql`
  query GetTeam($id: Int!) {
    teams_by_pk(id: $id) {
      id
      name
      type
      scoreboard_url
      scoreboard_url_change

      team_members {
        id
        company_user {
          id
          user_id
          wg_skills
          vops_skills
          user {
            ...UserFragment
          }
          users_team_count(where: { deleted: { _eq: false } }) {
            user_id
            company_id
          }
        }
      }

      team_permissions {
        id
        permission {
          id
          operation
          table
        }
      }

      company {
        id
        name
        expired
      }
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_TEAM;
