import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { Fragment, ReactElement, useRef, useState, useEffect } from 'react';
import { CombinedError, useQuery } from 'urql';
import { classNames, refreshData } from 'utils';
import { ErrorToast, SuccessToast } from 'components';
import { Meeting as MeetingType } from 'data/meetings/types';
import { GET_COMPANY } from 'data/company';
import { Avatar } from 'components';
import button from 'styles/button';
import { useRouter } from 'next/router';

type MeetingRecapDialogProps = {
  meeting: MeetingType;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function MeetingRecapDialog({ meeting, open, setOpen }: MeetingRecapDialogProps): ReactElement {
  const router = useRouter();
  const [error, setError] = useState<CombinedError>();
  const [send, setSend] = useState<string>();
  const [loading, setLoading] = useState(false);
  const cancelButtonRef = useRef(null);

  const participants = meeting?.meeting_participants || [];
  const [selectedParticipants, setSelectedParticipants] = useState<number[]>([]);

  const [result] = useQuery({
    query: GET_COMPANY,
    variables: { id: router.query.company },
  });

  const week_start_on = result.data?.companies?.[0]?.week_start_on;

  useEffect(() => {
    setSelectedParticipants(meeting?.meeting_participants.map(({ id }) => id) || []);
  }, [meeting]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
            setSelectedParticipants(participants.map(({ id }) => id) || []);
          }}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="flex items-start px-8">
                  <div className="py-6 w-full">
                    <Dialog.Title as="h3" className="text-md leading-6 font-semibold text-gray-700">
                      Send Recap Email
                    </Dialog.Title>
                    {participants.length > 0 ? (
                      <div className="mt-4">
                        <input
                          type="checkbox"
                          checked={selectedParticipants.length === participants.length}
                          className="rounded cursor-pointer mr-3 ml-1"
                          onChange={async (event) => {
                            let _selectedParticipants = [];
                            if (event.target.checked) {
                              _selectedParticipants = participants.map(({ id }) => id);
                              setSelectedParticipants(_selectedParticipants);
                            } else {
                              setSelectedParticipants(_selectedParticipants);
                            }
                          }}
                        />
                        <span className="text-sm font-semibold text-gray-600">Select all</span>
                      </div>
                    ) : null}
                    <div className="flex flex-col mt-3 max-h-96 overflow-auto space-y-2.5">
                      {participants.length ? (
                        participants.map((participant) => {
                          const { id } = participant;
                          return (
                            <div key={id} className="flex items-center justify-between text-sm max-w-lg">
                              <div className="flex items-center">
                                <input
                                  type="checkbox"
                                  checked={selectedParticipants.includes(id)}
                                  className="rounded cursor-pointer mr-3 ml-1"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                  }}
                                  onChange={async (event) => {
                                    let _selectedParticipants = [];
                                    if (event.target.checked) {
                                      _selectedParticipants = [...selectedParticipants, id];
                                      setSelectedParticipants(_selectedParticipants);
                                    } else {
                                      _selectedParticipants = selectedParticipants.filter((_id) => _id !== id);
                                      setSelectedParticipants(_selectedParticipants);
                                    }
                                  }}
                                />
                                <Avatar user={participant.company_user.user} showName={true} />
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <span className="text-sm font-semibold text-gray-600">No participants</span>
                      )}
                    </div>
                  </div>
                </div>

                <button
                  onClick={() => {
                    setOpen(false);
                    setSelectedParticipants(participants.map(({ id }) => id) || []);
                  }}
                >
                  <XIcon className="absolute top-4 cursor-pointer right-4 h-6 w-6" />
                </button>
                <div className="border px-4 py-3 flex justify-end">
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                    onClick={() => {
                      setOpen(false);
                      setSelectedParticipants(participants.map(({ id }) => id) || []);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      button.primary({ isDisabled: selectedParticipants.length < 1 }),
                      'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                    )}
                    onClick={async () => {
                      setLoading(true);
                      if (selectedParticipants.length < 1) {
                        return true;
                      }
                      await fetch(`/api/email/meeting-recap/${meeting.id}`, {
                        method: 'POST',
                        headers: {
                          'content-type': 'application/json',
                        },
                        body: JSON.stringify({ participants: selectedParticipants, week_start_on: week_start_on }),
                      });
                      await refreshData(router);
                      setLoading(false);
                      setOpen(false);
                      setSend('Recap Email Sent');
                    }}
                    ref={cancelButtonRef}
                  >
                    {loading ? 'Sending...' : 'Send'}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={send} setMessage={setSend} />
    </>
  );
}
