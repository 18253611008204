import { CompanyUser } from 'components/UsersList/UsersList';
import { GET_COMPANY_USERS } from 'data/company';
import { OperationContext, useQuery } from 'urql';

export default function useCompanyUsers(
  companyId: string | number,
): [CompanyUser[], (opts?: Partial<OperationContext>) => void] {
  const [data, refetch] = useQuery({
    query: GET_COMPANY_USERS,
    variables: { company_id: companyId },
  });

  const companyUsers: CompanyUser[] = data.data?.company_users || [];

  return [companyUsers, refetch];
}
