import { gql } from 'urql';

const ADD_TEAMS = gql`
  mutation AddTeams($objects: [teams_insert_input!]!) {
    insert_teams(objects: $objects) {
      returning {
        id
        name
        type
      }
    }
  }
`;

export default ADD_TEAMS;
