import { gql } from 'urql';

const GET_SCORES = gql`
  query GetScores($where: scores_bool_exp!, $order_by: [scores_order_by!]) {
    scores(where: $where, order_by: $order_by) {
      id
      scoreboard_id
      date
      value

      measurable {
        title
      }
    }
  }
`;

export default GET_SCORES;
