import { gql } from 'urql';

const UPDATE_MEASURABLE = gql`
  mutation UpdateMeasurable($_set: measurables_set_input!, $id: Int!) {
    update_measurables(where: { id: { _eq: $id } }, _set: $_set) {
      affected_rows
    }
  }
`;

export default UPDATE_MEASURABLE;
