import { Walkthrough } from 'types/joy-ride';

const ACCOUNT_SETTINGS_WALKTHROUGH: Walkthrough = {
  name: 'account-settings',
  steps: [
    {
      content: 'Click your company name to find your account settings.',
      target: '.company-name',
    },
    {
      content: 'Click on Company Settings.',
      target: '.company-settings',
    },
    {
      content:
        'On the profile page you can set which day of the week your company starts and change your company name as needed.',
      target: '.profile-page',
    },
    {
      content:
        'Here, you can add teams to your company. Teams provide your users access to data specific to their department or team. Each user must be part of at least one team, but can be a member of multiple.',
      target: '.teams-page',
    },
    {
      content: 'Add users to your account here.',
      target: '.users-page',
    },
    {
      content:
        'Edit your payment method and see previous and upcoming payments here. You can update your credit card information here as needed and set your card as “default.',
      target: '.billing-page',
    },
  ],
};

export default ACCOUNT_SETTINGS_WALKTHROUGH;
