import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { Avatar } from 'components';
import { CompanyUser, RoleTypes } from 'components/UsersList/UsersList';
import GET_WEBHOOKS_BY_USER_AND_TYPE from 'data/actions/get-webhooks-by-user-and-type';
import { GET_COMPANY, GET_COMPANY_USERS } from 'data/company';
import { UPDATE_MEETING } from 'data/meetings';
import { Meeting } from 'data/meetings/types';
import { RATINGS } from 'data/meetings/update-meeting-participant';
import { unset, useMeetingsDispatch } from 'data/providers/MeetingsProvider';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { serverClient } from 'src/urqlClient';
import button from 'styles/button';
import { useMutation, useQuery } from 'urql';
import { classNames, gqlArray, refreshData } from 'utils';
import { filterUsersByRoles } from 'utils/filterUsers';
import notifyZapier, { Webhook } from 'utils/notifyWebhooks';

export default function StopMeetingDialog({
  meeting,
  open,
  setOpen,
  setOngoingMeeting,
}: {
  meeting: Meeting;
  open: boolean;
  setOpen: (open: boolean) => void;
  setOngoingMeeting: (val: boolean) => void;
}): ReactElement {
  const router = useRouter();
  const dispatch = useMeetingsDispatch();
  const [, updateMeeting] = useMutation(UPDATE_MEETING);
  const { data: session } = useSession();
  const [participants, setParticipants] = useState([]);
  const [sendEmail, setSendEmail] = useState(false);
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([]);

  const [result] = useQuery({
    query: GET_COMPANY,
    variables: { id: router.query.company },
  });

  const [meetingCompletionWebhooks, refetchMeetingCompletionWebhooks] = useQuery({
    query: GET_WEBHOOKS_BY_USER_AND_TYPE,
    variables: {
      user_id: session.id,
      trigger_type: 'meeting_completion',
    },
  });

  const week_start_on = result.data?.companies?.[0]?.week_start_on;

  const fetchUsers = useCallback(async () => {
    if (!session || !session.token || typeof session.token !== 'string') {
      console.error('Session token is invalid or missing');
      return;
    }
    try {
      const sClient = serverClient(session.token);
      const usersRes = await sClient
        .query(GET_COMPANY_USERS, {
          company_id: router.query.company,
        })
        .toPromise();
      const filteredUsers: CompanyUser[] = usersRes.data?.company_users || [];
      setCompanyUsers(filteredUsers);
    } catch (error) {
      console.error('Failed to fetch users:', error);
      // Handle error appropriately
    }
  }, [session, router.query.company]);

  useEffect(() => {
    if (session) {
      fetchUsers();
    }
  }, [session, fetchUsers]);

  useEffect(() => {
    const filteredUsernames = filterUsersByRoles(companyUsers, [RoleTypes.ADMIN, RoleTypes.MEMBER]).map(
      (user) => user.user.id,
    );
    const updatedParticipants = (meeting?.meeting_participants || []).filter((participant) =>
      filteredUsernames.includes(participant.company_user.user.id),
    );
    console.log({ updatedParticipants });
    setParticipants(updatedParticipants);
  }, [meeting, companyUsers]);

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                    End {meeting.title}?
                  </Dialog.Title>
                  <div className="mt-6">
                    <p className="text-md">Rate the meeting!</p>
                  </div>
                  <div className="flex flex-col mt-2">
                    {participants?.map((participant) => {
                      return (
                        <div key={participant.id} className="h-12 flex items-center justify-between text-sm max-w-lg">
                          <div className="flex items-center">
                            <Avatar user={participant.company_user.user} showName={true} />
                          </div>
                          <Listbox
                            value={participant.rating}
                            onChange={(value) => {
                              participant.rating = value;
                              setParticipants(participants);
                            }}
                          >
                            {({ open: _open }) => (
                              <>
                                <div className="ml-3 relative w-18">
                                  <Listbox.Button
                                    className={classNames(
                                      button.white(),
                                      'flex items-center justify-center relative w-full rounded-md shadow-sm pl-2 pr-7 h-8 text-left cursor-default text-sm',
                                    )}
                                  >
                                    <span className="flex items-center justify-center">
                                      <span className="block truncate">{participant.rating || 'N/A'}</span>
                                    </span>
                                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                      <ChevronDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                    </span>
                                  </Listbox.Button>

                                  <Transition
                                    show={_open}
                                    as={Fragment}
                                    leave="transition ease-in duration-100"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                  >
                                    <Listbox.Options
                                      static
                                      className="absolute z-10 mt-1 w-20 bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                    >
                                      {RATINGS.map((value) => {
                                        const selected = value === participant.rating;
                                        return (
                                          <Listbox.Option
                                            key={value}
                                            className={({ active }) =>
                                              classNames(
                                                active ? 'bg-gray-100' : '',
                                                'cursor-default select-none relative py-2 px-3',
                                              )
                                            }
                                            value={value}
                                          >
                                            <>
                                              <div className="flex items-center">
                                                <span
                                                  className={classNames(
                                                    selected ? 'font-semibold' : 'font-normal',
                                                    'ml-3 block truncate',
                                                  )}
                                                >
                                                  {value}
                                                </span>
                                              </div>

                                              {selected ? (
                                                <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                </span>
                                              ) : null}
                                            </>
                                          </Listbox.Option>
                                        );
                                      })}
                                    </Listbox.Options>
                                  </Transition>
                                </div>
                              </>
                            )}
                          </Listbox>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:justify-between sm:items-center">
                <div className="relative flex items-center mr-4">
                  <div className="flex items-center">
                    <input
                      id="meeting-recap"
                      aria-describedby="meeting-recap"
                      name="meeting-recap"
                      type="checkbox"
                      className="focus:ring-primary-500 text-primary-600 border-gray-300 rounded"
                      defaultChecked={sendEmail}
                      onChange={(event) => {
                        setSendEmail(event.target.checked);
                      }}
                    />
                  </div>
                  <div className="ml-2 text-sm">
                    <label htmlFor="meeting-recap" className="font-medium text-gray-700">
                      Send recap email
                    </label>
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 sm:w-auto sm:text-sm"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 mt-3 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={async () => {
                      dispatch(unset({ teamId: Number(router.query.team) }));

                      const activeMeetingSection =
                        meeting?.meeting_sections?.find((section) => section.id === meeting.active_section_id) ||
                        meeting?.meeting_sections?.[0];
                      const sectionStops = activeMeetingSection?.stops || [];

                      const stops = meeting.stops;
                      const paused = meeting?.starts?.length === meeting?.stops?.length;
                      const now = Date.now();
                      if (!paused) {
                        stops.push(now);
                        sectionStops.push(now);
                      }

                      const addSections = activeMeetingSection
                        ? [{ id: activeMeetingSection.id, stops: gqlArray(sectionStops) }]
                        : [];

                      const meetingRes = await updateMeeting({
                        id: meeting.id,
                        _set: { completed: true, stops: gqlArray(stops) },
                        addParticipants: participants.map((participant) => ({
                          id: participant.id,
                          company_user_id: participant.company_user_id,
                          meeting_id: participant.meeting_id,
                          rating: participant.rating,
                        })),
                        participantsUpdateColumns: ['rating'],
                        addSections,
                        sectionsUpdateColumns: ['stops'],
                      });

                      const meetingData: Meeting = meetingRes.data.update_meetings_by_pk;
                      const wh: Webhook[] = meetingCompletionWebhooks.data.zapier_webhooks;

                      console.log(wh, 'COMPLETION WEBHOOKS=============================================');

                      if (meetingData) {
                        let participantString = '';
                        meeting.meeting_participants.forEach((p, index) => {
                          if (index == 0) {
                            participantString += `${p.company_user.user.name}`;
                          } else {
                            participantString += `, ${p.company_user.user.name}`;
                          }
                        });

                        let participantRatingString = '';
                        meeting.meeting_participants.forEach((p, index) => {
                          if (index === 0) {
                            participantRatingString += `${p?.company_user?.user?.name}: ${p.rating ? p.rating : 0}`;
                          } else {
                            participantRatingString += `, ${p?.company_user?.user?.name}: ${p.rating ? p.rating : 0}`;
                          }
                        });

                        const data = {
                          owner: meeting?.user?.name,
                          title: meeting?.title,
                          type: meeting?.type,
                          participants: participantString || '',
                          notes: meeting?.notes,
                          participantRatings: participantRatingString || '',
                          date: new Date(meeting?.date).toISOString().slice(0, 10),
                        };

                        const res = await notifyZapier(wh, data);
                      }

                      if (meetingRes.error) {
                        throw meetingRes.error;
                      } else {
                        if (router.asPath.includes('meetings') && Number(router.query.id) === meeting.id) {
                          refreshData(router);
                        } else {
                          router.push(
                            `/company/${router.query.company}/team/${router.query.team}/meetings/${meeting.id}`,
                          );
                        }
                      }

                      if (sendEmail) {
                        await fetch(`/api/email/meeting-recap/${meeting.id}`, {
                          method: 'POST',
                          headers: {
                            'content-type': 'application/json',
                          },
                          body: JSON.stringify({ week_start_on: week_start_on }),
                        });
                      }
                      setOpen(false);
                      setOngoingMeeting(false);
                    }}
                    ref={cancelButtonRef}
                  >
                    End
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
