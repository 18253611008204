import { Listbox, Transition, Popover } from '@headlessui/react';
import { Fragment, ReactElement, useEffect } from 'react';
import { MeetingParticipant, MeetingConnect, MeetingConnectUser } from 'data/meetings/types';
import { Avatar } from 'components';
import button from 'styles/button';
import { classNames } from 'utils';
import { CheckIcon, SelectorIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { UPDATE_MEETING_PARTICIPANT } from 'data/meetings';
import { useMutation } from 'urql';
import { Disclosure } from '@headlessui/react';

export default function MeetingConnectCard({
  participant,
  connectData,
  setConnectData,
  roadmapValues,
  teamMembers,
  companyUsers,
}: {
  participant: MeetingParticipant;
  connectData: MeetingConnect[];
  setConnectData;
  roadmapValues: string[];
  teamMembers: MeetingConnectUser[];
  companyUsers: MeetingConnectUser[];
}): ReactElement {
  const [, updateMeetingParticipant] = useMutation(UPDATE_MEETING_PARTICIPANT);
  const emotion = connectData?.find((connect) => connect.id === participant.id)?.emotion;
  const coreValueShoutOut = connectData?.find((connect) => connect.id === participant.id)?.core_value_shout_out;
  const coreValueShoutOutTo = connectData?.find((connect) => connect.id === participant.id)?.core_value_shout_out_to;
  const coreValueShoutOutToOther = connectData?.find(
    (connect) => connect.id === participant.id,
  )?.core_value_shout_out_to_other;
  const coreValueShoutOutUser = companyUsers?.find((data) => data.user?.id === coreValueShoutOutTo)?.user;
  const highlights = connectData?.find((connect) => connect.id === participant.id)?.connect_highlights;

  const shoutOutTo = [];
  shoutOutTo.push(
    {
      label: 'Team Members',
      users: teamMembers,
    },
    {
      label: 'Company Users',
      users: companyUsers,
    },
    {
      label: 'Other',
      users: [],
    },
  );

  useEffect(() => {
    let timeout;
    const participantcoreValueShoutOutToOther =
      participant.core_value_shout_out_to_other === null ? '' : participant.core_value_shout_out_to_other;
    if (
      participant.emotion !== emotion ||
      participant.connect_highlights !== highlights ||
      participantcoreValueShoutOutToOther !== coreValueShoutOutToOther
    ) {
      timeout = setTimeout(async () => {
        const set = {
          emotion: emotion,
          connect_highlights: highlights,
          core_value_shout_out_to_other: coreValueShoutOutToOther,
        };
        if (participantcoreValueShoutOutToOther !== coreValueShoutOutToOther) {
          set['core_value_shout_out_to'] = null;
        }
        await updateMeetingParticipant({
          id: participant.id,
          _set: set,
        });
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [
    participant.id,
    participant.emotion,
    emotion,
    participant.connect_highlights,
    highlights,
    participant.core_value_shout_out_to_other,
    coreValueShoutOutToOther,
    updateMeetingParticipant,
  ]);

  return (
    <div className="mx-1 h-80 border bg-white mt-2 shadow rounded-lg px-3">
      <div className="flex mt-3 space-x-2">
        <Avatar user={participant?.company_user?.user} showName={true} />
      </div>
      <div className=" mt-3 w-full border-t border-gray-200" />

      <div className="mt-3 px-6">
        <input
          type="text"
          name="emotion"
          id="emotion"
          value={emotion}
          className={classNames(
            emotion ? 'font-normal' : 'font-semibold',
            'w-full h-10 shadow-sm focus:ring-primary focus:border-primary block text-2xs sm:text-xs border-gray-300 rounded-md',
          )}
          placeholder={`I'm feeling ...`}
          onChange={(event) => {
            const _connects = connectData.map((_connect) => ({ ..._connect }));
            const _connect = _connects.find((connect) => connect.id === participant.id);
            _connect.emotion = event.target.value;
            setConnectData(_connects);
          }}
        />
      </div>
      <div className=" mt-3 w-full border-t border-gray-200" />

      <div className="mt-3 px-6 grid grid-cols-1 gap-2 3xl:grid-cols-2">
        <div className="">
          <Listbox
            value={coreValueShoutOut}
            onChange={async (value) => {
              const _connects = connectData.map((_connect) => ({ ..._connect }));
              const _connect = _connects.find((connect) => connect.id === participant.id);
              _connect.core_value_shout_out = value;
              setConnectData(_connects);
              if (value !== participant.core_value_shout_out) {
                await updateMeetingParticipant({
                  id: participant.id,
                  _set: {
                    core_value_shout_out: value,
                  },
                });
              }
            }}
          >
            {({ open: _open }) => (
              <>
                <div className="mt-1 relative">
                  <Listbox.Button
                    className={classNames(
                      button.white({ withBase: false }),
                      'relative w-full h-10 rounded-md shadow-sm pl-3 pr-7 text-left cursor-default text-2xs sm:text-xs py-1',
                    )}
                  >
                    {coreValueShoutOut ? (
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">{coreValueShoutOut}</span>
                      </span>
                    ) : (
                      <span className="flex items-center text-gray-500 font-semibold">Core Value Shout Out</span>
                    )}
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={_open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {roadmapValues?.map((data, i) => (
                        <Listbox.Option
                          key={i}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-100' : '',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            )
                          }
                          value={data}
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block truncate text-2xs sm:text-xs',
                                  )}
                                >
                                  {data}
                                </span>
                              </div>

                              {selected ? (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        <div className="flex space-x-2 w-full">
          <div className="text-2xs sm:text-xs mt-4 font-semibold">to</div>

          <div className="w-full">
            <Popover className="relative mt-1">
              <>
                <Popover.Button
                  className={classNames(
                    button.white({ withBase: false }),
                    'relative w-full h-10 rounded-md shadow-sm pl-3 pr-7 text-left cursor-default text-2xs sm:text-xs py-1',
                  )}
                >
                  {coreValueShoutOutUser || coreValueShoutOutToOther ? (
                    <span className="flex items-center">
                      <Avatar
                        user={coreValueShoutOutUser || { name: coreValueShoutOutToOther }}
                        size="s"
                        showName={true}
                      />
                    </span>
                  ) : (
                    <span className="flex items-center text-gray-500 font-semibold">Name</span>
                  )}
                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className="absolute z-10 mt-2 bg-white w-full shadow-lg max-h-96 rounded-md py-2 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                    {({ close }) => (
                      <div>
                        {shoutOutTo.map(({ label, users }, i) => (
                          <div key={i}>
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button className="mb-2 flex w-full justify-between bg-gray-100 px-4 py-2 text-left cursor-pointer text-2xs sm:text-xs text-gray-500 font-semibold hover:bg-primary-50 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                    <span className="block truncate text-2xs sm:text-xs">{label}</span>
                                    <ChevronDownIcon className="h-5 w-5 text-gray-500 font-semibold" />
                                  </Disclosure.Button>
                                  <Transition
                                    show={open}
                                    enter="transition duration-100 ease-out"
                                    enterFrom="transform scale-95 opacity-0"
                                    enterTo="transform scale-100 opacity-100"
                                    leave="transition duration-75 ease-out"
                                    leaveFrom="transform scale-100 opacity-100"
                                    leaveTo="transform scale-95 opacity-0"
                                  >
                                    <Disclosure.Panel className="px-3 mb-1 text-base text-sm text-gray-800 bg-white">
                                      {label !== 'Other' ? (
                                        users.map((data: MeetingConnectUser, k) => (
                                          <div
                                            key={k}
                                            className="flex items-center mt-2 cursor-pointer"
                                            onClick={async () => {
                                              const _connects = connectData.map((_connect) => ({ ..._connect }));
                                              const _connect = _connects.find(
                                                (connect) => connect.id === participant.id,
                                              );
                                              _connect.core_value_shout_out_to = data?.user?.id;
                                              _connect.core_value_shout_out_to_other = '';
                                              setConnectData(_connects);
                                              if (data?.user?.id !== participant.core_value_shout_out_to) {
                                                await updateMeetingParticipant({
                                                  id: participant.id,
                                                  _set: {
                                                    core_value_shout_out_to: data?.user?.id,
                                                    core_value_shout_out_to_other: '',
                                                  },
                                                });
                                              }
                                              close();
                                            }}
                                          >
                                            <Avatar user={data?.user} size="s" showName={true} />
                                          </div>
                                        ))
                                      ) : (
                                        <div className="mt-2">
                                          <input
                                            type="text"
                                            name="shout_out_to_other"
                                            id="shout_out_to_other"
                                            value={coreValueShoutOutToOther}
                                            className={classNames(
                                              coreValueShoutOutToOther ? 'font-normal' : 'font-semibold',
                                              'w-full h-10 shadow-sm focus:ring-primary focus:border-primary block text-2xs sm:text-xs border-gray-300 rounded-md',
                                            )}
                                            placeholder={`Name`}
                                            onChange={(event) => {
                                              const _connects = connectData.map((_connect) => ({ ..._connect }));
                                              const _connect = _connects.find(
                                                (connect) => connect.id === participant.id,
                                              );
                                              _connect.core_value_shout_out_to_other = event.target.value;
                                              _connect.core_value_shout_out_to = null;
                                              setConnectData(_connects);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </Disclosure.Panel>
                                  </Transition>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        ))}
                      </div>
                    )}
                  </Popover.Panel>
                </Transition>
              </>
            </Popover>
          </div>
        </div>
      </div>
      <div className=" mt-3 w-full border-t border-gray-200" />

      <div className="mt-3 px-6">
        <input
          type="text"
          name="highlights"
          id="highlights"
          value={highlights}
          className={classNames(
            highlights ? 'font-normal' : 'font-semibold',
            'w-full h-10 shadow-sm focus:ring-primary focus:border-primary block text-2xs sm:text-xs border-gray-300 rounded-md',
          )}
          placeholder={`Big Wins, Highlights, New Question...`}
          onChange={(event) => {
            const _connects = connectData.map((_connect) => ({ ..._connect }));
            const _connect = _connects.find((connect) => connect.id === participant.id);
            _connect.connect_highlights = event.target.value;
            setConnectData(_connects);
          }}
        />
      </div>
    </div>
  );
}
