import { SSRData } from '@urql/core/dist/types/exchanges/ssr';
import { GET_COMPANY_USER_BY_ID } from 'data/company';
import { Team } from 'data/teams/types';
import GET_TEAM_MEMBER_BY_ID from 'data/team_members/get-team-member-by-id';
import { Session } from 'next-auth';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { FunctionComponent, ReactElement, ReactNode, useEffect, useState } from 'react';
import { useQuery } from 'urql';
import FullScreenLoader from './FullScreenLoader';

type RouteProtections = {
  auth?: boolean;
  company?: boolean;
  redirectIfExpired?: boolean;
  restricted?: boolean;
};

export type PageProps = {
  pageTitle?: string;
  session?: Session;
  urqlState?: SSRData;
  isAdmin?: boolean;
  hideSidebar?: boolean;
  expired?: boolean;
  team?: number;
  company?: number;
  teams?: Team[];
  [key: string]: unknown;
};

export type ExtendedFunctionComponent = FunctionComponent<PageProps> & RouteProtections;

export default function Auth({
  component,
  pageProps,
  children,
}: {
  component: ExtendedFunctionComponent;
  pageProps: PageProps;
  children: ReactNode;
}): ReactElement {
  const router = useRouter();
  const { data: session, status } = useSession();
  const hasUser = Boolean(session?.user);
  const [loading, setLoading] = useState(status === 'loading');

  const sessionLoading = status === 'loading';
  const authenticated = status === 'authenticated';

  const [protectCompany] = useQuery({
    query: GET_COMPANY_USER_BY_ID,
    variables: {
      company_id: router.query.company,
      user_id: session?.id,
    },
    pause: !component.company || !router.query.company || !authenticated,
  });

  const teamId = pageProps.teamId;
  const [protectTeam] = useQuery({
    query: GET_TEAM_MEMBER_BY_ID,
    variables: {
      team_id: teamId,
      user_id: session?.id,
    },
    pause: !component.company || !teamId || !authenticated,
  });

  const teamDeleted = protectTeam?.data?.team_members?.[0]?.team?.deleted;

  const dataLoading = sessionLoading || protectCompany.fetching || protectTeam.fetching;

  useEffect(() => {
    if (sessionLoading || protectCompany.fetching || protectTeam.fetching) {
      setLoading(true);
    }
  }, [sessionLoading, protectCompany.fetching, protectTeam.fetching]);

  useEffect(() => {
    if (!authenticated) {
      router.push('/auth/signin');
    } else if (
      component.company &&
      !protectCompany.fetching &&
      router.query.company &&
      !protectCompany.data?.company_users?.[0]
    ) {
      router.push('/');
    } else if (
      component.company &&
      teamId &&
      !protectTeam.fetching &&
      (!protectTeam.data?.team_members?.[0] || teamDeleted)
    ) {
      router.push(`/company/${router.query.company}`);
    } else if (!dataLoading) {
      setLoading(false);
    }
  }, [authenticated, router, protectCompany, protectTeam, component, dataLoading, teamDeleted, teamId]);

  if (loading || !hasUser) {
    return <FullScreenLoader open />;
  }

  return <>{children}</>;
}
