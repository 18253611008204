import { gql } from 'urql';

const DELETE_INVITES = gql`
  mutation DeleteInvites($ids: [uuid!] = "") {
    delete_invites(where: { id: { _in: $ids } }) {
      affected_rows
    }
  }
`;

export default DELETE_INVITES;
