import { Dialog, Listbox, Transition } from '@headlessui/react';
import { ADD_TEAM_MEMBERS, DELETE_TEAM_MEMBERS } from 'data/team_members';
import { Fragment, ReactElement, useEffect, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation, useQuery } from 'urql';
import { classNames } from 'utils';
import Image from 'next/image';
import { ADD_TEAM, GET_TEAMS } from 'data/teams';
import { useRouter } from 'next/router';
import { theme } from 'tailwind.config';
import { CheckIcon } from '@heroicons/react/solid';
import { ErrorToast, SuccessToast } from 'components/Toast';

type TeamMembersListProps = {
  companyUserId: number;
  companyId: number;
  teamId: number;
  show: boolean;
  team_member_id?: number;
  popUpCloseFun: (param) => void;
  callback: () => void;
};

export default function UserTeamManagementModal({
  companyUserId,
  companyId,
  teamId,
  show,
  team_member_id,
  popUpCloseFun,
  callback,
}: TeamMembersListProps): ReactElement {
  const [removing, setRemoving] = useState<number>(team_member_id);
  const [removeRestriction, setRemoveRestriction] = useState<boolean>(false);
  const [updated, setUpdated] = useState<string>(null);
  const [error, setError] = useState<CombinedError>();
  const [newTeamName, setNewTeamName] = useState<string>(null);
  const cancelButtonRef = useRef(null);
  const [teamList, setTeamList] = useState<Array<{ id: number; name: string }>>([]);
  const [selectedTeamId, setSelectedTeamId] = useState<number>(null);
  const [createTeamBtnActive, setCreateTeamBtnActive] = useState<boolean>(false);
  const [assignBtnActive, setAssignBtnActive] = useState<boolean>(false);
  const [initialTeamSelect, setInitialTeamSelect] = useState<boolean>(false);

  const [, addTeamMembers] = useMutation(ADD_TEAM_MEMBERS);
  const [, deleteTeamMembers] = useMutation(DELETE_TEAM_MEMBERS);
  const [, addTeam] = useMutation(ADD_TEAM);

  const [usersTeamListRes] = useQuery({
    query: GET_TEAMS,
    variables: { where: { company_id: { _eq: companyId }, deleted: { _neq: true } } },
  });

  const router = useRouter();

  useEffect(() => {
    setRemoveRestriction(show);
  }, [show]);

  useEffect(() => {
    popUpCloseFun(removeRestriction);
  }, [removeRestriction]);

  useEffect(() => {
    const list = (usersTeamListRes.data?.teams || []).map(({ id, name }) => ({ id, name }));
    // remove current team from the list
    const filteredList = list.filter((team) => team.id !== parseInt(teamId.toString()));

    if (!filteredList.length) {
      setAssignBtnActive(false);
    }
    setTeamList(filteredList);
    // setSelectedTeamId(teamList[0]?.id);
  }, [usersTeamListRes]);

  useEffect(() => {
    if (selectedTeamId) {
      setAssignBtnActive(true);
    }
  }, [initialTeamSelect, selectedTeamId]);

  useEffect(() => {
    if (selectedTeamId) {
      setAssignBtnActive(true);
    }
  }, [removeRestriction, removing]);

  const assignToExistingTeamAndRemove = async () => {
    if (!assignBtnActive) {
      return null;
    }

    const addRes = await addTeamMembers({
      objects: [
        {
          company_user_id: companyUserId,
          team_id: selectedTeamId,
        },
      ],
    });

    if (addRes.error) {
      setError(addRes.error);
      setAssignBtnActive(true);
    } else {
      setAssignBtnActive(false);
      await deleteTeamMembers({ ids: [team_member_id] });

      setRemoveRestriction(false);
      setRemoving(null);
      setUpdated('Reassigned');
      callback();
    }
  };

  const createAndAssignToATeam = async (): Promise<boolean> => {
    setCreateTeamBtnActive(false);

    const teamObject = {
      object: {
        company_id: companyId,
        name: newTeamName,
        team_members: { data: [{ company_user_id: companyUserId }] },
      },
    };

    const addTeamRes = await addTeam(teamObject);
    if (addTeamRes.error) {
      setCreateTeamBtnActive(true);
      setError(addTeamRes.error);
      return false;
    }
    return true;
  };

  const assignToNewAndRemove = async (): Promise<void> => {
    if (!createTeamBtnActive) {
      return null;
    }
    const isAssignSuccess: boolean = await createAndAssignToATeam();

    if (isAssignSuccess) {
      await deleteTeamMembers({ ids: [team_member_id] });
    }
    setRemoveRestriction(false);
    setRemoving(null);
    setUpdated('Reassigned');
    callback();
  };

  const teamListRef = useRef(null);

  return (
    <Transition.Root show={removeRestriction} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-10 inset-0 overflow-y-auto"
        open={removing !== null}
        onClose={() => setRemoving(null)}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="fixed inset-0 overflow-y-auto bg-gray-500 bg-opacity-75 transition-opacity duration-300 ease-in-out">
              <div className="relative top-40 mx-auto py-4 px-8 w-full bg-white rounded-lg shadow-lg modal w-1/1 md:w-1/2 2xl:w-1/3">
                <div
                  className={`flex justify-center p-4 -mx-8 -mt-8 mb-1 rounded-md`}
                  style={{ backgroundColor: theme.extend.colors.primary[50] }}
                >
                  <Image
                    className="w-20 h-20 rounded-full content-center"
                    src="/images/teams_icon.png"
                    height={100}
                    width={100}
                    alt=""
                    referrerPolicy="no-referrer"
                    style={{
                      maxWidth: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
                <h5 className="text-2xl font-medium my-6 text-center font-bold">Unable to remove user</h5>
                <p className="text-gray-700 text-base text-justify">
                  Looks like you&apos;re trying to set someone adrift without a team. In the spirit of System & Soul,
                  every person needs at least one anchor team.
                </p>
                <div className="mb-6">
                  <label htmlFor="new-team" className="text-gray-700 block  font-semibold w-44 my-4">
                    Assign to a new team:
                  </label>
                  <div className="flex flex-col justify-between md:flex-row">
                    <div className="w-full md:mr-4 md:my-0">
                      <input
                        type="text"
                        id="new-team"
                        placeholder="New team name"
                        className="w-full h-10  my-4 px-2 shadow-sm focus:ring-primary focus:border-primary block text-sm border-gray-300 rounded-md border border-gray-300 focus:outline-none focus:ring-blue-500 focus:ring-1 md:mr-4 md:my-0"
                        onChange={(event) => {
                          setNewTeamName(event.target.value);
                          if (event.target.value) {
                            setCreateTeamBtnActive(true);
                          } else {
                            setCreateTeamBtnActive(false);
                          }
                        }}
                      />
                    </div>

                    <button
                      className={classNames(
                        button.primary({ isDisabled: !createTeamBtnActive }),
                        'w-full h-10 inline-flex items-center justify-center py-2 rounded-md shadow-sm text-sm font-semibold text-white max-w-xs',
                      )}
                      onClick={assignToNewAndRemove}
                    >
                      Create & Assign
                    </button>
                  </div>
                </div>
                <div className="mb-6">
                  <label htmlFor="new-team" className="text-gray-700 block  font-semibold my-4 w-56">
                    Assign to an existing team:
                  </label>
                  <div className="flex flex-col justify-between md:flex-row">
                    <div className="w-full my-4 md:mr-4 md:my-0">
                      <Listbox
                        value={selectedTeamId}
                        onChange={(value) => {
                          setSelectedTeamId(value);
                        }}
                      >
                        {({ open }) => (
                          <>
                            <div>
                              <Listbox.Button
                                className={classNames(
                                  button.white(),
                                  'relative w-full rounded-md shadow-sm pl-3 pr-7 h-10 text-left cursor-default text-sm',
                                )}
                                ref={teamListRef}
                              >
                                <span className="flex items-center font-medium">
                                  {teamList.length ? (
                                    teamList.find((team) => team.id === selectedTeamId)?.name || (
                                      <span className="flex items-center text-gray-500">Select a team</span>
                                    )
                                  ) : (
                                    <option key={0} value={0} className="text-md">
                                      - No options to select -
                                    </option>
                                  )}
                                </span>
                              </Listbox.Button>

                              <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                              >
                                <Listbox.Options
                                  static
                                  className="absolute z-10 mt-1 w-21/50 bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                >
                                  {teamList.map(({ id, name }) => (
                                    <Listbox.Option
                                      key={id}
                                      className={({ active }) =>
                                        classNames(
                                          active ? 'bg-gray-100' : '',
                                          'cursor-default select-none relative py-2 pl-3 pr-9',
                                        )
                                      }
                                      onClick={() => {
                                        setInitialTeamSelect(true);
                                      }}
                                      value={id}
                                      style={{ width: teamListRef?.current?.offsetWidth }}
                                    >
                                      {({ selected }) => (
                                        <>
                                          <div className="flex items-center mr-2 font-medium hover:font-extrabold">
                                            <span className="hover:font-extrabold">{name}</span>
                                          </div>
                                          {selected ? (
                                            <span className="absolute inset-y-0 right-0 flex items-center mr-4 hover:font-extrabold">
                                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Listbox.Option>
                                  ))}
                                </Listbox.Options>
                              </Transition>
                            </div>
                          </>
                        )}
                      </Listbox>
                    </div>
                    <button
                      className={classNames(
                        button.primary({ isDisabled: !assignBtnActive }),
                        'w-full h-10 inline-flex items-center justify-center py-2 rounded-md shadow-sm text-sm font-semibold text-white max-w-xs',
                      )}
                      onClick={assignToExistingTeamAndRemove}
                    >
                      Assign
                    </button>
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    type="button"
                    className="w-1/3 justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                    onClick={() => {
                      setRemoving(null);
                      setRemoveRestriction(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
          <ErrorToast error={error} setError={setError} />
          <SuccessToast message={updated} setMessage={setUpdated} />
        </div>
      </Dialog>
    </Transition.Root>
  );
}
