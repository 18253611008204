import { CheckCircleIcon, XIcon } from '@heroicons/react/outline';
import { Dispatch, ReactElement, SetStateAction } from 'react';
import { CombinedError } from 'urql';
import Toast from './Toast';

export type ErrorToastError = {
  name: string;
  message: string;
};

type ErrorToastProps = {
  error: ErrorToastError;
  setError: Dispatch<SetStateAction<CombinedError>>;
};

export default function ErrorToast({ error, setError }: ErrorToastProps): ReactElement {
  return (
    <Toast
      open={Boolean(error)}
      onClose={() => {
        setError(null);
      }}
      time={5000}
      content={
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="p-4">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <CheckCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3 w-0 flex-1">
                <p className="text-sm font-semibold text-gray-900">{error?.name}</p>
                <p className="mt-1 text-sm text-gray-500">{error?.message}</p>
              </div>
              <div className="ml-4 flex-shrink-0 flex">
                <button
                  type="button"
                  className="rounded-md border-0 inline-flex text-gray-700"
                  onClick={() => {
                    setError(null);
                  }}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
}
