import { gql } from 'urql';

const SEND_USER_INVITE = gql`
  mutation SendUserInvite($company_id: Int!, $email: String!, $role_id: Int!) {
    insert_invites_one(object: { company_id: $company_id, email: $email, role_id: $role_id }) {
      id
    }
  }
`;

export default SEND_USER_INVITE;
