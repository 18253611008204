import d3 from 'd3';
import { Config, Node } from 'types/d3';
import { collapse } from '../utils';

export const PERSON_REPORTS_CLASS = 'org-chart-person-reports';

function onExpandClick(configOnClick: Config): (datum: Node) => void {
  const { loadConfig } = configOnClick;

  return (datum) => {
    if ((d3.event as Event).defaultPrevented) return;
    const config = loadConfig();
    const { loadChildren, render } = config;

    // If this person doesn't have children but `hasChild` is true,
    // attempt to load using the `loadChildren` config function
    if (!datum.children && !datum._children && datum.hasChild) {
      if (!loadChildren) {
        console.error('react-org-chart.onClick: loadChildren() not found in config');
        return;
      }

      const result = loadChildren(datum);
      const handler = handleChildrenResult(config, datum);

      return handler(result);
    }

    const node = config.svg.selectAll(`[data-id="${datum.id}"]`);
    const reportsCircle = node.selectAll(`.${PERSON_REPORTS_CLASS}`);
    if (datum.children) {
      // Collapse the children
      config.callerNode = datum;
      config.callerMode = 0;
      datum._children = datum.children;
      datum.children = null;
      reportsCircle.text(datum.person.totalReports);
    } else {
      // Expand the children
      config.callerNode = datum;
      config.callerMode = 1;
      datum.children = datum._children;
      datum._children = null;
      reportsCircle.text('-');
    }

    // Pass in the clicked datum as the sourceNode which
    // tells the child nodes where to animate in from
    render({
      ...config,
      sourceNode: datum,
    });
  };
}

function handleChildrenResult(config, datum) {
  const { tree, render } = config;

  return (children) => {
    const result = {
      ...datum,
      children,
    };

    // Collapse the nested children
    children.forEach(collapse);

    result.children.forEach((child) => {
      if (!tree.nodes(datum)[0]._children) {
        tree.nodes(datum)[0]._children = [];
      }

      child.x = datum.x;
      child.y = datum.y;
      child.x0 = datum.x0;
      child.y0 = datum.y0;

      tree.nodes(datum)[0]._children.push(child);
    });

    if (datum.children) {
      // Collapse the children
      config.callerNode = datum;
      config.callerMode = 0;
      datum._children = datum.children;
      datum.children = null;
    } else {
      // Expand the children
      config.callerNode = null;
      config.callerMode = 1;
      datum.children = datum._children;
      datum._children = null;
    }

    // Pass in the newly rendered datum as the sourceNode
    // which tells the child nodes where to animate in from
    render({
      ...config,
      sourceNode: result,
    });
  };
}

function onLeftClick(configOnClick: Config): (datum: Node) => void {
  const { loadConfig } = configOnClick;

  return (datum) => {
    const config = loadConfig();

    const { onMovePerson, render } = config;

    const siblings = datum.parent?.children || datum.parent?._children || [];
    if (!datum.parent || siblings[0]?.id === datum.id) {
      return false;
    }
    const updateNodes = [];
    const curIndex = siblings.map((s) => s.id).indexOf(datum.id);
    const left = siblings?.find((sibling) => sibling?.right_sibling === datum?.id) || siblings?.[curIndex - 1];
    const right = siblings?.find((sibling) => sibling?.id === datum?.right_sibling) || siblings?.[curIndex + 1];
    const nextLeftSibling =
      siblings?.find((sibling) => sibling?.right_sibling === left?.id) || siblings?.[curIndex - 2];
    if (nextLeftSibling) {
      updateNodes.push({ id: nextLeftSibling?.id, right_sibling: datum?.id });
    }
    updateNodes.push({ id: datum?.id, right_sibling: left?.id });
    updateNodes.push({ id: left?.id, right_sibling: right?.id || null });

    const leftIndex = siblings.map((s) => s.id).indexOf(left?.id);
    siblings[leftIndex] = datum;
    siblings[curIndex] = left;
    datum.parent.children = siblings;
    onMovePerson?.({ siblings: updateNodes, loadConfig, datum });

    render({
      ...config,
      sourceNode: datum,
    });
  };
}

function enableMoveArrows(loadConfig: () => Config, datum: Node): void {
  const config = loadConfig();

  const { render, titleColor, borderColor } = config;
  const siblings = datum.parent?.children || datum.parent?._children || [];

  siblings.forEach((s) => {
    const leftIcon = config.svg.selectAll(`#left-${s.id}`);
    leftIcon
      .style('cursor', (d) => {
        return !d.parent || siblings[0]?.id === d.id ? 'default' : 'pointer';
      })
      .text('<')
      .attr('fill', (d) => {
        return !d.parent || siblings[0]?.id === d.id ? borderColor : titleColor;
      });
    const rightIcon = config.svg.selectAll(`#right-${s.id}`);
    rightIcon
      .style('cursor', (d) => {
        return !d.parent || siblings[0]?.id === d.id ? 'default' : 'pointer';
      })
      .text('>')
      .attr('fill', (d) => {
        return !d.parent || siblings[siblings.length - 1]?.id === d.id ? borderColor : titleColor;
      });
  });

  render({
    ...config,
    sourceNode: datum,
  });
}

function disableMoveArrows(loadConfig: () => Config, datum: Node): void {
  const config = loadConfig();

  const { render, borderColor } = config;
  const siblings = datum.parent?.children || datum.parent?._children || [];
  if (!datum.parent || siblings[0]?.id === datum.id) {
    return;
  }

  siblings.forEach((s) => {
    const leftIcon = config.svg.selectAll(`#left-${s.id}`);
    leftIcon.style('cursor', 'default').text('<').attr('fill', borderColor);
    const rightIcon = config.svg.selectAll(`#right-${s.id}`);
    rightIcon.style('cursor', 'default').text('>').attr('fill', borderColor);
  });

  render({
    ...config,
    sourceNode: datum,
  });
}

function onRightClick(configOnClick: Config): (datum: Node) => void {
  const { loadConfig } = configOnClick;

  return (datum) => {
    const config = loadConfig();

    const { onMovePerson, render, titleColor, borderColor } = config;

    const siblings = datum.parent?.children || datum.parent?._children || [];
    if (!datum.parent || siblings[siblings.length - 1]?.id === datum.id) {
      return false;
    }

    const updateNodes = [];
    const curIndex = siblings.map((s) => s.id).indexOf(datum.id);
    const left = siblings?.find((sibling) => sibling?.right_sibling === datum?.id) || siblings?.[curIndex - 1];
    const right =
      siblings?.find((sibling) => sibling?.id === datum?.right_sibling) || siblings?.length - 1 > curIndex
        ? siblings?.[curIndex + 1]
        : null;
    const nextRightSibling =
      siblings?.find((sibling) => sibling?.id === right?.right_sibling) || siblings?.[curIndex + 2];
    if (right) {
      updateNodes.push({ id: right?.id, right_sibling: datum?.id });
    }
    if (left) {
      updateNodes.push({ id: left?.id, right_sibling: right?.id || null });
    }
    updateNodes.push({ id: datum?.id, right_sibling: nextRightSibling?.id || null });

    let newOrderSiblings = [];
    const rightIndex = siblings.map((s) => s.id).indexOf(right?.id);
    siblings[rightIndex] = datum;
    siblings[curIndex] = right;
    datum.parent.children = siblings;
    newOrderSiblings = siblings;

    newOrderSiblings.forEach((s) => {
      const leftIcon = config.svg.selectAll(`#left-${s.id}`);
      leftIcon
        .style('cursor', (d) => {
          return !d.parent || newOrderSiblings[0]?.id === d.id ? 'default' : 'pointer';
        })
        .text('<')
        .attr('fill', (d) => {
          return !d.parent || newOrderSiblings[0]?.id === d.id ? borderColor : titleColor;
        });
      const rightIcon = config.svg.selectAll(`#right-${s.id}`);
      rightIcon
        .style('cursor', (d) => {
          return !d.parent || newOrderSiblings[0]?.id === d.id ? 'default' : 'pointer';
        })
        .text('>')
        .attr('fill', (d) => {
          return !d.parent || newOrderSiblings[newOrderSiblings.length - 1]?.id === d.id ? borderColor : titleColor;
        });
    });

    onMovePerson?.({ siblings: updateNodes, loadConfig, datum });

    render({
      ...config,
      sourceNode: datum,
    });
  };
}

export { onExpandClick, onLeftClick, onRightClick, enableMoveArrows, disableMoveArrows };
