import { Measurable, MeasurableType, WeekStartType } from 'data/measurables/types';
import { Score } from 'data/scores/types';
import {
  differenceInCalendarWeeks,
  differenceInCalendarDays,
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  differenceInCalendarYears,
  subDays,
  startOfMonth,
  isSameMonth,
  subMonths,
  subQuarters,
  startOfQuarter,
  isSameQuarter,
  subYears,
  subWeeks,
  isSameWeek,
  isSameDay,
  isSameYear,
  startOfDay,
  startOfWeek,
  startOfYear,
  parseISO,
  isAfter,
  addMinutes,
  format,
  endOfWeek,
} from 'date-fns';

/**
 * Categorize scores into date intervals
 * @param measurable
 * @param numPeriods
 * @param startDate
 * @returns
 */
const bucketizeScoresPerIntervals = (
  measurable: Measurable,
  _numPeriods = 12,
  startDate = null,
  start_week_on = 0,
): Score[] => {
  const { scores, interval } = measurable;
  let scoreDate;
  let now = startDate || new Date();
  now = addMinutes(now, now.getTimezoneOffset());
  const groupedScores = {};
  let dates = [];

  const numPeriods = Math.abs(_numPeriods);
  if (!numPeriods) {
    return [];
  }

  switch (interval) {
    case 'monthly':
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subMonths(startOfMonth(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfMonth(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'quarterly':
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subQuarters(startOfQuarter(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfQuarter(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'daily':
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subDays(startOfDay(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfDay(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
    case 'annually':
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) => format(subYears(startOfYear(now), i), 'yyyy-MM-dd'));
      dates.forEach((date) => (groupedScores[date] = []));

      scores.forEach((score) => {
        scoreDate = format(startOfYear(parseISO(score.date)), 'yyyy-MM-dd');
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });

      break;
    case 'weekly':
    default:
      dates = Array(numPeriods)
        .fill('')
        .map((_, i) =>
          format(subWeeks(startOfWeek(now, { weekStartsOn: start_week_on as WeekStartType }), i), 'yyyy-MM-dd'),
        );
      dates.forEach((date) => (groupedScores[date] = []));
      scores.forEach((score) => {
        scoreDate = format(
          startOfWeek(parseISO(score.date), { weekStartsOn: start_week_on as WeekStartType }),
          'yyyy-MM-dd',
        );
        if (scoreDate in groupedScores) {
          groupedScores[scoreDate].push(score);
        }
      });
      break;
  }

  const filteredScores = [];
  Object.values(groupedScores).forEach((scoresForPeriod: Score[]) => {
    if (scoresForPeriod.length) {
      const mostRecentScore = scoresForPeriod.reduce(
        (mrs, score) => (isAfter(parseISO(score.updated_at), parseISO(mrs.updated_at)) ? score : mrs),
        scoresForPeriod[0],
      );
      filteredScores.push(mostRecentScore);
    }
  });
  return filteredScores;
};

/**
 * Get inerval count as per weeks | days | months | qauters | years
 * @param interval
 * @returns count
 */
const countPerDynamicRange =
  (interval) =>
  (_start, _end): number => {
    const intervalWiseCal = {
      daily: Math.abs(differenceInCalendarDays(new Date(_start), new Date(_end))),
      weekly: Math.abs(differenceInCalendarWeeks(new Date(_start), new Date(_end))),
      monthly: Math.abs(differenceInCalendarMonths(new Date(_start), new Date(_end))),
      quarterly: Math.abs(differenceInCalendarQuarters(new Date(_start), new Date(_end))),
      annually: Math.abs(differenceInCalendarYears(new Date(_start), new Date(_end))),
    };

    return intervalWiseCal[interval];
  };

const removeDuplicateScores = (data) => {
  const uniqueScores = {};

  return data.reduce((acc, item) => {
    const key = item.date;

    if (!uniqueScores[key]) {
      uniqueScores[key] = true;
      acc.push(item);
    }
    return acc;
  }, []);
};

const INTERVAL_LABLES = {
  daily: 'days',
  weekly: 'weeks',
  monthly: 'months',
  quarterly: 'quaters',
  annually: 'years',
};

/**
 * Get scores for given intervals
 * @param measurable
 * @param week_start_on
 * @param start
 * @param end
 * @returns
 */
const getScoresByIntervals = (
  measurable: Measurable,
  week_start_on?: number,
  start?: string | Date,
  end?: string | Date,
): { score?: Score; date: Date }[] => {
  const weekStart: WeekStartType = (week_start_on as WeekStartType) || 0;
  // startDateToRender: This value represents the date from which the table starts to render(end date),
  // going backward (till start date from calendar).
  let startDateToRender = endOfWeek(new Date(end), { weekStartsOn: weekStart });
  let subFn;
  let isSameFn;
  let diffFn;

  switch (measurable?.interval) {
    case 'monthly':
      subFn = subMonths;
      startDateToRender = startOfMonth(startDateToRender);
      isSameFn = isSameMonth;
      diffFn = differenceInCalendarMonths;
      break;
    case 'quarterly':
      subFn = subQuarters;
      startDateToRender = startOfQuarter(startDateToRender);
      isSameFn = isSameQuarter;
      diffFn = differenceInCalendarQuarters;
      break;
    case 'annually':
      subFn = subYears;
      startDateToRender = startOfYear(startDateToRender);
      isSameFn = isSameYear;
      diffFn = differenceInCalendarYears;
      break;
    case 'daily':
      subFn = subDays;
      startDateToRender = startOfDay(startDateToRender);
      isSameFn = isSameDay;
      diffFn = differenceInCalendarDays;
      break;
    case 'weekly':
    default:
      subFn = subWeeks;
      startDateToRender = startOfWeek(startDateToRender, { weekStartsOn: weekStart });
      isSameFn = isSameWeek;
      diffFn = differenceInCalendarWeeks;
      break;
  }

  const totalNumber = diffFn(new Date(startDateToRender), new Date(start), { weekStartsOn: weekStart });
  const filteredScores = bucketizeScoresPerIntervals(measurable, totalNumber, startDateToRender);
  const scores: { score?: Score; date: Date }[] = [];
  for (let i = 0; i < totalNumber; ++i) {
    const date: Date = subFn(startDateToRender, i, { weekStartsOn: weekStart });
    const score = filteredScores.find(({ date: scoreDate }) => {
      return isSameFn(date, parseISO(scoreDate), { weekStartsOn: weekStart });
    });

    if (score) {
      scores.push({ score, date });
    } else {
      scores.push({ date });
    }
  }

  return scores;
};

/**
 * Get interval counts by interval type
 * @param param
 * @returns {number}
 */
const getIntervalCounts = ({ start, end, intervalType, week_start_on }): number => {
  const diffFunction = {
    daily: differenceInCalendarDays,
    monthly: differenceInCalendarMonths,
    quarterly: differenceInCalendarQuarters,
    annually: differenceInCalendarYears,
    weekly: differenceInCalendarWeeks,
  };
  const diffFunctionOptions = {
    daily: {},
    monthly: {},
    quarterly: {},
    annually: {},
    weekly: { weekStartsOn: week_start_on },
  };

  const diffCount = diffFunction[intervalType](new Date(end), new Date(start), diffFunctionOptions[intervalType]);
  if (intervalType === 'annually' && diffCount === 0) {
    return Math.ceil(differenceInCalendarDays(new Date(end), new Date(start)) / 365);
  }
  return diffCount + 1;
};

/**
 * Get the total based on start and end dates based buckets
 * @param measurable
 * @param numPeriods
 * @param startDate
 * @param start_week_on
 * @returns
 */
const getBucketizedTotal = (measurable: Measurable, numPeriods = 12, endDate = null, start_week_on = 0): number => {
  const scores: Array<Score> = bucketizeScoresPerIntervals(measurable, numPeriods, endDate, start_week_on);
  return scores.reduce((acc, i) => acc + i.value, 0);
};

/**
 * Calculate average for healty fit
 * @param measurable
 * @param week_start_on number
 * @returns number | null
 */
const averageForKIPs = (measurable: Measurable, week_start_on = 0): number | null => {
  const start = startOfYear(new Date());
  const end = new Date();
  const intervalType = measurable.interval;
  const _intervalCount = getIntervalCounts({ start, end, intervalType, week_start_on });

  const scores = bucketizeScoresPerIntervals(measurable, _intervalCount, end, week_start_on);
  if (!scores?.length) {
    return null;
  }

  const total = scores.reduce((acc, i) => acc + i.value, 0);
  const average = total / scores.length;
  return average;
};

/**
 * Calculate total for healty fit
 * @param measurable
 * @param week_start_on
 * @returns number | null
 */
const getTotalForKPIs = (measurable: Measurable, week_start_on = 0): number | null => {
  const start = startOfYear(new Date());
  const end = new Date();
  const intervalType = measurable.interval;
  const _intervalCount = getIntervalCounts({ start, end, intervalType, week_start_on });

  return getBucketizedTotal(measurable, _intervalCount, end, week_start_on);
};

export { removeDuplicateScores, INTERVAL_LABLES };

export default {
  countPerDynamicRange,
  getScoresByIntervals,
  bucketizeScoresPerIntervals,
  getIntervalCounts,
  getBucketizedTotal,
  averageForKIPs,
  getTotalForKPIs,
};
