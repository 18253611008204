import { Walkthrough } from 'types/joy-ride';

const CREATE_MEETING_WALKTHROUGH: Walkthrough = {
  name: 'create-meeting',
  steps: [
    {
      title: 'Add Meeting',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Click on "+ New Meeting" in order to create a new meeting.
        </div>
      ),
      target: '.create-meeting-button',
    },
    {
      title: 'Choose or Create a Meeting Template',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Here, you can select a meeting from a template or create your own custom template. A good place to start is
          with the “Weekly Sync” template.
        </div>
      ),
      target: '.meeting-templates-container',
      placement: 'right',
      hideBackButton: true,
    },
    {
      title: 'Customize Your Meeting Setup',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Here, you can decide which participants to include in the meeting and change the duration of each section of
          the meeting. Once you have finished customizing to your liking, you can choose to save it as a template to be
          used in future meetings.
        </div>
      ),
      target: '.weekly-sync-customize-button',
    },
    {
      title: 'Walkthrough Complete!',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            Give it a try, and if you have any issues or additional questions, you can always reach us at
            help@systemandsoul.com.
          </div>
        </div>
      ),
      target: '.completion-placeholder', // Targeting the entire body to center the content
      placement: 'center', // Ensure the content is placed at the center
    },
  ],
};

export default CREATE_MEETING_WALKTHROUGH;
