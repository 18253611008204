import { gql } from 'urql';

const DELETE_TEAM_MEMBERS = gql`
  mutation DeleteTeamMembers($ids: [Int!]!) {
    delete_team_members(where: { id: { _in: $ids } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export default DELETE_TEAM_MEMBERS;
