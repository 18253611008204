import { gql } from 'urql';

const UPDATE_USER_INVITE_ROLE = gql`
  mutation MyMutation($pk_columns: invites_pk_columns_input!, $role_id: Int!) {
    update_invites_by_pk(pk_columns: $pk_columns, _set: { role_id: $role_id }) {
      id
    }
  }
`;

export default UPDATE_USER_INVITE_ROLE;
