import { ReactElement, ReactNode } from 'react';
import { classNames } from 'utils';

type CardLayoutContentProps = {
  children: ReactElement | ReactNode;
  className?: string;
};

export default function CardLayoutContent({ className = 'p-6', children }: CardLayoutContentProps): ReactElement {
  return <div className={classNames('w-full bg-white relative rounded-lg', className ?? '')}>{children}</div>;
}
