import { captureException } from '@sentry/nextjs';
import { Listbox, Transition, Menu } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import {
  Avatar,
  RichTextEditor,
  RichTextViewer,
  CommentSection,
  FileAttachmentDialog,
  ErrorToast,
  SuccessToast,
} from 'components';
import { CompanyUser } from 'components/UsersList/UsersList';
import {
  OPPORTUNITY_TYPE_OPTIONS,
  GET_OPPORTUNITY_COMMENTS,
  DELETE_OPPORTUNITY_COMMENT,
  DELETE_OPPORTUNITY_FILE,
} from 'data/opportunities';
import { Opportunity, OpportunityTypeLabel, OpportunityTypeValue } from 'data/opportunities/types';
import { Dispatch, Fragment, ReactElement, SetStateAction, useState } from 'react';
import button from 'styles/button';
import { classNames, refreshData } from 'utils';
import { CreateActionsDialog } from 'components';
import { useQuery, useMutation } from 'urql';
import { useSession } from 'next-auth/react';
import { DATE_FORMAT_OPTIONS, TIME_FORMAT_OPTIONS, Comment } from 'types/comment';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { GET_MEASURABLE } from 'data/measurables';
import { FileAttachment } from 'types/file-attachment';
import { ErrorToastError } from 'components/Toast/ErrorToast';

export function getOpportunityTypeLabel(type: OpportunityTypeValue): OpportunityTypeLabel {
  return OPPORTUNITY_TYPE_OPTIONS.find(({ value }) => value === type)?.label;
}

export default function OpportunityForm({
  form,
  setForm,
  users,
  commentSectionOpen,
  setCommentSectionOpen,
  opportunityFiles,
  refetchFiles,
}: {
  form: Opportunity;
  setForm: Dispatch<SetStateAction<Opportunity>>;
  users: CompanyUser[];
  commentSectionOpen?: boolean;
  setCommentSectionOpen?: (commentSectionOpen: boolean) => void;
  opportunityFiles?: FileAttachment[];
  refetchFiles?;
}): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [createOpportunityAction, setCreateOpportunityAction] = useState<{
    title: string;
    description: string;
    opportunityId: number;
    comments?: Comment[];
    objectiveId: number;
  }>();
  const [createActionsDialogOpen, setCreateActionsDialogOpen] = useState(false);

  const selectedUser = users.find(({ user }) => user.id === form.assignee)?.user;

  const [commentsResult, refetchComments] = useQuery({
    query: GET_OPPORTUNITY_COMMENTS,
    variables: {
      where: {
        entity_id: { _eq: form?.id },
      },
    },
    pause: !form?.id,
  });
  const comments: Comment[] = commentsResult.data?.opportunity_comments || [];

  const [displayEditor, setDisplayEditor] = useState(false);
  const [, deleteComment] = useMutation(DELETE_OPPORTUNITY_COMMENT);
  const [comment, setComment] = useState<Comment>({
    id: null,
    comment: '',
    entity_id: form.id,
  });
  const commentActions = [];
  commentActions.push(
    {
      action: (_comment) => {
        setComment({
          comment: _comment?.comment,
          entity_id: form.id,
          id: _comment?.id,
        });
        setDisplayEditor(true);
      },
      label: 'Edit',
    },
    {
      action: async (_comment) => {
        const { error: deleteError } = await deleteComment({
          commentId: _comment?.id,
          id: form.id,
          activity: 'update',
          timestamp: Date.now(),
          userId: session.id,
        });
        if (deleteError) {
          captureException(deleteError);
        }
        refetchComments({ variables: { id: _comment?.entity_id } });
        await refreshData(router);
      },
      label: 'Delete',
    },
  );

  // Fetch the  measurable
  const [measurableResult] = useQuery({
    query: GET_MEASURABLE,
    variables: { id: form?.measurable_id },
    pause: !form?.id && !form?.measurable_id,
  });

  const measurable = measurableResult.data?.measurables?.[0];
  const displayMeasurableLink = measurable?.id && measurable?.teams_id === Number(router.query.team);

  const [fileUploadDailogOpen, setFileUploadDailogOpen] = useState(false);
  const [, deleteOpportunityFile] = useMutation(DELETE_OPPORTUNITY_FILE);
  const [error, setError] = useState<ErrorToastError>();
  const [deleteFile, setDeleteFile] = useState<string>();

  return (
    <>
      <div className="p-6 overflow-auto flex-1">
        <form className="w-full">
          <div className="w-full">
            <div className="flex space-x-2 ">
              <div className="flex-1 mt-2">
                <label htmlFor="title" className="block text-xs text-gray-700">
                  Title
                </label>
              </div>
              {!form.archived && form.id ? (
                <button
                  type="button"
                  className={classNames(
                    button.primary(),
                    'inline-flex items-center justify-center  py-2 rounded-md shadow-sm text-sm font-semibold text-white',
                  )}
                  onClick={async () => {
                    setCreateOpportunityAction({
                      title: form?.title,
                      description: form?.description,
                      opportunityId: form?.id,
                      comments: comments,
                      objectiveId: null,
                    });
                    setCreateActionsDialogOpen(true);
                  }}
                >
                  create action
                </button>
              ) : (
                ''
              )}
            </div>

            <div className="mt-1">
              <input
                type="text"
                name="title"
                id="title"
                className="shadow-sm focus:ring-primary focus:border-primary block w-full text-sm border-gray-300 rounded-md"
                defaultValue={form.title}
                onChange={(event) => {
                  setForm({
                    ...form,
                    title: event.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="w-full flex mt-4">
            <div className="flex flex-col flex-1">
              <label htmlFor="description" className="block text-xs text-gray-700">
                Description
              </label>
              <div className="mt-1">
                <RichTextEditor
                  text={form.description}
                  onChange={(content) => {
                    setForm({
                      ...form,
                      description: content,
                    });
                  }}
                />
              </div>
            </div>
          </div>

          {form?.actions?.length > 0 ? (
            <div className="mt-4">
              <label htmlFor="actions" className="block text-xs text-gray-700">
                Actions
              </label>
              <div className="mt-1 text-xs text-blue-500 font-semibold rounded-md border border-gray-300  pl-2 py-2 max-h-28 overflow-y-auto">
                {form.actions
                  .filter((action) => action.teams_id === Number(router.query.team))
                  .map((action) => {
                    return (
                      <div key={action.id} className="flex space-x-2 mt-1">
                        <span> . </span>
                        <div className="underline">
                          <Link
                            href={`/company/${router.query.company}/team/${router.query.team}/actions/${action?.id}`}
                            legacyBehavior
                          >
                            {action.title}
                          </Link>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : null}

          {displayMeasurableLink ? (
            <div className="mt-4 text-xs text-blue-500 font-semibold underline">
              <Link href={`/company/${router.query.company}/team/${router.query.team}/scoreboard/${measurable?.id}`}>
                Measurable link
              </Link>
            </div>
          ) : null}

          {commentSectionOpen ? (
            <div className="w-full flex-col mt-4">
              <div className="flex flex-col flex-1">
                <label htmlFor="comments" className="block text-xs text-gray-700">
                  Comments
                </label>
                <div className="mt-1 h-full bg-gray-200 rounded-md shadow-sm">
                  {comments.length === 0 ? (
                    <div className="text-sm font-semibold text-gray-700 text-center py-6">
                      <span>There are no comments at this time</span>
                    </div>
                  ) : null}
                  {comments?.map((_comment, i) => {
                    const _user = users.find(({ user }) => user.id === _comment?.user_id)?.user;
                    return (
                      <div key={i} className="flex-1 flex items-center justify-between pr-4 relative my-6">
                        <div className="text-xs ml-4">
                          <div className="font-semibold">{_user?.name || _user?.email}</div>
                          <span className="text-xs text-gray-700 font-semibold italic">
                            {new Date(_comment?.timestamp).toLocaleDateString([], DATE_FORMAT_OPTIONS)}{' '}
                            {new Date(_comment?.timestamp).toLocaleTimeString([], TIME_FORMAT_OPTIONS)}
                          </span>
                          <div className="text-sm mt-0.5">
                            <RichTextViewer text={_comment?.comment} />
                          </div>
                        </div>

                        {Number(session?.id) === _comment?.user_id ? (
                          <div className="">
                            <Menu as="div" className="flex items-center justify-center w-full text-left">
                              {({ open }) => (
                                <>
                                  <div
                                    className="w-full h-full"
                                    onClick={(event) => {
                                      // Prevents redirecting to single page for item when clicking dots to close dropdown
                                      event.stopPropagation();
                                    }}
                                  >
                                    <Menu.Button className="flex items-center justify-center h-full w-full">
                                      <span className="sr-only">Open options</span>
                                      <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                    </Menu.Button>
                                  </div>

                                  <Transition
                                    show={open}
                                    as={Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                  >
                                    <Menu.Items
                                      static
                                      className="z-10 origin-top-right absolute right-0 top-4 mt-6 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    >
                                      <div className="py-1">
                                        {commentActions.map(({ label, action }) => (
                                          <Menu.Item key={`${_comment.id}-${label}`}>
                                            {({ active }) => (
                                              <button
                                                type="button"
                                                className={classNames(
                                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                  'block px-4 py-2 text-sm w-full text-left',
                                                )}
                                                onClick={async (event) => {
                                                  event.stopPropagation();
                                                  action?.(_comment);
                                                }}
                                              >
                                                {label}
                                              </button>
                                            )}
                                          </Menu.Item>
                                        ))}
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </>
                              )}
                            </Menu>
                          </div>
                        ) : null}
                      </div>
                    );
                  })}
                </div>
                {!displayEditor ? (
                  <div className="flex mt-2 mb-4 space-x-2 justify-end">
                    <button
                      type="button"
                      className={classNames(
                        button.primary(),
                        'inline-flex items-center justify-center  py-2 rounded-md shadow-sm text-sm font-semibold text-white',
                      )}
                      onClick={async () => {
                        setCommentSectionOpen(false);
                      }}
                    >
                      Hide Comments
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        button.primary(),
                        'inline-flex items-center justify-center  py-2 rounded-md shadow-sm text-sm font-semibold text-white',
                      )}
                      onClick={async () => {
                        setDisplayEditor(true);
                        setComment({
                          id: null,
                          comment: '',
                          entity_id: form.id,
                        });
                      }}
                    >
                      Add Comment
                    </button>
                  </div>
                ) : null}
              </div>

              {displayEditor ? (
                <CommentSection
                  comment={comment}
                  setComment={setComment}
                  setOpen={setDisplayEditor}
                  refetchComments={refetchComments}
                  entity={'opportunity'}
                />
              ) : null}
            </div>
          ) : null}

          <div className="w-full flex flex-col md:flex-row mt-4 gap-y-4 gap-x-2">
            <div className="flex flex-col flex-1 mr-2">
              <label htmlFor="location" className="block text-xs text-gray-700">
                Type
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 block w-full pl-3 pr-10 h-12 text-base border-gray-300 focus:outline-none focus:ring-primary focus:border-primary rounded-md"
                defaultValue={form.type}
                onChange={(event) => {
                  setForm({
                    ...form,
                    type: event.target.value as OpportunityTypeValue,
                  });
                }}
              >
                {OPPORTUNITY_TYPE_OPTIONS.map((type) => (
                  <option key={type.value} value={type.value}>
                    {type.label}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col flex-1">
              <Listbox
                value={form.assignee}
                onChange={(value) => {
                  setForm({
                    ...form,
                    assignee: value,
                  });
                }}
              >
                {({ open }) => (
                  <>
                    <Listbox.Label className="block text-xs text-gray-700">Assignee</Listbox.Label>
                    <div className="mt-1">
                      <Listbox.Button
                        className={classNames(
                          button.white(),
                          'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                        )}
                      >
                        {selectedUser ? (
                          <span className="flex items-center">
                            <Avatar user={selectedUser} showName={true} />
                          </span>
                        ) : (
                          <span className="flex items-center text-gray-500">Select a user</span>
                        )}
                        <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </span>
                      </Listbox.Button>

                      <Transition
                        show={open}
                        as={Fragment}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <Listbox.Options
                          static
                          className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                        >
                          {users.map(({ user }) => (
                            <Listbox.Option
                              key={user.id}
                              className={({ active }) =>
                                classNames(
                                  active ? 'bg-gray-100' : '',
                                  'cursor-default select-none relative py-2 pl-3 pr-9',
                                )
                              }
                              value={user.id}
                            >
                              {({ selected }) => (
                                <>
                                  <div className="flex items-center mr-2">
                                    <Avatar user={user} showName={true} />
                                  </div>

                                  {selected ? (
                                    <span className="absolute inset-y-0 right-0 flex items-center mr-4">
                                      <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                        </Listbox.Options>
                      </Transition>
                    </div>
                  </>
                )}
              </Listbox>
            </div>
          </div>
          <>
            <div className="block text-xs text-gray-700 mt-4">File Attachments</div>
            <div className="mt-1 rounded-md border border-gray-300 px-4 w-full">
              <div className="flex justify-end mt-2 mb-6">
                <button
                  type="button"
                  className={classNames(
                    button.primary(),
                    'inline-flex items-center justify-center rounded-md shadow-sm text-sm font-semibold text-white ',
                  )}
                  onClick={() => {
                    setFileUploadDailogOpen(true);
                  }}
                >
                  Add Files
                </button>
              </div>

              <>
                <div className="flex text-xs font-semibold text-gray-500 uppercase bg-gray-100 h-8 w-full">
                  <div className="w-5/6 py-2 ml-2"> File Name</div>
                  <div
                    className={classNames(
                      opportunityFiles?.length > 2 ? '-ml-4' : '',
                      'flex w-1/6 py-2 justify-center',
                    )}
                  >
                    Delete
                  </div>
                </div>
                {opportunityFiles?.length > 0 ? (
                  <div className="max-h-28 overflow-y-auto">
                    <div className="mt-2 text-xs text-blue-500 font-semibold mb-2">
                      {opportunityFiles
                        .filter((file) => file.url !== null)
                        .map((file) => {
                          return (
                            <div key={file.id} className="mt-3">
                              <div className="flex">
                                <div className="w-5/6 truncate">
                                  <Link href={file.url} className="ml-2" target="_blank">
                                    {file.file_name}
                                  </Link>
                                </div>
                                <div className="flex w-1/6 justify-center">
                                  <button
                                    type="button"
                                    onClick={async () => {
                                      // Delete file
                                      const opportunityId = form.id;
                                      const { error: deleteError } = await deleteOpportunityFile({
                                        fileId: file.id,
                                        id: opportunityId,
                                        activity: 'update',
                                        timestamp: Date.now(),
                                        userId: session.id,
                                      });
                                      refetchFiles({ variables: { opportunityId } });
                                      const key = 'opportunity'.concat('_').concat(file?.id?.toString());
                                      await fetch(`/api/s3/file-delete`, {
                                        method: 'POST',
                                        headers: {
                                          'content-type': 'application/json',
                                        },
                                        body: JSON.stringify({ key: key }),
                                      });
                                      if (deleteError) {
                                        setError(deleteError);
                                        captureException(deleteError);
                                      } else {
                                        setDeleteFile('File Deleted');
                                      }
                                    }}
                                  >
                                    <XIcon fill="red" className="h-4 w-4 mb-0.5" />
                                  </button>
                                </div>
                              </div>
                              <div className="mt-2 w-full border-t border-gray-300" />
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  <div className="flex items-center justify-center mb-6">
                    <span className="mt-4 text-sm font-semibold text-gray-400 items-center">
                      There are no files at this time
                    </span>
                  </div>
                )}
              </>
            </div>
          </>
          {form.created_at ? (
            <div className="mt-6 text-center text-gray-400 text-sm">
              <span>Created on {new Date(form.created_at).toLocaleDateString()}</span>
            </div>
          ) : null}
        </form>
      </div>
      <CreateActionsDialog
        createAction={createOpportunityAction}
        open={createActionsDialogOpen}
        setOpen={setCreateActionsDialogOpen}
      />
      <FileAttachmentDialog
        entityType={'opportunity'}
        open={fileUploadDailogOpen}
        setOpen={setFileUploadDailogOpen}
        refetchFiles={refetchFiles}
        form={form}
        setForm={setForm}
      />
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={deleteFile} setMessage={setDeleteFile} />
    </>
  );
}
