import { gql } from 'urql';

const ADD_MEASURABLES = gql`
  mutation AddMeasurables($objects: [measurables_insert_input!]!) {
    insert_measurables(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export default ADD_MEASURABLES;
