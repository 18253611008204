import { gql } from 'urql';

const GET_WEBHOOKS_BY_USER_AND_TYPE = gql`
  query GetWebhooksByUserAndType($user_id: Int!, $trigger_type: String!) {
    zapier_webhooks(where: {user_id: {_eq: $user_id}, trigger_type: {_eq: $trigger_type}}, order_by: {created_at: desc}) {
      id
      user_id
      trigger_type
      webhook_url
      created_at
      updated_at
    }
  }
`;

export default GET_WEBHOOKS_BY_USER_AND_TYPE;