import { gql } from 'urql';

const ADD_OPPORTUNITY_FILE = gql`
  mutation AddOpportunityFile(
    $object: opportunity_files_insert_input!
    $id: Int!
    $activity: String
    $timestamp: bigint
    $userId: Int
  ) {
    insert_opportunity_files_one(object: $object) {
      id
    }

    insert_opportunities_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default ADD_OPPORTUNITY_FILE;
