export default function digitalClock(totalSeconds: number): string {
  const days = Math.floor(totalSeconds / 60 / 60 / 24);
  const daysSeconds = days * 60 * 60 * 24;

  const hours = Math.floor((totalSeconds - daysSeconds) / 60 / 60);
  const hoursSeconds = hours * 60 * 60;

  const minutes = Math.floor((totalSeconds - daysSeconds - hoursSeconds) / 60);
  const minutesSeconds = minutes * 60;

  const seconds = Math.floor(totalSeconds - daysSeconds - hoursSeconds - minutesSeconds);

  let clock = `${`${minutes}`.padStart(2, '0')}:${`${seconds}`.padStart(2, '0')}`;

  if (hours) {
    clock = `${`${hours}`.padStart(2, '0')}:${clock}`;
  }

  if (days) {
    if (!hours) {
      clock = `00:${clock}`;
    }

    clock = `${`${hours}`.padStart(2, '0')}:${clock}`;
  }

  return clock;
}
