import { User } from 'components/UsersList/UsersList';
import Image from 'next/image';
import { ReactElement } from 'react';
import { classNames, getUserName, initials } from 'utils';

type AvatarSize = 's' | 'm' | 'l' | 'xl';

export default function Avatar({
  user,
  size = 'm',
  showName = false,
}: {
  user: User;
  size?: AvatarSize;
  showName?: boolean;
}): ReactElement {
  let diameter = 28;
  let tailwindClass = 'h-7 w-7';
  let textSize = 'text-md';
  switch (size) {
    case 's':
      diameter = 20;
      tailwindClass = 'h-5 w-5';
      textSize = 'text-2xs';
      break;
    case 'l':
      diameter = 40;
      tailwindClass = 'h-10 w-10';
      textSize = 'text-lg';
      break;
    case 'xl':
      diameter = 48;
      tailwindClass = 'h-12 w-12';
      textSize = 'text-3xl';
      break;
    case 'm':
    default:
      diameter = 28;
      tailwindClass = 'h-7 w-7';
      textSize = 'text-md';
      break;
  }

  return (
    <div className="flex">
      <div
        className={classNames(
          user?.image ? 'bg-white' : 'bg-primary',
          tailwindClass,
          'flex flex-shrink-0 flex-grow-0 items-center justify-center rounded-full  text-base border-solid border-2 border-sky-500',
        )}
      >
        {user?.image ? (
          <Image
            className="w-full h-full rounded-full "
            src={user.image}
            height={diameter}
            width={diameter}
            alt=""
            referrerPolicy="no-referrer"
            style={{
              maxWidth: '100%',
              objectFit: 'cover',
            }}
          />
        ) : (
          <span className={classNames(textSize, 'text-white font-normal')}>{initials(getUserName(user))}</span>
        )}
      </div>
      {showName ? <span className="ml-2">{getUserName(user)}</span> : null}
    </div>
  );
}
