import { gql } from 'urql';

const GET_TEAM_MEMBER_BY_ID = gql`
  query GET_TEAM_MEMBER_BY_ID($user_id: Int!, $team_id: Int!) {
    team_members(where: { team_id: { _eq: $team_id }, company_user: { user_id: { _eq: $user_id } } }) {
      id
      future_type_toggle
      opp_filter_type
      sort_field
      sort_order
      opp_alert_display
      selected_opps
      selected_actions
      selected_objectives
      selected_meetings
      team {
        id
        deleted
      }
      objective_sort_field
      objective_sort_order
      action_sort_field
      action_sort_order
    }
  }
`;

export default GET_TEAM_MEMBER_BY_ID;
