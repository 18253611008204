import { gql } from 'urql';
import { OPPORTUNITY_FRAGMENT } from './fragments';

const GET_OPPORTUNITY = gql`
  query GetOpportunity($id: Int!) {
    opportunities_by_pk(id: $id) {
      ...OpportunityFragment
    }
  }
  ${OPPORTUNITY_FRAGMENT}
`;

export default GET_OPPORTUNITY;
