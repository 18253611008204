import { gql } from 'urql';

const DELETE_INVITE = gql`
  mutation DeleteInvite($id: uuid!) {
    delete_invites_by_pk(id: $id) {
      id
    }
  }
`;

export default DELETE_INVITE;
