import { gql } from 'urql';

const DELETE_COMPANY_USER = gql`
  mutation DeleteCompanyUser($id: Int!) {
    delete_company_users(where: { id: { _eq: $id } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export default DELETE_COMPANY_USER;
