import { gql } from 'urql';

const DELETE_OPPORTUNITY_COMMENT = gql`
  mutation DeleteOpportunityComment($commentId: Int!, $id: Int!, $activity: String, $timestamp: bigint, $userId: Int) {
    delete_opportunity_comments_by_pk(id: $commentId) {
      id
    }
    insert_opportunities_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default DELETE_OPPORTUNITY_COMMENT;
