import { gql } from 'urql';

const ADD_OBJECTIVE = gql`
  mutation AddObjective($object: objectives_insert_input!) {
    insert_objectives_one(object: $object) {
      id
    }
  }
`;

export default ADD_OBJECTIVE;
