import S2HalfScreenImage from 'components/S2HalfScreenImage';
import { ReactElement, ReactNode } from 'react';

type HalfScreenProps = {
  children: ReactNode;
  image?: {
    preText?: string | ReactElement;
    postText?: string | ReactElement;
    background?: string;
    src?: string;
  };
};

export default function HalfScreen({ children, image }: HalfScreenProps): ReactElement {
  return (
    <div className="flex flex-col md:flex-row min-h-screen w-screen bg-white">
      <div className="flex-1 flex flex-col justify-center p-12">
        <div className="mx-auto w-full">{children}</div>
      </div>
      <S2HalfScreenImage
        src={image?.src}
        preText={image?.preText}
        postText={image?.postText}
        background={image?.background}
      />
    </div>
  );
}
