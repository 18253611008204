import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const ACTION_FRAGMENT = gql`
  fragment ActionFragment on actions {
    company_id
    description
    date
    id
    archived
    completed
    assignee
    user {
      ...UserFragment
    }
    title
    opportunity
    opportunityByOpportunity {
      id
      title
    }
    teams_id
    created_at
    action_comments {
      id
      comment
      user_id
      timestamp
    }
    objective
    objectiveByObjective {
      id
      title
    }
    action_files {
      id
      url
      file_name
      file_type
      status
    }
  }
  ${USER_FRAGMENT}
`;

export { ACTION_FRAGMENT };
