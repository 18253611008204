import { gql } from 'urql';

const GET_SCORE = gql`
  query GetScore($id: Int!) {
    scores_by_pk(id: $id) {
      id
      scoreboard_id
      date
      value
    }
  }
`;

export default GET_SCORE;
