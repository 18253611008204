import { gql } from 'urql';

const UPDATE_TEAM = gql`
  mutation UpdateTeam($id: Int!, $set: teams_set_input!) {
    update_teams_by_pk(pk_columns: { id: $id }, _set: $set) {
      id
    }
  }
`;

export default UPDATE_TEAM;
