/* eslint-disable */
// @ts-nocheck

import { MinusIcon, PlusIcon } from '@heroicons/react/solid';
import { Org } from 'data/org/types';
import dynamic, { DynamicOptions } from 'next/dynamic';
import { Component, ComponentType, Dispatch, ReactElement, SetStateAction } from 'react';
import button from 'styles/button';
import { Config, Node } from 'types/d3';
import { classNames, getUserName } from 'utils';
import { init } from '../chart';

type ClientOrgChartRootProps = {
  tree: Node;
  onConfigChange: (config: Config) => void;
  loadConfig: () => void;
  loadParent: (d: Node) => void;
  loadChildren: (d: Node) => void;
  downloadingOrgChart: (downloadingChart: boolean) => void;
  onPersonClick: (d: Node, event: Event) => void;
  onAddClick: (d: Node, right: number) => void;
  onMoveUpDown?: (d: Node, direction: string) => void;
  onMovePerson?: ({ siblings, loadConfig, datum }: { siblings: Org[]; loadConfig: () => Config; datum: Node }) => void;
  onDragPerson?: ({ draggingNode, selectedNode }: { draggingNode: Node; selectedNode: Node }) => void;
  initDepth: number;
  refresh: boolean;
  centerNode: number;
};

const ClientOrgChartRoot: ComponentType<ClientOrgChartRootProps> = dynamic(
  (() => import('./OrgChartRoot')) as DynamicOptions<ClientOrgChartRootProps>,
  {
    ssr: false,
  },
);

function getLeaf(user: Org, org: Org[], withChildren = true): Node {
  if (!user) return;
  const children = getChildren(user, org);
  const leaf: Node = {
    id: user.id,
    person: {
      id: user.company_user?.id,
      name: getUserName(user.company_user?.user),
      title: user.title,
      totalReports: children.length,
      mission: user.mission,
      kpis: user.kpis,
      core_functions: user.core_functions,
      image: user.company_user?.user?.image,
      email: user.company_user?.user?.email,
      user_typed_name: user.name,
      wg_skills: user.company_user?.wg_skills,
      vops_skills: user.company_user?.vops_skills,
      other_assessments: user.company_user?.other_assessments,
      enabled_wg_profile: user.company_user?.company?.enabled_wg_profile,
      enabled_vops_profile: user.company_user?.company?.enabled_vops_profile,
      enabled_assessments: user.company_user?.company?.enabled_assessments,
      org_status: user.org_status,
    },
    right_sibling: user.right_sibling,
    hasChild: Boolean(children.length),
    hasParent: Boolean(user.parent),
    parent: getLeaf(
      org.find((o) => o.id === user.parent),
      org,
      false,
    ),
  };
  if (withChildren) {
    leaf.children = getChildLeaves(user, org);
  }

  return leaf;
}

export function getChildren(leaf: Node | Org, org: Org[]): Org[] {
  const children = org.filter((o) => o.parent === leaf.id);
  if (children.length) {
    const hasRightSiblings = children.filter((child) => child?.right_sibling !== null);
    if (hasRightSiblings.length > 0) {
      const rightSiblingIds = children
        ?.filter((child) => child.right_sibling !== null)
        ?.map(({ right_sibling }) => right_sibling);
      const nonRightSiblings = children.filter((child) => !rightSiblingIds.includes(child?.id));

      const sortedChildren = [];
      nonRightSiblings.map((sibling) => {
        let rightSibling = sibling?.right_sibling;
        let hasRightSibling = rightSibling !== null ? true : false;
        sortedChildren.push(sibling?.id);
        while (hasRightSibling) {
          sortedChildren.push(rightSibling);
          rightSibling = children.find((child) => child?.id === rightSibling)?.right_sibling;
          rightSibling ? (hasRightSibling = true) : (hasRightSibling = false);
        }
      });
      const formatedChildren = sortedChildren.filter((c, index) => {
        return sortedChildren.indexOf(c) === index;
      });
      return formatedChildren?.map((id) => children.find((child) => child?.id === id));
    } else {
      return children;
    }
  }
  return [];
}

function getChildLeaves(leaf: Node | Org, org: Org[]): Node[] {
  return getChildren(leaf, org).map((o) => getLeaf(o, org));
}

function getParent(leaf: Node, org: Org[]): Node {
  if (leaf.hasParent) {
    return getLeaf(
      org.find((o) => leaf.parent?.id === o.id),
      org,
    );
  }
}

function getInitTree(org: Org[]): Node {
  const leaf = org.find((o) => isNaN(o.parent) || o.parent === null) || org[0];

  return getLeaf(leaf, org);
}

type OrgChartProps = {
  org: Org[];
  setConfig: Dispatch<SetStateAction<Config>>;
  setDownloadingChart: Dispatch<SetStateAction<boolean>>;
  onPersonClick: (d: Node) => void;
  onAddClick: (d: Node, right: number) => void;
  onMoveUpDown?: (d: Node, direction: string) => void;
  onMovePerson?: ({ siblings, loadConfig, datum }: { siblings: Org[]; loadConfig: () => Config; datum: Node }) => void;
  onDragPerson?: ({ draggingNode, selectedNode }: { draggingNode: Node; selectedNode: Node }) => void;
  editable: boolean;
  refresh: boolean;
  chartDepth: number;
  centerNode: number;
};

type OrgChartState = {
  config: Config;
  tree: Node;
};

export default class OrgChart extends Component<OrgChartProps, OrgChartState> {
  constructor(props: OrgChartProps) {
    super(props);

    this.state = {
      tree: getInitTree(props.org),
      config: {},
    };
  }

  init = init;

  componentDidUpdate(prevProps: OrgChartProps): void {
    if (!prevProps.refresh && this.props.refresh) {
      this.setState({ ...this.state, tree: getInitTree(this.props.org) });
    }
  }

  handleOnChangeConfig = (config: Config): void => {
    this.setState({ ...this.state, config });
  };

  handleLoadConfig = (): Config => {
    return this.state.config;
  };

  render(): ReactElement {
    const { tree } = this.state;

    return (
      <div id="root" className="relative h-org-h w-full">
        <div className="absolute top-5 left-5 flex flex-col">
          <button
            className={classNames(
              button.white({ withBase: false }),
              'h-10 w-10 mb-2 flex items-center justify-center rounded-md',
            )}
            id="zoom-in"
          >
            <PlusIcon height="16" width="16" />
          </button>
          <button
            className={classNames(
              button.white({ withBase: false }),
              'h-10 w-10 flex items-center justify-center rounded-md',
            )}
            id="zoom-out"
          >
            <MinusIcon height="16" width="16" className="ml-0.5" />
          </button>
        </div>
        {/* @ts-ignore */}
        <ClientOrgChartRoot
          refresh={this.props.refresh}
          tree={tree}
          onConfigChange={(config) => {
            this.handleOnChangeConfig(config);

            this.props.setConfig(config);
          }}
          loadConfig={() => {
            const configuration = this.handleLoadConfig();
            if (configuration) {
              return configuration;
            }
          }}
          downloadingOrgChart={this.props.setDownloadingChart}
          loadParent={(d) => {
            const parentData = getParent(d, this.props.org);
            return parentData;
          }}
          loadChildren={(d) => {
            const childrenData = getChildLeaves(d, this.props.org);
            return childrenData;
          }}
          onPersonClick={this.props.editable ? this.props.onPersonClick : null}
          onAddClick={this.props.editable ? this.props.onAddClick : null}
          onMoveUpDown={this.props.editable ? this.props.onMoveUpDown : null}
          onMovePerson={this.props.editable ? this.props.onMovePerson : null}
          onDragPerson={this.props.editable ? this.props.onDragPerson : null}
          initDepth={this.props.chartDepth || 3}
          centerNode={this.props.centerNode || null}
        />
      </div>
    );
  }
}
