import { gql } from 'urql';

const GET_OBJECTIVE_FILES = gql`
  query GetObjectiveFiles($where: objective_files_bool_exp!) {
    objective_files(where: $where, order_by: [{ updated_at: desc }]) {
      id
      url
      file_name
      status
      file_type
    }
  }
`;

export default GET_OBJECTIVE_FILES;
