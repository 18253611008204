import { gql } from 'urql';

const GET_COMPANY_USER_BY_ID = gql`
  query GET_COMPANY_USER_BY_ID($user_id: Int!, $company_id: Int!) {
    company_users(where: { company_id: { _eq: $company_id }, user_id: { _eq: $user_id } }) {
      id
      email_notifs
      role {
        id
        name
      }
      wg_skills
      vops_skills
      other_assessments
      meetings_walkthrough_completed
      users_walkthrough_completed
      settings_walkthrough_completed
    }
  }
`;

export default GET_COMPANY_USER_BY_ID;
