import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import { Fragment, ReactElement } from 'react';
import { classNames } from 'utils';

type Option = {
  label: string;
  action: () => void;
};

type CardHeaderProps = {
  options?: Option[];
  title?: string | ReactElement;
};

export default function CardHeader({ options, title }: CardHeaderProps): ReactElement {
  return (
    <div className="h-20 px-6 w-full relative flex justify-center bg-white border-gray-200 border-b flex-col rounded-t-lg">
      <div className="flex items-center justify-between">
        <h3 className="text-md leading-6 font-semibold text-gray-900">{title}</h3>
        <div className="flex">
          {options.length ? (
            <Menu as="div" className="relative">
              {({ open }) => (
                <div className="flex">
                  <>
                    <div className="flex">
                      <Menu.Button className="w-full bg-white border border-gray-300 rounded-md shadow-sm px-4 py-2 inline-flex justify-center text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500">
                        Options
                        <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1 text-gray-700" aria-hidden="true" />
                      </Menu.Button>
                    </div>

                    <Transition
                      show={open}
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items
                        static
                        className="z-10 origin-top-right top-full absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                      >
                        <div className="py-1">
                          {options.map((option) => (
                            <Menu.Item key={option.label}>
                              {({ active }) => (
                                <button
                                  type="button"
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm w-full text-left',
                                  )}
                                  onClick={() => {
                                    option.action?.();
                                  }}
                                >
                                  {option.label}
                                </button>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </>
                </div>
              )}
            </Menu>
          ) : null}
        </div>
      </div>
    </div>
  );
}

CardHeader.defaultProps = {
  options: [],
  title: '',
};
