import React, { ReactElement, ReactNode } from 'react';
import styles from './tooltip.module.css';

interface TooltipProps {
  content: string;
  children: ReactNode;
}

const Tooltip = ({ content, children }: TooltipProps): ReactElement => (
  <div className={`group relative ${styles.tooltip}`}>
    {children}
    <span className={`${styles.tooltiptext} group-hover:opacity-100 group-hover:visible`}>{content}</span>
  </div>
);

export default Tooltip;
