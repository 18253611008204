import d3 from 'd3';

// One way of achieving text-wrapping capability in SVG
export default function wrapText(text: d3.Selection<'text'>, width: number): string | void {
  if (text.length === 0) {
    return '';
  }

  let editedClass = '';

  text[0].forEach((textNode) => {
    const _text = d3.select(textNode);
    const x = _text.attr('x');
    const y = _text.attr('y');
    const dy = parseFloat(_text.attr('dy'));
    const lineHeight = 1.1;
    const words = _text.text().split(/\s+/).reverse();

    let lineNumber = 0;
    let word;
    let line = [];
    let tspan: d3.Selection<'tspan'> = _text
      .text(null)
      .append('tspan')
      .style('text-anchor', 'middle')
      .attr('x', x)
      .attr('y', y)
      .attr('dy', dy + 'em');

    while ((word = words.pop())) {
      line.push(word);
      tspan.text(line.join(' '));

      if ((tspan.node() as SVGTSpanElement).getComputedTextLength() > width) {
        line.pop();
        tspan.text(line.join(' '));
        line = [word];
        tspan = _text
          .append('tspan')
          .style('text-anchor', 'middle')
          .attr('x', x)
          .attr('y', y)
          .attr('dy', ++lineNumber * lineHeight + dy + 'em')
          .text(word);
      }
    }

    if (!editedClass) {
      editedClass = _text.attr('class').replace(' unedited', '');
    }

    _text.attr('class', editedClass);
  });
}
