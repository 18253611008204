import { ReactElement } from 'react';
import { genuisAreas } from 'components/UsersList/UsersList';
import { WorkingGenuisIcon } from 'components';
import { wgSkills } from 'components/WGProfileDialog/WGProfileDialog';
import { classNames } from 'utils';

function getWGTeamSkills(teamMembers) {
  let genius = [],
    competency = [],
    frustration = [];

  teamMembers.map(({ company_user }) => {
    genuisAreas
      .filter((area) => area?.id !== '1')
      .map((area) => {
        if (company_user?.wg_skills) {
          const values = Object.keys(company_user?.wg_skills).filter((key) => company_user?.wg_skills[key] === area.id);
          area?.id === '2'
            ? (genius = genius.concat(values))
            : area?.id === '3'
            ? (competency = competency.concat(values))
            : (frustration = frustration.concat(values));
        }
      });
  });
  return formatWGTeamSkills(genius, competency);
}

function formatWGTeamSkills(genius: string[], competency: string[]) {
  const wgTeamSkills = [];
  wgSkills.map((skill) => {
    const _skill = { name: skill };
    genius.includes(skill)
      ? (_skill['color'] = genuisAreas[1]?.color)
      : competency.includes(skill)
      ? (_skill['color'] = genuisAreas[2]?.color)
      : (_skill['color'] = genuisAreas[3]?.color);
    wgTeamSkills.push(_skill);
  });
  return wgTeamSkills;
}

export default function WGTeamAssessment({ teamMembers }: { teamMembers }): ReactElement {
  const teamSkills = getWGTeamSkills(teamMembers);
  return (
    <div className="flex">
      {teamSkills.map((skill, i) => {
        return (
          <div key={i} className={classNames(i % 2 === 0 ? '' : 'mt-2', 'relative flex')}>
            <WorkingGenuisIcon color={skill?.color} className={'w-6 h-6'} />
            <div className="flex absolute text-xs capitalize w-6 h-6 mt-1 font-bold justify-around ">
              <span>{skill?.name?.charAt(0)}</span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
