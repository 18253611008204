import { Dialog, Transition } from '@headlessui/react';
import { Fragment, ReactElement, useRef } from 'react';
import Image from 'next/image';
import { classNames } from 'utils';
import button from 'styles/button';

type LogoutModalProps = {
  show: boolean;
  handleClose: () => void;
  onSignOut: () => void;
};

export default function SignOutConfirmationModal({ show, handleClose, onSignOut }: LogoutModalProps): ReactElement {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog as="div" static className="fixed z-10 inset-0 overflow-y-auto" open={show} onClose={handleClose}>
        <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-gray-500 bg-opacity-75 transition-opacity duration-300 ease-in-out">
                <div className="relative mx-auto py-4 px-8 w-full bg-white rounded-lg shadow-lg modal">
                  <div className="flex justify-center bg-blue-200 -mx-8 -mt-8 mb-1 rounded-md">
                    <img
                      className="content-center w-full rounded-md"
                      src="/sign-out.png"
                      alt="Logout Icon"
                      referrerPolicy="no-referrer"
                      style={{
                        maxWidth: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <h5 className="text-2xl font-medium mb-6 text-center font-bold tracking-tighter">
                    Are You Sure You Want to Sign Out?
                  </h5>
                  <p className="text-gray-700 text-base text-justify tracking-tight">
                    Your login <b>stays active for 90 days</b> when you sign in to S2 Sync, for your convenience.
                  </p>
                  <p className="text-gray-700 text-base text-justify tracking-tight mt-2">
                    Signing out is only recommended when you are on a device that is shared with others.
                  </p>
                  <div className="flex justify-end mt-6">
                    <button
                      type="button"
                      className="w-1/4 justify-center rounded-md border shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 tracking-tight focus:outline-none ml-3"
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        button.primary(),
                        'w-1/4 justify-center rounded-md border shadow-sm px-4 py-2 text-sm font-semibold text-white focus:outline-none ml-3 tracking-tight',
                      )}
                      onClick={onSignOut}
                    >
                      Sign Out
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
