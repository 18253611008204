import { gql } from 'urql';

const ADD_ACTIONS = gql`
  mutation AddActions($objects: [actions_insert_input!]!) {
    insert_actions(objects: $objects) {
      returning {
        id
      }
    }
  }
`;

export default ADD_ACTIONS;
