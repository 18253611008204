import { gql } from 'urql';

const UPDATE_COMPANY_USER = gql`
  mutation UpdateCompanyUsers($_set: company_users_set_input!, $ids: [Int!]!) {
    update_company_users(where: { id: { _in: $ids } }, _set: $_set) {
      affected_rows
    }
  }
`;

export default UPDATE_COMPANY_USER;
