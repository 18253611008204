import { captureException } from '@sentry/nextjs';
import { Dialog, Transition } from '@headlessui/react';
import { XIcon } from '@heroicons/react/solid';
import { Fragment, ReactElement, useRef, useState, useEffect, SetStateAction, Dispatch } from 'react';
import { useMutation } from 'urql';
import { classNames } from 'utils';
import { ErrorToast, SuccessToast, Loader } from 'components';
import button from 'styles/button';
import { ADD_ACTION_FILE, UPDATE_ACTION_FILE, ADD_ACTION } from 'data/actions';
import { useSession } from 'next-auth/react';
import { ErrorToastError } from 'components/Toast/ErrorToast';
import { Action } from 'data/actions/types';
import { useRouter } from 'next/router';
import { FileStatus } from 'types/file-attachment';
import { ADD_OPPORTUNITY, ADD_OPPORTUNITY_FILE, UPDATE_OPPORTUNITY_FILE } from 'data/opportunities';
import { ADD_OBJECTIVE, ADD_OBJECTIVE_FILE, UPDATE_OBJECTIVE_FILE } from 'data/objectives';
import { ADD_USER_PROFILE_IMAGE_FILE, UPDATE_USER_PROFILE_IMAGE_FILE } from 'data/user';

type FileAttachmentDialogProps = {
  entityType: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  refetchFiles?;
  form: Action;
  setForm: Dispatch<SetStateAction<Action>>;
};

export default function FileAttachmentDialog({
  entityType,
  open,
  setOpen,
  refetchFiles,
  form,
  setForm,
}: FileAttachmentDialogProps): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [error, setError] = useState<ErrorToastError>();
  const [validationError, setValidationError] = useState<string>(null);
  const [upload, setUpload] = useState<string>();
  const cancelButtonRef = useRef(null);

  const [file, setFile] = useState<File | null>(null);
  const [, addActionFile] = useMutation(ADD_ACTION_FILE);
  const [, updateActionFile] = useMutation(UPDATE_ACTION_FILE);
  const [uploadingFile, setUploadingFile] = useState<boolean>(false);
  const [, addAction] = useMutation(ADD_ACTION);

  const [, addOpportunity] = useMutation(ADD_OPPORTUNITY);
  const [, addOpportunityFile] = useMutation(ADD_OPPORTUNITY_FILE);
  const [, updateOpportunityFile] = useMutation(UPDATE_OPPORTUNITY_FILE);

  const [, addObjective] = useMutation(ADD_OBJECTIVE);
  const [, addObjectiveFile] = useMutation(ADD_OBJECTIVE_FILE);
  const [, updateObjectiveFile] = useMutation(UPDATE_OBJECTIVE_FILE);

  const [, addUserProfileImageFile] = useMutation(ADD_USER_PROFILE_IMAGE_FILE);
  const [, updateUserProfileImageFile] = useMutation(UPDATE_USER_PROFILE_IMAGE_FILE);

  const MAX_FILE_SIZE = 3 * 1024 * 1024;
  useEffect(() => {
    if (file && file.size > MAX_FILE_SIZE) {
      setValidationError('File size exceeds the maximum allowed size. Maximum file size is 3MB. ');
    } else if (file && file.size < MAX_FILE_SIZE) {
      setValidationError(null);
    }
  }, [file, MAX_FILE_SIZE]);

  // handle drag events
  const [dragActive, setDragActive] = useState(false);
  const inputRef = useRef(null);
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      setFile(e.dataTransfer.files[0]);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          open={open}
          onClose={() => {
            setOpen(false);
            setValidationError(null);
            setFile(null);
          }}
          initialFocus={cancelButtonRef}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="flex items-start px-8">
                  <div className="py-6 w-full">
                    <Dialog.Title as="h3" className="text-md leading-6 font-semibold text-gray-700 mb-4">
                      Add File
                    </Dialog.Title>

                    <form id="form-file-upload" onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
                      <input
                        ref={inputRef}
                        type="file"
                        id="input-file-upload"
                        multiple={true}
                        onChange={handleChange}
                      />
                      <label
                        id="label-file-upload"
                        htmlFor="input-file-upload"
                        className={dragActive ? 'drag-active' : ''}
                      >
                        <div className="flex flex-col space-y-2">
                          <div className="ml-20">
                            <svg
                              className="w-8 h-8 text-gray-500 dark:text-gray-400"
                              aria-hidden="true"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 20 16"
                            >
                              <path
                                stroke="currentColor"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                              />
                            </svg>
                          </div>
                          <span className="text-sm text-gray-500 dark:text-gray-400 font-semibold">
                            Drag and drop file here
                          </span>
                          <span className="text-2xs text-gray-500 font-normal">
                            SVG, PNG, JPG, PDF, CSV or MP4 (MAX. 3Mb)
                          </span>
                          <span className="mt-1 text-sm text-gray-500 font-semibold">or</span>

                          <div>
                            <button
                              className={classNames(
                                button.primary(),
                                'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3 mb-4 mr-1 ',
                              )}
                              onClick={onButtonClick}
                            >
                              Browse file
                            </button>
                          </div>
                        </div>
                      </label>

                      {dragActive && (
                        <div
                          id="drag-file-element"
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>

                    {validationError ? (
                      <div className="mt-2">
                        <span className="ml-1 text-red-600 text-xs font-medium">{validationError}</span>
                      </div>
                    ) : null}
                    {file ? (
                      <div className="ml-1 mt-2 truncate w-96 text-sm text-gray-500 font-semibold">{file.name}</div>
                    ) : (
                      <div className="ml-1 mt-2 text-xs text-gray-500 font-medium">No file selected</div>
                    )}
                  </div>
                </div>

                <button
                  onClick={() => {
                    setOpen(false);
                    setValidationError(null);
                    setFile(null);
                  }}
                >
                  <XIcon className="absolute top-4 cursor-pointer right-4 h-6 w-6" />
                </button>
                <div className="border px-4 py-3 flex justify-end">
                  <button
                    type="button"
                    className="w-auto inline-flex justify-center rounded-md border shadow-sm border-gray-300 px-4 py-2 text-sm font-semibold text-gray-700 focus:outline-none ml-3"
                    onClick={(e) => {
                      e.preventDefault();
                      setOpen(false);
                      setValidationError(null);
                      setFile(null);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className={classNames(
                      button.primary({ isDisabled: !file || validationError !== null }),
                      'w-auto inline-flex justify-center rounded-md px-4 py-2 text-sm font-semibold text-white ml-3',
                    )}
                    onClick={async (event) => {
                      try {
                        event.preventDefault();
                        if (!file || validationError !== null) return;
                        let addEntityMutation = addAction;
                        let addEntityFileMutation = addActionFile;
                        let updateEntityFileMutation = updateActionFile;
                        let activityType = 'actions_activities';
                        let addEntityResponseType = 'insert_actions_one';
                        let addEntityFileResponseType = 'insert_action_files_one';
                        switch (entityType) {
                          case 'opportunity':
                            addEntityMutation = addOpportunity;
                            addEntityFileMutation = addOpportunityFile;
                            updateEntityFileMutation = updateOpportunityFile;
                            activityType = 'opportunities_activities';
                            addEntityResponseType = 'insert_opportunities_one';
                            addEntityFileResponseType = 'insert_opportunity_files_one';
                            break;
                          case 'objective':
                            addEntityMutation = addObjective;
                            addEntityFileMutation = addObjectiveFile;
                            updateEntityFileMutation = updateObjectiveFile;
                            activityType = 'objectives_activities';
                            addEntityResponseType = 'insert_objectives_one';
                            addEntityFileResponseType = 'insert_objective_files_one';
                            break;
                          case 'profile_picture': // This one isnt like above options
                            addEntityMutation = null;
                            addEntityFileMutation = addUserProfileImageFile;
                            updateEntityFileMutation = updateUserProfileImageFile;
                            activityType = '';
                            addEntityResponseType = '';
                            addEntityFileResponseType = 'insert_user_profile_image_files_one';
                            break;
                          default:
                            break;
                        }

                        setUploadingFile(true);
                        // If new action , add action first
                        let entityId = form?.id;
                        if (!form?.id) {
                          const _object = {
                            company_id: Number(router.query.company),
                            teams_id: Number(router.query.team),
                          };
                          _object[activityType] = {
                            data: {
                              activity: 'insert',
                              user_id: session.id,
                              timestamp: Date.now(),
                            },
                          };
                          const { data, error: addError } = await addEntityMutation({
                            object: _object,
                          });

                          if (addError) {
                            setOpen(false);
                            setError({ name: 'Error', message: 'Failed to upload file' });
                            setFile(null);
                            setUploadingFile(false);
                            return;
                          }
                          entityId = data[addEntityResponseType]?.id;
                          setForm({
                            ...form,
                            id: entityId,
                          });
                        }

                        console.log({
                          object: {
                            entity_id: entityId,
                            status: FileStatus.InProgress,
                            file_name: file.name,
                            file_type: file.type,
                          },
                          id: entityId,
                          activity: 'update',
                          timestamp: Date.now(),
                          userId: session.id,
                        });

                        console.log({ entityType });
                        console.log({ addEntityFileResponseType });

                        console.log({ addEntityFileMutation });

                        // Save file info in db
                        const { data, error: addError } = await addEntityFileMutation({
                          object: {
                            entity_id: entityId,
                            status: FileStatus.InProgress,
                            file_name: file.name,
                            file_type: file.type,
                          },
                          id: entityId,
                          activity: 'update',
                          timestamp: Date.now(),
                          userId: session.id,
                        });

                        if (addError) {
                          setOpen(false);
                          console.log({ addError });
                          setError({ name: 'Error', message: 'Failed to upload file' });
                          setFile(null);
                          setUploadingFile(false);
                          return;
                        }

                        // Get s3 secure url
                        const fileId = data[addEntityFileResponseType]?.id;
                        const key = entityType && fileId ? entityType.concat('_').concat(fileId) : null;
                        const reponse = await fetch(`/api/s3/file-upload`, {
                          method: 'POST',
                          headers: {
                            'content-type': 'application/json',
                          },
                          body: JSON.stringify({ key: key, fileType: file.type }),
                        });

                        console.log({ reponse });

                        if (reponse?.status !== 200) {
                          setOpen(false);
                          setError({ name: 'Error', message: 'Failed to upload file' });
                          setFile(null);
                          setUploadingFile(false);
                          return;
                        }

                        // Upload file directly to the s3 using secure url
                        const resData = await reponse.json();
                        const url = resData['s3_url'];
                        const s3Reponse = await fetch(url, {
                          method: 'PUT',
                          headers: {
                            'Content-Type': file.type,
                          },
                          body: file,
                        });

                        console.log({ s3Reponse });
                        let isUploadCompleted = false;
                        if (s3Reponse?.status === 200) {
                          isUploadCompleted = true;
                        } else {
                          captureException(new Error('Failed to upload to s3 - '.concat(s3Reponse?.statusText)));
                        }

                        // Update s3 file info in db
                        const { error: updateError } = await updateEntityFileMutation({
                          fileId: fileId,
                          _set: {
                            url: isUploadCompleted ? url.split('?')[0] : null,
                            status: isUploadCompleted ? FileStatus.Complete : FileStatus.Failed,
                          },
                          id: entityId,
                          activity: 'update',
                          timestamp: Date.now(),
                          userId: session.id,
                        });

                        console.log({ updateError });

                        setUploadingFile(false);
                        refetchFiles({ variables: { entityId } });
                        setOpen(false);
                        isUploadCompleted
                          ? setUpload('File Uploaded')
                          : setError({ name: 'Error', message: 'Failed to upload file' });
                        setFile(null);
                      } catch (e) {
                        captureException(e);
                        setOpen(false);
                        setError({ name: 'Error', message: 'Failed to upload file' });
                        setFile(null);
                        setUploadingFile(false);
                      }
                    }}
                    ref={cancelButtonRef}
                  >
                    {uploadingFile ? (
                      <>
                        <Loader color="text-white" /> <div className="ml-1">Uploading</div>
                      </>
                    ) : (
                      <span>Upload</span>
                    )}
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={upload} setMessage={setUpload} />
    </>
  );
}
