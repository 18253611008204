import { gql } from 'urql';
import { ACTION_FRAGMENT } from './fragments';

const GET_ACTION = gql`
  query GetAction($id: Int!) {
    actions_by_pk(id: $id) {
      ...ActionFragment
    }
  }
  ${ACTION_FRAGMENT}
`;

export default GET_ACTION;
