import TeamCard from 'components/TeamCard';
import { Team } from 'data/teams/types';
import router from 'next/router';
import { ReactElement } from 'react';

type TeamsListProps = {
  teams: Team[];
  companyId: string | number;
  canEdit?: boolean;
  canRemove?: boolean;
  onRemove?: (id: number) => void;
  showMenu?: boolean;
  hrefFn?: ({ teamId, companyId }: { teamId: string | number; companyId: string | number }) => string;
};

export default function TeamsList({
  showMenu,
  canEdit,
  canRemove,
  teams,
  companyId,
  onRemove,
  hrefFn,
}: TeamsListProps): ReactElement {
  const menuItems = [
    {
      label: 'View',
      onClick: (id) => {
        router.push(`/company/${companyId}/settings/teams/${id}`);
      },
    },
  ];

  if (canEdit) {
    menuItems.push({
      label: 'Edit',
      onClick: (id) => {
        router.push(`/company/${companyId}/settings/teams/${id}/edit`);
      },
    });
  }

  if (canRemove) {
    menuItems.push({
      label: 'Delete',
      onClick: (id) => {
        onRemove?.(id);
      },
    });
  }

  return (
    <div className="h-full">
      <div className="align-middle inline-block min-w-full h-full">
        <ul role="list" className="grid grid-cols-1 gap-3 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
          {teams.map((team, i) => {
            let backgroundColor = 'bg-primary';
            switch (i % 4) {
              case 3:
                backgroundColor = 'bg-primary-900';
                break;
              case 2:
                backgroundColor = 'bg-primary-800';
                break;
              case 1:
                backgroundColor = 'bg-primary-700';
                break;
              case 0:
              default:
                break;
            }

            return (
              <li key={team.id}>
                <div
                  style={{ gridTemplateColumns: 'auto minmax(0, 1fr) auto' }}
                  className="col-span-1 shadow-sm rounded-md"
                >
                  <TeamCard
                    team={team}
                    color={backgroundColor}
                    numAvatars={4}
                    menuItems={showMenu ? menuItems : []}
                    hrefFn={hrefFn}
                  />
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

TeamsList.defaultProps = {
  canEdit: false,
};
