import { GET_COMPANY_USER_BY_ID } from 'data/company';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useQuery } from 'urql';

export default function useIsCoach(): boolean {
  const router = useRouter();
  const { data: session } = useSession();

  const [res] = useQuery({
    query: GET_COMPANY_USER_BY_ID,
    variables: {
      company_id: router.query.company,
      user_id: session?.id,
    },
  });

  return res?.data?.company_users?.[0]?.role?.name === 'Coach';
}
