import { gql } from 'urql';
import { MEETING_FRAGMENT } from './fragments';

export default gql`
  mutation UpdateMeeting(
    $_set: meetings_set_input
    $addSections: [meeting_sections_insert_input!]! = []
    $deleteSections: [Int!]! = []
    $addParticipants: [meeting_participants_insert_input!]! = []
    $deleteParticipants: [Int!]! = []
    $sectionsUpdateColumns: [meeting_sections_update_column!]! = []
    $participantsUpdateColumns: [meeting_participants_update_column!]! = []
    $id: Int!
  ) {
    update_meetings_by_pk(pk_columns: { id: $id }, _set: $_set) {
      ...MeetingFragment
    }
    insert_meeting_sections(
      objects: $addSections
      on_conflict: { constraint: meeting_sections_pkey, update_columns: $sectionsUpdateColumns }
    ) {
      affected_rows
    }
    insert_meeting_participants(
      objects: $addParticipants
      on_conflict: { constraint: meeting_participants_pkey, update_columns: $participantsUpdateColumns }
    ) {
      affected_rows
    }

    delete_meeting_sections(where: { id: { _in: $deleteSections } }) {
      affected_rows
    }
    delete_meeting_participants(where: { company_user_id: { _in: $deleteParticipants } }) {
      affected_rows
    }
  }
  ${MEETING_FRAGMENT}
`;
