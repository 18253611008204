import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const MEASURABLE_FRAGMENT = gql`
  fragment MeasurableFragment on measurables {
    id
    company_id
    title
    description
    owner
    goal
    archived
    interval
    type
    conditional
    trend
    user {
      ...UserFragment
    }
    scores {
      id
      value
      date
      updated_at
    }
    teams_id
    min_range
    max_range
    sort_order
  }
  ${USER_FRAGMENT}
`;

const MEASURABLE_FRAGMENT_WITH_FILTER = gql`
  fragment MeasurableFragment on measurables {
    id
    company_id
    title
    description
    owner
    goal
    archived
    interval
    type
    conditional
    trend
    user {
      ...UserFragment
    }
    scores(where: $scoreDateFilter) {
      id
      value
      date
      updated_at
    }
    teams_id
    min_range
    max_range
    sort_order
  }
  ${USER_FRAGMENT}
`;

export { MEASURABLE_FRAGMENT, MEASURABLE_FRAGMENT_WITH_FILTER };
