import { captureException } from '@sentry/nextjs';
import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Avatar, Loader } from 'components';
import { FloatingActionButton, FloatingActionButtons } from 'components/FloatingActionButton';
import { ErrorToast, SuccessToast } from 'components/Toast';
import { UPDATE_MEETINGS } from 'data/meetings';
import { Meeting, MeetingParticipant } from 'data/meetings/types';
import { format } from 'date-fns';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useState, useMemo } from 'react';
import { useMutation } from 'urql';
import { classNames, refreshData, gqlArray } from 'utils';
import { UPDATE_TEAM_MEMBER } from 'data/team_members';

type MeetingsListProps = {
  meetings: Meeting[];
  meetingActions?: {
    label: string;
    action: (meeting: Meeting) => void;
  }[];
  editable?: boolean;
  isArchivedList?: boolean;
  teamMember?: { id?: number; selected_meetings?: number[] };
};

export function getAverage(meetingParticipants: MeetingParticipant[]): number {
  const participants = meetingParticipants ? meetingParticipants.filter(({ rating }) => rating !== null) : [];
  const numParticipants = participants?.length;
  const total = participants.reduce((accum, { rating }) => accum + rating, 0);
  return numParticipants ? Math.round((total / numParticipants) * 100) / 100 : 0;
}

export default function MeetingsList({
  meetings,
  meetingActions,
  editable,
  isArchivedList,
  teamMember,
}: MeetingsListProps): ReactElement {
  const router = useRouter();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  let checkedMeetings = teamMember?.selected_meetings || [];
  const MeetingList = useMemo(() => {
    return meetings.map(({ id }) => id);
  }, [meetings]);
  if (checkedMeetings.length > 0) {
    checkedMeetings = checkedMeetings.filter((id) => MeetingList.includes(id));
  }
  const [selectedMeetings, setSelectedMeetings] = useState<number[]>(checkedMeetings);

  const [, updateMeetings] = useMutation(UPDATE_MEETINGS);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [, updateTeamMember] = useMutation(UPDATE_TEAM_MEMBER);

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  {editable ? (
                    <th scope="col" className="pl-4 pr-4 w-8">
                      <input
                        type="checkbox"
                        checked={selectedMeetings.length === meetings.length}
                        className="rounded cursor-pointer"
                        onChange={async (event) => {
                          let _selectedMeetings = [];
                          if (event.target.checked) {
                            _selectedMeetings = meetings.map(({ id }) => id);
                            setSelectedMeetings(_selectedMeetings);
                          } else {
                            setSelectedMeetings(_selectedMeetings);
                          }
                          // Remember checked meetings
                          if (!isArchivedList) {
                            await updateTeamMember({
                              id: teamMember?.id,
                              set: {
                                selected_meetings: gqlArray(_selectedMeetings),
                              },
                            });
                          }
                        }}
                      ></input>
                    </th>
                  ) : null}
                  <th
                    scope="col"
                    className="pl-4 pr-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th
                    scope="col"
                    className="pl-4 pr-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Score
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Owner
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Date
                  </th>
                  <th scope="col" className="relative px-6 py-3 w-16">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {meetings.map((meeting) => {
                  const { id, title, user, date } = meeting;
                  const average = getAverage(meeting?.meeting_participants);
                  return (
                    <tr key={id}>
                      {editable ? (
                        <td className="pl-4 w-8 py-3">
                          <input
                            type="checkbox"
                            checked={selectedMeetings.includes(id)}
                            className="rounded cursor-pointer"
                            onClick={(event) => {
                              event.stopPropagation();
                            }}
                            onChange={async (event) => {
                              let _selectedMeetings = [];
                              if (event.target.checked) {
                                _selectedMeetings = [...selectedMeetings, id];
                                setSelectedMeetings(_selectedMeetings);
                              } else {
                                _selectedMeetings = selectedMeetings.filter((_id) => _id !== id);
                                setSelectedMeetings(_selectedMeetings);
                              }
                              // Remember checked meetings
                              if (!isArchivedList) {
                                await updateTeamMember({
                                  id: teamMember?.id,
                                  set: {
                                    selected_meetings: gqlArray(_selectedMeetings),
                                  },
                                });
                              }
                            }}
                          ></input>
                        </td>
                      ) : null}
                      <td className="h-12 pl-4 pr-6 whitespace-nowrap">
                        <div className="flex items-center">
                          <div
                            className="truncate w-80 cursor-pointer"
                            onClick={() =>
                              router.push(`/company/${router.query.company}/team/${router.query.team}/meetings/${id}`)
                            }
                          >
                            {title}
                          </div>
                        </div>
                      </td>
                      <td className="h-12 px-6 whitespace-nowrap">
                        <div className="flex items-center">{average}</div>
                      </td>
                      <td className="h-12 px-6 whitespace-nowrap">
                        {user ? (
                          <div className="flex items-center">
                            <Avatar user={user} showName={true} />
                          </div>
                        ) : null}
                      </td>
                      <td className="h-12 px-6 whitespace-nowrap">
                        <div className="flex items-center">{format(new Date(date), 'M/d/yyyy h:mm aa')}</div>
                      </td>
                      <td className="h-12 w-16 whitespace-nowrap text-sm text-gray-500 flex border-l border-gray-200">
                        {meetingActions?.length ? (
                          <Menu as="div" className="flex items-center justify-center w-full text-left">
                            {({ open }) => (
                              <>
                                <div
                                  className="w-full h-full"
                                  onClick={(event) => {
                                    // Prevents redirecting to single page for item when clicking dots to close dropdown
                                    event.stopPropagation();
                                  }}
                                >
                                  <Menu.Button className="flex items-center justify-center h-full w-full">
                                    <span className="sr-only">Open options</span>
                                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    static
                                    className="z-10 origin-top-right absolute right-0 top-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    <div className="py-1">
                                      {meetingActions.map(({ label, action }) => (
                                        <Menu.Item key={`${meeting.id}-${label}`}>
                                          {({ active }) => (
                                            <button
                                              type="button"
                                              className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm w-full text-left',
                                              )}
                                              onClick={async (event) => {
                                                event.stopPropagation();

                                                action?.(meeting);
                                                // Update checked meetings when user archive a checked meeting
                                                if (
                                                  !isArchivedList &&
                                                  label === 'Archive' &&
                                                  selectedMeetings.includes(id)
                                                ) {
                                                  await updateTeamMember({
                                                    id: teamMember?.id,
                                                    set: {
                                                      selected_meetings: gqlArray(
                                                        selectedMeetings.filter((_id) => _id !== id),
                                                      ),
                                                    },
                                                  });
                                                }
                                              }}
                                            >
                                              {label}
                                            </button>
                                          )}
                                        </Menu.Item>
                                      ))}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {selectedMeetings.length ? (
        <FloatingActionButtons hpos="center" vpos="bottom">
          <FloatingActionButton
            onClick={async () => {
              if (archiving) return;

              setArchiving(true);
              const { error: updateError } = await updateMeetings({
                ids: selectedMeetings,
                _set: {
                  archived: isArchivedList ? false : true,
                },
              });

              if (updateError) {
                setError(updateError);
                captureException(updateError);
              } else {
                setSuccess('Meetings '.concat(isArchivedList ? 'Unarchived' : 'Archived'));
                await refreshData(router);
              }

              setSelectedMeetings([]);
              setArchiving(false);
              // Update checked meetings
              if (!isArchivedList) {
                updateTeamMember({
                  id: teamMember?.id,
                  set: {
                    selected_meetings: gqlArray([]),
                  },
                });
              }
            }}
          >
            <div className="flex items-center justify-center w-14">
              {archiving ? <Loader color="text-white" /> : isArchivedList ? 'Unarchive' : 'Archive'}
            </div>
          </FloatingActionButton>
        </FloatingActionButtons>
      ) : null}
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={success} setMessage={setSuccess} />
    </>
  );
}
