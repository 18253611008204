/* eslint-disable */
// @ts-nocheck
import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import dynamic from 'next/dynamic';
import { ComponentType, ReactElement } from 'react';
import { EditorProps } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Editor: ComponentType<EditorProps> = dynamic(() => import('react-draft-wysiwyg').then((mod) => mod.Editor), {
  ssr: false,
});

export default function RichTextViewer({ text }: { text?: string }): ReactElement {
  let contentState;

  if (text) {
    try {
      const richText = JSON.parse(text);
      contentState = convertFromRaw(richText);
    } catch (e) {
      contentState = ContentState.createFromText(text);
    }
  }

  const editorState = contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty();

  return (
    <Editor
      editorState={editorState}
      toolbarClassName="hidden"
      wrapperClassName=""
      editorClassName=""
      readOnly
      toolbar={{
        options: [],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        blockType: {
          inDropdown: false,
          options: ['Blockquote'],
        },
        list: {
          options: ['unordered', 'ordered'],
        },
        link: {
          popupClassName: 'h-auto',
        },
      }}
      localization={{
        locale: 'en',
        translations: {
          'components.controls.blocktype.blockquote': '“”',
        },
      }}
    />
  );
}
