import { ReactElement, ReactNode } from 'react';
import { classNames } from 'utils';

type CardLayoutProps = {
  children: ReactElement | ReactNode;
  className?: string;
};

export default function CardLayout({ className = 'relative', children }: CardLayoutProps): ReactElement {
  return <div className={classNames(className ?? '', 'flex overflow-auto bg-gray-50 h-full')}>{children}</div>;
}
