import { gql } from 'urql';

const UPDATE_USER_ROLE = gql`
  mutation UpdateUserRole($role_id: Int!, $company_id: Int!, $user_id: Int!) {
    update_company_users(
      where: { _and: { company_id: { _eq: $company_id }, user_id: { _eq: $user_id } } }
      _set: { role_id: $role_id }
    ) {
      affected_rows
      returning {
        id
        role_id
      }
    }
  }
`;

export default UPDATE_USER_ROLE;
