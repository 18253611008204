import { gql } from 'urql';

const GET_ROLE = gql`
  query GetRole($where: roles_bool_exp) {
    roles(where: $where) {
      id
    }
  }
`;

export default GET_ROLE;
