import { XIcon } from '@heroicons/react/solid';
import { Loader, RichTextEditor } from 'components';
import { UPDATE_MEETING } from 'data/meetings';
import { Meeting } from 'data/meetings/types';
import { ReactElement, useState, useEffect } from 'react';
import { useMutation } from 'urql';
import { classNames } from 'utils';
import button from 'styles/button';
import { set, useMeetingsDispatch } from 'data/providers/MeetingsProvider';
import { useRouter } from 'next/router';

type MeetingNotesDialogProps = {
  meeting: Meeting;
};

export default function MeetingNotes({ meeting }: MeetingNotesDialogProps): ReactElement {
  const [notes, setNotes] = useState(meeting?.notes);
  const [saving, setSaving] = useState(false);
  const [, updateMeeting] = useMutation(UPDATE_MEETING);

  const router = useRouter();
  const dispatch = useMeetingsDispatch();
  const teamId = router.query.team;

  useEffect(() => {
    let timeout;
    if (meeting.notes !== notes) {
      timeout = setTimeout(async () => {
        const meetingRes = await updateMeeting({ _set: { notes }, id: meeting.id });
        if (!meetingRes.error) {
          const meetingNotes = meetingRes?.data?.update_meetings_by_pk?.notes;
          dispatch(
            set({
              teamId: Number(teamId),
              meeting: {
                ...meeting,
                notes: meetingNotes,
              },
            }),
          );
        }
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [notes, updateMeeting, meeting, dispatch, teamId]);

  return (
    <div className="bg-gray-50 py-1.5 px-6">
      <div className="shadow rounded-md bg-gray-200 p-2">
        <div className="p-2 bg-white shadow rounded-md pl-6">
          <div className="text-sm font-semibold text-gray-700 mt-2 flex justify-between">
            <span>Meeting Notes</span>
            <XIcon
              className="cursor-pointer fill-current text-gray-400 mr-2 h-6 w-6"
              onClick={() => {
                dispatch(
                  set({
                    teamId: Number(teamId),
                    meeting: {
                      ...meeting,
                      display_meeting_note: false,
                    },
                  }),
                );
              }}
            />
          </div>
          <div className="mt-2 h-28 overflow-y-scroll">
            <RichTextEditor
              text={notes}
              onChange={(content) => {
                setNotes(content);
              }}
            />
          </div>

          <div className="flex mt-3 justify-end mr-12">
            <button
              type="button"
              className={classNames(
                button.white(),
                'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-gray-700 ml-3 mb-4',
              )}
              onClick={() => {
                dispatch(
                  set({
                    teamId: Number(teamId),
                    meeting: {
                      ...meeting,
                      display_meeting_note: false,
                    },
                  }),
                );
              }}
            >
              Cancel
            </button>
            {meeting ? (
              <button
                type="button"
                className={classNames(
                  button.primary(),
                  'w-auto inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-sm font-semibold text-white ml-3 mb-4 mr-6',
                )}
                onClick={async () => {
                  setSaving(true);
                  const meetingRes = await updateMeeting({ _set: { notes }, id: meeting.id });
                  if (!meetingRes.error) {
                    const meetingNotes = meetingRes?.data?.update_meetings_by_pk?.notes;
                    dispatch(
                      set({
                        teamId: Number(teamId),
                        meeting: {
                          ...meeting,
                          notes: meetingNotes,
                        },
                      }),
                    );
                  }
                  setSaving(false);
                }}
              >
                {saving ? <Loader color="text-primary" /> : 'Save'}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
