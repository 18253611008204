import { gql } from 'urql';

const GET_INVITES = gql`
  query GetInvites($where: invites_bool_exp!) {
    invites(where: $where) {
      id
      company_id
      role_id
      email
      team_id
    }
  }
`;

export default GET_INVITES;
