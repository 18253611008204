import { gql } from 'urql';

const UPDATE_OPPORTUNITY_FILE = gql`
  mutation UpdateOpportunityFile(
    $fileId: Int!
    $_set: opportunity_files_set_input!
    $id: Int!
    $activity: String
    $timestamp: bigint
    $userId: Int
  ) {
    update_opportunity_files_by_pk(pk_columns: { id: $fileId }, _set: $_set) {
      id
    }

    insert_opportunities_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default UPDATE_OPPORTUNITY_FILE;
