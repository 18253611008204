import { gql } from 'urql';

const USER_FRAGMENT = gql`
  fragment UserFragment on users {
    id
    name
    email
    image
  }
`;

export { USER_FRAGMENT };
