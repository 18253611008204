import { gql } from 'urql';
import { MEETING_FRAGMENT } from './fragments';

export default gql`
  mutation UpdateMeetingTimers(
    $meeting: meetings_set_input
    $meetingId: Int!
    $meetingSectionId: Int
    $meetingSection: meeting_sections_set_input
  ) {
    update_meetings_by_pk(pk_columns: { id: $meetingId }, _set: $meeting) {
      ...MeetingFragment
    }
    update_meeting_sections(where: { id: { _eq: $meetingSectionId } }, _set: $meetingSection) {
      affected_rows
    }
  }
  ${MEETING_FRAGMENT}
`;
