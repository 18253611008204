import { gql } from 'urql';

const GET_COMPANIES = gql`
  query GetCompanies($expired: Boolean) {
    companies(where: { expired: { _eq: $expired } }) {
      id
      name
    }
  }
`;

export default GET_COMPANIES;
