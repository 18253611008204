export default function gqlArray(arr: unknown[], type?: string): string {
  switch (type) {
    case 'string':
      return `{${arr.map((item: string) =>
        item === '' ? '""' : `"${item.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}"`,
      )}}`;
    case 'number':
    default:
      return `{${arr.join(',')}}`;
  }
}
