import { Team } from 'data/teams/types';

export default function hasRoadmapPermission(teams: Team[]): boolean {
  return Boolean(
    teams?.find(
      (team) =>
        team.type === 'leadership' ||
        team.team_permissions.find(
          ({ permission }) => permission.operation === 'read' && permission.table === 'roadmaps',
        ),
    ),
  );
}
