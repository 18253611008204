import { gql } from 'urql';

const DELETE_OPPORTUNITY_FILE = gql`
  mutation DeleteOpportunityFile($fileId: Int!, $id: Int!, $activity: String, $timestamp: bigint, $userId: Int) {
    delete_opportunity_files_by_pk(id: $fileId) {
      id
    }
    insert_opportunities_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default DELETE_OPPORTUNITY_FILE;
