import { gql } from 'urql';

const ADD_OBJECTIVE_FILE = gql`
  mutation AddObjectiveFile(
    $object: objective_files_insert_input!
    $id: Int!
    $activity: String
    $timestamp: bigint
    $userId: Int
  ) {
    insert_objective_files_one(object: $object) {
      id
    }

    insert_objectives_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default ADD_OBJECTIVE_FILE;
