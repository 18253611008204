import { CheckIcon } from '@heroicons/react/solid';
import { Loader, RichTextEditor } from 'components';
import { UPDATE_MEETING } from 'data/meetings';
import { Meeting } from 'data/meetings/types';
import { ReactElement, useEffect, useState } from 'react';
import { useMutation } from 'urql';
import { set, useMeetings } from 'data/providers/MeetingsProvider';
import { useRouter } from 'next/router';
import { isClient } from 'utils';

type MeetingNotesProps = {
  meeting: Meeting;
};

export default function MeetingNotes({ meeting }: MeetingNotesProps): ReactElement {
  const [notes, setNotes] = useState(meeting?.notes);
  const [savingNotes, setSavingNotes] = useState(false);
  const [savedNotes, setSavedNotes] = useState(false);

  const [, updateMeeting] = useMutation(UPDATE_MEETING);
  const router = useRouter();

  const teamId = router.query.team;
  const [meetings, dispatch] = useMeetings();
  let activeMeeting: Meeting;
  if (isClient() && meetings) {
    activeMeeting = meetings?.[Number(teamId)];
  }

  useEffect(() => {
    let timeout;

    if (meeting.notes !== notes) {
      setSavedNotes(false);

      timeout = setTimeout(async () => {
        setSavingNotes(true);

        const meetingRes = await updateMeeting({ _set: { notes }, id: meeting.id });
        if (activeMeeting && activeMeeting?.id === meeting?.id && !meetingRes.error) {
          const meetingNotes = meetingRes?.data?.update_meetings_by_pk?.notes;
          dispatch(
            set({
              teamId: Number(teamId),
              meeting: {
                ...activeMeeting,
                notes: meetingNotes,
              },
            }),
          );
        }

        setSavingNotes(false);
        setSavedNotes(true);
      }, 1000);
    }

    return () => clearTimeout(timeout);
  }, [notes, updateMeeting, meeting.id, meeting.notes, dispatch, activeMeeting, teamId]);

  return (
    <div className="relative">
      <RichTextEditor
        text={notes}
        onChange={(content) => {
          setNotes(content);
        }}
      />
      {savingNotes ? (
        <div className="absolute top-2 right-2">
          <Loader color="text-primary-500" />
        </div>
      ) : null}
      {savedNotes ? (
        <div className="absolute top-2 right-2">
          <CheckIcon className="h-5 w-5 text-green-500" />
        </div>
      ) : null}
    </div>
  );
}
