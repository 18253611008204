import { ReactElement, useState } from 'react';
import { Carousel } from 'components';
import { classNames } from 'utils';
import { QuestionMarkCircleIcon } from '@heroicons/react/outline';
import { Objective } from 'data/objectives/types';
import { Team } from 'data/teams/types';
import { useSession } from 'next-auth/react';
import RoadmapObjectiveDialog from 'components/RoadmapObjectives/RoadmapObjectiveDialog';

const ObectiveStatus = [
  {
    id: '1',
    value: 'on-track',
    team_status_color: 'bg-green-300',
    border_color: 'border-green-500',
    card_status_color: 'bg-roadmap-green',
    label: 'On Track',
  },
  {
    id: '2',
    value: 'off-track',
    team_status_color: 'bg-red-300',
    border_color: 'border-red-500',
    card_status_color: 'bg-roadmap-red',
    label: 'Off Track',
  },
  {
    id: '3',
    value: 'complete',
    team_status_color: 'bg-blue-300',
    border_color: 'border-blue-500',
    card_status_color: 'bg-roadmap-blue',
    label: 'Done',
  },
];

const MAX_WORD_LENGTH = 37;
const TRIM_WORD_LENGTH = 100;

function getTeamObjectives(objectives: Objective[]) {
  return objectives.reduce((result, objective) => {
    (result[objective['teams_id']] = result[objective['teams_id']] || []).push(objective);
    return result;
  }, {});
}

function groupObjectives(objectives: Objective[]) {
  return objectives.reduce((result, objective) => {
    (result[objective['status']] = result[objective['status']] || []).push(objective);
    return result;
  }, {});
}

export default function RoadmapObjectivesList({
  objectives,
  teams,
}: {
  objectives: Objective[];
  teams: Team[];
}): ReactElement {
  const { data: session } = useSession();
  const teamObjectives = getTeamObjectives(objectives);
  const nonObjectivesTeams = teams?.filter((team) => !Object.keys(teamObjectives).includes(team?.id?.toString()));
  const userCompanyTeams = [];
  teams?.map((team) => {
    if (team?.team_members?.find((teamMember) => teamMember['company_user']?.['user_id'] === Number(session.id))) {
      userCompanyTeams.push(team?.id);
    }
  });

  const [objectiveDialogOpen, setObjectiveDialogOpen] = useState(false);
  const [editObjective, setEditObjective] = useState<Objective>();

  return (
    <>
      <div className="flex flex-col w-full space-y-4">
        {Object.keys(teamObjectives)?.map((team) => {
          const teamName = teams?.find((_team) => _team.id === Number(team))?.name;
          const formatedObjectives = groupObjectives(teamObjectives[team]);
          const editable = userCompanyTeams.includes(Number(team));
          return (
            <div key={team}>
              <div className="ml-1 mt-6 text-sm font-semibold">{teamName}</div>
              <div className="flex space-x-8">
                <div className="mx-1 h-28 border border-gray-400 bg-white mt-2 shadow-sm rounded-md">
                  <div className="flex py-7 px-2">
                    {ObectiveStatus.map((_status) => {
                      const status = _status?.value;
                      return (
                        <div
                          key={_status?.id}
                          className={classNames(
                            _status?.team_status_color,
                            _status?.border_color,
                            'flex  h-full mx-1 justify-center items-center border shadow-sm rounded-md font-semibold',
                          )}
                        >
                          <span
                            className={classNames(
                              formatedObjectives[status]?.length?.toString()?.length > 1
                                ? 'w-full  px-3'
                                : 'w-8 px-2.5',
                              'py-2',
                            )}
                          >
                            {formatedObjectives[status] ? formatedObjectives[status]?.length : 0}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="text-xs px-4 py-2 w-carousel-h">
                  <Carousel>
                    {teamObjectives[team]?.map((objective, i) => {
                      const objectiveStatus = ObectiveStatus?.find((_status) => _status.value === objective?.status);
                      const maxWordLength = objective?.title
                        ?.trim()
                        .split(' ')
                        .find((word) => word.length > MAX_WORD_LENGTH);
                      return (
                        <div
                          key={i}
                          className={classNames(
                            editable ? 'cursor-pointer' : '',
                            'h-28 border border-gray-400 bg-white shadow-sm rounded-md',
                          )}
                          onClick={() => {
                            if (!editable) {
                              return false;
                            }
                            setObjectiveDialogOpen(true);
                            setEditObjective(objective);
                          }}
                        >
                          <div
                            className={classNames(
                              objectiveStatus?.card_status_color
                                ? objectiveStatus?.card_status_color
                                : 'bg-roadmap-gray',
                              'flex border rounded-full py-1 ml-2 mt-2 w-24 justify-center items-center font-bold text-white',
                            )}
                          >
                            {objectiveStatus?.label ? (
                              objectiveStatus?.label
                            ) : (
                              <QuestionMarkCircleIcon className="h-5 w-5 mx-2 inline text-gray-500" />
                            )}
                          </div>
                          <div className={classNames(maxWordLength ? 'truncate' : '', 'mx-4 my-2')}>
                            <span className="">
                              {objective?.title?.length > TRIM_WORD_LENGTH
                                ? objective?.title?.slice(0, TRIM_WORD_LENGTH).concat('...')
                                : objective?.title}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </Carousel>
                </div>
              </div>
            </div>
          );
        })}

        {nonObjectivesTeams?.map((team) => {
          return (
            <div key={team?.id}>
              <div className="ml-1 mt-6 text-sm font-semibold">{team?.name}</div>
              <div className="h-24 shadow-sm rounded-md border border-gray-400  bg-white mt-2 text-xs pl-3.5 pt-9 w-64">
                <span>This team has no assigned Objectives</span>
              </div>
            </div>
          );
        })}
      </div>
      <RoadmapObjectiveDialog
        objective={editObjective}
        open={objectiveDialogOpen}
        setOpen={setObjectiveDialogOpen}
        setObjective={setEditObjective}
      />
    </>
  );
}
