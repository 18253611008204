import { TeamMember } from 'data/teams/types';
import { GET_TEAM_MEMBERS } from 'data/team_members';
import { OperationContext, useQuery } from 'urql';

export default function useTeamMembers(
  teamId: string | number,
): [TeamMember[], (opts?: Partial<OperationContext>) => void] {
  const [data, refetch] = useQuery({
    query: GET_TEAM_MEMBERS,
    variables: { team_id: teamId },
  });

  const teamMembers: TeamMember[] = data.data?.team_members || [];

  return [teamMembers, refetch];
}
