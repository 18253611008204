export interface Webhook {
  id: number;
  user_id: number;
  trigger_type: string;
  webhook_url: string;
  created_at: string;
  updated_at: string;
}

export interface ActionRequestBody {
  title: string;
  description: string;
  assignee?: string;
}

const notifyZapier = async <T>(webhooks: Webhook[] = [], payload: T) => {
  const promises = webhooks.map((webhook) => {
    const data = {
      ...payload,
      url: webhook.webhook_url,
    };

    return fetch(`/api/zapier-hook`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) =>
        response.json().then((responseData) => ({
          webhookId: webhook.id,
          status: 'success',
          response: responseData,
        })),
      )
      .catch((error) => ({
        webhookId: webhook.id,
        status: 'error',
        error: error.message,
      }));
  });

  return Promise.all(promises); // This will execute all fetch calls concurrently
};

export default notifyZapier;
