import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid';
import {
  getConditional,
  getInterval,
  getTrend,
  getType,
  MEASURABLE_CONDITIONALS,
  MEASURABLE_INTERVALS,
  MEASURABLE_TRENDS,
  MEASURABLE_TYPES,
} from 'data/measurables';
import { MeasurableAdditionalDetail } from 'data/measurables/types';
import { Dispatch, Fragment, ReactElement, SetStateAction, useState } from 'react';
import button from 'styles/button';
import { classNames } from 'utils';

export default function MeasurableAdditionalInfo({
  measurableAdditionalInfo,
  setMeasurableAdditionalInfo,
  errors,
  setErrors,
}: {
  measurableAdditionalInfo: MeasurableAdditionalDetail;
  setMeasurableAdditionalInfo: Dispatch<SetStateAction<MeasurableAdditionalDetail>>;
  errors: { [key: string]: string };
  setErrors: Dispatch<SetStateAction<{ [key: string]: string }>>;
}): ReactElement {
  const selectedInterval = getInterval(measurableAdditionalInfo.interval);
  const selectedType = getType(measurableAdditionalInfo.type);
  const selectedConditional = getConditional(measurableAdditionalInfo.conditional);
  const selectedTrend = getTrend(measurableAdditionalInfo.trend);

  const [minGoal, setMinGoal] = useState(measurableAdditionalInfo?.min_range);
  const [maxGoal, setMaxGoal] = useState(measurableAdditionalInfo?.max_range);
  return (
    <div>
      <div className="flex relative space-x-2 mt-4 w-full">
        <div className="w-full">
          <Listbox
            value={measurableAdditionalInfo.interval}
            onChange={(value) => {
              setMeasurableAdditionalInfo({
                ...measurableAdditionalInfo,
                interval: value,
              });
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-xs text-gray-700">Interval</Listbox.Label>
                <div className="mt-1">
                  <Listbox.Button
                    className={classNames(
                      errors.interval ? 'border-red-500' : '',
                      button.white(),
                      'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                    )}
                  >
                    {selectedInterval ? (
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">{selectedInterval.label}</span>
                      </span>
                    ) : (
                      <span className="flex items-center text-gray-500">Select an interval</span>
                    )}
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {MEASURABLE_INTERVALS.filter(({ value }) => value !== 'all').map(({ value, label }) => (
                        <Listbox.Option
                          key={value}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-100' : '',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            )
                          }
                          value={value}
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block truncate',
                                  )}
                                >
                                  {label}
                                </span>
                              </div>

                              {selected ? (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>

        <div className="w-full">
          <Listbox
            value={measurableAdditionalInfo.trend}
            onChange={(value) => {
              setMeasurableAdditionalInfo({
                ...measurableAdditionalInfo,
                trend: value,
              });
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-xs text-gray-700">Trend</Listbox.Label>
                <div className="mt-1">
                  <Listbox.Button
                    className={classNames(
                      errors.trend ? 'border-red-500' : '',
                      button.white(),
                      'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                    )}
                  >
                    {selectedTrend ? (
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">{selectedTrend.label}</span>
                      </span>
                    ) : (
                      <span className="flex items-center text-gray-500">Select a trend</span>
                    )}
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {MEASURABLE_TRENDS.map(({ value, label }) => (
                        <Listbox.Option
                          key={value}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-100' : '',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            )
                          }
                          value={value}
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block truncate',
                                  )}
                                >
                                  {label}
                                </span>
                              </div>

                              {selected ? (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className="flex relative space-x-2 mt-4 w-96">
        <div className="w-full">
          <Listbox
            value={measurableAdditionalInfo.type}
            onChange={(value) => {
              setMeasurableAdditionalInfo({
                ...measurableAdditionalInfo,
                type: value,
              });
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-xs text-gray-700">Type</Listbox.Label>
                <div className="mt-1">
                  <Listbox.Button
                    className={classNames(
                      errors.type ? 'border-red-500' : '',
                      button.white(),
                      'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                    )}
                  >
                    {selectedType ? (
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">{selectedType.label}</span>
                      </span>
                    ) : (
                      <span className="flex items-center text-gray-500">Select a type</span>
                    )}
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {MEASURABLE_TYPES.map(({ value, label }) => (
                        <Listbox.Option
                          key={value}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-100' : '',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            )
                          }
                          value={value}
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block truncate',
                                  )}
                                >
                                  {label}
                                </span>
                              </div>

                              {selected ? (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className="w-full">
          <Listbox
            value={measurableAdditionalInfo.conditional}
            onChange={(value) => {
              if (value === 'range') {
                setMeasurableAdditionalInfo({
                  ...measurableAdditionalInfo,
                  conditional: value,
                  goal: null,
                });
                const err =
                  (minGoal || minGoal === 0) && (maxGoal || maxGoal === 0) && minGoal > maxGoal
                    ? 'Max should be greater than min'
                    : undefined;
                setErrors({
                  ...errors,
                  goal: err,
                });
              } else {
                setMeasurableAdditionalInfo({
                  ...measurableAdditionalInfo,
                  conditional: value,
                  min_range: null,
                  max_range: null,
                });
                setMinGoal(null);
                setMaxGoal(null);
                const err =
                  measurableAdditionalInfo?.goal && isNaN(measurableAdditionalInfo?.goal)
                    ? 'Please enter a valid number'
                    : undefined;
                setErrors({
                  ...errors,
                  goal: err,
                });
              }
            }}
          >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-xs text-gray-700">Condition</Listbox.Label>
                <div className="mt-1">
                  <Listbox.Button
                    className={classNames(
                      errors.conditional ? 'border-red-500' : '',
                      button.white(),
                      'relative w-full rounded-md shadow-sm pl-3 pr-7 h-12 text-left cursor-default text-sm',
                    )}
                  >
                    {selectedConditional ? (
                      <span className="flex items-center">
                        <span className="ml-3 block truncate">{selectedConditional.label}</span>
                      </span>
                    ) : (
                      <span className="flex items-center text-gray-500 whitespace-nowrap">Select a condition</span>
                    )}
                    <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                      <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options
                      static
                      className="absolute z-10 mt-1 max-w-xs bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                    >
                      {MEASURABLE_CONDITIONALS.map(({ label, value }) => (
                        <Listbox.Option
                          key={value}
                          className={({ active }) =>
                            classNames(
                              active ? 'bg-gray-100' : '',
                              'cursor-default select-none relative py-2 pl-3 pr-9',
                            )
                          }
                          value={value}
                        >
                          {({ selected }) => (
                            <>
                              <div className="flex items-center">
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'ml-3 block truncate',
                                  )}
                                >
                                  {label}
                                </span>
                              </div>

                              {selected ? (
                                <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                  <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
      </div>
      <div className="mt-4">
        <label htmlFor="title" className="block text-xs text-gray-700">
          Goal
          {errors.goal ? <span className="ml-2 text-red-500">{errors.goal}</span> : null}
        </label>
        {measurableAdditionalInfo?.conditional === 'range' ? (
          <div className="flex mt-1 space-x-2 w-96">
            <div>
              <input
                type="number"
                name="min_range"
                id="min_range"
                className={classNames(
                  errors.goal ? 'border-red-500' : 'focus:ring-primary focus:border-primary',
                  'shadow-sm h-12 block w-full text-sm border-gray-300 rounded-md',
                )}
                defaultValue={measurableAdditionalInfo?.min_range}
                placeholder={'min'}
                onChange={(event) => {
                  const value = parseFloat(event.target.value);
                  setMinGoal(value);
                  if ((value || value === 0) && (maxGoal || maxGoal === 0) && value >= maxGoal) {
                    setErrors({
                      ...errors,
                      goal: 'Max should be greater than min',
                    });
                  } else {
                    setMeasurableAdditionalInfo({
                      ...measurableAdditionalInfo,
                      min_range: value || value === 0 ? value : null,
                      max_range: maxGoal || maxGoal === 0 ? maxGoal : null,
                      goal: null,
                    });
                    setErrors({
                      ...errors,
                      goal: undefined,
                    });
                  }
                }}
              />
            </div>

            <div className="mt-2.5">-</div>
            <div>
              <input
                type="number"
                name="max_range"
                id="max_range"
                className={classNames(
                  errors.goal ? 'border-red-500' : 'focus:ring-primary focus:border-primary',
                  'shadow-sm h-12 block w-full text-sm border-gray-300 rounded-md',
                )}
                defaultValue={measurableAdditionalInfo?.max_range}
                placeholder={'max'}
                onChange={(event) => {
                  const value = parseFloat(event.target.value);
                  setMaxGoal(value);
                  if ((value || value === 0) && (minGoal || minGoal === 0) && minGoal >= value) {
                    setErrors({
                      ...errors,
                      goal: 'Max should be greater than min',
                    });
                  } else {
                    setMeasurableAdditionalInfo({
                      ...measurableAdditionalInfo,
                      max_range: value || value === 0 ? value : null,
                      min_range: minGoal || minGoal === 0 ? minGoal : null,
                      goal: null,
                    });
                    setErrors({
                      ...errors,
                      goal: undefined,
                    });
                  }
                }}
              />
            </div>
          </div>
        ) : (
          <div className="mt-1">
            <input
              type="text"
              name="goal"
              id="goal"
              className={classNames(
                errors.goal ? 'border-red-500' : 'focus:ring-primary focus:border-primary',
                'shadow-sm h-12 block w-full text-sm border-gray-300 rounded-md',
              )}
              defaultValue={measurableAdditionalInfo.goal}
              onChange={(event) => {
                const value = parseFloat(event.target.value);
                if (event.target.value && isNaN(value)) {
                  setErrors({
                    ...errors,
                    goal: 'Please enter a valid number',
                  });
                } else {
                  setMeasurableAdditionalInfo({
                    ...measurableAdditionalInfo,
                    goal: value || value === 0 ? value : null,
                    min_range: null,
                    max_range: null,
                  });
                  setErrors({
                    ...errors,
                    goal: undefined,
                  });
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
