import { gql } from 'urql';

const GET_OBJECTIVE_REMINDER_ACTION = gql`
  query GetReminderActions($due_date: date) {
    companies(where: { expired: { _eq: false }, enabled_email_notifs: { _eq: true } }) {
      id
      name
      enabled_email_notifs
      teams(where: { deleted: { _eq: false } }) {
        id
        name
        team_members {
          company_user {
            user_id
            email_notifs
          }
        }
        objectives(
          where: {
            due: { _eq: $due_date }
            _or: [{ status: { _eq: "on-track" } }, { status: { _eq: "off-track" } }, { status: { _is_null: true } }]
            archived: { _eq: false }
            owner: { _is_null: false }
          }
        ) {
          id
          title
          due
          user {
            id
            name
            email
          }
        }
      }
    }
  }
`;

export default GET_OBJECTIVE_REMINDER_ACTION;
