import { gql } from 'urql';

const DELETE_USER_PROFILE_IMAGE_FILE = gql`
  mutation DeleteUserProfileImageFile($fileId: Int!) {
    delete_user_profile_image_files_by_pk(id: $fileId) {
      id
    }
  }
`;

export default DELETE_USER_PROFILE_IMAGE_FILE;
