import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/outline';
import { Company } from 'data/company/types';
import { NextRouter, useRouter } from 'next/router';
import { Fragment, ReactElement } from 'react';
import { classNames } from 'utils';

type CompanyAction = {
  label: string;
  action: (company: Company) => void;
};

type CompaniesListProps = {
  companies: Company[];
  companyActions?: CompanyAction[];
};

export const COMPANY_ACTIONS = (router: NextRouter): CompanyAction[] => [
  {
    action: (company) => {
      router.push(`/company/${company.id}/settings/profile`);
    },
    label: 'Profile',
  },
  {
    action: (company) => {
      router.push(`/company/${company.id}/settings/users`);
    },
    label: 'Users',
  },
  {
    action: (company) => {
      router.push(`/company/${company.id}/team/${router.query.team}/objectives`);
    },
    label: 'Objectives',
  },
  {
    action: (company) => {
      router.push(`/company/${company.id}/team/${router.query.team}/actions`);
    },
    label: 'Actions',
  },
  {
    action: (company) => {
      router.push(`/company/${company.id}/team/${router.query.team}/opportunities`);
    },
    label: 'Opportunities',
  },
];

export default function CompaniesList({ companies, companyActions }: CompaniesListProps): ReactElement {
  const router = useRouter();

  return (
    <>
      <div className="h-full">
        <div className="align-middle inline-block min-w-full h-full">
          <div className="border-b border-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  >
                    Title
                  </th>
                  <th scope="col" className="relative px-6 py-3 w-16">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                {companies.map((company) => {
                  const { id, name } = company;
                  return (
                    <tr key={id} className="cursor-pointer" onClick={() => router.push(`/company/${company.id}`)}>
                      <td className="h-12 px-6 whitespace-nowrap">
                        <div className="flex items-center">{name}</div>
                      </td>
                      <td className="h-12 w-16 whitespace-nowrap text-sm text-gray-500 flex border-l border-gray-200">
                        {companyActions?.length ? (
                          <Menu as="div" className="flex items-center justify-center w-full text-left">
                            {({ open }) => (
                              <>
                                <div
                                  className="w-full h-full"
                                  onClick={(event) => {
                                    // Prevents redirecting to single page for item when clicking dots to close dropdown
                                    event.stopPropagation();
                                  }}
                                >
                                  <Menu.Button className="flex items-center justify-center h-full w-full">
                                    <span className="sr-only">Open options</span>
                                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                                  </Menu.Button>
                                </div>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Menu.Items
                                    static
                                    className="z-10 origin-top-right absolute right-0 top-10 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                  >
                                    <div className="py-1">
                                      {companyActions.map(({ label, action }) => (
                                        <Menu.Item key={`${company.id}-${label}`}>
                                          {({ active }) => (
                                            <button
                                              type="button"
                                              className={classNames(
                                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                'block px-4 py-2 text-sm w-full text-left',
                                              )}
                                              onClick={async (event) => {
                                                event.stopPropagation();

                                                action?.(company);
                                              }}
                                            >
                                              {label}
                                            </button>
                                          )}
                                        </Menu.Item>
                                      ))}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </>
                            )}
                          </Menu>
                        ) : null}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}
