import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const MEETING_PARTICIPANT_FRAGMENT = gql`
  fragment MeetingParticipantFragment on meeting_participants {
    id
    rating
    meeting_id
    company_user_id
    company_user {
      id
      user_id
      wg_skills
      vops_skills
      user {
        ...UserFragment
      }
    }
    emotion
    core_value_shout_out
    core_value_shout_out_to
    core_value_shout_out_to_other
    connect_highlights
  }
  ${USER_FRAGMENT}
`;

const MEETING_SECTION_FRAGMENT = gql`
  fragment MeetingSectionFragment on meeting_sections {
    id
    order
    completed
    min_duration
    max_duration
    title
    description
    starts
    stops
  }
`;

const MEETING_FRAGMENT = gql`
  fragment MeetingFragment on meetings {
    id
    active_section_id
    company_id
    title
    description
    duration
    owner
    date
    archived
    notes
    completed
    starts
    stops
    deleted
    type
    enable_connect_feature
    user {
      ...UserFragment
    }
    meeting_sections(order_by: { order: asc }) {
      ...MeetingSectionFragment
    }
    meeting_participants(order_by: { id: asc }) {
      ...MeetingParticipantFragment
    }
    teams_id
  }
  ${USER_FRAGMENT}
  ${MEETING_SECTION_FRAGMENT}
  ${MEETING_PARTICIPANT_FRAGMENT}
`;

export { MEETING_FRAGMENT, MEETING_SECTION_FRAGMENT, MEETING_PARTICIPANT_FRAGMENT, USER_FRAGMENT };
