import { Node } from 'types/d3';

export default function collapseNode(node: Node): void {
  // Check if this node has children
  if (node?.children) {
    node._children = node.children;
    node._children.forEach(collapseNode);
    node.children = null;
  }
}
