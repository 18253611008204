import { Team } from 'data/teams/types';
import { useRouter } from 'next/router';
import { ReactElement, useMemo } from 'react';
import { classNames, hasRoadmapPermission } from 'utils';
import getNavigation from './getNavigation';
import Image from 'next/image';
import Tooltip from 'components/Tooltip';

export default function Navigation({
  teams,
  teamId,
  isCollapsed,
}: {
  teams: Team[];
  teamId?: number;
  isCollapsed: boolean;
}): ReactElement {
  const router = useRouter();
  const currentTeam = teams.filter((team) => team?.id === Number(teamId))[0];
  const scoreboardData = useMemo(() => {
    return {
      scoreboard_url: currentTeam?.scoreboard_url,
      scoreboard_url_change: currentTeam?.scoreboard_url_change,
    };
  }, [currentTeam]);
  const navigation = useMemo(() => {
    return getNavigation(router.query.company as string, teamId, hasRoadmapPermission(teams), scoreboardData);
  }, [router.query.company, teamId, teams, scoreboardData]);

  const RenderIconsWithTooltip = ({ children, content, hasTooltip }) => {
    return hasTooltip ? <Tooltip content={content}>{children}</Tooltip> : children;
  };

  return (
    <nav className="px-3 h-full">
      {navigation.map(({ name, route, Icon, newFeature }) => {
        const active = router.asPath.includes(route);
        return (
          <button
            key={name}
            type="button"
            onClick={() =>
              scoreboardData?.scoreboard_url_change && name === 'Scoreboard'
                ? window.open(route, '_blank')
                : router.push(route)
            }
            className={classNames(
              'align-baseline flex items-center justify-center md:justify-start w-full border-2 border-gray-700 px-2 py-2 text-sm rounded-md overflow-hidden cursor-pointer',
              active ? 'bg-gray-800' : 'hover:bg-gray-800 group',
            )}
          >
            <RenderIconsWithTooltip content={name} hasTooltip={isCollapsed}>
              <Icon
                className={classNames(
                  active ? 'text-gray-300' : 'text-gray-400',
                  'h-6 w-6 md:mr-4 group-hover:text-gray-300',
                )}
              />
            </RenderIconsWithTooltip>
            <span
              className={classNames(
                active ? 'text-gray-300' : 'text-gray-400',
                'transition duration-300 ease-in-out gray-400 group-hover:text-gray-300',
                isCollapsed ? 'hidden' : 'hidden md:block',
              )}
            >
              {name}
            </span>
            {newFeature ? (
              <>
                {'  '}
                <Image
                  src="/images/new-icons/new3.png"
                  className="-z-1 ml-3"
                  alt="New Feature"
                  height={30}
                  width={30}
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </>
            ) : null}
          </button>
        );
      })}
    </nav>
  );
}
