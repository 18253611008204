import { gql } from 'urql';

const DELETE_OBJECTIVE_FILE = gql`
  mutation DeleteObjectiveFile($fileId: Int!, $id: Int!, $activity: String, $timestamp: bigint, $userId: Int) {
    delete_objective_files_by_pk(id: $fileId) {
      id
    }
    insert_objectives_activity_one(
      object: { activity: $activity, entity_id: $id, timestamp: $timestamp, user_id: $userId }
    ) {
      id
    }
  }
`;

export default DELETE_OBJECTIVE_FILE;
