/* eslint-disable */
// @ts-nocheck

import { ContentState, convertFromRaw, EditorState } from 'draft-js';
import dynamic from 'next/dynamic';
import { ReactElement, useState } from 'react';
import { EditorProps } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { classNames } from 'utils';

const Editor: any = dynamic(() => import('react-draft-wysiwyg').then((mod) => mod.Editor), {
  ssr: false,
});

export default function RichTextEditor({
  text,
  onChange,
}: {
  text?: string;
  onChange?: (content: string) => void;
}): ReactElement {
  let contentState;

  if (text) {
    try {
      const richText = JSON.parse(text);
      contentState = convertFromRaw(richText);
    } catch (e) {
      contentState = ContentState.createFromText(text);
    }
  }
  const [editorState, setEditorState] = useState(
    contentState ? EditorState.createWithContent(contentState) : EditorState.createEmpty(),
  );
  const [focused, setFocused] = useState(false);
  {
    /* @ts-ignore */
  }
  return (
    <Editor
      editorState={editorState}
      toolbarClassName="border border-gray-300 rounded-md"
      wrapperClassName="border border-gray-300 rounded-md p-2"
      editorClassName={classNames(focused ? 'border-primary' : '', 'border border-gray-300 rounded-md p-2 text-xs')}
      stripPastedStyles={true}
      toolbar={{
        options: ['inline', 'blockType', 'list', 'link'],
        inline: {
          options: ['bold', 'italic', 'underline', 'strikethrough'],
        },
        blockType: {
          inDropdown: false,
          options: ['Blockquote'],
        },
        list: {
          options: ['unordered', 'ordered'],
        },
        link: {
          popupClassName: 'h-auto',
        },
      }}
      onFocus={() => setFocused(true)}
      onBlur={() => setFocused(false)}
      localization={{
        locale: 'en',
        translations: {
          'components.controls.blocktype.blockquote': '“”',
        },
      }}
      onEditorStateChange={setEditorState}
      onContentStateChange={(content) => {
        onChange?.(JSON.stringify(content));
      }}
    />
  );
}
