import { captureException } from '@sentry/nextjs';
import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import { Avatar, ErrorToast, SuccessToast, RichTextEditor, Loader, FileAttachmentDialog } from 'components';
import { useTeamMembers } from 'data/hooks';
import { useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useMemo, useRef, useState, Dispatch, SetStateAction } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation, useQuery } from 'urql';
import { classNames } from 'utils';
import { Objective, ObjectiveStatusValue } from 'data/objectives/types';
import { STATUS_OPTIONS } from 'components/ObjectiveForm/ObjectiveForm';
import { UPDATE_OBJECTIVE, ADD_OBJECTIVE, GET_OBJECTIVE_FILES, DELETE_OBJECTIVE_FILE } from 'data/objectives';
import { Team } from 'data/teams/types';
import { FileAttachment, FileStatus } from 'types/file-attachment';
import Link from 'next/link';

type RoadmapObjectiveDialogProps = {
  objective: Objective;
  open: boolean;
  setOpen: (open: boolean) => void;
  setObjective: Dispatch<SetStateAction<Objective>>;
  userTeams?: Team[];
};

export default function RoadmapObjectiveDialog({
  objective,
  open,
  setOpen,
  setObjective,
  userTeams,
}: RoadmapObjectiveDialogProps): ReactElement {
  const router = useRouter();
  const { data: session } = useSession();
  const [teamMembers] = useTeamMembers(objective?.teams_id);
  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();
  const users = useMemo(() => teamMembers.map(({ company_user }) => company_user), [teamMembers]);
  const cancelButtonRef = useRef(null);
  const selectedOwner = users.find(({ user }) => user.id === objective?.owner)?.user;
  const [, updateObjective] = useMutation(UPDATE_OBJECTIVE);
  const [saving, setSaving] = useState(false);

  const selectedTeam = userTeams?.find((team) => team.id === objective?.teams_id);
  const [, addObjective] = useMutation(ADD_OBJECTIVE);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const [filesResult, refetchFiles] = useQuery({
    query: GET_OBJECTIVE_FILES,
    variables: {
      where: {
        entity_id: { _eq: objective?.id },
        status: { _eq: FileStatus.Complete },
      },
    },
    pause: !objective?.id,
  });
  const objectiveFiles: FileAttachment[] = filesResult.data?.objective_files || [];
  const [fileUploadDailogOpen, setFileUploadDailogOpen] = useState(false);
  const [, deleteObjectiveFile] = useMutation(DELETE_OBJECTIVE_FILE);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={() => {
            setOpen(false);
            setErrors({});
          }}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative w-modal-sm md:w-modal-md">
                {/* Empty div to get rounded corners. Can't put overflow on form because of dropdowns */}
                <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden"></div>
                <form
                  className="align-bottom text-left background-transparent shadow-xl transform transition-all sm:align-middle"
                  onSubmit={async (event) => {
                    event.preventDefault();
                    if (!objective?.teams_id) {
                      setErrors({
                        ...errors,
                        team: 'Please select a team',
                      });
                      return true;
                    }

                    if (!objective?.owner) {
                      setErrors({
                        ...errors,
                        owner: 'Please select an owner',
                      });
                      return true;
                    }
                    setSaving(true);
                    let err: CombinedError;
                    if (objective?.id) {
                      delete objective['user'];
                      delete objective['actions'];
                      delete objective['objective_files'];
                      const { error: editError } = await updateObjective({
                        _set: {
                          ...objective,
                        },
                        id: objective?.id,
                        activity: 'update',
                        timestamp: Date.now(),
                        userId: session.id,
                      });
                      err = editError;
                    } else {
                      const { error: addError } = await addObjective({
                        object: {
                          ...objective,
                          company_id: router.query.company,
                          objectives_activities: {
                            data: {
                              activity: 'insert',
                              user_id: session.id,
                              timestamp: Date.now(),
                            },
                          },
                        },
                      });

                      err = addError;
                    }

                    if (err) {
                      setError(err);
                      setOpen(false);
                      captureException(err);
                    } else {
                      setOpen(false);
                      setUpdated(objective?.id ? 'Objective updated' : 'Objective added');
                    }
                    setErrors({});
                    setSaving(false);
                    router.push(`/company/${router.query.company}/roadmap-objectives`);
                  }}
                >
                  <div className="flex items-center justify-between p-6 w-full border-b border-gray-300">
                    <div className="text-gray-900 flex items-center">
                      <label className="text-md font-semibold text-gray-700 mr-2">
                        {objective?.id ? 'Edit ' : 'Add '}Objective
                      </label>
                    </div>
                    <XIcon
                      className="cursor-pointer fill-current text-gray-400 right-0 h-6 w-6"
                      onClick={() => {
                        setOpen(false);
                        setErrors({});
                      }}
                    />
                  </div>
                  <div
                    className={classNames(objective?.id ? 'max-h-h overflow-y-auto' : '', 'flex flex-col p-6 pt-2 ')}
                  >
                    {/* Display user teams when adding objective*/}
                    {!objective?.id ? (
                      <div className="mt-4">
                        <Listbox
                          value={objective?.teams_id}
                          onChange={(value) => {
                            if (value) {
                              setErrors({
                                ...errors,
                                team: undefined,
                              });
                            }
                            setObjective({
                              ...objective,
                              teams_id: value,
                            });
                          }}
                        >
                          {({ open: _open }) => (
                            <>
                              <Listbox.Label className="block text-xs text-gray-700">
                                Assign Team{' '}
                                {errors.team ? <span className="ml-2 text-red-500">{errors.team}</span> : null}
                              </Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button
                                  className={classNames(
                                    button.white({ withBase: false }),
                                    errors.team ? 'border-red-500' : 'focus:ring-primary focus:border-primary',
                                    'relative w-full h-8 rounded-md shadow-sm pl-3 pr-7 text-left cursor-default text-xs py-1',
                                  )}
                                >
                                  {selectedTeam ? (
                                    <span className="flex items-center">
                                      <span className="ml-3 block truncate">{selectedTeam?.name}</span>
                                    </span>
                                  ) : (
                                    <span className="flex items-center text-gray-500">Select a team</span>
                                  )}
                                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={_open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    {userTeams?.map((team) => (
                                      <Listbox.Option
                                        key={team?.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-default select-none relative py-2 pl-3 pr-9',
                                          )
                                        }
                                        value={team?.id}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <div className="flex items-center">
                                              <span
                                                className={classNames(
                                                  selected ? 'font-semibold' : 'font-normal',
                                                  'ml-3 block truncate text-xs',
                                                )}
                                              >
                                                {team?.name}
                                              </span>
                                            </div>

                                            {selected ? (
                                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                    ) : null}

                    <div className="flex relative space-x-2 mt-4 w-full">
                      <div className="flex-1">
                        <label htmlFor="title" className="block text-xs text-gray-700">
                          Title
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="title"
                            id="title"
                            value={objective?.title}
                            className="w-full h-8 shadow-sm focus:ring-primary focus:border-primary block text-xs border-gray-300 rounded-md"
                            placeholder={`Name the objective`}
                            onChange={(event) => {
                              setObjective({
                                ...objective,
                                title: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <Listbox
                          value={objective?.owner}
                          onChange={(value) => {
                            if (value) {
                              setErrors({
                                ...errors,
                                owner: undefined,
                              });
                            }
                            setObjective({
                              ...objective,
                              owner: value,
                            });
                          }}
                        >
                          {({ open: _open }) => (
                            <>
                              <Listbox.Label className="block text-xs text-gray-700">
                                Owner {errors.owner ? <span className="ml-2 text-red-500">{errors.owner}</span> : null}
                              </Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button
                                  className={classNames(
                                    button.white({ withBase: false }),
                                    errors.owner ? 'border-red-500' : 'focus:ring-primary focus:border-primary',
                                    'relative w-48 h-8 rounded-md shadow-sm pl-3 pr-7 text-left cursor-default text-xs py-1',
                                  )}
                                >
                                  {selectedOwner ? (
                                    <span className="flex items-center">
                                      <Avatar user={selectedOwner} size="s" showName={true} />
                                    </span>
                                  ) : (
                                    <span className="flex items-center text-gray-500">Select a user</span>
                                  )}
                                  <span className="ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={_open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options
                                    static
                                    className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                                  >
                                    {users.map(({ user }) => (
                                      <Listbox.Option
                                        key={user.id}
                                        className={({ active }) =>
                                          classNames(
                                            active ? 'bg-gray-100' : '',
                                            'cursor-default select-none relative py-2 pl-3 pr-9',
                                          )
                                        }
                                        value={user.id}
                                      >
                                        {({ selected }) => (
                                          <>
                                            <div className="flex items-center">
                                              <Avatar user={user} showName={true} />
                                            </div>

                                            {selected ? (
                                              <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                                                <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                              </span>
                                            ) : null}
                                          </>
                                        )}
                                      </Listbox.Option>
                                    ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                      </div>
                    </div>

                    <div className="flex flex-col flex-1 mt-3">
                      <label htmlFor="description" className="block text-xs text-gray-700">
                        Description
                      </label>
                      <div className="mt-1">
                        <RichTextEditor
                          text={objective?.description}
                          onChange={(content) => {
                            setObjective({
                              ...objective,
                              description: content,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col flex-1 mt-3">
                      <label htmlFor="key-results" className="block text-xs text-gray-700">
                        Key Results
                      </label>
                      <div className="mt-1">
                        <RichTextEditor
                          text={objective?.key_results}
                          onChange={(content) => {
                            setObjective({
                              ...objective,
                              key_results: content,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col flex-1 mt-3">
                      <label htmlFor="30-day" className="block text-xs text-gray-700">
                        30-Day Milestones
                      </label>
                      <div className="mt-1">
                        <RichTextEditor
                          text={objective?.column_30_day}
                          onChange={(content) => {
                            setObjective({
                              ...objective,
                              column_30_day: content,
                            });
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex flex-col flex-1 mt-3">
                      <label htmlFor="60-day" className="block text-xs text-gray-700">
                        60-Day Milestones
                      </label>
                      <div className="mt-1">
                        <RichTextEditor
                          text={objective?.column_60_day}
                          onChange={(content) => {
                            setObjective({
                              ...objective,
                              column_60_day: content,
                            });
                          }}
                        />
                      </div>
                    </div>
                    {objective?.id ? (
                      <>
                        <div className="block text-xs text-gray-700 mt-4">File Attachments</div>
                        <div className="mt-1 rounded-md border border-gray-300 px-4 w-full">
                          <div className="flex justify-end mt-2 mb-2">
                            <button
                              type="button"
                              className={classNames(
                                button.primary(),
                                'inline-flex items-center justify-center rounded-md shadow-sm text-sm font-semibold text-white ',
                              )}
                              onClick={() => {
                                setFileUploadDailogOpen(true);
                              }}
                            >
                              Add Files
                            </button>
                          </div>
                          <>
                            <div className="flex text-xs font-semibold text-gray-500 uppercase bg-gray-100 h-8 w-full">
                              <div className="w-5/6 py-2 ml-2"> File Name</div>
                              <div
                                className={classNames(
                                  objectiveFiles?.length > 2 ? '-ml-4' : '',
                                  'flex w-1/6 py-2 justify-center',
                                )}
                              >
                                Delete
                              </div>
                            </div>
                            {objectiveFiles?.length > 0 ? (
                              <div className="max-h-28 overflow-y-auto">
                                <div className="mt-2 text-xs text-blue-500 font-semibold mb-2">
                                  {objectiveFiles
                                    .filter((file) => file.url !== null)
                                    .map((file) => {
                                      return (
                                        <div key={file.id} className="mt-3">
                                          <div className="flex">
                                            <div className="w-5/6 truncate">
                                              <Link href={file.url} className="ml-2" target="_blank">
                                                {file.file_name}
                                              </Link>
                                            </div>
                                            <div className="flex w-1/6 justify-center">
                                              <button
                                                type="button"
                                                onClick={async () => {
                                                  // Delete file
                                                  const objectiveId = objective.id;
                                                  const { error: deleteError } = await deleteObjectiveFile({
                                                    fileId: file.id,
                                                    id: objectiveId,
                                                    activity: 'update',
                                                    timestamp: Date.now(),
                                                    userId: session.id,
                                                  });
                                                  refetchFiles({ variables: { objectiveId } });
                                                  const key = 'objective'.concat('_').concat(file?.id?.toString());
                                                  await fetch(`/api/s3/file-delete`, {
                                                    method: 'POST',
                                                    headers: {
                                                      'content-type': 'application/json',
                                                    },
                                                    body: JSON.stringify({ key: key }),
                                                  });
                                                  if (deleteError) {
                                                    setError(deleteError);
                                                    captureException(deleteError);
                                                  } else {
                                                    setUpdated('File Deleted');
                                                  }
                                                }}
                                              >
                                                <XIcon fill="red" className="h-4 w-4 mb-0.5" />
                                              </button>
                                            </div>
                                          </div>
                                          <div className="mt-2 w-full border-t border-gray-300" />
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            ) : (
                              <div className="flex items-center justify-center mb-6">
                                <span className="mt-4 text-sm font-semibold text-gray-400 items-center">
                                  There are no files at this time
                                </span>
                              </div>
                            )}
                          </>
                        </div>
                      </>
                    ) : null}

                    <div className="flex relative space-x-2 mt-4 w-full">
                      <div className="flex flex-col flex-1">
                        <label htmlFor="location" className="block text-xs text-gray-700">
                          Status
                        </label>
                        <select
                          id="location"
                          name="location"
                          className="mt-1 block w-full pl-3 pr-10 h-9  text-xs border-gray-300 focus:outline-none focus:ring-primary focus:border-primary rounded-md"
                          defaultValue={objective?.status}
                          onChange={(event) => {
                            setObjective({
                              ...objective,
                              status: event.target.value as ObjectiveStatusValue,
                            });
                          }}
                        >
                          {!objective?.status ? <option value={null} disabled selected></option> : null}
                          {STATUS_OPTIONS.map((status) => (
                            <option key={status.value} value={status.value}>
                              {status.label}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="flex flex-col flex-1">
                        <label htmlFor="due-date" className="block text-xs text-gray-700">
                          Due Date
                        </label>
                        <div className="mt-1">
                          <input
                            type="date"
                            name="due-date"
                            id="due-date"
                            className="focus:ring-primary focus:border-primary text-sm block w-full border-gray-300 rounded-md h-9"
                            defaultValue={objective?.due}
                            onChange={(event) => {
                              setObjective({
                                ...objective,
                                due: event.target.value,
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-grey bg-gray-50 overflow-hidden rounded-b-lg px-6 py-3 flex items-center justify-end">
                    <button
                      type="button"
                      className={classNames(
                        button.white(),
                        'w-auto inline-flex items-center justify-center rounded-md shadow-sm text-sm text-gray-700 ml-3',
                      )}
                      onClick={() => {
                        setOpen(false);
                        setErrors({});
                      }}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className={classNames(
                        button.primary(),
                        'w-auto inline-flex items-center justify-center rounded-md text-sm ml-3',
                      )}
                    >
                      <CheckIcon className="h-5 w-5 mr-2" />
                      {saving ? <Loader color="text-primary" /> : 'Save'}
                    </button>
                  </div>
                </form>
              </div>
            </Transition.Child>
          </div>
          <FileAttachmentDialog
            entityType={'objective'}
            open={fileUploadDailogOpen}
            setOpen={setFileUploadDailogOpen}
            refetchFiles={refetchFiles}
            form={objective}
            setForm={setObjective}
          />
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
