import { gql } from 'urql';

const DELETE_INVITE_BY_EMAIL = gql`
  mutation DeleteInvites($where: invites_bool_exp!) {
    delete_invites(where: $where) {
      affected_rows
    }
  }
`;

export default DELETE_INVITE_BY_EMAIL;
