import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const GET_USER_COMPANY_TEAMS = gql`
  query GetUserCompanyTeams($user_id: Int, $company_id: Int) {
    teams(
      where: {
        team_members: { company_user: { user_id: { _eq: $user_id } } }
        company_id: { _eq: $company_id }
        deleted: { _neq: true }
      }
      order_by: { name: asc }
    ) {
      id
      name
      type
      scoreboard_url
      scoreboard_url_change

      company {
        id
        expired
      }

      team_members {
        id
        company_user {
          id
          user_id
          user {
            ...UserFragment
          }
        }
      }

      team_permissions {
        id
        permission {
          id
          operation
          table
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_USER_COMPANY_TEAMS;
