import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const OPPORTUNITY_FRAGMENT = gql`
  fragment OpportunityFragment on opportunities {
    company_id
    description
    id
    assignee
    title
    archived
    type
    priority
    user {
      ...UserFragment
    }
    teams_id
    created_at
    opportunity_comments {
      id
      comment
      user_id
      timestamp
    }
    measurable_id
    actions {
      id
      title
      teams_id
    }
    opportunity_files {
      id
      url
      file_name
      file_type
      status
    }
  }
  ${USER_FRAGMENT}
`;

export { OPPORTUNITY_FRAGMENT };
