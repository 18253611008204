import { gql } from 'urql';

const UPDATE_ACTIONS = gql`
  mutation UpdateActions(
    $_set: actions_set_input!
    $ids: [Int!]!
    $activityObjects: [actions_activity_insert_input!]!
    $activity: String
    $timestamp: bigint
    $userId: Int
  ) {
    update_actions(where: { id: { _in: $ids } }, _set: $_set) {
      affected_rows
    }

    insert_actions_activity(objects: $activityObjects) {
      affected_rows
    }
  }
`;

export default UPDATE_ACTIONS;
