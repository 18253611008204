import { Dialog, Listbox, Transition } from '@headlessui/react';
import { CheckIcon, SelectorIcon, XIcon } from '@heroicons/react/solid';
import { ErrorToast, SuccessToast } from 'components';
import { ADD_ACTION } from 'data/actions';
import { ADD_OBJECTIVE } from 'data/objectives';
import { ADD_OPPORTUNITY } from 'data/opportunities';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useMemo, useRef, useState } from 'react';
import button from 'styles/button';
import { CombinedError, useMutation } from 'urql';
import { classNames, refreshData } from 'utils';
import { EntityType, ENTITY_TYPE_LABELS } from 'components/QuickAddDialog/QuickAddDialog';
import { Action } from 'data/actions/types';
import { Objective } from 'data/objectives/types';
import { Opportunity } from 'data/opportunities/types';

type CopyEntityDialogProps = {
  entityType: EntityType;
  originalEntity: Action | Objective | Opportunity;
  open: boolean;
  setOpen: (open: boolean) => void;
};

export default function CopyEntityDialog({
  entityType,
  originalEntity,
  open,
  setOpen,
}: CopyEntityDialogProps): ReactElement {
  const router = useRouter();

  const [error, setError] = useState<CombinedError>();
  const [updated, setUpdated] = useState<string>();

  const copyOptions: EntityType[] = useMemo(() => {
    return Object.keys(ENTITY_TYPE_LABELS).filter((_entityType) => _entityType !== entityType) as EntityType[];
  }, [entityType]);

  const [entity, setEntity] = useState<EntityType>(copyOptions[0]);

  const [, addAction] = useMutation(ADD_ACTION);
  const [, addObjective] = useMutation(ADD_OBJECTIVE);
  const [, addOpportunity] = useMutation(ADD_OPPORTUNITY);

  const cancelButtonRef = useRef(null);

  const ownerKey = useMemo(() => {
    switch (entityType) {
      case 'action':
      case 'opportunity':
        return 'assignee';
      case 'objective':
      default:
        return 'owner';
    }
  }, [entityType]);

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          static
          className="fixed z-10 inset-0 overflow-y-auto"
          initialFocus={cancelButtonRef}
          open={open}
          onClose={setOpen}
        >
          <div className="flex items-center justify-center h-screen pt-4 px-4 pb-20 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative">
                {/* Empty div to get rounded corners. Can't put overflow on form because of dropdowns */}
                <div className="absolute top-0 left-0 w-full h-full bg-white rounded-lg overflow-hidden"></div>
                <div className="align-bottom text-left background-transparent transform transition-all sm:align-middle">
                  <div className="p-6 flex justify-between items-center border-b border-gray-300">
                    <span className="text-md font-semibold text-gray-700 mr-2">
                      Copy {ENTITY_TYPE_LABELS[entityType]}
                    </span>
                    <XIcon
                      className="cursor-pointer inline fill-current text-gray-400 right-0 ml-12 h-6 w-6"
                      onClick={() => setOpen(false)}
                    />
                  </div>
                  <form
                    onSubmit={async (event) => {
                      event.preventDefault();

                      let newOwnerKey = 'owner';
                      let mutation = addObjective;
                      switch (entity) {
                        case 'action':
                          newOwnerKey = 'assignee';
                          mutation = addAction;
                          break;
                        case 'opportunity':
                          newOwnerKey = 'assignee';
                          mutation = addOpportunity;
                          break;
                        case 'objective':
                        default:
                          break;
                      }

                      const { error: err } = await mutation({
                        object: {
                          title: originalEntity.title,
                          description: originalEntity.description,
                          [newOwnerKey]: originalEntity[ownerKey],
                          company_id: router.query.company,
                          teams_id: router.query.team,
                        },
                      });

                      if (err) {
                        setError(err);
                      } else {
                        setUpdated(`${ENTITY_TYPE_LABELS[entity]} added.`);
                        setEntity(entityType);
                      }

                      setOpen(false);

                      refreshData(router);
                    }}
                  >
                    <div className="flex items-center justify-between p-6 w-full border-b border-gray-300">
                      <div className="w-full text-gray-900">
                        <Listbox value={entity} onChange={setEntity}>
                          <Listbox.Label className="block text-xs text-gray-700">Copy to</Listbox.Label>
                          <div className="relative">
                            <Listbox.Button className="relative border border-gray-300 rounded-md shadow-sm w-full mt-1 pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 text-sm">
                              <span className="truncate">{ENTITY_TYPE_LABELS[entity]}</span>
                              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                              </span>
                            </Listbox.Button>

                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                            >
                              <Listbox.Options className="absolute bg-white z-20 mt-1 shadow-lg max-h-60 w-full rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-sm">
                                {copyOptions.map((_entityType) => (
                                  <Listbox.Option
                                    key={_entityType}
                                    className={({ active }) =>
                                      classNames(
                                        active ? 'text-white bg-primary' : 'text-gray-900',
                                        'flex justify-between cursor-default select-none relative py-2 pl-3',
                                      )
                                    }
                                    value={_entityType}
                                  >
                                    {({ selected, active }) => (
                                      <>
                                        <span
                                          className={classNames(
                                            selected ? 'font-semibold' : 'font-normal',
                                            'block truncate',
                                          )}
                                        >
                                          {ENTITY_TYPE_LABELS[_entityType]}
                                        </span>

                                        {selected ? (
                                          <span
                                            className={classNames(
                                              active ? 'text-white' : 'text-primary',
                                              'inset-y-0 flex items-center pr-3',
                                            )}
                                          >
                                            <CheckIcon className="ml-2 h-5 w-5" aria-hidden="true" />
                                          </span>
                                        ) : null}
                                      </>
                                    )}
                                  </Listbox.Option>
                                ))}
                              </Listbox.Options>
                            </Transition>
                          </div>
                        </Listbox>
                      </div>
                    </div>
                    <div className="bg-gray-50 overflow-hidden rounded-b-lg px-6 py-3 flex items-center justify-end">
                      <button
                        type="button"
                        className={classNames(
                          button.white(),
                          'w-auto inline-flex items-center justify-center rounded-md shadow-sm text-sm text-gray-700 ml-3',
                        )}
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={classNames(
                          button.primary(),
                          'w-auto inline-flex items-center justify-center rounded-md text-sm ml-3',
                        )}
                      >
                        <CheckIcon className="h-5 w-5 mr-2" />
                        Create Copy
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
      <ErrorToast error={error} setError={setError} />
      <SuccessToast message={updated} setMessage={setUpdated} />
    </>
  );
}
