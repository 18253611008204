import { gql } from 'urql';
import { MEASURABLE_FRAGMENT_WITH_FILTER } from './fragments';

const GET_MEASURABLES = gql`
  query GetMeasurables($where: measurables_bool_exp!, $scoreDateFilter: scores_bool_exp = null) {
    measurables(where: $where, order_by: [{ sort_order: asc }, { owner: asc }, { title: asc }]) {
      ...MeasurableFragment
      scores(where: $scoreDateFilter) {
        id
        value
        date
        updated_at
      }
    }
  }
  ${MEASURABLE_FRAGMENT_WITH_FILTER}
`;

export default GET_MEASURABLES;
