import { gql } from 'urql';

const SEND_USER_INVITES = gql`
  mutation SendUserInvites($objects: [invites_insert_input!]!) {
    insert_invites(objects: $objects) {
      returning {
        email
        id
        company {
          name
          image
          expired
        }
      }
    }
  }
`;

export default SEND_USER_INVITES;
