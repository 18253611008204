import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XCircleIcon, XIcon } from '@heroicons/react/solid';
import { ReactElement } from 'react';
import { classNames } from 'utils';
import { Alert as AlertType } from './types';

export default function Alert({ alert, onDismiss }: { alert: AlertType; onDismiss: () => void }): ReactElement {
  let Icon = InformationCircleIcon;
  let iconColor = 'text-primary';
  let titleColor = 'text-primary-800';
  let textColor = 'text-primary-700';
  let linkColor = 'text-primary-800';
  let linkHoverColor = 'hover:text-primary-700';
  let bgColor = 'bg-primary-50';
  switch (alert.type) {
    case 'error':
      Icon = XCircleIcon;
      iconColor = 'text-red-500';
      titleColor = 'text-red-800';
      textColor = 'text-red-700';
      linkColor = 'text-red-800';
      linkHoverColor = 'hover:text-red-700';
      bgColor = 'bg-red-50';
      break;
    case 'warning':
      Icon = ExclamationIcon;
      iconColor = 'text-yellow-500';
      titleColor = 'text-yellow-800';
      textColor = 'text-yellow-700';
      linkColor = 'text-yellow-800';
      linkHoverColor = 'hover:text-yellow-700';
      bgColor = 'bg-yellow-50';
      break;
    case 'success':
      Icon = CheckCircleIcon;
      iconColor = 'text-green-500';
      titleColor = 'text-green-800';
      textColor = 'text-green-700';
      linkColor = 'text-green-800';
      linkHoverColor = 'hover:text-green-700';
      bgColor = 'bg-green-50';
      break;
    case 'info':
    default:
      Icon = InformationCircleIcon;
      iconColor = 'text-primary';
      titleColor = 'text-primary-800';
      textColor = 'text-primary-700';
      linkColor = 'text-primary-800';
      linkHoverColor = 'hover:text-primary-700';
      bgColor = 'bg-primary-50';
      break;
  }
  return (
    <div className={classNames(bgColor, 'rounded-md p-4 pr-10 relative')}>
      <div className="flex flex-row items-center gap-4">
        <div className="flex-shrink-0">
          <Icon className={classNames(iconColor, 'h-5 w-5')} aria-hidden="true" />
        </div>
        <div className="flex flex-col md:flex-1 md:flex-row md:items-end md:justify-between gap-4">
          <div className="flex gap-x-4 items-center">
            <div className="text-sm flex-1 flex flex-col">
              <div className="flex flex-col gap-y-2">
                {alert.title ? <h3 className={classNames(titleColor, 'text-sm font-medium')}>{alert.title}</h3> : null}
                <div className={classNames(textColor, 'text-sm')}>
                  <p>{alert.message}</p>
                </div>
              </div>
            </div>
          </div>
          {alert.action ? (
            <p className="text-sm">
              <button
                onClick={() => alert.action.onClick()}
                className={classNames(linkColor, linkHoverColor, 'whitespace-nowrap font-medium')}
              >
                {alert.action.label} <span aria-hidden="true">&rarr;</span>
              </button>
            </p>
          ) : null}
        </div>
      </div>
      {alert.dismissable ? (
        <p className="text-sm absolute top-2 right-2">
          <button
            type="button"
            className={classNames(iconColor, 'inline-flex rounded-md p-1.5')}
            onClick={() => onDismiss?.()}
          >
            <span className="sr-only">Dismiss</span>
            <XIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </p>
      ) : null}
    </div>
  );
}
