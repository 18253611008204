import { gql } from 'urql';

const GET_USERS_BY_EMAIL = gql`
  query GetUsersByEmail($emails: [String]!) {
    users(where: { email: { _in: $emails } }) {
      id
      email
    }
  }
`;

export default GET_USERS_BY_EMAIL;
