import { gql } from 'urql';

const GET_ACTION_FILES = gql`
  query GetActionFiles($where: action_files_bool_exp!) {
    action_files(where: $where, order_by: [{ updated_at: desc }]) {
      id
      url
      file_name
      status
      file_type
    }
  }
`;

export default GET_ACTION_FILES;
