export default async function syncStripeSeatCount(
  seatsCount: number,
  paidUsersCount: number,
  companyId: string | number | string[],
): Promise<void> {
  if (seatsCount !== paidUsersCount && paidUsersCount > 0) {
    console.log('updating');
    const response = await fetch('/api/billing/update-seats', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        quantity: paidUsersCount,
        companyId: companyId,
      }),
    });

    console.log({ response });
    const resJson = await response.json();

    return resJson;
  }
}
