import { Menu, RadioGroup, Transition } from '@headlessui/react';
import { CheckCircleIcon, CheckIcon, ChevronDownIcon, XIcon } from '@heroicons/react/solid';
import { Avatar } from 'components';
import { CompanyUser } from 'components/UsersList/UsersList';
import UserTeamManagementModal from 'components/UserTeamManagementModal';
import { Team, Permission } from 'data/teams/types';
import { Dispatch, Fragment, ReactElement, SetStateAction, useMemo, useState } from 'react';
import { classNames } from 'utils';

const TEAM_TYPES = [
  {
    type: 'leadership',
    label: 'Leadership',
    description: 'There can be only one Leadership Team. This team can see all data.',
  },
  {
    type: 'standard',
    label: 'Standard',
    description: 'There can be multiple Standard Teams. This team can see some data.',
  },
];

// eslint-disable-next-line
const urlRegex =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

export default function TeamForm({
  users,
  team,
  setTeam,
  teamMembers,
  teams,
  roadmapRead,
  isNewTeam = false,
  urlError,
  setUrlError,
}: {
  users: CompanyUser[];
  team: Team;
  setTeam: Dispatch<SetStateAction<Team>>;
  teamMembers: number[];
  teams: Team[];
  roadmapRead: Permission;
  isNewTeam?: boolean;
  urlError?: boolean;
  setUrlError?;
}): ReactElement {
  const [urlErrorMsg, setUrlErrorMsg] = useState<string>('');
  const [companyUserId, setCompnaytUserId] = useState<number>(null);
  const [teamUserManagmentVisible, setTeamUserManagmentVisibile] = useState<boolean>(false);
  // const [teamWithMeta, setTeamWithMeta] = useState<Team>(team);
  const [userToRemove, setUserToRemove] = useState<CompanyUser>(null);

  function handleUserRemoveEvent(_company_user) {
    if (!isNewTeam) {
      const company_user_id: number = _company_user.id;
      // const team_members = teamWithMeta?.team_members || [];
      // const [selectedUser] = team_members.filter((member) => member?.company_user?.id === company_user_id);

      setCompnaytUserId(company_user_id);

      const usersTeamCount: number = _company_user?.users_team_count?.length;

      if (usersTeamCount < 2) {
        setTeamUserManagmentVisibile(true);
        setUserToRemove(_company_user);
      } else {
        toggleTeamMember(_company_user);
      }
    } else {
      toggleTeamMember(_company_user);
    }
  }

  function removeFromListAfterRestrictions() {
    toggleTeamMember(userToRemove);
  }

  function toggleTeamMember(_company_user) {
    let _teamMembers = [...(team?.team_members || [])];
    if (_teamMembers.map(({ company_user }) => company_user.id).includes(_company_user.id)) {
      _teamMembers = _teamMembers.filter(({ company_user }) => company_user.id !== _company_user.id);
    } else {
      const companyUser = users.find(({ id }) => id === _company_user.id);
      if (companyUser) {
        _teamMembers.push({ company_user: companyUser });
      }
    }
    setTeam({
      ...team,
      team_members: _teamMembers,
    });
  }
  const leadershipTeam = useMemo(() => teams?.find((t) => t.type === 'leadership'), [teams]);

  return (
    <div className="w-full mx-auto p-6">
      <div>
        <div className="flex flex-col mb-2">
          <div className="flex space-x-2 mb-2">
            <div className="flex-1">
              <label htmlFor="name" className="block text-xs text-gray-700 flex-1 mr-6">
                Team Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="name"
                  className="w-full h-8 shadow-sm focus:ring-primary focus:border-primary block text-xs border-gray-300 rounded-md"
                  placeholder="Team Name"
                  value={team?.name || ''}
                  onChange={(event) => {
                    const teamCopy = { ...team };
                    teamCopy.name = event.target.value;
                    setTeam(teamCopy);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex mt-4">
        <div className="flex flex-col flex-1">
          <label htmlFor="participants" className="block text-xs text-gray-700">
            Team Members
          </label>
          <Menu>
            <div className="mt-1">
              <Menu.Button className="w-full rounded-md px-4 py-2 h-12 text-left cursor-default text-sm inline-flex justify-between items-center border border-gray-300 shadow-sm bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-primary-500 ">
                Select Team Members
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <div className="relative z-10">
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  static
                  className="absolute top-0 mt-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  {users?.map((company_user) => (
                    <Menu.Item key={company_user.id}>
                      {({ active }) => (
                        <div
                          className={classNames(
                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                            'cursor-pointer select-none relative py-2 pl-3 pr-9',
                          )}
                          onClick={() => toggleTeamMember(company_user)}
                        >
                          <div className="flex items-center">
                            <Avatar user={company_user.user} showName={true} />
                          </div>

                          {teamMembers.includes(company_user.id) ? (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-4">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </div>
                      )}
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </div>
          </Menu>
        </div>
      </div>
      <div className="w-full flex mt-4 flex-wrap -m-2">
        {team.team_members?.map(({ company_user }) => (
          <div
            key={company_user.id}
            className="m-2 rounded-lg border border-gray-300 bg-white px-3 py-2 shadow-sm flex items-center space-x-3 hover:border-gray-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
          >
            <Avatar user={company_user.user} showName={true} />
            <div className="cursor-pointer" onClick={() => handleUserRemoveEvent(company_user)}>
              <XIcon height="16" width="16" />
            </div>
          </div>
        ))}
      </div>
      <div className="flex-1 mt-4">
        <label className="block text-xs text-gray-700 flex-1 mr-6">Team Type</label>
        <div className="mt-1">
          <RadioGroup
            value={team.type}
            onChange={(value) => {
              let teamPermissions = [];
              if (value === 'standard' && isNewTeam) {
                teamPermissions = [{ permissions_id: roadmapRead?.id, permission: roadmapRead }];
              } else if (value === 'standard' && teams.length === 1) {
                teamPermissions = [{ permissions_id: roadmapRead?.id, permission: roadmapRead }];
              }
              setTeam({
                ...team,
                type: value,
                team_permissions: teamPermissions,
              });
            }}
          >
            <div className="grid grid-cols-1 gap-y-2 sm:grid-cols-2 sm:gap-x-4">
              {TEAM_TYPES.map(({ type, label, description }) => (
                <RadioGroup.Option
                  key={type}
                  value={type}
                  className={({ checked }) =>
                    classNames(
                      checked ? 'border-transparent' : 'border-gray-300',
                      'relative bg-white border rounded-lg shadow-sm p-2 flex cursor-pointer focus:outline-none',
                    )
                  }
                >
                  {({ checked }) => (
                    <>
                      <CheckCircleIcon
                        className={classNames(checked ? 'text-primary' : 'text-gray-300', 'h-5 w-5')}
                        aria-hidden="true"
                      />
                      <div className="flex-1 flex ml-2">
                        <div className="flex flex-col">
                          <RadioGroup.Label as="span" className="block text-sm font-medium text-gray-900">
                            {label}
                          </RadioGroup.Label>
                          <RadioGroup.Description as="span" className="mt-1 flex items-center text-sm text-gray-500">
                            {description}
                          </RadioGroup.Description>
                        </div>
                      </div>
                      <div
                        className={classNames(
                          checked ? 'border-primary-500' : 'border-transparent',
                          'absolute border-2 -inset-px rounded-lg pointer-events-none',
                        )}
                        aria-hidden="true"
                      />
                    </>
                  )}
                </RadioGroup.Option>
              ))}
            </div>
          </RadioGroup>
        </div>
      </div>
      {team.type === 'leadership' && leadershipTeam && leadershipTeam.id !== team.id ? (
        <div className="border-yellow-400 border-2 -inset-px rounded-lg px-4 py-2 shadow-sm mt-2">
          Setting this team to Leadership will convert the existing leadership team {leadershipTeam.name} to a Standard
          team.
        </div>
      ) : null}
      {team.type === 'standard' ? (
        <div className="mt-4">
          <label className="block text-xs text-gray-700 flex-1 mr-6">Access</label>
          <div className="flex items-center mt-1 h-5">
            <input
              id="roadmap"
              name="roadmap"
              type="checkbox"
              className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
              defaultChecked={Boolean(
                team.team_permissions?.find(
                  (p) => p.permission.operation === 'read' && p.permission.table === 'roadmaps',
                ),
              )}
              onChange={(event) => {
                const teamPermissions = [];
                if (event.target.checked) {
                  teamPermissions.push({ permissions_id: roadmapRead?.id, permission: roadmapRead });
                }
                setTeam({
                  ...team,
                  team_permissions: teamPermissions,
                });
              }}
            />
            <div className="ml-3 mt-0.5 text-sm">
              <label htmlFor="comments" className="font-medium text-gray-700">
                S2 Road Map (read-only)
              </label>
            </div>
          </div>
        </div>
      ) : null}

      <div className="my-4">
        <label className="block text-xs text-gray-700 flex-1 mr-6">
          Override Scoreboard
          {urlError ? (
            <span className="ml-2 text-red-600" id="url-error">
              ({urlErrorMsg})
            </span>
          ) : null}
        </label>
        <div className="flex items-center mt-1 h-5">
          <input
            id="scoreboard"
            name="scoreboard"
            type="checkbox"
            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
            checked={team?.scoreboard_url_change}
            onChange={(event) => {
              const teamCopy = { ...team };
              teamCopy.scoreboard_url_change = event.target.checked;
              setTeam(teamCopy);
              if (event.target.checked && (team?.scoreboard_url === null || team?.scoreboard_url === undefined)) {
                setUrlError(true);
                setUrlErrorMsg('URL is required');
              } else {
                setUrlError(false);
                setUrlErrorMsg('');
              }
            }}
          />
          <div className="ml-3 mt-0.5 text-sm w-full">
            {team?.scoreboard_url_change ? (
              <div className="flex flex-col mb-2 mt-4">
                <div className="flex space-x-2 mb-2">
                  <div className="flex-1">
                    <div className="mt-1">
                      <input
                        type="url"
                        name="url"
                        className={classNames(
                          urlError ? 'border-red-600' : '',
                          'w-full h-8 shadow-sm focus:ring-primary focus:border-primary block text-xs border-gray-300 rounded-md',
                        )}
                        placeholder="URL"
                        value={team?.scoreboard_url || ''}
                        onChange={(event) => {
                          const teamCopy = { ...team };
                          teamCopy.scoreboard_url = event.target.value;
                          setTeam(teamCopy);
                          if (urlRegex.test(event.target.value)) {
                            setUrlError(false);
                            setUrlErrorMsg('');
                          } else {
                            setUrlError(true);
                            setUrlErrorMsg('Invalid URL');
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>

      {team.company ? (
        <UserTeamManagementModal
          companyUserId={companyUserId}
          companyId={team.company.id}
          teamId={team.id}
          show={teamUserManagmentVisible}
          popUpCloseFun={setTeamUserManagmentVisibile}
          callback={removeFromListAfterRestrictions}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
