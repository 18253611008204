import React, { createContext, useContext, useEffect, useState, ReactNode, ReactElement, FC } from 'react';
import { useRouter } from 'next/router';

// Define the types
interface RouteHistoryContextType {
  history: string[];
}

const RouteHistoryContext = createContext<RouteHistoryContextType | undefined>(undefined);

export const useRouteHistory = (): string[] => {
  const context = useContext(RouteHistoryContext);
  if (!context) {
    throw new Error('useRouteHistory must be used within a RouteHistoryProvider');
  }
  return context.history;
};

interface RouteHistoryProviderProps {
  children: ReactNode;
}

export const RouteHistoryProvider: FC<RouteHistoryProviderProps> = ({ children }) => {
  const [history, setHistory] = useState<string[]>([]);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setHistory((prevHistory) => [...prevHistory, url]);
    };

    // Listen to route changes
    router.events.on('routeChangeComplete', handleRouteChange);

    // Cleanup event listener on unmount
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  return <RouteHistoryContext.Provider value={{ history }}>{children}</RouteHistoryContext.Provider>;
};
