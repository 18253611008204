import { gql } from 'urql';

const MOVE_ORG = gql`
  mutation MoveOrg($right_sibling: Int, $id: Int!) {
    update_org_by_pk(pk_columns: { id: $id }, _set: { right_sibling: $right_sibling }) {
      id
    }
  }
`;

export default MOVE_ORG;
