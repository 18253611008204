import { captureException } from '@sentry/nextjs';
import isClient from './isClient';

export type TeamData = {
  teamId: string;
  companyId: string;
  expires: number;
};

export default function getTeamData(pathname = ''): TeamData {
  let teamData: TeamData;
  if (isClient()) {
    const localStorageTeam = localStorage.getItem('team');
    if (localStorageTeam) {
      try {
        teamData = JSON.parse(localStorageTeam);

        if (isNaN(teamData?.expires) || teamData?.expires < Date.now()) {
          // Clear the team data if expired
          localStorage.removeItem('team');
          teamData = undefined;
        } else {
          // Update expiration to 30 days from last access (i.e. now)
          localStorage.setItem('team', JSON.stringify({ ...teamData, expires: Date.now() + 30 * 24 * 60 * 60 * 1000 }));
        }
      } catch (e) {
        captureException(e);
      }
    }
  }

  return teamData;
}
