import { gql } from 'urql';

const USER_COMPANIES_QUERY = gql`
  query UserCompaniesQuery($userId: Int) {
    company_users(
      where: { user_id: { _eq: $userId }, leave_company: { _eq: false } }
      order_by: { company: { name: asc } }
    ) {
      id
      leave_company
      company {
        id
        name
        expired
        enabled_wg_profile
        enabled_vops_profile
        enabled_assessments
      }
    }
  }
`;

export default USER_COMPANIES_QUERY;
