import { gql } from 'urql';

const UPDATE_SCORE = gql`
  mutation UpdateScore($_set: scores_set_input!, $id: Int!) {
    update_scores_by_pk(pk_columns: { id: $id }, _set: $_set) {
      id
    }
  }
`;

export default UPDATE_SCORE;
