import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Avatar } from 'components';
import SignOutConfirmationModal from 'components/SignOutConfirmationModal';
import { signOut, useSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { Fragment, ReactElement, useState } from 'react';
import { classNames } from 'utils';

interface UserControlsProps {
  collapsedHanlder: () => void;
}

export default function UserControls({ collapsedHanlder }: UserControlsProps): ReactElement {
  const router = useRouter();
  const { data: session, status } = useSession();
  const [show, setShow] = useState(false);
  return status === 'authenticated' ? (
    <div className="flex items-center md:justify-between h-10">
      <div className="flex-shrink-0 items-center hidden md:flex">
        <div onClick={collapsedHanlder}>
          <Avatar user={session.user} size="l" />
        </div>
      </div>
      <div className="ml-4 mr-2 hidden md:flex flex-1 flex-col truncate">
        <p className="text-sm font-semibold text-gray-200 truncate">{session.user?.name}</p>
        {session.user?.email ? <p className="text-xs text-gray-400 truncate">{session.user?.email}</p> : null}
      </div>
      <Menu as="div" className="flex items-center justify-center relative text-left">
        {({ open }) => (
          <>
            <div className="w-full h-full">
              <Menu.Button className="flex items-center justify-center h-full w-full">
                <span className="sr-only">Open options</span>
                <DotsVerticalIcon className="h-5 w-5 stroke-current text-gray-100 hidden md:block" aria-hidden="true" />
                <div className="flex items-center justify-center md:hidden">
                  <Avatar user={session.user} size="l" />
                </div>
              </Menu.Button>
            </div>

            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="z-10 fixed left-5 bottom-14 sm:absolute md:-left-40 md:bottom-8 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left',
                        )}
                        onClick={async (event) => {
                          event.stopPropagation();

                          router.push('https://form.typeform.com/to/OUHcTTZ7');
                        }}
                      >
                        Submit Feedback
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left',
                        )}
                        onClick={async (event) => {
                          event.stopPropagation();

                          router.push(`/user/${session.id}/profile`);
                        }}
                      >
                        User Settings
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        type="button"
                        className={classNames(
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 text-sm w-full text-left',
                        )}
                        onClick={async (event) => {
                          setShow(true);
                        }}
                      >
                        Sign Out
                      </button>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
      <SignOutConfirmationModal
        show={show}
        handleClose={() => setShow(false)}
        onSignOut={() => {
          signOut({ callbackUrl: '/' });
        }}
      />
    </div>
  ) : null;
}
