import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const GET_ORG_USERS = gql`
  query GetOrgUsers($company_id: Int!) {
    org(where: { company_id: { _eq: $company_id } }) {
      id
      title
      mission
      kpis
      core_functions
      name
      parent
      right_sibling
      org_status
      company_user {
        id
        wg_skills
        vops_skills
        other_assessments
        user {
          ...UserFragment
        }
        company {
          enabled_wg_profile
          enabled_vops_profile
          enabled_assessments
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_ORG_USERS;
