import { ReactElement } from 'react';
import { VOPSSkills } from 'components/UsersList/UsersList';
import { classNames } from 'utils';

function getVOPSAverage(teamMembers) {
  const vopsTeamValues: VOPSSkills = {
    visionary: 0,
    operator: 0,
    processor: 0,
    synergist: 0,
  };
  let numTeamMembers = 0;
  teamMembers.map(({ company_user }) => {
    if (company_user?.vops_skills) {
      Object.entries(company_user?.vops_skills).filter(([key, value]) => {
        vopsTeamValues[key] += Number(value);
      });
      ++numTeamMembers;
    }
  });

  return getAverage(numTeamMembers, vopsTeamValues);
}

function getAverage(numTeamMembers, vopsTeamValues) {
  Object.keys(vopsTeamValues).forEach((skill) => {
    vopsTeamValues[skill] = numTeamMembers !== 0 ? Math.round(Number(vopsTeamValues[skill]) / numTeamMembers) : 0;
  });
  return vopsTeamValues;
}

function getAverageColorCode(average: number) {
  let color = 'bg-vops-red';
  if (average < 120) {
    color = 'bg-vops-orange';
  } else if (average >= 120 && average < 240) {
    color = 'bg-vops-blue';
  } else if (average >= 240 && average < 480) {
    color = 'bg-vops-green';
  }
  return color;
}

export default function VOPSTeamAssessment({ teamMembers }: { teamMembers }): ReactElement {
  const vopsteamAverage = getVOPSAverage(teamMembers);
  return (
    <div className="flex divide-x divide-gray-200 border border-gray-200 w-44">
      {Object.entries(vopsteamAverage).map(([skill, average], i) => {
        const color = getAverageColorCode(Number(average));
        return (
          <div
            key={i}
            className={classNames(
              color,
              'relative flex text-2xs capitalize items-center justify-center font-semibold h-6 w-11 text-white',
            )}
          >
            <span>
              {skill?.charAt(0)} - {average}
            </span>
          </div>
        );
      })}
    </div>
  );
}
