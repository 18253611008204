import { MouseEvent as ReactMouseEvent, ReactElement, ReactNode } from 'react';
import { classNames } from 'utils';

type FloatingActionButtonProps = {
  children?: ReactElement | ReactNode;
  color?: string;
  onClick?: (event: ReactMouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export default function FloatingActionButton({ children, color, onClick }: FloatingActionButtonProps): ReactElement {
  return (
    <div
      onClick={(event) => onClick?.(event)}
      className={classNames(color ?? 'bg-primary', 'text-white text-sm rounded-md px-3 py-1 cursor-pointer')}
    >
      {children}
    </div>
  );
}
