import { ReactElement, ReactNode } from 'react';

type FloatingActionButtonsContainerProps = {
  children?: ReactElement | ReactNode;
};

export default function FloatingActionButtonsContainer({
  children,
}: FloatingActionButtonsContainerProps): ReactElement {
  return <div className="relative h-full overflow-hidden">{children}</div>;
}
