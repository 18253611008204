import { Walkthrough } from 'types/joy-ride';

const ADD_USERS_WALKTHROUGH: Walkthrough = {
  name: 'add-team',
  steps: [
    {
      title: 'Add Users',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Click on the “Add” button to add any new users to your account.
        </div>
      ),
      target: '.add-users-button',
    },
    {
      title: 'Enter Email Addresses',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Type in an email address. You can send multiple invitations at the same time!
        </div>
      ),
      target: '.user-email-input',
      hideBackButton: true,
    },
    {
      title: 'Select User Roles',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Select each user role as either member, admin, or coach. An admin has more permissions than a member. Admins
          have permission to edit users and change billing. Members may only view the content on the teams they are
          assigned.
        </div>
      ),
      target: '.user-role-list',
    },
    {
      title: 'Choose Teams',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Choose which team they should be a part of. Each user needs to be included in at least one team. Once the user
          is added to the team, they will be able to add, edit, comment, and own assignments on that team. They will
          also be able to view the company Road Map and Org Chart.
        </div>
      ),
      target: '.user-team-list',
    },
    {
      title: 'Add Additional Users',
      content: (
        <div style={{ fontWeight: 100, letterSpacing: '-0.04em' }}>Add any additional users to your account here.</div>
      ),
      target: '.add-additional-users',
    },
    {
      title: 'Send Invitation',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          Click “Send Invite.” The email address you provided will then immediately receive an email invitation to join
          your company in S2 Sync. They will need to open the email and click “Accept Invitation.”
        </div>
      ),
      target: '.send-invite',
    },
    {
      title: 'Walkthrough Complete!',
      content: (
        <div
          style={{
            fontWeight: 300,
            letterSpacing: '-0.04em',
            color: '#333',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div>
            Give it a try, and if you have any issues or additional questions, you can always reach us at
            help@systemandsoul.com.
          </div>
        </div>
      ),
      target: '.completion-placeholder', // Targeting the entire body to center the content
      placement: 'center', // Ensure the content is placed at the center
    },
  ],
};

export default ADD_USERS_WALKTHROUGH;
