import { ReactElement } from 'react';

export default function WorkingGenuisIcon({ color, className }: { color?: string; className?: string }): ReactElement {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 39.61 39.67"
      stroke={color}
      className={className ? className : 'w-6 h-6'}
      fill={color}
    >
      <path d="M39.6,22.11c.07-.62-.15-.88-.73-1.05l-2.14-.63c-.87-.26-.87-.26-.91-1.18a.53.53,0,0,0,0-.13l-.61-4.25,2.47-1.62c.59-.38.71-.68.44-1.31a19.86,19.86,0,0,0-2.39-4.11,3.24,3.24,0,0,0-.56-.55.83.83,0,0,0-1-.08c-.91.52-1.85,1-2.64,1.43L27.16,5.4c.2-.93.43-2,.65-3a.81.81,0,0,0-.53-1A19.89,19.89,0,0,0,22.08,0,.87.87,0,0,0,21,.72c-.28.89-.57,1.78-.82,2.67a.46.46,0,0,1-.55.4,5.74,5.74,0,0,0-1.17.06c-1.2.18-2.4.39-3.65.59L13.09,1.78c-.26-.4-.58-.55-.95-.39A20.25,20.25,0,0,0,7.33,4.23a.86.86,0,0,0-.21,1.21c.21.4.44.79.65,1.19.3.55.58,1.1.86,1.65a15.62,15.62,0,0,0-2.91,3.81.48.48,0,0,1-.62.29c-.88-.19-1.77-.37-2.66-.53-.69-.13-1,0-1.22.65-.42,1.33-.78,2.68-1.13,4A4.37,4.37,0,0,0,0,17.79a.85.85,0,0,0,.73.85c.9.24,1.78.55,2.67.81a.37.37,0,0,1,.31.44A7.07,7.07,0,0,0,3.78,21c.18,1.25.39,2.5.59,3.78L1.79,26.48a.82.82,0,0,0-.36,1.22A19.93,19.93,0,0,0,4.1,32.21a.9.9,0,0,0,1.33.26c.84-.45,1.68-.89,2.51-1.35a.33.33,0,0,1,.48.07A16.66,16.66,0,0,0,12.11,34c.2.12.28.21.22.47-.21,1-.39,2-.59,2.92a.8.8,0,0,0,.54,1,19.57,19.57,0,0,0,5.16,1.31.91.91,0,0,0,1.13-.75c.26-.88.51-1.77.79-2.64.05-.15.25-.35.39-.35a17.51,17.51,0,0,0,4.89-.77l.46.67c.46.68.89,1.38,1.37,2a.81.81,0,0,0,1.24.29c.95-.48,1.9-1,2.81-1.51a10.15,10.15,0,0,0,1.7-1.29c.45-.41.43-.72.14-1.25-.44-.81-.88-1.61-1.29-2.43a.48.48,0,0,1,0-.44,19.45,19.45,0,0,0,2.82-3.76.52.52,0,0,1,.4-.18c.93.17,1.86.35,2.79.55.63.14,1,0,1.2-.61.34-1,.67-2.05,1-3.09A15.34,15.34,0,0,0,39.6,22.11ZM16.84,31.35a11.87,11.87,0,1,1,14.41-8.59A11.87,11.87,0,0,1,16.84,31.35Z" />
    </svg>
  );
}
