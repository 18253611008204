import { loadStripe, Stripe as StripeJS } from '@stripe/stripe-js';

let stripe;
async function getStripe(): Promise<StripeJS> {
  if (!stripe) {
    stripe = await loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY);
  }
  return stripe;
}

export { getStripe };
