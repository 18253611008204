import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const GET_COMPANY_USERS_BY_ID = gql`
  query GetCompanyUsersById($user_ids: [Int!]!, $company_id: Int!) {
    company_users(where: { user_id: { _in: $user_ids }, company_id: { _eq: $company_id } }) {
      id
      user {
        ...UserFragment
      }
      role {
        id
        name
      }
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_COMPANY_USERS_BY_ID;
