import { ReactElement, useState, useCallback, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';

const MAX_DISPLAYED = 4;

export default function Carousel({
  displayedItems = MAX_DISPLAYED,
  children,
}: {
  displayedItems?: number;
  children;
}): ReactElement {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [length, setLength] = useState(children?.length);
  useEffect(() => {
    setLength(children?.length);
  }, [children]);

  const next = useCallback(
    (e) => {
      e.stopPropagation();
      if (currentIndex < length - displayedItems) {
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }
    },
    [currentIndex, displayedItems, length],
  );

  const prev = useCallback(
    (e) => {
      e.stopPropagation();
      if (currentIndex > 0) {
        setCurrentIndex((prevIndex) => prevIndex - 1);
      }
    },
    [currentIndex],
  );

  return (
    <div className="carousel-wrapper">
      {currentIndex > 0 && <ChevronLeftIcon onClick={prev} className="z-10 mt-8 w-8 h-8 cursor-pointer" />}
      <div className="carousel-content-wrapper">
        <div
          className={`carousel-content show`}
          style={{ transform: `translateX(-${currentIndex * (100 / displayedItems)}%)` }}
        >
          {children}
        </div>
      </div>
      {currentIndex < length - displayedItems && (
        <ChevronRightIcon onClick={next} className="z-10 mt-8 w-8 h-8 cursor-pointer" />
      )}
    </div>
  );
}
