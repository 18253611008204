import { USER_FRAGMENT } from 'data/user/fragments';
import { gql } from 'urql';

const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers($team_id: Int) {
    team_members(where: { team_id: { _eq: $team_id } }, order_by: { company_user: { user: { name: asc } } }) {
      id
      team {
        id
        name
      }
      company_user {
        id
        user_id
        user {
          ...UserFragment
        }
        wg_skills
        vops_skills
        role {
          id
          name
        }
      }
    }
  }
  ${USER_FRAGMENT}
`;

export default GET_TEAM_MEMBERS;
