import { gql } from 'urql';

const GET_USER_BY_ID = gql`
  query GetUsersByEmail($id: Int!) {
    users(where: { id: { _eq: $id } }) {
      id
      name
      email
      image
    }
  }
`;

export default GET_USER_BY_ID;
