import { Menu, Transition } from '@headlessui/react';
import { DotsVerticalIcon } from '@heroicons/react/solid';
import { Avatar } from 'components';
import { Team } from 'data/teams/types';
import Link from 'next/link';
import { Fragment, ReactElement } from 'react';
import { classNames } from 'utils';
import { WGTeamAssessment, VOPSTeamAssessment } from 'components';

type MenuItem = {
  label: string;
  onClick: (id: number | string) => void;
};

type TeamCardProps = {
  team: Team;
  color?: string;
  numAvatars?: number;
  menuItems?: MenuItem[];
  hrefFn?: ({ teamId, companyId }: { teamId: string | number; companyId: string | number }) => string;
};

function TeamCardContent({ color, team, menuItems, numAvatars, hrefFn }: TeamCardProps): ReactElement {
  const companyId = team.company.id;
  const teamMembers = team.team_members || [];
  const teamMembersWithAvatars = teamMembers.filter((teamMember) => teamMember.company_user?.user?.image);
  const teamMembersWithoutAvatars = teamMembers.filter((teamMember) => !teamMember.company_user?.user?.image);
  const teamMemberCircles = teamMembersWithAvatars.slice(0, numAvatars);

  if (teamMemberCircles.length < numAvatars && teamMembersWithoutAvatars.length) {
    for (let j = 0; j < teamMembersWithoutAvatars.length && teamMemberCircles.length < numAvatars; ++j) {
      teamMemberCircles.push(teamMembersWithoutAvatars[j]);
    }
  }

  const remainingTeamMembersCount = teamMembers.length - teamMemberCircles.length;
  const showMenu = Boolean(menuItems?.length);

  const enabledWGProfile = team?.company?.enabled_wg_profile;
  const enabledVOPSProfile = team?.company?.enabled_vops_profile;
  const enabledAssessments = team?.company?.enabled_assessments;

  return (
    <>
      <div
        className={classNames(
          color,
          'flex-shrink-0 flex flex-wrap items-center justify-center w-16 text-white font-medium rounded-l-md py-3',
        )}
      >
        <span className="text-2xl w-full text-center leading-5">{teamMembers?.length}</span>
        <span className="text-2xs leading-3">Member{teamMembers?.length === 1 ? '' : 's'}</span>
      </div>
      <div
        className={classNames(
          showMenu ? '' : 'border-r rounded-r-md pr-4',
          'flex-1 flex items-center justify-between border-t border-b border-gray-200 bg-white',
        )}
      >
        <div className="w-full flex-1 pl-4 py-2 text-sm">
          <div className="truncate">
            {showMenu ? (
              <Link
                href={hrefFn({ companyId, teamId: team.id })}
                className="text-gray-900 font-medium hover:text-gray-600"
              >
                {team.name}
              </Link>
            ) : (
              team.name
            )}
          </div>
          <div className="flex items-center h-5 mt-1">
            <div className="flex -space-x-1 overflow-hidden">
              {teamMemberCircles.map((teamMember) =>
                teamMember?.company_user ? <Avatar key={teamMember.id} user={teamMember.company_user?.user} /> : null,
              )}
            </div>
            <span className="ml-1 text-md">{remainingTeamMembersCount ? `+${remainingTeamMembersCount}` : ''}</span>
          </div>

          {enabledAssessments ? (
            <div className="mt-3">
              <WGTeamAssessment teamMembers={teamMembers} />
            </div>
          ) : null}
          {enabledAssessments ? (
            <div className="my-2">
              <VOPSTeamAssessment teamMembers={teamMembers} />
            </div>
          ) : null}
        </div>
      </div>
      {showMenu ? (
        <div className="flex items-center border-b border-t border-r border-gray-200 bg-white rounded-r-md px-2">
          <Menu as="div" className="flex items-center justify-center relative text-left">
            {({ open }) => (
              <>
                <div
                  className=""
                  onClick={(event) => {
                    // Prevents redirecting to single page for item when clicking dots to close dropdown
                    event.stopPropagation();
                  }}
                >
                  <Menu.Button className="flex items-center justify-center w-6">
                    <span className="sr-only">Open options</span>
                    <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  show={open}
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items
                    static
                    className="z-10 origin-top-right absolute right-0 top-6 w-auto rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                  >
                    <div className="flex flex-col py-1">
                      {menuItems.map(({ label, onClick }) => (
                        <Menu.Item key={label}>
                          {({ active }) => (
                            <button
                              type="button"
                              className={classNames(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                'px-4 py-2 text-sm text-left',
                              )}
                              onClick={async (event) => {
                                event.stopPropagation();

                                onClick?.(team.id);
                              }}
                            >
                              {label}
                            </button>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </>
            )}
          </Menu>
        </div>
      ) : null}
    </>
  );
}

export default function TeamCard({
  color = 'bg-primary',
  team,
  numAvatars = 4,
  menuItems,
  hrefFn = ({ companyId, teamId }) => `/company/${companyId}/settings/teams/${teamId}`,
}: TeamCardProps): ReactElement {
  const companyId = team.company.id;
  const showMenu = Boolean(menuItems?.length);

  return showMenu ? (
    <div className="flex w-full">
      <TeamCardContent team={team} color={color} numAvatars={numAvatars} menuItems={menuItems} hrefFn={hrefFn} />
    </div>
  ) : (
    <Link href={hrefFn({ companyId, teamId: team.id })} className="flex w-full">
      <TeamCardContent team={team} color={color} numAvatars={numAvatars} menuItems={menuItems} />
    </Link>
  );
}
